import { all, put, call, takeLatest } from 'redux-saga/effects';

import journal from '../../api/journal';
import * as types from './journalActionTypes';
import { getCommonErrorProps } from '../reduxHelpers';

function* getNotes({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const result = yield call(journal.getNotes, payload);
    yield put({ type: types.GET_NOTES_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: types.JOURNAL_REQUEST_ERROR, error });
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getNote({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const result = yield call(journal.getNote, payload);
    yield put({ type: types.GET_NOTE_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: types.JOURNAL_REQUEST_ERROR, error });
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* addNote({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const result = yield call(journal.addNote, payload);
    yield put({ type: types.ADD_NOTE_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: types.JOURNAL_REQUEST_ERROR, error });
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* updateNote({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const result = yield call(journal.updateNote, payload);
    yield put({ type: types.UPDATE_NOTE_SUCCESS, payload, result });
  } catch (error) {
    yield put({ type: types.JOURNAL_REQUEST_ERROR, error });
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* deleteNote({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(journal.deleteNote, payload);
    yield put({ type: types.REMOVE_NOTE_SUCCESS, payload });
  } catch (error) {
    yield put({ type: types.JOURNAL_REQUEST_ERROR, error });
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_NOTES, getNotes),
    yield takeLatest(types.GET_NOTE_REQUEST, getNote),
    yield takeLatest(types.ADD_NOTE, addNote),
    yield takeLatest(types.UPDATE_NOTE, updateNote),
    yield takeLatest(types.REMOVE_NOTE, deleteNote),
  ]);
}
