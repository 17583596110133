import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Breadcrumbs, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import DescriptionIcon from '@material-ui/icons/Description';
import { Pagination } from '@material-ui/lab';

import { useWindowDimension } from '../../hooks';
import {
  clearSingleArticleAction,
  getArticleListCategoryRequest,
  getArticleListRequest,
  getSingleArticleRequest,
  searchArticleListRequest,
} from '../../redux/blog/blogActions';
import ScrollToTop from '../shared/components/ScrollToTop';
import SearchField from '../shared/components/SearchField';

const useStyles = makeStyles((theme) => ({
  content: {
    paddingRight: '60px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
  sidebar: {
    width: '350px',
    backgroundColor: theme.overrides.backgroundResources,
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    paddingBottom: '65px',
    position: 'relative',
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  item: {
    display: 'block',
    marginBottom: '16px',
    borderBottom: `solid 1px ${theme.overrides.itemBorder}`,
    '& div': {
      width: '100%',
      marginBottom: '16px',
    },
    '& p': {
      margin: 0,
      maxWidth: 'calc(100% - 40px)',
      fontSize: '1em',
      fontWeight: '400',
      opacity: '0.8',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  nextArrow: {
    transform: 'rotate(-90deg)',
    fontSize: '1.75em',
    fill: theme.overrides.searchPlaceholder,
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    width: "100%",
    position: 'absolute',
    bottom: '24px',
    left: '50%',
    transform: 'translateX(-50%)',
    '& button': {
      border: '1px solid #CCCCCC',
      borderRadius: 2,
      backgroundColor: 'rgba(242, 242, 242, 0.2)',
    },
  },
  backLink: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '125px',
    height: '36px',
    fontSize: '1em',
    padding: '22px 16px 22px 8px',
    textTransform: 'capitalize',
    border: `solid 1px ${theme.overrides.searchPlaceholder}`,
    borderRadius: '8px',
    '& span': {
      marginLeft: '3px',
    },
  },
  resourcesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden',
    padding: '50px 0 40px',
    margin: '45px 0',
    borderTop: '1px solid #c6c1c1',
    borderBottom: '1px solid #c6c1c1',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      margin: '35px 0',
      padding: '40px 0 35px',
    },
  },
  resourcesTitle: {
    margin: '0 0 20px',
    color: theme.palette.text.primary,
    fontWeight: '700',
    fontSize: '24px',
  },
  resourcesLink: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '25px',
    '& img': {
      marginRight: '15px',
      width: '24px',
    },
  },
  backArrow: {
    transform: 'rotate(90deg)',
    fontSize: '1.75em',
  },
  previewImage: {
    height: 320,
    width: '100%',
    objectFit: 'cover',
  },
  articlesWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '20px',
  },
  articlesTitle: {
    margin: '50px 0 25px',
    color: theme.palette.text.primary,
    fontWeight: '700',
    fontSize: '24px',
  },
  icon: {
    marginRight: '10px',
    fill: theme.palette.text.primary,
    width: '20px',
  },
  articlesLink: {
    width: 'max-content',
    display: 'flex',
    alignItems: 'center',
    border: theme.palette.type === 'dark' ? '1px solid #F2F2F2' : '1px solid rgba(0,0,0, 0.2)',
    borderRadius: '8px',
    padding: '13px 17px',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'center',
    },
    '&:hover': {
      color: '#2A2B2A',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      border: '1px solid rgba(0, 0, 0, 0.2)',
      '& svg': {
        fill: '#2A2B2A',
      },
    },
  },
}));

const FocusPointArticle = ({
  article,
  match,
  getSingleArticle,
  clearSingleArticle,
  gender,
  articleListCategory = [],
  articleListLength = 0,
  articleList = [],
  isDarkTheme,
  getArticleList,
  getArticleListCategory,
  searchArticleList,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { slug } = match.params;

  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const history = useHistory();
  const [width] = useWindowDimension();

  const categoryInit = () => {
    getArticleListCategory({ category: article.category });
  };

  useEffect(() => {
    if (!search) {
      getArticleList({ page: page, page_size: 10 });
    } else {
      searchArticleList({ query: search });
    }
  }, [page, search]);

  useEffect(() => {
    setCount(Math.ceil(articleListLength / 10));
  }, [articleListLength]);

  const searchHandler = (value) => {
    setSearch(value);

    page !== 1 && setPage(1);
  };

  useEffect(() => {
    getSingleArticle({ slug: slug });

    return () => clearSingleArticle();
  }, [slug]);

  useEffect(() => {
    categoryInit();
  }, [article]);

  return (
    <>
      <Helmet>
        <title>Myndful</title>
      </Helmet>
      <Grid container style={{ marginTop: width < 1150 ? '0' : '100px' }}>
        <div className="breadcrumbs-outer">
          <div className="breadcrumbs-wrapper">
            <Breadcrumbs className="breadcrumbs">
              <Link to="/dashboard">{t('m_dashboard')}</Link>
              <Link to="/resources/focus-point-articles">{t('m_articles')}</Link>
              <Link to={`/resources/focus-point-articles/${article.slug}`}>{article.title}</Link>
            </Breadcrumbs>
          </div>
        </div>
        <ScrollToTop />
        <Grid item md={8} xs={12} className={classes.content}>
          <Box mb={4}>
            <Button onClick={history.goBack} className={classes.backLink}>
              <ExpandMore className={classes.backArrow} />
              <span>{t('back')}</span>
            </Button>
          </Box>
          <Box mb={3}>
            <Typography variant="h4">{article.title || ''}</Typography>
          </Box>
          <Box>
            {!!article?.malePreview && !!article?.femalePreview && (
              <img
                className={classes.previewImage}
                src={gender === 'MALE' ? article.malePreview : article.femalePreview}
                alt="Article image"
              />
            )}
          </Box>
          {Boolean(article.text) && (
            <div className="main-form__section">
              <div className="main-form__section-text article__content-text">
                {parse(article.text || '')}
              </div>
            </div>
          )}
        </Grid>
        <Grid item md={4} xs={12}>
          <Box className={classes.sidebar}>
            <Box mb={2}>
              <SearchField value={search} type="dark" onChange={searchHandler} />
            </Box>
            <div>
              {articleList.map((item) => (
                <Link
                  to={`/resources/focus-point-articles/${item.slug}`}
                  key={`article-${item.id}`}
                  className={`${classes.item} article-link`}
                >
                  <Box mb={2} px={3} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography>{item.title}</Typography>
                    <ExpandMore className={classes.nextArrow} />
                  </Box>
                </Link>
              ))}
            </div>
            {count > 1 && (
              <Pagination
                className={classes.pagination}
                siblingCount={0} 
                boundaryCount={1}
                count={count}
                shape="rounded"
                page={page}
                onChange={(evt, data) => setPage(data)}
              />
            )}
          </Box>
        </Grid>
        {article?.resources?.length > 0 ? (
          <>
            <div className={`${classes.resourcesWrapper}`}>
              <Typography variant="h5" className={`${classes.resourcesTitle}`} style={{ display: 'block' }}>
                {t('m_resources')}:{' '}
              </Typography>

              {article?.resources?.map((item, index) => {
                return (
                  <Link key={index} className={classes.resourcesLink} target="_blank" to={{ pathname: `${item.url}` }}>
                    <img src={isDarkTheme ? '/images/icon-link-dark.svg' : '/images/icon-link.svg'} alt="link-icon" />
                    {item.title}
                  </Link>
                );
              })}
            </div>
          </>
        ) : null}

        {articleListCategory.length > 1 ? (
          <div>
            <Typography variant="h5" className={classes.articlesTitle} style={{ display: 'block' }}>
              {`${t('articles_related')}:`}
            </Typography>
            <div className={classes.articlesWrapper}>
              {articleListCategory.map((item, index) => {
                if (item.title === article.title) {
                  return false;
                }

                if (index <= 7) {
                  return (
                    <Link to={`/resources/focus-point-articles/${item.slug}`} key={index} className={classes.articlesLink}>
                      <DescriptionIcon className={classes.icon} />
                      {`${item.title}`}
                    </Link>
                  );
                }
              })}
            </div>
          </div>
        ) : (
          ''
        )}
      </Grid>
    </>
  );
};

FocusPointArticle.propTypes = {
  article: PropTypes.shape({
    category: PropTypes.number,
    femalePreview: PropTypes.string,
    id: PropTypes.number,
    malePreview: PropTypes.string,
    resources: PropTypes.array,
    section: PropTypes.number,
    slug: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  articleListCategory: PropTypes.array,
  articleListLength: PropTypes.number,
  articleList: PropTypes.array,
  gender: PropTypes.string.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  isDarkTheme: PropTypes.bool.isRequired,
  error: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  getArticleList: PropTypes.func.isRequired,
  searchArticleList: PropTypes.func.isRequired,
  getArticleListCategory: PropTypes.func.isRequired,
  getSingleArticle: PropTypes.func.isRequired,
  clearSingleArticle: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    article: state.blog.singleArticle,
    articleListLength: state.blog.articleList.count,
    articleList: state.blog.articleList.results,
    articleListCategory: state.blog.articleListCategory.results,
    isProcessing: state.blog.processing,
    error: state.blog.error || {},
    gender: state.profile.data.sex || 'MALE',
    isDarkTheme: state.general.isDarkTheme,
  }),
  {
    getArticleList: getArticleListRequest,
    getArticleListCategory: getArticleListCategoryRequest,
    searchArticleList: searchArticleListRequest,
    getSingleArticle: getSingleArticleRequest,
    clearSingleArticle: clearSingleArticleAction,
  }
)(FocusPointArticle);
