import { Grid, Hidden, makeStyles, useMediaQuery } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import GetEarlyAccessButton from './GetEarlyAccessButton';
import { useWindowDimension } from '../../hooks';
import { theme } from '../providers/ThemeProvider';
import { CONTAINER_MAX_WIDTH } from '../../constants';
import SubscribeButton from './Subscribe/SubscribeButton';
import { useSelector } from 'react-redux';

const imageCircles = '/images/circles.svg';
const imageSleep = '/images/image-sleep.svg';
const imageSocial = '/images/image-social.svg';
const imageCutlery = '/images/image-cutlery.svg';
const imageExercise = '/images/image-exercise.svg';
const imageCross = '/images/image-cross.svg';
const imageMind = '/images/image-mind.svg';
const imageStatistics = '/images/image-statistics.png';
const landFirstScreen = '/images/landing-first-screen.png';
const landSecondScreen = '/images/landing-second-screen.png';
const imageGuidance = '/images/image-guidance.png';
const imageProgress = '/images/image-progress.png';
const imageHabits = '/images/image-habits.png';
const iconUserManual = '/images/icon-user-manual.svg';
const iconHealth = '/images/icon-mental-health.svg';
const iconLike = '/images/icon-like.svg';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: CONTAINER_MAX_WIDTH,
  },
  hero: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: 16,
    marginTop: 80,
    backgroundImage: 'url("/images/background-top.png")',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.down('xs')]: {
      minHeight: 412,
      backgroundPosition: '65%',
      marginTop: 65,
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: 412,
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 600,
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: 776,
      backgroundImage: 'url("/images/background-top.svg")',
      backgroundSize: '100% 100%',
    },
    '& .hero-container': {
      position: 'relative',
    },
    '& .title': {
      fontSize: '80px',
      color: '#263D79',
      fontWeight: 700,
      textTransform: 'capitalize',
      [theme.breakpoints.down('md')]: {
        fontSize: 40,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 30,
      },
    },
    '& .subtitle': {
      fontSize: '28px',
      maxWidth: '90%',
      margin: '20px 0 44px',
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
        margin: '16px 0',
      },
    },
    '& .icons': {
      display: 'none',
      position: 'absolute',
      right: 40,
      [theme.breakpoints.up('md')]: {
        display: 'initial',
        top: -125,
      },
      [theme.breakpoints.up('lg')]: {
        display: 'initial',
        top: -110,
      },
      [theme.breakpoints.up('xl')]: {
        display: 'initial',
        top: -100,
        right: 40,
      },
    },
    '& .icon-container': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& .icon-block': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#fff',
        boxShadow: '0px 8px 24px rgba(46, 62, 210, 0.15)',
        borderRadius: '16px',
        width: 150,
        height: 150,
        margin: 15,
        [theme.breakpoints.down('lg')]: {
          width: 125,
          height: 125,
        },
      },
    },
  },
  circles: {
    position: 'absolute',
    right: 0,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  benefits: {
    padding: '66px 16px 95px',
    [theme.breakpoints.down('xs')]: {
      padding: '30px 16px 45px',
    },
    '& .benefit': {
      textAlign: 'center',
      fontSize: 18,
      fontWeight: 400,
      lineHeight: '28px',
      '& .title': {
        fontSize: 22,
        fontWeight: 600,
        margin: '16px 0',
      },
    },
  },
  learn: {
    padding: '180px 16px 95px',
    '& .title': {
      fontSize: 54,
      fontWeight: 'bold',
      textTransform: 'capitalize',
      [theme.breakpoints.down('xs')]: {
        fontSize: 24,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 32,
        textAlign: 'center',
      },
    },
    '& .subtitle': {
      marginTop: 24,
      fontSize: 18,
      opacity: 0.8,
    },
    '& img': {
      maxWidth: '100%',
    },
  },
  learnContent: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
      width: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  guidance: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '150px 16px 16px',
    backgroundImage: 'url("/images/background-middle.png")',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: 784,
    [theme.breakpoints.down('xs')]: {
      padding: '50px 16px',
      backgroundPosition: '100% 0%',
      backgroundSize: 'contain',
      background:
        'linear-gradient(transparent, transparent 20%, #FACBA6 20%, #FACBA6 100%), url("/images/background-middle.png")',
      backgroundRepeat: 'no-repeat',
    },
    [theme.breakpoints.up('lg')]: {
      backgroundImage: 'url("/images/background-middle.svg")',
      backgroundSize: '100% 100%',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
    '& .title': {
      fontSize: 54,
      fontWeight: 'bold',
      textTransform: 'capitalize',
      [theme.breakpoints.down('xs')]: {
        fontSize: 24,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 32,
        textAlign: 'center',
      },
    },
    '& .subtitle': {
      fontSize: 18,
      margin: '24px 0 32px',
      opacity: 0.8,
    },
    '& img': {
      maxWidth: '100%',
      padding: '0 85px',
      [theme.breakpoints.down('md')]: {
        padding: '0 20px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '40px 0',
      },
    },
  },
  priority: {
    backgroundColor: '#FBF8F6',
    padding: '105px 16px 100px',
    textAlign: 'center',
    '& .title': {
      fontSize: 54,
      fontWeight: 'bold',
      textTransform: 'capitalize',
      [theme.breakpoints.down('xs')]: {
        fontSize: 24,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 32,
        textAlign: 'center',
      },
    },
    '& .subtitle': {
      fontSize: 18,
      margin: '16px 0 40px',
      opacity: 0.8,
    },
  },
  progress: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '0 16px',
    backgroundColor: '#FBF8F6',
    backgroundImage: 'url("/images/background-bottom.png")',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.down('xs')]: {
      padding: '90px 16px 40px 16px',
      backgroundPosition: '100% 0%',
      backgroundSize: 'contain',
      background:
        'linear-gradient(transparent, transparent 20%, #FACBA6 20%, #FACBA6 100%), url("/images/background-bottom.png")',
      backgroundRepeat: 'no-repeat',
    },
    [theme.breakpoints.up('xs')]: {
      minHeight: 600,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 40,
    },
    [theme.breakpoints.up('md')]: {
      height: 752,
    },
    [theme.breakpoints.up('lg')]: {
      backgroundImage: 'url("/images/background-bottom.svg")',
      backgroundSize: '100% 100%',
    },
    '& .title': {
      fontSize: 54,
      fontWeight: 'bold',
      textTransform: 'capitalize',
      [theme.breakpoints.down('xs')]: {
        fontSize: 24,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 32,
        textAlign: 'center',
      },
    },
    '& .subtitle': {
      fontSize: 18,
      margin: '24px 0 32px',
      opacity: 0.8,
    },
    '& img': {
      maxWidth: '100%',
      padding: '0 85px',
      [theme.breakpoints.down('md')]: {
        padding: '0 20px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '40px 0',
      },
    },
  },
  habits: {
    padding: '80px 16px 145px',
    [theme.breakpoints.down('xs')]: {
      padding: '40px 16px',
      '& .MuiGrid-item': {
        padding: 24,
      },
    },
    '& .title': {
      fontSize: 54,
      fontWeight: 'bold',
      textTransform: 'capitalize',
      [theme.breakpoints.down('xs')]: {
        fontSize: 24,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 32,
        textAlign: 'center',
      },
    },
    '& .subtitle': {
      margin: '24px 0 32px',
      fontSize: 18,
      opacity: 0.8,
    },
    '& img': {
      maxWidth: '100%',
    },
  },
  newsletter: {
    backgroundColor: '#FBF8F6',
    padding: '80px 16px 145px',
    [theme.breakpoints.down('xs')]: {
      padding: '40px 16px',
      '& .MuiGrid-item': {
        padding: 24,
      },
    },
    '& .title': {
      fontSize: 54,
      fontWeight: 'bold',
      textTransform: 'capitalize',
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        fontSize: 24,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 32,
        textAlign: 'center',
      },
    },
    '& .subtitle': {
      margin: '24px 0 32px',
      fontSize: 18,
      opacity: 0.8,
      textAlign: 'center',
    },
    '& .subscribe-button': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  showOnScroll: {
    opacity: 0,
    transform: 'translateY(4em) rotateZ(0deg)',
    transition: 'transform 4s .25s cubic-bezier(0,1,.3,1), opacity .3s .25s ease-out;',
    willChange: 'transform, opacity',
    '&.is-visible': {
      opacity: 1,
      transform: 'rotateZ(0deg)',
    },
  },
  landingBlocks: {
    position: 'relative',
    marginLeft: '60px',
    height: '364px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      height: '280px',
    },
  },
  landingBlock: {
    maxWidth: '400px',
    width: '100%',
    top: 0,
    position: 'absolute',
    '&:nth-child(2)': {
      top: '87px',
      left: '140px',
      [theme.breakpoints.down('xs')]: {
        left: '90px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '300px',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '250px',

    },
  },
}));

const Home = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const isSmallMediaQuery = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' });

      let scroll = window.requestAnimationFrame;
      if (!scroll) {
        scroll = function (callback) {
          window.setTimeout(callback, 1000 / 60);
        };
      }
      const elementsToShow = document.querySelectorAll('.show-on-scroll');

      const isElementInViewport = (el) => {
        const rect = el.getBoundingClientRect();

        return (
          (rect.top <= 0 && rect.bottom >= 0) ||
          (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.top <= (window.innerHeight || document.documentElement.clientHeight)) ||
          (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
        );
      };

      const showElements = () => {
        elementsToShow.forEach(function (element) {
          if (isElementInViewport(element)) {
            element.classList.add('is-visible');
          }
        });

        scroll(showElements);
      };

      showElements();
    }
  }, []);

  const [width] = useWindowDimension();

  return (
    <>
      <Grid item xs={12} className={classes.hero}>
        <img className={classes.circles} src={imageCircles} alt="circles" />
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} className="hero-container">
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} container justify="flex-start" className={classes.container}>
                <Grid item xs={12} md={7} className={`${classes.showOnScroll} show-on-scroll`}>
                  <div className="title">{t('landing_title_0')}</div>
                  <div className="subtitle">{t('landing_text_0')}</div>
                  <GetEarlyAccessButton />
                </Grid>
                <Grid item xs={12} md={5} className={`icons ${classes.showOnScroll} show-on-scroll`}>
                  <Grid container justify="center" className="icons-container">
                    <Grid container item xs={4} alignItems="center" className="icon-container">
                      <div className="icon-block">
                        <img src={imageSleep} alt="" />
                      </div>
                      <div className="icon-block">
                        <img src={imageSocial} alt="" />
                      </div>
                      <div className="icon-block">
                        <img src={imageCutlery} alt="" />
                      </div>
                    </Grid>
                    <Grid container item xs={4} alignItems="center" className="icon-container">
                      <div className="icon-block">
                        <img src={imageExercise} alt="" />
                      </div>
                      <div className="icon-block">
                        <img src={imageCross} alt="" />
                      </div>
                    </Grid>
                    <Grid container item xs={4} alignItems="center" className="icon-container">
                      <div className="icon-block">
                        <img src={imageMind} alt="" />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.benefits}>
        <Grid container justify="center">
          <Grid item xs={12} className={`${classes.container} ${classes.showOnScroll} show-on-scroll`}>
            <Grid style={{ width: width < 1150 && 'calc(100% + 34px)' }} container justify="space-between" spacing={5}>
              <Grid item xs={12} sm={4} className="benefit">
                <img src={iconUserManual} alt="guidance" />
                <div className="title">{t('landing_title_1')}</div>
                <div>{t('landing_text_1')}</div>
              </Grid>
              <Grid item xs={12} sm={4} className="benefit">
                <img src={iconHealth} alt="health" />
                <div className="title">{t('landing_title_2')}</div>
                <div>{t('landing_text_2')}</div>
              </Grid>
              <Grid item xs={12} sm={4} className="benefit">
                <img src={iconLike} alt="confidence" />
                <div className="title">{t('landing_title_3')}</div>
                <div>{t('landing_text_3')}</div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container justify="center" xs={12} className={classes.learn}>
        <Grid item xs={12} className={`${classes.container} ${classes.showOnScroll} show-on-scroll`}>
          <Grid
            style={{ width: width < 1150 && 'calc(100% + 34px)' }}
            container
            justify="space-between"
            alignItems="center"
            spacing={5}
            className={classes.learnContent}
          >
            <Hidden smUp>
              <Grid style={{ width: '100%' }} item xs={12} sm={6}>
                <div className={classes.landingBlocks}>
                  <div className={classes.landingBlock}>
                    <img src={landFirstScreen} alt="land-first" />
                  </div>
                  <div className={classes.landingBlock}>
                    <img src={landSecondScreen} alt="land-second" />
                  </div>
                </div>
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={6}>
              <div className="title">{t('landing_title_4')}</div>
              <div className="subtitle">{t('landing_text_4')}</div>
            </Grid>
            <Hidden smUp>
              <Grid container justify="center" item xs={12} sm={6}>
                <GetEarlyAccessButton />
              </Grid>
            </Hidden>
            <Hidden xsDown>
              <Grid style={{ width: '100%' }} item xs={12} sm={6}>
                <div className={classes.landingBlocks}>
                  <div className={classes.landingBlock}>
                    <img src={landFirstScreen} alt="land-first" />
                  </div>
                  <div className={classes.landingBlock}>
                    <img src={landSecondScreen} alt="land-second" />
                  </div>
                </div>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} container justify="center" className={classes.guidance}>
        <Grid item xs={12} className={`${classes.container} ${classes.showOnScroll} show-on-scroll`}>
          <Grid container justify="space-between" alignItems="center">
            <Grid
              item
              {...(isSmallMediaQuery && { container: true, justify: 'center' })}
              xs={12}
              sm={6}
              className="image-container"
            >
              <img src={imageGuidance} alt="guidance" className="show-on-scroll" />
            </Grid>
            <Grid item {...(isSmallMediaQuery && { container: true, justify: 'center' })} xs={12} sm={6}>
              <div className="title">{t('landing_title_5')}</div>
              <div className="subtitle">{t('landing_text_5')}</div>
              <GetEarlyAccessButton />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.priority}>
        <Grid container justify="center">
          <Grid item xs={12} className={`${classes.container} ${classes.showOnScroll} show-on-scroll`}>
            <Grid container justify="center">
              <Grid item xs={12} sm={6}>
                <div className="title">{t('landing_title_6')}</div>
                <div className="subtitle">{t('landing_text_6')}</div>
                <GetEarlyAccessButton />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container justify="center" xs={12} className={classes.progress}>
        <Grid
          item
          container
          justify="space-between"
          alignItems="center"
          xs={12}
          className={`${classes.container} ${classes.showOnScroll} show-on-scroll`}
        >
          <Hidden smUp>
            <Grid item xs={12} sm={6}>
              <img src={imageProgress} alt="progress" />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={6}>
            <div className="title">{t('landing_title_7')}</div>
            <div className="subtitle">{t('landing_text_7')}</div>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={12} sm={6}>
              <img src={imageProgress} alt="progress" />
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.habits}>
        <Grid container justify="center">
          <Grid item xs={12} className={`${classes.container} ${classes.showOnScroll} show-on-scroll`}>
            <Grid
              style={{ width: width < 1150 && 'calc(100% + 54px)' }}
              container
              justify="space-between"
              alignItems="center"
              spacing={10}
            >
              <Grid item {...(isSmallMediaQuery && { container: true, justify: 'center' })} xs={12} sm={6}>
                <img src={imageHabits} alt="habits" />
              </Grid>
              <Grid item {...(isSmallMediaQuery && { container: true, justify: 'center' })} xs={12} sm={6}>
                <div className="title">{t('landing_title_8')}</div>
                <div className="subtitle">{t('landing_text_8')}</div>
                <GetEarlyAccessButton />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!isAuthenticated ? (
        <Grid item xs={12} className={classes.newsletter}>
          <Grid container justify="center">
            <Grid item xs={12} className={`${classes.container} ${classes.showOnScroll} show-on-scroll`}>
              <Grid container direction="row" justify="center" alignItems="center" spacing={15}>
                <Grid
                  item
                  {...(isSmallMediaQuery && { display: 'flex', container: true, justify: 'center', align: 'center' })}
                  xs={12}
                  sm={6}
                >
                  <div className="title">{t('landing_title_9')}</div>
                  <div className="subtitle">{t('landing_text_9')}</div>
                  <div className="subscribe-button">
                    <SubscribeButton />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default Home;
