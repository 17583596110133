import { Box, Button, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import { clearErrorAction, resetPasswordRequest } from '../../redux/auth/authActions';

const ResetPasswordSchema = yup.object().shape({
  email: yup.string().email('Please provide correct email').required('Email can not be empty'),
});

const ResetPassword = ({ isResetPasswordEmailSent, isProcessing, error, resetPassword, clearError }) => {
  const [email, setEmail] = useState('');

  const { handleSubmit, errors, register } = useForm({
    validationSchema: ResetPasswordSchema,
  });

  if (isResetPasswordEmailSent) {
    return (
      <>
        <Helmet>
          <title>Myndful</title>
        </Helmet>
        <Typography align="center" variant="h5" gutterBottom={true}>
          Password Reset Email Sent
        </Typography>
        <Box mx={3} mb={2}>
          <Typography align="center" variant="body2">
            An email has been sent. Follow the directions in the email to reset your password.
          </Typography>
        </Box>
        <Link to="/auth/sign-in" onClick={clearError}>
          <Button type="button" fullWidth variant="contained" color="primary" className="auth-button">
            Sign In
          </Button>
        </Link>
        <Typography align="center" variant="body2" className="auth-text">
          <Box component="span" m={0.5}>
            Didn’t receive an Email?
          </Box>
          <Link to="#" onClick={clearError}>
            Re-Send Email
          </Link>
        </Typography>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Myndful</title>
      </Helmet>
      <Typography align="center" variant="h5" gutterBottom={true}>
        Reset Your Password
      </Typography>
      <Box mx={3} mb={2}>
        <Typography align="center" variant="body2">
          Confirm your email then we'll send you a link to reset your password.
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(resetPassword)}>
        <Box className="form-input__group">
          <TextField
            variant="filled"
            inputRef={register}
            fullWidth
            defaultValue={email}
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ''}
            id="email"
            placeholder="Email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(evt) => {
              setEmail(evt.target.value);
              error.email && clearError();
            }}
          />
        </Box>
        <Button
          type="submit"
          disabled={isProcessing}
          fullWidth
          variant="contained"
          color="primary"
          className="auth-button"
        >
          Reset my password
        </Button>
      </form>
      <Typography align="center" variant="body2" className="auth-text">
        <Link to="/auth/sign-in">Go Back</Link>
      </Typography>
    </>
  );
};

ResetPassword.propTypes = {
  isResetPasswordEmailSent: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  error: PropTypes.object,
  resetPassword: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isResetPasswordEmailSent: state.auth.isResetPasswordEmailSent,
    isProcessing: state.auth.processing,
    error: state.auth.error || {},
  }),
  {
    resetPassword: resetPasswordRequest,
    clearError: clearErrorAction,
  }
)(ResetPassword);
