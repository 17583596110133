import * as types from './profileActionsTypes';
import * as authTypes from '../auth/authActionsTypes';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const initialState = {
  data: cookies.get('user') || { hasPassTestFirstTime: false },
  processing: false,
  languagesOptionsProcessing: true,
  languagesOptions: [],
  selectedLanguage: 'en',
  error: null,
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };

    case types.GET_PROFILE_SUCCESS:
    case types.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };

    case types.GET_LANGUAGES_OPTIONS_REQUEST:
      return {
        ...state,
        languagesOptionsProcessing: true,
      };

    case types.GET_LANGUAGES_OPTIONS_SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    case types.GET_LANGUAGES_OPTIONS_ERROR:
      return {
        ...state,
        languagesOptionsProcessing: false,
      };

    case types.TOGGLE_CURRENT_LANGUAGE_ACTION:
      localStorage.setItem('language', action.payload.language);
      window.location.reload();

      return {
        ...state,
        selectedLanguage: action.payload.language,
      };

    case types.PROFILE_REQUEST_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case authTypes.LOGOUT_REQUEST:
      return {
        data: {},
        processing: false,
        error: null,
      };

    case types.CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export default profile;
