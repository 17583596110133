import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import parse from 'html-react-parser';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { getPreviousGoalsRequest, updateStandardGoalRequest } from '../../redux/dashboard/dashboardActions';

const useStyles = makeStyles((theme) => ({
  accordion: {
    position: 'relative',
    marginBottom: '12px',
    backgroundColor: theme.overrides.accordionBackground,
    borderRadius: '8px !important',
  },
  noDescription: {
    padding: '12px 16px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.down('sm')]: {
      padding: '17px 16px',
    },
    '& label': {
      cursor: 'default !important',
      marginRight: '0px !important',
      marginLeft: '0px !important',
    },
  },
  accordionQuestionWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  container: {
    position: 'relative',
    width: '100%',
  },
  hr: {
    position: 'absolute',
    top: '-8px',
    width: '100%',
    borderTop: '1px solid rgba(255, 255, 255, 0.2)',
  },
  checkbox: {
    '&.Mui-checked': {
      color: theme.palette.type === 'dark' ? '#FFFFFF' : 'rgba(0, 0, 0, 0.54)',
    },
  },
  checkedGoal: {
    opacity: '0.5',
    pointerEvents: 'none',
  },
  goalValue: {
    marginLeft: '8px',
    fontSize: '12px',
    backgroundColor: '#0ABF53',
    padding: '6px 12px',
    color: '#FFFFFF',
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: 10,
      fontSize: 12,
    },
  },
  content: {
    marginTop: '12px',
    display: 'flex',
    flexDirection: 'column',
    '& p': {
      margin: '0 0 5px',
      fontSize: '0.75rem',
      lineHeight: '0.875rem',
    },
    '& a': {
      textDecoration: 'underline',
    },
  },
  link: {
    fontSize: '0.75rem',
    lineHeight: 1.5,
    textDecoration: 'underline',
  },
  placeholder: {
    fontStyle: 'italic',
    width: '100%',
    padding: 40,
    textAlign: 'center',
    opacity: 0.8,
  },
}));

const PreviousGoals = ({
  tasks = [],
  isDarkTheme,
  getPreviousTasks,
  updateStandardGoal,
  isProcessing,
  showConfetti,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const emptyBoardSrc = isDarkTheme ? '/images/board-empty.svg' : '/images/icon-empty-board-light.svg';
  const [current, setCurrent] = useState(null);
  const isLastGoal = useMemo(() => tasks.length > 0 && tasks.filter((goal) => !goal.isChecked).length === 1, [tasks]);

  useEffect(() => {
    !isProcessing && setCurrent(null);
  }, [isProcessing]);

  useEffect(() => {
    getPreviousTasks();
  }, []);

  const onCheck = (item, value) => {
    setCurrent(item.id);
    updateStandardGoal({ id: item.id, body: { isChecked: value } });
    if (isLastGoal) {
      showConfetti(true);
    }
  };

  const renderGoal = (item) => {
    return (
      <FormControlLabel
        className={classNames({ [classes.checkedGoal]: item.isChecked })}
        control={
          <>
            <Checkbox
              className={classes.checkbox}
              color="primary"
              checked={item.isChecked}
              disabled={isProcessing && current === item.id}
              onChange={(evt, value) => onCheck(item, value)}
            />
            {!!item?.sectionIcon && (
              <Box pr="10px">
                <img src={item?.sectionIcon } width={24} alt="" />
              </Box>
            )}
          </>
        }
        label={
          <>
            <div className={classes.accordionQuestionWrapper}>
              <span>{item.title || item.actionItem || ''}</span>
              {!!item.score && (
                <span className={classes.goalValue}>
                  {Math.round(item.score) || 0}&#160;{t('points')}
                </span>
              )}
            </div>
          </>
        }
      />
    );
  };

  const renderTasks = () => {
    if (!tasks.length) {
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          <img src={emptyBoardSrc} alt="board-empty" />
          <Box display="flex" flexDirection="column" style={{ maxWidth: '500px', flexGrow: 1, paddingLeft: '20px' }}>
            <Typography variant="h5" style={{ fontWeight: 600 }}>
              {t('your_plan_you_did_not_completed_tasks')}
            </Typography>
          </Box>
        </Box>
      );
    }

    return tasks.map((item) => {
      if (!item.question || !item.question.message || typeof item.question.message !== 'string') {
        return (
          <div key={`standard-goal-${item.id}`} className={classNames(classes.accordion)}>
            <div className={classes.noDescription}>{renderGoal(item)}</div>
          </div>
        );
      }

      let text = item.question.message;
      try {
        text = parse(lodash.unescape(text));
      } catch (e) {
        console.error(e);
      }

      return (
        <Accordion key={`standard-goal-${item.id}`} className={classNames(classes.accordion)}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            id="additional-actions1-header"
          >
            {renderGoal(item)}
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.container}>
              <div className={classes.hr} />
              <Box className={classes.content}>{text}</Box>
            </div>
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  return (
    <div>
      <Box mb={3} mt={9}>
        <Typography variant="h5">{t('your_plan_previous_day')}</Typography>
      </Box>
      <div className="main-form__section">{renderTasks()}</div>
    </div>
  );
};

PreviousGoals.propTypes = {
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string,
      actionItem: PropTypes.string,
      score: PropTypes.number.isRequired,
      isChecked: PropTypes.bool.isRequired,
    })
  ).isRequired,
  isProcessing: PropTypes.bool.isRequired,
  isDarkTheme: PropTypes.bool,
  updateStandardGoal: PropTypes.func.isRequired,
  getPreviousTasks: PropTypes.func,
  showConfetti: PropTypes.func,
};

export default connect(
  (state) => ({
    tasks: state.dashboard.previewGoals,
    isProcessing: state.dashboard.processing,
    isDarkTheme: state.general.isDarkTheme,
  }),
  {
    updateStandardGoal: updateStandardGoalRequest,
    getPreviousTasks: getPreviousGoalsRequest,
  }
)(PreviousGoals);
