export const GET_TEMPLATE_LIST_REQUEST = 'GET_TEMPLATE_LIST_REQUEST';
export const GET_TEMPLATE_LIST_SUCCESS = 'GET_TEMPLATE_LIST_SUCCESS';

export const GET_SINGLE_TEMPLATE_REQUEST = 'GET_SINGLE_TEMPLATE_REQUEST';
export const GET_SINGLE_TEMPLATE_SUCCESS = 'GET_SINGLE_TEMPLATE_SUCCESS';

export const CLONE_TEMPLATE_REQUEST = 'CLONE_TEMPLATE_REQUEST';
export const CLONE_TEMPLATE_SUCCESS = 'CLONE_TEMPLATE_SUCCESS';
export const CLONE_TEMPLATE_ERROR = 'CLONE_TEMPLATE_ERROR';

export const CREATE_TEMPLATE_REQUEST = 'CREATE_TEMPLATE_REQUEST';
export const CREATE_TEMPLATE_SUCCESS = 'CREATE_TEMPLATE_SUCCESS';
export const CREATE_TEMPLATE_ERROR = 'CREATE_TEMPLATE_ERROR';

export const UPDATE_TEMPLATE_REQUEST = 'UPDATE_TEMPLATE_REQUEST';
export const UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS';
export const UPDATE_TEMPLATE_ERROR = 'UPDATE_TEMPLATE_ERROR';

export const UPDATE_SINGLE_TEMPLATE_REQUEST = 'UPDATE_SINGLE_TEMPLATE_REQUEST';
export const UPDATE_SINGLE_TEMPLATE_SUCCESS = 'UPDATE_SINGLE_TEMPLATE_SUCCESS';
export const UPDATE_SINGLE_TEMPLATE_ERROR = 'UPDATE_SINGLE_TEMPLATE_ERROR';

export const STATUS_TEMPLATE_SHOW_REQUEST = 'STATUS_TEMPLATE_SHOW_REQUEST';
export const STATUS_TEMPLATE_SHOW_SUCCESS = 'STATUS_TEMPLATE_SHOW_SUCCESS';
export const STATUS_TEMPLATE_SHOW_ERROR = 'STATUS_TEMPLATE_SHOW_ERROR';

export const DELETE_TEMPLATE_REQUEST = 'DELETE_TEMPLATE_REQUEST';

export const DESELECT_TEMPLATE_REQUEST = 'DESELECT_TEMPLATE_REQUEST';

export const APPLY_TEMPLATE_REQUEST = 'APPLY_TEMPLATE_REQUEST';

export const SET_TEMPLATE_PARAMS_ACTION = 'SET_TEMPLATE_PARAMS_ACTION';

export const CLEAR_SINGLE_TEMPLATE_ACTION = 'CLEAR_SINGLE_TEMPLATE_ACTION';

export const TOGGLE_PROCESSING = 'TOGGLE_TEMPLATE_PROCESSING';
