import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Breadcrumbs, Button, CircularProgress, Grid, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core';

import { showModalAction } from '../../redux/general/generalActions';
import { MODAL_TYPE_REQUEST_SHARED_ACCESS } from '../../redux/general/generalActionsTypes';
import {
  deleteSharedAccessRequest,
  getSharedUsersRequest,
  sharedAccessClear,
} from '../../redux/shared-access/sharedAccessActions';

const useStyles = makeStyles((theme) => {
  const isDark = theme.palette.type === 'dark';

  return {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      color: theme.palette.text.primary,
      fontWeight: '700',
      fontSize: '24px',
      paddingBottom: '18px',
      borderBottom: `1px solid ${isDark ? 'rgba(242, 242, 242, 0.2)' : 'rgba(0, 0, 0, 0.2)'}`,
      [theme.breakpoints.down(680)]: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '24px'
      },
    },
    buttons: {
      [theme.breakpoints.down(680)]: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
      }
    },
    shareButton: {
      background: '#F33935',
      color: '#FFFFFF',
      borderRadius: '8px',
      fontSize: '16px',
      textTransform: 'none',
      fontFamily: 'Inter, Montserrat, Helvetica, Arial, sans-serif',
      '&:hover': {
        backgroundColor: '#F33935'
      }
    },
    superviseButton: {
      backgroundColor: '#0ABF53',
      color: '#FFFFFF',
      borderRadius: '8px',
      fontSize: '16px',
      textTransform: 'none',
      marginLeft: '16px',
      fontFamily: 'Inter, Montserrat, Helvetica, Arial, sans-serif',
      '&:hover': {
        backgroundColor: '#09BE52'
      }
    },
    card: {
      backgroundColor: isDark ? '#3A5188' : '#ffffff',
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
    },
    cardHeader: {
      fontSize: '20px',
      padding: '24px',
      boxShadow: isDark ? 'inset 0px -1px 0px rgba(242, 242, 242, 0.2)' : 'inset 0px -1px 0px #f5f5f5',
    },
    moreIcon: {
      float: 'right',
      cursor: 'pointer',
    },
    cardBody: {
      padding: '24px',

      '& > div > span': {
        display: 'block',
        '&:first-child': {
          color: isDark ? '#93A0BE' : '#757575',
          marginBottom: '8px',
        },
      },
    },
    button: {
      border: `1px solid ${isDark ? '#F2F2F2' : 'rgba(0, 0, 0, 0.2)'}`,
      padding: '16px !important',
      borderRadius: '8px',
      textTransform: 'none',
      textDecoration: 'none',
    },
    emptyText: {
      textAlign: 'center',
      fontStyle: 'italic',
      fontWeight: '300',
      color: theme.palette.text.primary,
      marginTop: '250px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '100px',
      },
    },
  };
});

const SharedAccess = ({
  showModal,
  sharedUsers,
  getSharedUsers,
  isProcessing,
  isDarkTheme,
  deleteSharedAccess,
  clear,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);

  const shareAccount = () => {
    showModal({
      title: t('modal_shared_access_share'),
      type: MODAL_TYPE_REQUEST_SHARED_ACCESS,
      params: {
        sharingType: 'sharing',
        cancelAction: clear,
      },
    });
  };

  const superviseAccount = () => {
    showModal({
      title: t('modal_shared_access_supervise_account'),
      type: MODAL_TYPE_REQUEST_SHARED_ACCESS,
      params: {
        sharingType: 'request',
        cancelAction: clear,
      },
    });
  };

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const revokeAccessHandler = (id) => {
    deleteSharedAccess(id);
  };

  const dotsIconSrc = isDarkTheme ? '/images/icon-dots-dark.svg' : '/images/icon-dots-light.svg';

  useEffect(() => {
    getSharedUsers();
  }, [getSharedUsers]);

  return (
    <>
      <div className="breadcrumbs-outer">
        <div className="breadcrumbs-wrapper">
          <Breadcrumbs className="breadcrumbs">
            <Link to="/dashboard">{t('m_dashboard')}</Link>
            <Link to="/shared-access">{t('m_shared_access')}</Link>
          </Breadcrumbs>
        </div>
      </div>
      <Box className={classes.header} mt={10} mb={4} display="flex" alignItems="flex-end" flexWrap="wrap">
        <span>{t('m_shared_access')}</span>
        <div className={classes.buttons}>
          <Button className={classes.shareButton} onClick={shareAccount}>
            {t('shared_access_share_my_account')}
          </Button>
          <Button className={classes.superviseButton} onClick={superviseAccount}>
            {t('shared_access_supervise_account')}
          </Button>
        </div>
      </Box>
      {isProcessing ? (
        <Grid container justify="center">
          <CircularProgress disableShrink />
        </Grid>
      ) : (
        <Grid container spacing={4}>
          {sharedUsers?.length ? (
            sharedUsers.map((user) => {
              return (
                <Grid key={user.id} item md={4} sm={6} xs={12}>
                  <Box className={classes.card}>
                    <Box className={classes.cardHeader}>
                      {user.clientUsername}
                      <img
                        id="more-menu"
                        src={dotsIconSrc}
                        alt="more icon"
                        className={classes.moreIcon}
                        onClick={openMenu}
                      />
                      <Menu
                        id="more-menu"
                        anchorEl={anchorEl}
                        open={!!anchorEl}
                        onClose={closeMenu}
                        transitionDuration={0}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        <MenuItem onClick={() => revokeAccessHandler(user.id)}>
                          {t('shared_access_revoke_access')}
                        </MenuItem>
                      </Menu>
                    </Box>
                    <Grid container spacing={3} className={classes.cardBody}>
                      <Grid item sm={6} xs={12}>
                        <span>{t('form_label_age')}</span>
                        <span>{user.clientAge || '\u2014'}</span>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <span>{t('form_label_sex')}</span>
                        <span>{user.clientSex || '\u2014'}</span>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <span>{t('form_label_nationality')}</span>
                        <span>{user.clientNationality || '\u2014'}</span>
                      </Grid>
                    </Grid>
                    <Box p={3} display="flex" alignItems="center" justifyContent="center">
                      <Link to={`/shared-access/user/${user.client}`} className={classes.button}>
                        {t('shared_access_get_summary_report')}
                      </Link>
                    </Box>
                  </Box>
                </Grid>
              );
            })
          ) : (
            <Grid item>
              <Typography className={classes.emptyText}>{t('shared_access_empty_text')}</Typography>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

SharedAccess.propTypes = {
  showModal: PropTypes.func.isRequired,
  sharedUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isActive: PropTypes.bool.isRequired,
      client: PropTypes.number.isRequired,
      clientUsername: PropTypes.string.isRequired,
      clientAge: PropTypes.string,
      clientSex: PropTypes.string,
      clientNationality: PropTypes.string,
    })
  ).isRequired,
  isDarkTheme: PropTypes.bool,
  getSharedUsers: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool,
  deleteSharedAccess: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isProcessing: state.sharedAccess.processing,
    isDarkTheme: state.general.isDarkTheme,
    sharedUsers: state.sharedAccess.sharedUsers,
  }),
  {
    showModal: showModalAction,
    getSharedUsers: getSharedUsersRequest,
    deleteSharedAccess: deleteSharedAccessRequest,
    clear: sharedAccessClear,
  }
)(SharedAccess);
