import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Box, Button, DialogActions, DialogContentText, makeStyles } from '@material-ui/core';

import PasswordField from '../components/PasswordField';
import { updatePasswordRequest, clearErrorAction } from '../../../redux/profile/profileActions';
import { hideModalAction } from '../../../redux/general/generalActions';

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: '104px',
    marginLeft: '16px',
    background: theme.palette.type === 'dark' ? '#406BE1' : 'linear-gradient(90deg, #0035FF 0%, #00C1FF 100%)',
  },
  cancelButton: {
    minWidth: '104px',
    marginLeft: '16px',
  },
  color: {
    color: theme.overrides.textBlue,
  },
}));

const UpdatePasswordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required('Password can not be empty.')
    .min(8, 'Password should be at least 8 characters long.'),
  confirmedPassword: yup
    .string()
    .required('Password can not be empty.')
    .min(8, 'Password should be at least 8 characters long.')
    .oneOf([yup.ref('newPassword')], 'The two password fields didn\'t match.'),
});

const ModalUpdatePassword = ({ isProcessing, error, updatePassword, clearError, hideModal }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { handleSubmit, errors, register } = useForm({
    reValidateMode: 'onChange',
    validationSchema: UpdatePasswordSchema,
  });

  const serverError = Object.values(error).length && Object.values(error)[0].length ? Object.values(error)[0][0] : '';

  return (
    <>
      <DialogContentText>
        {t('auth_password_validation_1')}
      </DialogContentText>
      <form onSubmit={handleSubmit(updatePassword)}>
        <Box className="form-input__group">
          <PasswordField
            inputRef={register}
            error={error}
            errors={errors}
            name="newPassword"
            label={t('form_label_new_password')}
            id="newPassword"
            autoComplete="current-password"
            onChange={() => serverError && clearError()}
          />
        </Box>
        <Box className="form-input__group">
          <PasswordField
            inputRef={register}
            error={error}
            errors={errors}
            name="confirmedPassword"
            label={t('form_label_confirm_new_password')}
            id="confirmedPassword"
            onChange={() => serverError && clearError()}
          />
        </Box>
        <DialogActions>
          <Button
            className={`${classes.cancelButton} ${classes.color}`}
            disabled={isProcessing}
            color="primary"
            onClick={hideModal}
          >
            {t('cancel')}
          </Button>
          <Button className={classes.button} type="submit" disabled={isProcessing} variant="contained" color="primary">
            {t('save')}
          </Button>
        </DialogActions>
      </form>
    </>
  );
};

ModalUpdatePassword.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  error: PropTypes.object.isRequired,
  updatePassword: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isProcessing: state.profile.processing,
    error: state.profile.error || {},
  }),
  {
    updatePassword: updatePasswordRequest,
    clearError: clearErrorAction,
    hideModal: hideModalAction,
  }
)(ModalUpdatePassword);
