import { CircularProgress, Drawer, Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import {
  clearSingleArticleAction,
  getArticleListRequest,
  getSingleArticleRequest,
  searchArticleListRequest,
} from '../../redux/blog/blogActions';
import { showModalAction } from '../../redux/general/generalActions';
import SearchField from '../shared/components/SearchField';
import { LightThemeContainer, theme } from '../providers/ThemeProvider';
import RandomHeaderImage from './RandomHeaderImage';
import { CONTAINER_MAX_WIDTH } from '../../constants';

const useStyles = makeStyles((Theme) => ({
  root: {
    padding: '80px 16px 30px',
    flex: 1,
    display: 'flex',
    alignItems: 'stretch',
    [Theme.breakpoints.down('xs')]: {
      padding: '40px 16px 20px',
    },
  },
  container: {
    display: 'flex',
    margin: '0 auto',
    maxWidth: CONTAINER_MAX_WIDTH,
    [Theme.breakpoints.down('xs')]: {
      marginTop: 46,
      flexDirection: 'column',
    },
  },
  listContainer: {
    flex: 0.3,
    position: 'relative',
    maxWidth: '286px',
    paddingTop: '80px',
    paddingBottom: 62,
    borderRight: '1px solid #EAEAEA',
    [Theme.breakpoints.down('md')]: {
      flex: 1,
    },
  },
  list: {
    height: '100%',
    padding: '0 16px',
    overflowY: 'auto',
  },
  searchWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    padding: '20px 16px',
    backgroundColor: 'transparent',
  },
  item: {
    padding: '8px 16px',
    borderRadius: '8px',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    '&.selected': {
      backgroundColor: 'rgba(40, 83, 195, 0.2)',
    },
  },
  content: {
    flex: 0.7,
    padding: 32,
    minHeight: 1000,
    [Theme.breakpoints.down('md')]: {
      flex: 1,
    },
  },
  preloader: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    color: '#304659',
    fontSize: '2.75em',
    fontWeight: 500,
    marginBottom: '16px',
    fontFamily: 'Montserrat',
  },
  body: {
    '& p': {
      marginTop: 0,
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    padding: 15,
    marginTop: 40,
    backgroundColor: 'transparent',
  },
  paginationItem: {
    color: '#000',
  },

  // Mobile styles

  mobileContainer: {
    width: '100%',
    paddingTop: 60,
    paddingBottom: 30,
  },
  mobileSearch: {
    padding: Theme.spacing(2),
  },
  mobileItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: Theme.spacing(1, 2, 1, 0),
    marginLeft: Theme.spacing(2),
    borderRadius: Theme.spacing(1),
    backgroundColor: 'transparent',
    cursor: 'pointer',
    borderBottom: '1px solid #EAEAEA',
    '&.selected': {
      backgroundColor: 'rgba(40, 83, 195, 0.2)',
    },
  },
  chevronRight: {
    fill: '#4E4E4E',
    marginLeft: 'auto',
  },
  mobileTitleContainer: {
    display: 'flex',
    color: '#4E4E4E',
    fontSize: 18,
    fontWeight: 600,
    padding: Theme.spacing(2, 3),
    alignItems: 'center',
  },
  mobileTitle: {
    fontSize: 18,
    fontWeight: 600,
  },
  mobileBody: {
    color: '#4E4E4E',
    padding: Theme.spacing(0, 2),
  },
  preloaderMobile: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Blog = ({
  articleList,
  singleArticle,
  getArticleList,
  searchArticleList,
  getSingleArticle,
  articleListLength,
  match,
  isProcessing,
  clearSingleArticle,
}) => {
  const isMobileVersion = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const [selected, setSelected] = useState(null);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const history = useHistory();
  const classes = useStyles();
  const count = Math.ceil(articleListLength / 10);

  useEffect(() => {
    if (!search) {
      getArticleList({ page: page, page_size: 10 });
    } else {
      searchArticleList({ query: search });
    }

    return () => clearSingleArticle();
  }, [page, search]);

  useEffect(() => {
    const findArticle = articleList.find((article) => article.slug === match.params.slug);
    if (findArticle && findArticle !== selected) {
      return setSelected(findArticle);
    } else if (!isMobileVersion && !selected) {
      setSelected(articleListLength ? articleList[0] : {});
    }
  }, [articleList, match.params.slug]);

  useEffect(() => {
    if (selected && selected.slug) {
      getSingleArticle({ slug: selected.slug });
      history.push(`/blog/${selected.slug}`);
    }
  }, [selected]);

  const onChangePage = (value) => {
    setSelected(null);
    setPage(value);
  };

  const onDividerClose = () => {
    setSelected(null);
    history.push('/blog');
  };

  if (isMobileVersion) {
    return (
      <LightThemeContainer>
        <Grid item xs={12} className={classes.mobileContainer}>
          <div className={classes.mobileSearch}>
            <SearchField value={search} type="light" onChange={(value) => setSearch(value)} />
          </div>
          {articleList.map((item) => (
            <div key={`article-${item.id}`} className={classes.mobileItem} onClick={() => setSelected(item)}>
              <span>{item.title}</span>
              <ChevronRight className={classes.chevronRight} fill="#EAEAEA" />
            </div>
          ))}
          {count > 1 && (
            <Pagination
              siblingCount={0}
              boundaryCount={1}
              size="small"
              color="primary"
              className={classes.pagination}
              count={count}
              page={page}
              onChange={(evt, data) => onChangePage(data)}
            />
          )}
          <Drawer
            onClose={onDividerClose}
            open={!!selected}
            anchor="right"
            classes={{ paper: classes.mobileContainer }}
          >
            {!!singleArticle.slug && !isProcessing && (
              <>
                <div className={classes.mobileTitleContainer} onClick={onDividerClose}>
                  <ChevronLeft />
                  <Typography className={classes.mobileTitle}>{singleArticle.title}</Typography>
                </div>
                <RandomHeaderImage />
                <div className={classes.mobileBody}>{parse(singleArticle.text || '')}</div>
              </>
            )}
            {isProcessing && (
              <div className={classes.preloaderMobile}>
                <CircularProgress color="primary" />
              </div>
            )}
          </Drawer>
        </Grid>
      </LightThemeContainer>
    );
  }

  return (
    <>
      <Grid item xs={12} className={classes.root}>
        <Grid item xs={12} className={classes.container}>
          <div className={classes.listContainer}>
            <div className={classes.searchWrapper}>
              <SearchField value={search} type="light" onChange={(value) => setSearch(value)} />
            </div>
            <div className={classes.list}>
              {articleList.map((item) => (
                <div
                  key={`article-${item.id}`}
                  className={`article-link ${classes.item}${selected && item.id === selected.id ? ' selected' : ''}`}
                  onClick={() => setSelected(item)}
                >
                  {item.title}
                </div>
              ))}
              {count > 1 && (
                <LightThemeContainer>
                  <Pagination
                    siblingCount={0}
                    boundaryCount={1}
                    size="small"
                    color="primary"
                    className={classes.pagination}
                    count={count}
                    page={page}
                    onChange={(evt, data) => onChangePage(data)}
                  />
                </LightThemeContainer>
              )}
            </div>
          </div>
          <div className={classes.content}>
            {!!singleArticle.slug && !isProcessing && (
              <>
                <Typography variant="h3" className={classes.title}>
                  {singleArticle.title}
                </Typography>
                <RandomHeaderImage />
                <div className={classes.body}>{parse(singleArticle.text || '')}</div>
              </>
            )}
            {isProcessing && (
              <div className={classes.preloader}>
                <CircularProgress color="primary" />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

Blog.propTypes = {
  articleListLength: PropTypes.number,
  articleList: PropTypes.array.isRequired,
  singleArticle: PropTypes.object.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  error: PropTypes.object,
  match: PropTypes.object.isRequired,
  getArticleList: PropTypes.func.isRequired,
  getSingleArticle: PropTypes.func.isRequired,
  searchArticleList: PropTypes.func.isRequired,
  clearSingleArticle: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    articleList: state.blog.articleList.results,
    articleListLength: state.blog.articleList.count,
    singleArticle: state.blog.singleArticle,
    isProcessing: state.blog.processing,
    error: state.blog.error || {},
  }),
  {
    getArticleList: getArticleListRequest,
    searchArticleList: searchArticleListRequest,
    getSingleArticle: getSingleArticleRequest,
    showModal: showModalAction,
    clearSingleArticle: clearSingleArticleAction,
  }
)(Blog);
