import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Icon, FormLabel, makeStyles, TextField } from '@material-ui/core';

import { requestAccessRequest, sharedAccessClear } from '../../../redux/shared-access/sharedAccessActions';

const useStyles = makeStyles((theme) => {
  const isDark = theme.palette.type === 'dark';

  return {
    comment: {
      marginBottom: '24px',
      color: '#FE4A5E'
    },
    label: {
      display: 'block',
      marginBottom: '8px',
      color: isDark ? '#F2F2F2' : '#2A2B2A',
      fontFamily: 'Inter, Montserrat, Helvetica, Arial, sans-serif',
      fontWeight: 400,
      lineHeight: '19px'
    },
    textField: {
      flexGrow: 1,
      marginRight: '16px',
      [theme.breakpoints.down('xs')]: {
        margin: '16px 0',
      },
    },
    input: {
      padding: '15px 12px',
      borderRadius: '8px!important',
      border: `2px solid ${theme.overrides.inputBorder}`,
      backgroundColor: 'transparent',
      '& input': {
        padding: '0 !important'
      }
    },
    addButton: {
      height: '53px',
      background: isDark ? '#406BE1' : 'linear-gradient(90deg, #0035FF 0%, #00C1FF 100%)',
      color: '#FFFFFF',
      borderRadius: '8px',
      fontSize: '16px',
      textTransform: 'none',
      marginLeft: 'auto',
      padding: '16px 24px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    anchorButton: {
      cursor: 'pointer',
      marginLeft: '12px',
      color: isDark ? '#fff' : '#0035FF',
      fontWeight: isDark ? 500 : 400,
    },
  };
});


const ModalRequestAccess = ({
  isProcessing,
  requestAccess,
  clear,
  accessRequested,
  error,
  isDarkTheme,
  sharingType
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [userEmail, setUserEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    if (!accessRequested && isProcessing && error?.email?.[0]) {
      setEmailError(error.email[0]);
    }
  }, [accessRequested, isProcessing, error]);

  const requestAccessHandler = () => {
    if (!userEmail.length) return setEmailError(t('form_not_be_blank'));

    requestAccess({ email: userEmail, sharingType });
  };

  const checkIconSrc = isDarkTheme ? '/images/check-icon-dark.svg' : '/images/check-icon-light.svg';

  if (accessRequested) {
    return (
      <Box display="flex" alignItems="center">
        <Icon>
          <img src={checkIconSrc} alt="check icon" />
        </Icon>
        {t('shared_access_request_successfully')}
        <span className={classes.anchorButton} onClick={clear}>
          {t('shared_access_send_another')}
        </span>
      </Box>
    );
  }

  return (
    <>
      {sharingType === 'sharing' && (
        <Box className={classes.comment}>
          {t('shared_access_text_1')}
          <br />
          {t('shared_access_text_2')}
        </Box>
      )}
      <FormLabel className={classes.label}>{t('form_label_email')}</FormLabel>
      <Box display="flex" mb={2} flexWrap="wrap">
        <TextField
          className={classes.textField}
          variant="filled"
          InputProps={{
            disableUnderline: true,
            className: classes.input,
          }}
          error={!!emailError}
          helperText={emailError}
          placeholder={t('form_enter_email_here')}
          value={userEmail}
          onChange={(e) => {
            emailError && setEmailError('');
            setUserEmail(e.target.value);
          }}
        />
        <Button className={classes.addButton} onClick={requestAccessHandler} disabled={isProcessing}>
          {sharingType === 'sharing' ? t('share') : t('add_user')}
        </Button>
      </Box>
    </>
  );
};

ModalRequestAccess.propTypes = {
  isProcessing: PropTypes.bool,
  accessRequested: PropTypes.bool,
  requestAccess: PropTypes.func.isRequired,
  error: PropTypes.any,
  clear: PropTypes.func.isRequired,
  sharingType: PropTypes.oneOf(['request', 'sharing']),
  isDarkTheme: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    isProcessing: state.sharedAccess.processing,
    accessRequested: state.sharedAccess.accessRequested,
    error: state.sharedAccess.error,
    isDarkTheme: state.general.isDarkTheme,
  }),
  {
    requestAccess: requestAccessRequest,
    clear: sharedAccessClear,
  }
)(ModalRequestAccess);
