import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  DialogActions,
  FormControlLabel,
  InputLabel,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@material-ui/core';
import { BootstrapInput } from '../../Dashboard/UserTestCharts';
import { applyTemplateRequest } from '../../../redux/template/templateActions';

const useStyles = makeStyles((theme) => ({
  group: {
    marginBottom: '24px'
  },
  radioGroup: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    marginBottom: '24px',
  },
  selector: {
    margin: '8px 0 0 !important',

    '& .MuiFormControl-root': {
      margin: 0,
      padding: 0,
    },
  },
  selectMenuPaper: {
    backgroundColor: theme.overrides.filterListBackground,
  },
  button: {
    minWidth: '104px',
    marginLeft: '16px',
    background: theme.palette.type === 'dark' ? '#406BE1' : 'linear-gradient(90deg, #0035FF 0%, #00C1FF 100%)',
  },
}));


const ModalTemplate = ({
  id,
  profileId,
  sharedUsers = [],
  applyTemplate,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [target, setTarget] = useState('you');
  const [userId, setUserId] = useState('');

  const users = useMemo(() => {
    return sharedUsers.filter((user) => user.isActive);
  }, [sharedUsers]);

  const onSubmitHandler = () => {
    applyTemplate({ id, targetUser: target === 'you' ? profileId : userId });
  };

  return (
    <>
      <Box className={`form-input__group ${classes.group}`}>
        <RadioGroup className={classes.radioGroup}>
          <FormControlLabel
            value="you"
            control={<Radio color="primary" />}
            checked={target === 'you'}
            label={t('modal_template_label_1')}
            style={{ width: '50%' }}
            onChange={() => setTarget('you')}
          />
          <FormControlLabel
            value="user"
            control={<Radio color="primary" />}
            disabled={!users.length}
            checked={target === 'user'}
            label={t('modal_template_label_2')}
            style={{ width: '50%' }}
            onChange={() => setTarget('user')}
          />
        </RadioGroup>
        {target !== 'you' && (
          <>
            <InputLabel>{t('modal_template_label_3')}</InputLabel>
            <Select
              id="userId"
              value={userId}
              className={classes.selector}
              MenuProps={{
                classes: { paper: classes.selectMenuPaper },
                transitionDuration: 0,
              }}
              onChange={(_evt, data) => setUserId(data.props.value)}
              input={<BootstrapInput variant="contained" fullWidth />}
            >
              {users?.map((user) => (
                <MenuItem key={`shared-user-${user.client}`} value={user.client}>
                  {user.clientUsername}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
      </Box>
      <DialogActions>
        <Button
          className={classes.button}
          disabled={target === 'user' && !userId}
          variant="contained"
          color="primary"
          onClick={onSubmitHandler}
        >
          {t('apply')}
        </Button>
      </DialogActions>
    </>
  );
};

ModalTemplate.propTypes = {
  id: PropTypes.number.isRequired,
  profileId: PropTypes.number,
  sharedUsers: PropTypes.array.isRequired,
  applyTemplate: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    profileId: state.profile.data.id,
    // sharedUsers: state.profile.data.sharedAccess,
    sharedUsers: state.sharedAccess.sharedUsers,
    isProcessing: state.template.processing,
  }),
  {
    applyTemplate: applyTemplateRequest,
  }
)(ModalTemplate);
