import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { clearErrorAction, createAnonymusUserSubscriptionRequest } from '../../../redux/subscription/subscriptionActions';
import IconClose from '../../../assets/inline-images/IconClose';

const NewsLetterEmailSchema = yup.object().shape({
  email: yup.string().email('Please provide correct email').required('Email can not be empty'),
});

const styles = () => ({
  root: {
    margin: 0,
    '&.MuiDialogTitle-root': {
      boxShadow: 'none',
      paddingTop: '62px',
      paddingBottom: '0',
    },
    '& .title': {
      textAlign: 'center',
      fontSize: '36px',
    },
  },
  closeButton: {
    position: 'absolute',
    color: '#000000',
    right: '5px',
    top: '5px',
  },
  dialogContainer: {
    '& .MuiPaper-root': {
      backgroundColor: '#FFFFFF',
      color: '#000',
    },
  },
  dialogContent: {
    border: 'none',
    width: '100%',
    '& .form-input__field': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& .form-input__field input': {
      border: '1px solid #00000020',
      borderRadius: '8px',
      padding: '16px',
      color: '#000',
      maxWidth: '476px',
      width: '100%',
    },
    '& .form-input__field .Mui-error.MuiFormHelperText-filled': {
      maxWidth: '510px',
      width: '100%',
    },
    '& .form-input__field .MuiInputBase-root.MuiInput-root': {
      display: 'flex',
      justifyContent: 'center',
      '&::after': {
        content: 'none',
      },
      '&::before': {
        content: 'none',
      },
    },
  },
  saveButton: {
    maxWidth: '510px',
    width: '100%',
    padding: '16px 0',
    background: 'linear-gradient(90deg, #0035FF, #00C1FF)',
    color: '#fff',
    textTransform: 'capitalize',
  },
  gratitudeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  gratitudeText: {
    width: '100%',
    fontSize: '31px',
    fontWeight: '600',
    textAlign: 'center',
  },
  gratitudeBtn: {
    border: '1px solid #00000020',
    borderRadius: '8px',
    color: '#2A2B2A',
    textTransform: 'capitalize',
  },
  dialogAction: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '32px',
  },
  loader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "50px",
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className="title" variant="h6">
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const checkIcon = '/images/check-icon-green.svg';

const SubscribeDialog = withStyles(styles)(
  ({ handleClose, show, classes, sendAnonymousUserSubscription, subscriptionError, isProcessing, clearError }) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [showGratitudeModal, setShowGratitudeModal] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
      if (show) {
        setShowGratitudeModal(false);
      }
    }, [show]);

    const onSubmitHandler = () => {
      NewsLetterEmailSchema.validate({
        email,
      })
        .then((valid) => {
          clearError();
          setError('');
          sendAnonymousUserSubscription(valid);
          setShowGratitudeModal(true);
        })
        .catch((err) => {
          setError(err.message);
        });
    };

    const SuccessModal = () => (
      <>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <img src={checkIcon} alt="check" />
        </DialogTitle>
        <DialogContent>
          <div className={classes.gratitudeContainer}>
            <div className={classes.gratitudeText}>{t('newsletter_gratitude')}</div>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button onClick={handleClose} className={classes.gratitudeBtn}>
            {t('modal_subscription_button')}
          </Button>
        </DialogActions>
      </>
    );

    const ErrorModal = () => (
      <>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <IconClose size={60} currentColor="#f33835" />
        </DialogTitle>
        <DialogContent>
          <div className={classes.gratitudeContainer}>
            <div className={classes.gratitudeText}>{t('newsletter_error')}</div>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button onClick={handleClose} className={classes.gratitudeBtn}>
            {t('modal_subscription_button')}
          </Button>
        </DialogActions>
      </>
    );

    return (
      <>
        {!showGratitudeModal ? (
          <Dialog
            className={classes.dialogContainer}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={show}
          >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              {t('newsletter_title')}
            </DialogTitle>
            <form noValidate autoComplete="off">
              <DialogContent className={classes.dialogContent} dividers>
                <TextField
                  className="form-input__field"
                  fullWidth
                  id="email"
                  placeholder={t('form_label_email')}
                  error={!!error}
                  helperText={error}
                  name="email"
                  onChange={(evt) => {
                    setEmail(evt.target.value);
                  }}
                />
              </DialogContent>
              <DialogActions className={classes.dialogAction}>
                <Button className={classes.saveButton} color="primary" onClick={onSubmitHandler}>
                  {t('newsletter_join')}
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        ) : (
          <Dialog
            className={classes.dialogContainer}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={show}
          >
            { isProcessing ? (
              <div className={classes.loader}>
                <CircularProgress color="primary" />
              </div>
            ) : subscriptionError?.email ? ErrorModal() : SuccessModal()}
          </Dialog>
        )}
      </>
    );
  }
);

export default connect(
  (state) => ({
    subscriptionError: state.subscription.error,
    isProcessing: state.subscription.processing,
  }),
  {
    sendAnonymousUserSubscription: createAnonymusUserSubscriptionRequest,
    clearError: clearErrorAction,
  }
)(SubscribeDialog);
