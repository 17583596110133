import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Grid, makeStyles, Switch } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { showModalAction, hideModalAction } from '../../redux/general/generalActions';
import { MODAL_TYPE_CONFIRM } from '../../redux/general/generalActionsTypes';
import { disconnectGoogleCalendarRequest } from '../../redux/profile/profileActions';

const googleCalendarIcon = '/images/google-calendar.png';
const useStyles = makeStyles((theme) => {
  const isDarkTheme = theme.palette.type === 'dark';

  return {
    blockStyles: {
      backgroundColor: theme.overrides.accordionBackground,
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      padding: '20px 36px',
      [theme.breakpoints.down('sm')]: {
        padding: '15px 20px',
      },
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      cursor: 'default'
    },
    alignEnd: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
    },
    blockFlex: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    text: {
      color: theme.palette.text.primary,
      fontSize: '0.75rem',
      letterSpacing: '0.4px',
      [theme.breakpoints.down('sm')]: {
        margin: '20px 0',
      },
    },
    textDisabled: {
      color: theme.overrides.labelFormColor,
    },
    textEnabled: {
      color: theme.palette.text.primary,
    },
    switchLabel: {
      display: 'inline-flex',
      fontSize: '14px',
      margin: '8px 0',
      cursor: 'default',
    },
    switchRoot: {
      width: 32,
      height: 18,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 2,
      color: '#FFF',
      '&$checked': {
        transform: 'translateX(14px)',
        color: isDarkTheme ? '#253E7B' : '#FFF',
        '& + $track': {
          backgroundColor: isDarkTheme ? '#C2C2C2' : '#007EFF',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#FFF',
        border: '6px solid lightgrey',
      },
    },
    focusVisible: {},
    thumb: {
      width: 14,
      height: 14,
    },
    track: {
      borderRadius: 26 / 2,
      backgroundColor: '#C2C2C2',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
  };
});

const GoogleCalendarSubscription = ({
  profile,
  isProcessing,
  googleAuthUrl,
  showModal,
  hideModal,
  disconnectGoogleCalendar
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const isConnected = !!profile.isGoogleCalendarConnected;
  const canControlNotifications = !!profile.currentPlan || !!profile.hasTrial;
  const isDisabled = isProcessing || !googleAuthUrl || !canControlNotifications;

  const [isSubscription, setIsSubscription] = useState(isConnected);

  useEffect(() => {
    setIsSubscription(isConnected);
  }, [profile]);

  const onChangeSubscription = () => {
    console.log('isConnected :>> ', isConnected);
    if (isConnected) {
      showModal({
        params: {
          cancelAction: hideModal,
          confirmAction: () => {
            console.log('111');
            disconnectGoogleCalendar();
            hideModal();
          },
          confirmButtonText: t('modal_subscription_button'),
          text: t('modal_subscription_disconnect_text'),
        },
        title: t('modal_subscription_disconnect_title'),
        type: MODAL_TYPE_CONFIRM,
      });
    } else {
      showModal({
        params: {
          cancelAction: hideModal,
          confirmAction: () => {
            window.location.href = googleAuthUrl?.replace('social-google', 'profile');
            hideModal();
          },
          confirmButtonText: t('modal_subscription_button'),
          text: t('modal_subscription_text'),
        },
        title: t('modal_subscription_title'),
        type: MODAL_TYPE_CONFIRM,
      });
    }
    // showModal({
    //   params: {
    //     cancelAction: hideModal,
    //     confirmAction: () => {
    //       window.location.href = googleAuthUrl?.replace('social-google', 'profile');
    //       hideModal();
    //     },
    //     confirmButtonText: t('modal_subscription_button'),
    //     text: t('modal_subscription_text'),
    //   },
    //   title: t('modal_subscription_title'),
    //   type: MODAL_TYPE_CONFIRM,
    // });
  };

  return (
    <div className={classNames(classes.blockStyles, 'main-form__section')}>
      <Box>
        <Grid container display="flex" alignItems="center">
          <Grid item xs={12} sm={6} className={classes.title}>
            <img src={googleCalendarIcon} alt="" />
            <div style={{ fontSize: 20 }}>{t('google_calendar')}</div>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.alignEnd}>
            <Box>
              <div className={classes.text}>
                {t('profile_notification_help_text')}
              </div>
            </Box>
            <Grid item xs={12} sm={6} className={classes.blockFlex}>
              <Switch
                classes={{
                  root: classes.switchRoot,
                  switchBase: classes.switchBase,
                  thumb: classes.thumb,
                  track: classes.track,
                  checked: classes.checked,
                }}
                color="default"
                checked={isSubscription}
                disabled={isDisabled}
                onChange={onChangeSubscription}
              />
              <p
                className={classNames(classes.switchLabel, {
                  [classes.textEnabled]: isSubscription,
                  [classes.textDisabled]: !isSubscription,
                })}
              >
                {t('enable')}
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

GoogleCalendarSubscription.propTypes = {
  profile: PropTypes.object.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  googleAuthUrl: PropTypes.string,
  error: PropTypes.object,
  isDarkTheme: PropTypes.bool,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  disconnectGoogleCalendar: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    profile: state.profile.data,
    googleAuthUrl: state.auth.googleAuthUrl,
    isProcessing: state.profile.processing,
    error: state.profile.error || {},
    isDarkTheme: state.general.isDarkTheme,
  }),
  {
    showModal: showModalAction,
    hideModal: hideModalAction,
    disconnectGoogleCalendar: disconnectGoogleCalendarRequest,
  }
)(GoogleCalendarSubscription);
