import * as types from './sharedAccessActionTypes';

export const initialState = {
  accessRequested: false,
  sharedUsers: [],
  currentSharedUser: {},
  sharedUserResult: {},
  sharedUserTestSectionList: [],
  processing: false,
  error: null,
};

const sharedAccess = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_PROCESSING:
      return { ...state, processing: action.payload };

    case types.REQUEST_ACCESS_SUCCESS:
      return { ...state, accessRequested: true };

    case types.SHARED_ACCESS_REQUEST_ERROR:
      return {
        ...state,
        error: action?.error || action?.payload?.error || {},
      };

    case types.REQUEST_SHARED_USERS_SUCCESS:
      return { ...state, sharedUsers: action.payload.filter((user) => user.isActive) };

    case types.REQUEST_SHARED_USER_RESULT_SUCCESS:
      return { ...state, sharedUserResult: action.payload };

    case types.DELETE_SHARED_ACCESS_SUCCESS: {
      return { ...state, sharedUsers: state.sharedUsers.filter((user) => user.id !== action.payload) };
    }

    case types.GET_SHARED_USER_SECTIONS_LIST_SUCCESS:
      return {
        ...state,
        sharedUserTestSectionList: action.payload,
      };

    case types.SHARED_ACCESS_CLEAR:
      return { ...state, accessRequested: false, error: null };

    default:
      return state;
  }
};

export default sharedAccess;
