import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

// Combine all middlewares into single enhancer
const middlewares = [sagaMiddleware];
const middlewareEnhancer = applyMiddleware(...middlewares);

// Compose all enhancer into single function
const enhancers = [middlewareEnhancer];
const composeFunction = process.env.NODE_ENV !== 'production' ? composeWithDevTools : compose;
const composedEnhancers = composeFunction(...enhancers);

const initState = typeof window !== 'undefined' ? window.__PRELOADED_STATE__ : {};
const hasSSR = typeof window !== 'undefined' ? !!window.__PRELOADED_STATE__ : false;

// Create store with preloaded state and enhancers
const store = createStore(rootReducer, initState, composedEnhancers);

// Run all sagas
sagaMiddleware.run(rootSaga);

if (typeof window !== 'undefined') {
  window.store = store;
}

export { store, hasSSR };
