import { Box, makeStyles, Typography } from '@material-ui/core';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import { Helmet } from 'react-helmet';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import auth from '../../api/auth';
import { API_URL } from '../../constants';
import { loginRequest } from '../../redux/auth/authActions';
import * as generalTypes from '../../redux/general/generalActionsTypes';
import { getErrorMessage } from '../../redux/reduxHelpers';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '28px',
    textAlign: 'center',
  },
  subTitle: {
    maxWidth: '290px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    textAlign: 'center',
    margin: '0',
  },
  button: {
    width: '100%',
    padding: '20px 0',
    background: '#4A98E9',
    borderRadius: '4px',
    border: 0,
    letterSpacing: '0.75px',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '16px',
    marginTop: '32px',
    cursor: 'pointer',
  },
  infoText: {
    marginTop: '32px',
    maxWidth: '280px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    textAlign: 'center',
  },
  linkText: {
    color: 'rgba(43, 102, 186, 1)',
    cursor: 'pointer',
    fontWeight: 500,
  },
  numberInput: {
    background: '#FAFAFA',
    borderBottom: '1px solid #909090',
    width: '50px',
    height: '70px',
    border: 'none',
    fontWeight: '400',
    fontSize: '32px',
    lineHeight: '38px',
    color: 'rgba(0, 0, 0, 0.6)',
    textAlign: 'center',
  },
  inputLine: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#000000',
    margin: '0 8px',
  },
  alert: {
    background: '#FACBA6',
    borderRadius: '8px',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'center',
    color: '#263D7A',
    padding: '14px',
  },
}));

const Verification = ({ login }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [confirmation, setConfirmation] = useState(false);
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const inputs = useRef([]);
  const isEduEmail = /.edu/.test(location.state?.email.split('@')[1]);
  const verificationToken = useRef(localStorage.getItem('verificationToken'));
  const refreshVerificationToken = localStorage.getItem('refreshVerificationToken');

  const regenerateVerificationToken = async (onSuccess) => {
    const result = await axios.post(`${API_URL}/auth/refresh/`, { refresh: refreshVerificationToken });
    if (result && result.status === 200) {
      verificationToken.current = result.data.access;
      onSuccess();
    }
  };

  const sendVerificationEmail = async () => {
    try {
      await auth.generateVerificationToken(verificationToken.current);
      setConfirmation(true);
    } catch (error) {
      if (error.code === 'token_not_valid') {
        await regenerateVerificationToken(() => sendVerificationEmail());
      } else {
        dispatch({
          type: generalTypes.SHOW_MODAL_ACTION,
          payload: {
            params: getErrorMessage(error),
            type: generalTypes.MODAL_TYPE_ALERT,
          },
        });
      }
    }
  };

  const startConfirmation = () => setConfirmation(true);

  const onSubmit = async (codes) => {
    try {
      await auth.verifyVerificationToken(verificationToken.current, codes);
      login(location.state);
    } catch (error) {
      if (error.code === 'token_not_valid') {
        await regenerateVerificationToken(() => onSubmit(codes));
      } else {
        dispatch({
          type: generalTypes.SHOW_MODAL_ACTION,
          payload: {
            params: getErrorMessage(error),
            type: generalTypes.MODAL_TYPE_ALERT,
          },
        });
      }
    }
  };

  const processInput = (e, slot) => {
    const num = e.target.value;
    if (/[^0-9]/.test(num)) return;
    const newCode = [...code];
    newCode[slot] = num;
    setCode(newCode);

    if (slot !== code.length - 1) {
      inputs.current[slot + 1].focus();
    }

    if (newCode.every((number) => number !== '')) {
      onSubmit(newCode.join('')).catch((error) => console.error(error));
    }
  };

  return (
    <>
      <Helmet>
        <title>Myndful</title>
      </Helmet>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography className={classes.title}>Check your e-mail</Typography>
        {confirmation && (
          <Box marginTop="16px" display="flex" alignItems="center" flexDirection="column">
            <p className={classes.subTitle}>A security code has been sent to your email address</p>
            <Box display="flex" alignItems="center" justifyContent="center" marginTop="40px">
              {code.map((num, index) => {
                return (
                  <>
                    {!!index && <p className={classes.inputLine}>-</p>}
                    <input
                      key={index}
                      type="text"
                      inputMode="numeric"
                      maxLength={1}
                      value={num}
                      className={classes.numberInput}
                      autoFocus={!code[0].length && index === 0}
                      onChange={(e) => processInput(e, index)}
                      ref={(ref) => inputs.current.push(ref)}
                    />
                  </>
                );
              })}
            </Box>
          </Box>
        )}
        {isEduEmail && (
          <Box className={classes.alert} margin={confirmation ? '32px 0 0' : '40px 0 32px'}>
            Get 3 months free Premium and In-Depth with a .edu account. Then get $10/mo for 2 years from signup date.{' '}
          </Box>
        )}
        {confirmation || (
          <Box marginTop={isEduEmail || '40px'}>
            <p className={classes.subTitle}>We already have sent you verification e-mail, please check it</p>
            <button onClick={startConfirmation} className={classes.button}>
              OK
            </button>
          </Box>
        )}
        <Box className={classes.infoText}>
          Didn't receive the email? Please check your spam folder or try to{' '}
          <span onClick={sendVerificationEmail} className={classes.linkText}>
            resend the email
          </span>
          .
        </Box>
      </Box>
    </>
  );
};

Verification.propTypes = {
  error: PropTypes.object,
  login: PropTypes.func,
};

export default connect(
  (state) => ({
    error: state.auth.error || {},
  }),
  {
    login: loginRequest,
  }
)(Verification);
