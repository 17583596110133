import React, { Fragment, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Button, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import lodash from 'lodash';

import { getAvailableSubscriptionsRequest, clearErrorAction } from '../../redux/subscription/subscriptionActions';
import { getProfileRequest } from '../../redux/profile/profileActions';
import { showModalAction, hideModalAction } from '../../redux/general/generalActions';
import {
  MODAL_TYPE_CONFIRM,
  MODAL_TYPE_UPGRADE_PLAN,
  MODAL_TYPE_DOWNGRADE_PLAN,
} from '../../redux/general/generalActionsTypes';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    margin: '24px 36px',
    padding: '24px',
    width: 'calc(100% - 72px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: theme.palette.type === 'dark' ? 'none' : '1px solid rgba(0, 0, 0, 0.2)',
    flexWrap: 'nowrap',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.type === 'dark' ? '#4966AC' : '#FFFFFF',
    borderRadius: '8px',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      '& > div:not(:first-child)': {
        marginTop: '16px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      margin: '15px 20px',
      width: 'calc(100% - 40px)',
    },
  },
  warningContainer: {
    borderRadius: '8px 8px 0 0',
    marginBottom: 0,
  },
  warning: {
    backgroundColor: '#FE4A5E',
    margin: '0 36px 24px',
    padding: '12px 0',
    textAlign: 'center',
    borderRadius: '0 0 8px 8px',

    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 20px 15px',
      width: 'calc(100% - 40px)',
    },
  },
  info: {
    backgroundColor: '#FACBA6',
    margin: '0 36px 24px',
    padding: '12px 30px',
    textAlign: 'center',
    borderRadius: '0 0 8px 8px',
    color: '#253E7B',

    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 20px 15px',
      width: 'calc(100% - 40px)',
    },
  },
  text: {
    minWidth: '162px',
    width: 'calc(100% - 260px)',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  blockStyles: {
    backgroundColor: theme.overrides.accordionBackground,
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    paddingBottom: '36px',
    marginBottom: '40px',
  },
  blockContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.text.primary,
    padding: '20px 36px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px 20px',
    },
  },
  blockTitle: {
    paddingRight: '15px',
  },
  premium: {
    marginTop: '16px',
    backgroundColor: theme.overrides.sidebarItemBackground,
  },
  premiumCurrent: {
    backgroundColor: '#15956C',
    '& .MuiButtonBase-root': {
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
      color: '#FFFFFF !important',
      fontWeight: 400,
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  planText: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    minWidth: '110px',
    textAlign: 'center',
    marginBottom: '0',
    marginTop: '0',
    marginLeft: '10px',
    backgroundColor: theme.overrides.backgroundPlanPrice,
    color: theme.overrides.textPrimaryRevert,
    padding: '5px 12px',
    borderRadius: '4px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
      marginTop: '10px',
      marginBottom: '2px',
    },
  },
  title: {
    marginBottom: '0',
    marginTop: '0',
    fontSize: '1.5rem',
    fontWeight: '700',
    letterSpacing: '0.5px',
    color: theme.palette.text.primary,
  },
  description: {
    fontSize: '1rem',
    lineHeight: '19px',
    color: theme.palette.text.primary,
  },
  freePlan: {
    opacity: theme.overrides.textOpacity,
  },
  fixedWidth: {
    minWidth: '130px',
    width: '130px',
    textAlign: 'center',
  },
  planButton: {
    minWidth: '180px',
    padding: '10px 24px!important',
    width: '180px',
    whiteSpace: 'pre',
    textAlign: 'center',
    borderRadius: '8px',
    textTransform: 'capitalize',
    '& .MuiButton-label': {
      fontSize: '20px',
    },
  },
  currentPlan: {
    fontWeight: '400',
    border: theme.palette.type === 'dark' ? '1px solid #FFFFFF' : '1px solid rgba(42, 43, 42, 0.2)',
    color: `${theme.palette.text.primary}!important`,
    backgroundColor: 'transparent!important',
  },
  upgradePlan: {
    fontWeight: 700,
    color: '#FFFFFF',
    backgroundColor: '#15956C!important',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
  },
  supportUkraine: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '24px 32px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: '24px',
      margin: '24px 16px',
    },
  },
  supportUkraineTitle: {
    margin: 0,
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '22px',
    color: theme.palette.type === 'dark' ? '#F2F2F2' : '#1b1b1b',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  supportUkraineDescription: {
    margin: 0,
    marginTop: '8px',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '17px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.type === 'dark' ? '#FFFFFF' : '#757575',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  learnMore: {
    background: theme.palette.type === 'dark' ? '#406BE1' : 'linear-gradient(90deg, #0035FF 0%, #00C1FF 100%)',
    color: '#FFFFFF',
    borderRadius: '8px',
    minWidth: '133px',
    textTransform: 'capitalize',
    padding: '7px 24px!important',
    fontSize: '16px',
    border: 'none',
    [theme.breakpoints.down('sm')]: {
      minWidth: '90px',
      padding: '6px 24px!important',
    },
  },
  studentSale: {
    backgroundColor: '#FACBA6',
    color: ' #000000',
    textDecorationLine: 'line-through',
  },
}));

const Subscription = ({
  profile,
  subscriptions,
  subscriptionCreated,
  subscriptionDeleted,
  currentPlan,
  hasTrial,
  trialDaysLeft,
  stripeSubscription,
  getAvailableSubscriptions,
  getProfile,
  clearError,
  showModal,
  hideModal,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const history = useHistory();
  const { hash } = useLocation();

  const isEduAccount = profile?.hasStudentPrivileges;

  const closeModalHelper = () => {
    getProfile();
    clearError();
    hideModal();
  };

  useEffect(() => {
    getAvailableSubscriptions();
  }, []);

  useEffect(() => {
    if (subscriptionCreated) {
      showModal({
        params: {
          cancelAction: closeModalHelper,
          confirmAction: closeModalHelper,
          confirmButtonText: t('modal_subscription_hooray'),
          text: t('modal_subscription_processing_premium'),
        },
        title: t('modal_subscription_welcome_on_board'),
        type: MODAL_TYPE_CONFIRM,
      });
      getProfile();
    } else if (subscriptionDeleted) {
      showModal({
        params: {
          cancelAction: closeModalHelper,
          confirmAction: () => {
            closeModalHelper();
            history.push('/feedback');
          },
          cancelButtonText: t('skip'),
          confirmButtonText: t('modal_subscription_leave_feedback'),
          text: (
            <span>
              {t('modal_subscription_feedback_text_1')}
              <span
                style={{ padding: '0 5px', cursor: 'pointer' }}
                onClick={() => {
                  closeModalHelper();
                  history.push('/feedback');
                }}
              >
                {t('modal_subscription_feedback_text_2')}
              </span>
              {t('modal_subscription_feedback_text_3')}
            </span>
          ),
        },
        title: t('modal_subscription_welcome_on_board'),
        type: MODAL_TYPE_CONFIRM,
      });
    }
  }, [subscriptionCreated, subscriptionDeleted]);

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [hash]);

  const isYearlyPlan = !!lodash.find(subscriptions, { id: currentPlan, interval: 'YEAR' });

  const isPlanCanceled = stripeSubscription?.cancelAtPeriodEnd;

  const premiumPlanDaysLeft =
    stripeSubscription?.currentPeriodEnd &&
    Math.ceil((new Date(stripeSubscription?.currentPeriodEnd) - new Date()) / (1000 * 3600 * 24));

  const trialLeft =
    stripeSubscription?.trialEnd &&
    Math.ceil((new Date(stripeSubscription?.trialEnd) - new Date()) / (1000 * 3600 * 24));

  const changeSubscription = (subscription) => {
    showModal({
      params: { plan: subscription.id, warning: isYearlyPlan },
      title: isYearlyPlan ? t('profile_downgrading_to_monthly') : t('profile_payment_information'),
      type: MODAL_TYPE_UPGRADE_PLAN,
    });
  };

  const renderWarningMessage = () => {
    if (isPlanCanceled) {
      return (
        <div className={classes.warning}>{t('profile_plan_will_be_downgraded', { count: premiumPlanDaysLeft })}</div>
      );
    } else if (trialLeft) {
      return <div className={classes.warning}>{t('profile_trial_days_left', { count: premiumPlanDaysLeft })}</div>;
    }
  };

  const renderStudentMessage = (interval) => {
    if (interval === 'MONTH') {
      return <div className={classes.info}>{t('profile_student_discount_month')}</div>;
    }
    if (interval === 'YEAR') {
      return <div className={classes.info}>{t('profile_student_discount_year')}</div>;
    }
  };

  const planInterval = (interval) => {
    switch (interval?.toLowerCase()) {
      case 'year':
        return t('year');
      case 'month':
        return t('month');
      default:
        return interval?.toLowerCase() || '';
    }
  };

  return (
    <div id="subscription" className={classes.blockStyles}>
      <div className={classNames(classes.blockContainer, 'main-form__section-title')}>
        <span className={classes.blockTitle}>{t('profile_subscription')}</span>
        {!!stripeSubscription?.endingBalance && (
          <span>
            {t('profile_your_balance')} {stripeSubscription.endingBalance / -100}$
          </span>
        )}
      </div>
      <Box className={classes.supportUkraine}>
        <div>
          <p className={classes.supportUkraineTitle}>{t('profile_stand_with_ukraine')} 🇺🇦</p>
          <p className={classes.supportUkraineDescription}>{t('profile_russian_invasion')}</p>
        </div>
        <a href="https://coaxsoft.com/blog/how-to-help-ukraine">
          <Button variant="contained" color="primary" className={classes.learnMore}>
            {t('learn_more')}
          </Button>
        </a>
      </Box>
      <div className={classes.container}>
        <div className={classes.text}>
          <div className={classes.titleContainer}>
            <p className={classes.title}>{hasTrial ? t('profile_premium_trial') : t('profile_basic_plan')}</p>
            <p className={classes.planText}>{hasTrial ? t('days_left', { count: trialDaysLeft }) : t('free')}</p>
          </div>
          <div className={classes.description}>
            {hasTrial ? `${t('profile_trial_text_1')} :)` : t('profile_trial_text_2')}
          </div>
        </div>
        <div>
          <Button
            className={classNames(classes.planButton, {
              [classes.currentPlan]: !currentPlan,
              [classes.upgradePlan]: currentPlan,
            })}
            type="button"
            disabled={!currentPlan || isPlanCanceled}
            variant="contained"
            onClick={() => {
              if (currentPlan) {
                showModal({
                  title: t('modal_are_you_sure'),
                  type: MODAL_TYPE_DOWNGRADE_PLAN,
                });
              }
            }}
          >
            {!currentPlan ? t('profile_current_plan') : t('profile_downgrade')}
          </Button>
        </div>
      </div>
      {subscriptions && subscriptions.length
        ? subscriptions.map((item) => {
            const isCurrentPlan = currentPlan === item.id;
            const availableForStudent = isEduAccount && isEduAccount === item?.onlyForStudents;
            const showStudentMessage = !isCurrentPlan && availableForStudent;

            return (
              <Fragment key={`subscription-${item.id}`}>
                <div
                  className={classNames(classes.container, {
                    [classes.warningContainer]: isEduAccount || (isCurrentPlan && (isPlanCanceled || trialLeft)),
                  })}
                >
                  <div className={classes.text}>
                    <div className={classes.titleContainer}>
                      <p className={classes.title}>{item.name}</p>
                      <p className={classNames(classes.planText, { [classes.studentSale]: item?.onlyForStudents })}>
                        {`$${availableForStudent ? item.oldPrice : item.unitAmount}/${planInterval(item.interval)}`}
                      </p>
                      {availableForStudent && <Box marginLeft="10px">{item.unitAmount}$</Box>}
                    </div>
                    <div className={classes.description}>{item.description}</div>
                  </div>
                  <div>
                    <Button
                      className={classNames(classes.planButton, {
                        [classes.currentPlan]: isCurrentPlan,
                        [classes.upgradePlan]: !isCurrentPlan,
                      })}
                      type="button"
                      disabled={isCurrentPlan || !item?.available}
                      variant="contained"
                      color="primary"
                      onClick={() => changeSubscription(item)}
                    >
                      {isCurrentPlan
                        ? t('profile_current_plan')
                        : isYearlyPlan
                        ? t('profile_downgrade')
                        : t('profile_upgrade')}
                    </Button>
                  </div>
                </div>
                {!!isCurrentPlan && renderWarningMessage()}
                {showStudentMessage && renderStudentMessage(item.interval)}
              </Fragment>
            );
          })
        : null}
    </div>
  );
};

Subscription.propTypes = {
  profile: PropTypes.object.isRequired,
  subscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      interval: PropTypes.oneOf(['MONTH', 'YEAR']).isRequired,
      unitAmount: PropTypes.number.isRequired,
    })
  ).isRequired,
  subscriptionCreated: PropTypes.bool.isRequired,
  subscriptionDeleted: PropTypes.bool.isRequired,
  currentPlan: PropTypes.number,
  hasTrial: PropTypes.bool,
  trialDaysLeft: PropTypes.number,
  stripeSubscription: PropTypes.shape({
    cancelAtPeriodEnd: PropTypes.bool,
    currentPeriodEnd: PropTypes.string,
    endingBalance: PropTypes.number,
    isActive: PropTypes.bool,
    trialEnd: PropTypes.string,
  }),
  getAvailableSubscriptions: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    profile: state.profile.data,
    subscriptions: state.subscription.data,
    subscriptionCreated: state.subscription.subscriptionCreated,
    subscriptionDeleted: state.subscription.subscriptionDeleted,
    currentPlan: state.profile.data.currentPlan,
    hasTrial: state.profile.data.hasTrial,
    trialDaysLeft: state.profile.data.trialDaysLeft,
    error: state.subscription.error || {},
    stripeSubscription: state.profile.data.stripeSubscription,
  }),
  {
    getAvailableSubscriptions: getAvailableSubscriptionsRequest,
    getProfile: getProfileRequest,
    clearError: clearErrorAction,
    showModal: showModalAction,
    hideModal: hideModalAction,
  }
)(Subscription);
