import Cookies from 'universal-cookie';

import { API_URL } from '../constants';
import axios from './axios';

const profile = {
  async getProfile() {
    try {
      const result = await axios.get(`${API_URL}/profile/`);

      const cookies = new Cookies();
      cookies.set('user', result.data, { path: '/' });

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async updateProfile(data) {
    try {
      const result = await axios.put(`${API_URL}/profile/`, data);

      const cookies = new Cookies();
      cookies.set('user', result.data, { path: '/' });

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async updatePhone(phoneNumber) {
    try {
      const result = await axios.put(`${API_URL}/profile/phone/`, {
        phoneNumber,
      });

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async sendPhoneToken() {
    try {
      const result = await axios.post(`${API_URL}/profile/phone/send-token/`, {});

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async disconnectGoogleCalendar() {
    try {
      const result = await axios.post(`${API_URL}/profile/remove-google-calendar/`, {});

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async verifyPhoneToken(data) {
    try {
      const result = await axios.post(`${API_URL}/profile/phone/verify-token/`, data);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async createReminder(data) {
    try {
      const result = await axios.post(`${API_URL}/reminders/`, data);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async updateReminder({ id, body }) {
    try {
      const result = await axios.patch(`${API_URL}/reminders/${id}/`, body);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async updatePassword(data) {
    try {
      const result = await axios.post(`${API_URL}/profile/password/update/`, data);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async unsubscribeEmail(data) {
    try {
      const result = await axios.get(`${API_URL}/profile/unsubscribe/${data}`);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getLanguagesOptions() {
    try {
      const result = await axios.get(`${API_URL}/languages/`);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default profile;
