import React from 'react';
import PropTypes from 'prop-types';

const IconAddPublic = ({ size = 24, className }) => {
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 13.0249 21.8458 14.0138 21.5594 14.9447C21.2006 14.15 20.6401 13.4659 19.9433 12.9576C19.9807 12.6437 20 12.3241 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 14.0289 4.75529 15.8814 6.00005 17.2917C7.59159 15.8675 9.69494 15 12 15C12.1381 15 12.2754 15.0031 12.412 15.0093C12.147 15.6193 12 16.2925 12 17C10.2934 17 8.73006 17.6099 7.5148 18.6254C8.79403 19.4931 10.3378 20 12 20C12.3241 20 12.6437 19.9807 12.9576 19.9433C13.4659 20.6401 14.15 21.2006 14.9447 21.5594C14.0138 21.8458 13.0249 22 12 22C6.47715 22 2 17.5228 2 12ZM16 10C16 10.8598 15.7287 11.6563 15.267 12.3085C14.4222 12.6207 13.6848 13.1545 13.1254 13.8395C12.7684 13.944 12.3907 14 12 14C9.79086 14 8 12.2091 8 10C8 7.79086 9.79086 6 12 6C14.2091 6 16 7.79086 16 10ZM12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8ZM16.9993 14.0007C17.2754 14.0007 17.4993 14.15 17.4993 14.3341V16.5007H19.6659C19.85 16.5007 19.9993 16.7246 19.9993 17.0007C19.9993 17.2769 19.85 17.5007 19.6659 17.5007H17.4993V19.6674C17.4993 19.8515 17.2754 20.0007 16.9993 20.0007C16.7231 20.0007 16.4993 19.8515 16.4993 19.6674V17.5007H14.3326C14.1485 17.5007 13.9993 17.2769 13.9993 17.0007C13.9993 16.7246 14.1485 16.5007 14.3326 16.5007H16.4993V14.3341C16.4993 14.15 16.7231 14.0007 16.9993 14.0007Z"
      />
    </svg>
  );
};

IconAddPublic.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

export default IconAddPublic;
