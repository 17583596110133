import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: '330px',
    marginTop: '96px',
    fontSize: '12px',
    textAlign: 'center',
    color: theme.overrides.noticeColor,
    borderRadius: '4px',
  },

  link: {
    color: `${theme.palette.text.primary}!important`,
  },
}));

const Notice = () => {
  const classes = useStyles();

  return (
    <div style={{ width: '100%' }}>
      <Box className={classes.card}>
        <div>
          We want to help you as much as we can, but keep in mind, we’re not doctors. Read more{' '}
          <Link to="/our-mission" className={classes.link}>
            About us
          </Link>
        </div>
      </Box>
    </div>
  );
};

export default Notice;
