import axios from './axios';
import { API_URL } from '../constants';

const feedback = {
  async createFeedback(data) {
    try {
      const result = await axios.post(`${API_URL}/feedback/`, data);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getReCaptcha() {
    try {
      const result = await axios.get(`${API_URL}/feedback/recaptcha/`);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default feedback;
