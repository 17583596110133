import * as types from './journalActionTypes';

export const initialState = {
  notes: { data: [], total: 0 },
  note: null,
  pageSize: 10,
  processing: false,
  error: null,
};

const journal = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_PROCESSING:
      return { ...state, processing: action.payload };

    case types.GET_NOTES_SUCCESS: {
      const { results, count } = action.payload;

      return { ...state, notes: { data: results, total: count } };
    }

    case types.GET_NOTE_SUCCESS: {
      const result = action.payload;

      return { ...state, note: result };
    }

    case types.CLEAR_SINGLE_NOTE: {

      return { ...state, note: null };
    }

    case types.ADD_NOTE_SUCCESS: {
      const notes = state.notes.data.length > state.pageSize ? state.notes.data.slice(0, -1) : state.notes.data;

      return { ...state, notes: { data: [action.payload, ...notes], total: state.notes.total + 1 } };
    }
    case types.UPDATE_NOTE_SUCCESS: {
      return {
        ...state,
        notes: {
          ...state.notes,
          data: state.notes.data.map((note) => (note.id === action.payload.id ? { ...note, ...action.payload } : note)),
        },
      };
    }
    case types.REMOVE_NOTE_SUCCESS: {
      return {
        ...state,
        notes: { ...state.notes, data: state.notes.data.filter((x) => x.id !== action.payload) },
      };
    }
    default:
      return state;
  }
};

export default journal;
