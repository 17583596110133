export const REQUEST_ACCESS = 'REQUEST_ACCESS';
export const REQUEST_ACCESS_SUCCESS = 'REQUEST_ACCESS_SUCCESS';

export const REQUEST_SHARED_USERS = 'REQUEST_SHARED_USERS';
export const REQUEST_SHARED_USERS_SUCCESS = 'REQUEST_SHARED_USERS_SUCCESS';

export const REQUEST_SHARED_USER_RESULT = 'REQUEST_SHARED_USER_RESULT';
export const REQUEST_SHARED_USER_RESULT_SUCCESS = 'REQUEST_SHARED_USER_RESULT_SUCCESS';

export const UPDATE_SHARED_ACCESS = 'UPDATE_SHARED_ACCESS';
export const UPDATE_SHARED_ACCESS_SUCCESS = 'UPDATE_SHARED_ACCESS_SUCCESS';

export const DELETE_SHARED_ACCESS = 'DELETE_SHARED_ACCESS';
export const DELETE_SHARED_ACCESS_SUCCESS = 'DELETE_SHARED_ACCESS_SUCCESS';

export const GET_SHARED_USER_SECTIONS_LIST = 'GET_SHARED_USER_SECTIONS_LIST';
export const GET_SHARED_USER_SECTIONS_LIST_SUCCESS = 'GET_SHARED_USER_SECTIONS_LIST_SUCCESS';

export const ACCEPT_SHARED_REQUEST = 'ACCEPT_SHARED_REQUEST';

export const TOGGLE_PROCESSING = 'TOGGLE_SHARED_ACCESS_PROCESSING';
export const SHARED_ACCESS_REQUEST_ERROR = 'SHARED_ACCESS_REQUEST_ERROR';
export const SHARED_ACCESS_CLEAR = 'SHARED_ACCESS_CLEAR';
