import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import * as profileTypes from '../../redux/profile/profileActionsTypes';

const LanguagesProvider = ({ children }) => {
  const dispatch = useDispatch();
  const processing = useSelector((state) => state.profile.languagesOptionsProcessing);

  useEffect(() => {
    dispatch({ type: profileTypes.GET_LANGUAGES_OPTIONS_REQUEST });
  }, []);

  return processing ? null : children;
}

LanguagesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LanguagesProvider;
