import * as types from './generalActionsTypes';

export const toggleThemeAction = (payload) => {
  return { type: types.TOGGLE_THEME_ACTION, payload };
};

export const showModalAction = (payload) => {
  return { type: types.SHOW_MODAL_ACTION, payload };
};

export const hideModalAction = () => {
  return { type: types.HIDE_MODAL_ACTION };
};
