import axios from 'axios';
import Cookies from 'universal-cookie';

import { API_URL } from '../constants';

const auth = {
  async login(data = {}) {
    const response = await fetch(`${API_URL}/auth/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(data),
    });

    try {
      const text = await response.text();
      const json = JSON.parse(text || '{}');

      if (!response.ok) {
        return Promise.reject(json);
      }

      const cookies = new Cookies();
      cookies.set('token', json, { path: '/' });

      return json;
    } catch (err) {
      return Promise.reject({
        status: response.status,
        message: response.statusText,
      });
    }
  },

  async register(data) {
    try {
      const result = await axios.post(`${API_URL}/auth/register/`, { ...data, confirmedPassword: data.password });

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async googleOAuthJwtPair(data) {
    try {
      const result = await axios.post(`${API_URL}/social/jwt-pair/google-oauth2/`, data);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async logout() {
    try {
      const cookies = new Cookies();
      cookies.remove('token', { path: '/' });
      cookies.remove('user', { path: '/' });

      return true;
    } catch (err) {
      throw { error: 'Logout error.' };
    }
  },

  async resetPassword(data) {
    try {
      const result = await axios.post(`${API_URL}/profile/password/forget/`, data);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async setNewPassword(data) {
    try {
      const result = await axios.post(`${API_URL}/profile/password/reset/`, data);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async generateVerificationToken(token) {
    try {
      const result = await axios.post(
        `${API_URL}/auth/otp/generate/`,
        {},
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      );

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async verifyVerificationToken(token, code) {
    try {
      const result = await axios.post(
        `${API_URL}/auth/otp/verify/`,
        {
          token: code,
        },
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      );

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getSocialKeys() {
    try {
      const result = await axios.get(`${API_URL}/auth/social-logins/`);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default auth;
