import { Box, Breadcrumbs, Grid, MenuItem, Select, Typography, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AchievementCard from './AchievementCard';
import { BootstrapInput } from '../Dashboard/UserTestCharts';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '120px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '40px',
    },
  },
  pageHeader: {
    width: '100%',
    alignItems: 'center',
  },
  backLink: {
    display: 'flex',
    alignItems: 'center',
    width: '100px',
    height: '52px',
    fontSize: '1em',
    padding: '22px 16px 22px 8px',
    textTransform: 'capitalize',
    border: `solid 1px ${theme.overrides.searchPlaceholder}`,
    borderRadius: '8px',
    '& span': {
      marginLeft: '3px',
      color: theme.palette.text.primary,
    },
  },
  backArrow: {
    transform: 'rotate(90deg)',
    fontSize: '1.75em',
    color: theme.palette.text.primary,
  },
  achieveContainer: {
    backgroundColor: theme.palette.type === 'dark' ? '#3A5188' : '#fff',
    boxShadow: theme.palette.type === 'dark' ? 'none' : "0px 2px 5px 0px #0000001A",
    color: theme.palette.type === 'dark' ? '#F2F2F2' : '#2A2B2A',
    padding: '32px',
    fontSize: '16px',
    borderRadius: '8px',
    fontWeight: '200',
    '& .content': {
      marginBottom: '10px',
    },
    '& .link': {
      color: '#00C1FF',
    },
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  selectMenuPaper: {
    backgroundColor: theme.overrides.filterListBackground,
  },
  achieveList: {
    margin: '48px 0',
    '& .achieve__content': {
      color: theme.palette.type === 'dark' ? '#F2F2F2' : '#2A2B2A',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: '62px',
    },
  },
  subtitle: {
    color: '#ffffffd1',
    fontSize: '14px'
  }
}));


const Achievements = ({ badges }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [achievementType, setAchievementType] = useState(t('achievements_type', { returnObjects: true })[0].type);

  const handleAchieveType = (event, data) => {
    setAchievementType(data.props.value)
  }

  const sortedBadges = useMemo(() => {
    switch(achievementType) {
      case 'all':
        return badges
      case 'completed':
        return badges.filter((badge) => badge.count)
      case 'uncompleted':
        return badges.filter((badge) => !badge.count)
      default:
        break;
    }
  }, [achievementType, badges])

  return (
    <>
      <Helmet>
        <title>Myndful</title>
      </Helmet>
      <Box my={3}>
        <div className="breadcrumbs-outer">
          <div className="breadcrumbs-wrapper">
            <Breadcrumbs className="breadcrumbs">
              <Link to="/dashboard">{t('m_dashboard')}</Link>
              <Link to="/dashboard/achievements">{t('m_achievements')}</Link>
            </Breadcrumbs>
          </div>
        </div>
        <div className={classes.titleContainer}>
          <div>
            <Typography variant="h4">{t('m_achievements')}</Typography>
            <span className={classes.subtitle}>
              {t('achievements_subtitle')}
            </span>
          </div>

          <Select
                labelId="category-label"
                id="category"
                value={achievementType}
                MenuProps={{
                  classes: { paper: classes.selectMenuPaper },
                  transitionDuration: 0,
                }}
                onChange={handleAchieveType}
                input={<BootstrapInput variant="contained" style={{ width: '185px' }} />}
              >
                {t('achievements_type', { returnObjects: true }).map((type, index) => (
                  <MenuItem key={`option-${index}`} value={type.type}>
                    { type.value }
                  </MenuItem>
                ))}
              </Select>
        </div>
      </Box>
      <Box my={3} className={classes.achieveContainer}>
        <div className="content">
          {t("m_achievements_content")}
        </div>
        <a href="https://myndful.us/" target="_blank" className="link">
          Myndful.us
        </a>
      </Box>
      <Box className={classes.achieveList}>
        <Grid className="achieve__content">
          {badges && badges.length && sortedBadges.map((badge, index) => <AchievementCard key={index} badge={badge} />)}
        </Grid>
      </Box>
    </>
  );
};

export default connect((state) => ({
  badges: state.profile.data.badges,
}))(Achievements);
