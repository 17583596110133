export const SHOW_MODAL_ACTION = 'SHOW_MODAL';
export const HIDE_MODAL_ACTION = 'HIDE_MODAL';
export const TOGGLE_THEME_ACTION = 'TOGGLE_THEME';

export const MODAL_TYPE_TEMPLATE_DESELECT = 'MODAL_TYPE_TEMPLATE_DESELECT';
export const MODAL_TYPE_WELCOME = 'MODAL_TYPE_WELCOME';
export const MODAL_TYPE_TEMPLATE_SHARE = 'MODAL_TYPE_TEMPLATE_SHARE';
export const MODAL_TYPE_TEMPLATE_PERSONAL = 'MODAL_TYPE_TEMPLATE_PERSONAL';
export const MODAL_TYPE_TEMPLATE_DELETE_PERSONAL = 'MODAL_TYPE_TEMPLATE_DELETE_PERSONAL';
export const MODAL_TYPE_ALERT = 'MODAL_TYPE_ALERT';
export const MODAL_TYPE_CONFIRM = 'MODAL_TYPE_CONFIRM';
export const MODAL_TYPE_CUSTOM_GOAL = 'MODAL_TYPE_CUSTOM_GOAL';
export const MODAL_TYPE_CUSTOM_TRACKER = 'MODAL_TYPE_CUSTOM_TRACKER';
export const MODAL_TYPE_DOWNGRADE_PLAN = 'MODAL_TYPE_DOWNGRADE_PLAN';
export const MODAL_TYPE_VERIFY_PHONE = 'MODAL_TYPE_VERIFY_PHONE';
export const MODAL_TYPE_REQUEST_SHARED_ACCESS = 'MODAL_TYPE_REQUEST_SHARED_ACCESS';
export const MODAL_TYPE_SKIP_TASK = 'MODAL_TYPE_SKIP_TASK';
export const MODAL_TYPE_TEMPLATE = 'MODAL_TYPE_TEMPLATE';
export const MODAL_TYPE_UPDATE_NOTE = 'MODAL_TYPE_UPDATE_NOTE';
export const MODAL_TYPE_UPDATE_PASSWORD = 'MODAL_TYPE_UPDATE_PASSWORD';
export const MODAL_TYPE_UPGRADE_PLAN = 'MODAL_TYPE_UPGRADE_PLAN';
