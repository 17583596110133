import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Checkbox,
  FormControlLabel,
  makeStyles,
  Typography,
  Switch,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { IconClock, IconDelete, IconEdit } from '../../assets/inline-images';
import { connect } from 'react-redux';
import { MODAL_TYPE_CONFIRM } from '../../redux/general/generalActionsTypes';

const googleCalendarIcon = '/images/google-calendar.png';

const useStyles = makeStyles((theme) => ({
  accordion: {
    position: 'relative',
    backgroundColor: `${theme.overrides.accordionBackground} !important`,
    borderRadius: '8px !important',
  },
  active: {
    backgroundColor: `${theme.overrides.accordionBackground} !important`,
  },
  checked: {
    backgroundColor: `${theme.overrides.accordionBackground} !important`,
    '& .custom-goal--label': {
      opacity: 0.38,
    },
  },
  actionsContent: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column"
    }
  },
  actions: {
    marginLeft: "15px",
    display: 'flex',
    gap: '16px',
    pointerEvents: 'all',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-around',
      position: 'relative',
      top: 'unset',
      right: 'unset',
      transform: 'none',
      width: '100%',
      marginTop: '25px',
      paddingTop: '12px',
      borderTop: theme.palette.type === 'dark' ? '1px solid #9c9c9c' : '1px solid rgba(0,0,0, 0.2)',
      '& img:not(:last-child)': {
        marginRight: '0',
      },
    },
  },
  actionIcon: {
    color: theme.palette.type === 'dark' ? '#F2F2F2' : '#757575',
    cursor: 'pointer'
  },
  container: {
    position: 'relative',
    width: '100%',
  },
  checkbox: {
    '&.Mui-checked': {
      color: theme.palette.type === 'dark' ? '#FFFFFF' : 'rgba(0, 0, 0, 0.54)',
    },
  },
  customGoalWrapper: {
    position: 'relative',
    borderRadius: '8px !important',
    marginBottom: '12px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '50px',
    },
    '& .MuiAccordionSummary-root.Mui-disabled': {
      opacity: 1,
    },
    '& .MuiAccordionSummary-content': {
      width: '100%'
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0'
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'unset',
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiAccordionSummary-content': {
        flexDirection: 'column'
      },
    },
  },
  shadowCheckbox: {
    width: '16px',
    height: '42px',
  },
  accordionQuestionWrapper: {
    wordBreak: 'break-word',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '8px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      width: "100%",
      alignItems: 'center',
      justifyContent: "space-between"
    },
  },
  goalLabel: {
    paddingRight: '8px',
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      padding: '0 !important'
    },
  },
  bigMarginRight: {
    marginRight: '86px'
  },
  goalValue: {
    fontSize: '12px',
    backgroundColor: '#0ABF53',
    padding: '6px 12px',
    color: '#FFFFFF',
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: 10,
      fontSize: 12,
    },
  },
  goalScheduled: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '8px',
    color: theme.palette.type === 'dark' ? '#FFFFFF' : '#0035FF',
    [theme.breakpoints.down('sm')]: {
      margin: '0 !important'
    },
  },
  template: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    maxWidth: '300px',
    height: '100%',
    marginRight: '86px'
  },
  noDescription: {
    marginRight: '120px',
  },
  templateLabel: {
    marginRight: '8px',
    whiteSpace: 'nowrap',
    opacity: 0.4
  },
  templateTitle: {
    maxWidth: '174px',
    padding: '3px 10px',
    backgroundColor: '#B37FEB',
    borderRadius: '3px',
    color: '#FFFFFF',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  content: {
    marginTop: '12px',
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-word',
  },
  link: {
    fontSize: '0.75rem',
    lineHeight: 1.5,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  cursorDefault: {
    cursor: 'default !important'
  },
  detailsContainer: {
    [theme.breakpoints.up('sm')]: {
      borderTop: theme.palette.type === 'dark' ? '1px solid #9c9c9c' : '1px solid rgba(0,0,0, 0.2)',
    },
  },
  placeholder: {
    fontStyle: 'italic',
    width: '100%',
    padding: 40,
    textAlign: 'center',
    opacity: 0.8,
  },
  googleSyncContent: {
    display: "flex",
    alignItems: "center",
    border: theme.palette.type === 'dark' ? '1px solid #9c9c9c' : '1px solid rgba(0,0,0, 0.2)',
    borderRadius: "8px",
    padding: "8px 16px",
    [theme.breakpoints.down('sm')]: {
      padding: "5px 10px",
    },
    '& .google-sync__content': {
      fontSize: "14px",
      marginLeft: "8px",
      marginRight: "16px",
      width: "max-content",
      [theme.breakpoints.down('sm')]: {
        width: "auto",
        fontSize: "12px",
      },
    },
    '& .google-sync__icon': {
      width: "16px",
      height: "16px",
      objectFit: "contain"
    }
  },
  switchLabel: {
    display: 'inline-flex',
    fontSize: '14px',
    margin: '8px 0',
    cursor: 'default',
  },
  switchRoot: {
    width: 32,
    height: 18,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 2,
    color: '#FFF',
    '&$checked': {
      transform: 'translateX(14px)',
      color: theme.palette.type === 'dark' ? '#253E7B' : '#FFF',
      '& + $track': {
        backgroundColor: theme.palette.type === 'dark' ? '#C2C2C2' : '#007EFF',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#FFF',
      border: '6px solid lightgrey',
    },
  },
  focusVisible: {},
  thumb: {
    width: 14,
    height: 14,
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: '#C2C2C2',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  scheduleContent: {
    display: "flex",
    marginRight: "20px",
    "& span": {
      width: "max-content"
    },
    [theme.breakpoints.down('sm')]: {
      margin: "10px 0"
    },
  }
}));

const CustomGoalItem = ({
  id,
  title,
  description,
  score,
  sectionIcon,
  actions,
  isActive,
  isChecked,
  createdAt,
  frequency,
  schedule,
  template,
  isProcessing,
  showTemplateLabel,
  onToggleCustomGoal,
  onEditCustomGoal,
  onDeleteCustomGoal,
  profile,
  googleAuthUrl,
  isDarkTheme,
  uploadToGoogleCalendar,
  handleSwitchSyncCalendar,
  hideModal,
  showModal,
  isTemplateGoal = false
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isConnected = !!profile.isGoogleCalendarConnected;
  const canControlNotifications = !!profile.currentPlan || !!profile.hasTrial;
  const isDisabled = isProcessing || !googleAuthUrl || !isConnected || !canControlNotifications;

  const isEdit = actions === 'edit';
  const isDescription = Boolean(description?.trim());
  const isTemplateLabel = Boolean(showTemplateLabel && !!template?.id);

  const onChangeSubscription = () => {
    !isDisabled && showModal({
      params: {
        cancelAction: hideModal,
        confirmAction: () => {
          window.location.href = googleAuthUrl?.replace('social-google', 'profile');
          hideModal();
        },
        confirmButtonText: t('modal_subscription_button'),
        text: t('modal_subscription_text'),
      },
      title: t('modal_subscription_title'),
      type: MODAL_TYPE_CONFIRM,
    });
  };

  const handleClickToggle = (_evt, value) => {
    onToggleCustomGoal(id, value);
  };

  const handleClickEdit = (evt) => {
    evt.stopPropagation();
    onEditCustomGoal(id);
  };

  const handleClickDelete = (evt) => {
    evt.stopPropagation();
    onDeleteCustomGoal(id);
  };

  const calculateDate = () => {
    if (frequency === 'DAILY') {
      return moment().add(1, 'day').format('ddd D MMM');
    } else if (frequency === 'WEEKLY') {
      let value = '';

      [1, 2, 3, 4, 5, 6, 7].forEach((index) => {
        const date = moment().add(index, 'day');
        const day = date.format('dddd').toUpperCase();

        if (schedule?.includes(day) && !value) {
          value = date.format('ddd D MMM');
        }
      });

      return value;
    } else if (frequency === 'MONTHLY') {
      return moment(createdAt).add(1, 'month').format('ddd D MMM');
    }

    return '';
  };
  const date = calculateDate();

  return (
    <div key={`custom-goal-${id}`} className={classes.customGoalWrapper}>
      <Accordion
        className={classNames(classes.accordion, { [classes.active]: isActive, [classes.checked]: isChecked })}
        disabled={showTemplateLabel && isEdit && (!isActive || isChecked)}
      >
        <AccordionSummary
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
          {...(isDescription ? {
            expandIcon: <ExpandMore />
          } : {
            className: classes.cursorDefault
          })}
        >
          <div
            className={classNames(
              classes.goalLabel,
              { [classes.bigMarginRight]: !isTemplateLabel },
              'custom-goal--label'
            )}
          >
            {typeof onToggleCustomGoal === 'function' ? (
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={(
                  <Checkbox
                    className={classes.checkbox}
                    color="primary"
                    checked={isChecked}
                    disabled={isProcessing}
                    onChange={handleClickToggle}
                  />
                )}
                label={!!sectionIcon && (
                  <Box component={'span'} pl="10px" display="flex">
                    <img src={sectionIcon} width={24} alt="" />
                  </Box>
                )}
              />
            ) : (
              <div className={classes.shadowCheckbox} />
            )}
            <div className={classes.accordionQuestionWrapper}>
              <span style={{ textDecoration: isChecked ? 'line-through' : 'none' }} >{title}</span>
              {!!score && (
                <span className={classes.goalValue}>
                  {Math.round(score) || 0}&#160;{t('points')}
                </span>
              )}
            </div>
          </div>
          <div className={classes.scheduleContent}>
            {(!isEdit || !isActive || isChecked) && !!date && (
                  <span className={classes.goalScheduled}>
                    <IconClock currentColor={isDarkTheme ? '#fff' : '#000'} /> {`Scheduled on ${date}`}
                  </span>
              )}
          </div>
          <div className={classes.actionsContent}>
           {!isTemplateGoal && <div className={classes.googleSyncContent} onClick={(e) => e.stopPropagation()}>
                <Switch
                  classes={{
                    root: classes.switchRoot,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked,
                  }}
                  color="default"
                  checked={ isConnected && uploadToGoogleCalendar}
                  disabled={isDisabled}
                  onChange={() => isDisabled ? onChangeSubscription() : handleSwitchSyncCalendar(id, !uploadToGoogleCalendar)}
                />
                <span className='google-sync__content'>
                  {t("google_sync")}
                </span>
                <img className='google-sync__icon' src={googleCalendarIcon} alt="" />
            </div>}
            {isEdit && (
              <div className={classes.actions}>
              <span className={classes.actionIcon} onClick={handleClickEdit}>
                <IconEdit size={26} />
              </span>
                <span className={classes.actionIcon} onClick={handleClickDelete}>
                <IconDelete size={26} />
              </span>
              </div>
            )}
          </div>
        </AccordionSummary>
        {isDescription && (
          <AccordionDetails className={classes.detailsContainer}>
            <div className={classes.container}>
              <Box className={classes.content}>
                <Typography component="div" variant="caption">
                  {description}
                </Typography>
              </Box>
            </div>
          </AccordionDetails>
        )}
      </Accordion>
    </div>
  );
};

CustomGoalItem.propTypes = {
  profile: PropTypes.object.isRequired,
  googleAuthUrl: PropTypes.string,
  handleSwitchSyncCalendar: PropTypes.func.isRequired,
  hideModal: PropTypes.func,
  showModal: PropTypes.func,
  id: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  score: PropTypes.number,
  sectionIcon: PropTypes.string,
  actions: PropTypes.oneOf(['view', 'check', 'edit']),
  isActive: PropTypes.bool,
  isChecked: PropTypes.bool,
  isProcessing: PropTypes.bool,
  createdAt: PropTypes.string,
  frequency: PropTypes.string,
  schedule: PropTypes.array,
  template: PropTypes.object,
  showTemplateLabel: PropTypes.bool,
  onToggleCustomGoal: PropTypes.func,
  onEditCustomGoal: PropTypes.func,
  onDeleteCustomGoal: PropTypes.func,
  isDarkTheme: PropTypes.bool,
  isTemplateGoal: PropTypes.bool
};

export default connect((state) => ({
  profile: state.profile.data,
  googleAuthUrl: state.auth.googleAuthUrl,
  isDarkTheme: state.general.isDarkTheme,
}))(CustomGoalItem)