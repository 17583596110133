import i18n from 'i18next';
import i18nBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';


i18n
  .use(i18nBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: localStorage.getItem('language') || 'en',
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/i18n/{{lng}}.json`,
    },
    react: {
      useSuspense: false,
      wait: true
    }
  })
  .catch((err) => {
    console.error(err);
  });

export default i18n;
