import * as types from './subscriptionActionsTypes';
import * as authTypes from '../auth/authActionsTypes';

export const initialState = {
  data: [],
  publicKey: '',
  processing: false,
  subscriptionCreated: false,
  subscriptionDeleted: false,
  error: null,
};

const subscription = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };

    case types.GET_AVAILABLE_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };

    case types.GET_SUBSCRIPTION_PUBLIC_KEY_SUCCESS:
      return {
        ...state,
        publicKey: action.payload.key,
      };

    case types.CREATE_USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptionCreated: true,
      };

    case types.DELETE_USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptionDeleted: true,
      };

    case types.SUBSCRIPTION_REQUEST_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case authTypes.LOGOUT_REQUEST:
      return initialState;

    case types.CLEAR_ERROR:
      return {
        ...state,
        subscriptionCreated: false,
        subscriptionDeleted: false,
        error: null,
      };

    default:
      return state;
  }
};

export default subscription;
