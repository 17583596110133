export const GET_ARTICLE_LIST_REQUEST = 'GET_ARTICLE_LIST_REQUEST';
export const GET_ARTICLE_LIST_SUCCESS = 'GET_ARTICLE_LIST_SUCCESS';

export const GET_ARTICLE_LIST_CATEGORY_REQUEST = 'GET_ARTICLE_LIST_CATEGORY_REQUEST';
export const GET_ARTICLE_LIST_CATEGORY_SUCCESS = 'GET_ARTICLE_LIST_CATEGORY_SUCCESS';

export const GET_ARTICLE_CATEGORY_REQUEST = 'GET_ARTICLE_CATEGORY_REQUEST';
export const GET_ARTICLE_CATEGORY_SUCCESS = 'GET_ARTICLE_CATEGORY_SUCCESS';

export const SEARCH_ARTICLE_LIST_REQUEST = 'SEARCH_ARTICLE_LIST_REQUEST';
export const SEARCH_ARTICLE_LIST_SUCCESS = 'SEARCH_ARTICLE_LIST_SUCCESS';

export const GET_SINGLE_ARTICLE_REQUEST = 'GET_SINGLE_ARTICLE_REQUEST';
export const GET_SINGLE_ARTICLE_SUCCESS = 'GET_SINGLE_ARTICLE_SUCCESS';

export const CLEAR_SINGLE_ARTICLE_ACTION = 'CLEAR_SINGLE_ARTICLE_ACTION';
export const TOGGLE_PROCESSING = 'TOGGLE_BLOG_PROCESSING';
