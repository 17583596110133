export const GET_SIGN_UP_QUESTIONNAIRE_REQUEST = 'GET_SIGN_UP_QUESTIONNAIRE_REQUEST';
export const GET_SIGN_UP_QUESTIONNAIRE_SUCCESS = 'GET_SIGN_UP_QUESTIONNAIRE_SUCCESS';

export const GET_QUESTIONNAIRE_LIST_REQUEST = 'GET_QUESTIONNAIRE_LIST_REQUEST';
export const GET_QUESTIONNAIRE_LIST_SUCCESS = 'GET_QUESTIONNAIRE_LIST_SUCCESS';

export const GET_QUESTIONNAIRE_GROUP_REQUEST = 'GET_QUESTIONNAIRE_GROUP_REQUEST';
export const GET_QUESTIONNAIRE_GROUP_SUCCESS = 'GET_QUESTIONNAIRE_GROUP_SUCCESS';

export const GET_QUESTIONNAIRE_REQUEST = 'GET_QUESTIONNAIRE_REQUEST';
export const GET_QUESTIONNAIRE_SUCCESS = 'GET_QUESTIONNAIRE_SUCCESS';

export const GET_QUESTIONNAIRE_SECTIONS_REQUEST = 'GET_QUESTIONNAIRE_SECTIONS_REQUEST';
export const GET_QUESTIONNAIRE_SECTIONS_SUCCESS = 'GET_QUESTIONNAIRE_SECTIONS_SUCCESS';

export const GET_QUESTIONNAIRE_QUESTIONS_REQUEST = 'GET_QUESTIONNAIRE_QUESTIONS_REQUEST';
export const GET_QUESTIONNAIRE_QUESTIONS_SUCCESS = 'GET_QUESTIONNAIRE_QUESTIONS_SUCCESS';

export const CREATE_USER_TEST_REQUEST = 'CREATE_USER_TEST_REQUEST';
export const CREATE_USER_TEST_SUCCESS = 'CREATE_USER_TEST_SUCCESS';

export const QUESTIONNAIRE_REQUEST_ERROR = 'QUESTIONNAIRE_REQUEST_ERROR';
export const CLEAR_ERROR = 'CLEAR_QUESTIONNAIRE_ERROR';

export const CLEAR_USER_TEST_ACTION = 'CLEAR_USER_TEST_ACTION';
export const TOGGLE_PROCESSING = 'TOGGLE_QUESTIONNAIRE_PROCESSING';
