import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  navActionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > .header__get-early-access': {
      margin: 0,
      background: '#325cc84d',
      borderRadius: 8,
      padding: '14px 28px',
      textTransform: 'none',
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontSize: 16,
      color: '#263D7A',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '14px 25px',
      },
    },
  },
  headerLink: {
    textTransform: 'none',
    fontFamily: 'Montserrat',
    display: 'block',
    margin: '0 40px',
    fontWeight: 500,
    fontSize: 16,
    color: '#263D7A',
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 20px',
    },
  },
  menuItem: {
    minWidth: 150,
    minHeight: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      color: '#000000',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
  },
  logoutButton: {
    color: '#F33935',
  },
  signUp: {
    fontFamily: 'Montserrat',
    fontWeight: '500'
  }
}));


const PublicHeaderDesktop = ({ isAuthenticated }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.navActionsContainer}>
      <Link to="/blog">
        <Typography className={classes.headerLink} noWrap>
          {t('m_blog')}
        </Typography>
      </Link>
      {isAuthenticated ? (
        <Link to="/personalized-plan">
          <Typography className={classes.headerLink} noWrap>
            {t('m_open_app')}
          </Typography>
        </Link>
      ) : (
        <>
          <Link to="/auth/sign-in">
            <Typography className={classes.headerLink} noWrap>{t('m_sign_in')}</Typography>
          </Link>
          <Link to="/auth/sign-up" className="header__get-early-access">
            <Typography className={classes.signUp} noWrap>{t('m_sign_up')}</Typography>
          </Link>
        </>
      )}
    </div>
  );
};

PublicHeaderDesktop.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
}; 

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  username: state.profile.data?.username || '',
});

export default connect(mapStateToProps)(PublicHeaderDesktop);
