import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import classNames from 'classnames';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import {
  getRandomGoalsRequest,
  getStandardGoalsRequest,
  updateStandardGoalRequest,
} from '../../redux/dashboard/dashboardActions';
import { showModalAction } from '../../redux/general/generalActions';
import { MODAL_TYPE_SKIP_TASK } from '../../redux/general/generalActionsTypes';

const useStyles = makeStyles((theme) => ({
  accordion: {
    position: 'relative',
    marginBottom: '12px',
    backgroundColor: theme.overrides.accordionBackground,
    borderRadius: '8px !important',
  },
  noDescription: {
    padding: '12px 16px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.down('sm')]: {
      padding: '17px 16px',
    },
    '& label, .MuiFormControlLabel-label': {
      width: '100% !important',
    },
  },
  accordionQuestionWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  container: {
    position: 'relative',
    width: '100%',
  },
  hr: {
    position: 'absolute',
    top: '-8px',
    width: '100%',
    borderTop: '1px solid rgba(255, 255, 255, 0.2)',
  },
  checkbox: {
    '&.Mui-checked': {
      color: theme.palette.type === 'dark' ? '#FFFFFF' : 'rgba(0, 0, 0, 0.54)',
    },
  },
  checkedGoal: {
    opacity: '0.5',
    pointerEvents: 'none',
  },
  goalValue: {
    marginLeft: '8px',
    fontSize: '12px',
    backgroundColor: '#0ABF53',
    padding: '6px 12px',
    color: '#FFFFFF',
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: 10,
      fontSize: 12,
    },
  },
  congratulationText: {
    fontSize: '14px',
    fontWeight: 300,
    color: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.6)' : '#000000',
  },
  content: {
    marginTop: '12px',
    display: 'flex',
    flexDirection: 'column',
    '& p': {
      margin: '0 0 5px',
      fontSize: '0.75rem',
      lineHeight: '0.875rem',
    },
    '& a': {
      textDecoration: 'underline',
    },
  },
  link: {
    fontSize: '0.75rem',
    lineHeight: 1.5,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  placeholder: {
    fontStyle: 'italic',
    width: '100%',
    padding: 40,
    textAlign: 'center',
    opacity: 0.8,
  },
  skipButton: {
    marginLeft: '20px',
    padding: '5px 16px',
    backgroundColor: theme.palette.type === 'dark' ? 'transparent' : ' white',
    color: theme.palette.type === 'dark' ? 'white' : 'black',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '22px',
    cursor: 'pointer',
    border: '1px solid #D9D9D9',
    boxShadow: '0 2px 0 rgba(0, 0, 0, 0.016)',
    borderRadius: '2px',
    '&:hover': {
      backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.1)' : '#EEEEEE',
    },
  },
  randomButton: {
    background: theme.palette.type === 'dark' ? '#406BE1' : 'linear-gradient(90deg, #0035FF 0%, #00C1FF 100%)',
    color: '#FFFFFF',
    padding: ' 6px 24px',
    borderRadius: '8px',
    fontSize: '16px',
    textTransform: 'none',
    border: 0,
  },
}));

const StandardGoals = ({
  showModal,
  standardGoals = [],
  isProcessing,
  getStandardGoals,
  getRandomGoals,
  isDarkTheme,
  updateStandardGoal,
  showConfetti,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [current, setCurrent] = useState(null);
  const isCheckedAll = standardGoals.length > 0 && standardGoals.every((x) => x.isChecked);
  const isLastGoal = standardGoals.length > 0 && standardGoals.filter((goal) => !goal.isChecked).length === 1;

  useEffect(() => {
    getStandardGoals();
  }, [getStandardGoals]);

  useEffect(() => {
    !isProcessing && setCurrent(null);
  }, [isProcessing]);

  const { emptyBoardSrc, boardSrc } = isDarkTheme
    ? { emptyBoardSrc: '/images/board-empty.svg', boardSrc: '/images/board-filled.svg' }
    : { emptyBoardSrc: '/images/icon-empty-board-light.svg', boardSrc: '/images/icon-board-light.svg' };

  const generateRandomTask = () => {
    getRandomGoals();
  };

  const skip = (id, isChecked) => {
    showModal({
      title: t('modal_plan_skip_task'),
      type: MODAL_TYPE_SKIP_TASK,
      params: {
        id,
        isChecked,
      },
    });
  };

  const onCheck = (item, value) => {
    setCurrent(item.id);
    updateStandardGoal({ id: item.id, body: { isChecked: value } });
    if (isLastGoal) {
      showConfetti(true);
    }
  };

  const renderGoal = (item) => {
    return (
      <FormControlLabel
        aria-label="Acknowledge"
        className={classNames({ [classes.checkedGoal]: item.isChecked })}
        onClick={(event) => event.stopPropagation()}
        onFocus={(event) => event.stopPropagation()}
        control={
          <>
            <Checkbox
              className={classes.checkbox}
              color="primary"
              checked={item.isChecked}
              disabled={isProcessing && current === item.id}
              onChange={(evt, value) => onCheck(item, value)}
            />
            {!!item?.sectionIcon && (
              <Box pr="10px">
                <img src={item?.sectionIcon} width={24} alt="" />
              </Box>
            )}
          </>
        }
        label={
          <div className={classes.accordionQuestionWrapper}>
            <div>
              <span>{item.title || item.actionItem || ''}</span>
              {!!item.score && (
                <span className={classes.goalValue}>
                  {Math.round(item.score) || 0}&#160;{t('points')}
                </span>
              )}
            </div>
            <button onClick={() => skip(item.id, item.isChecked)} className={classes.skipButton}>
              {t('skip')}
            </button>
          </div>
        }
      />
    );
  };

  const renderAllGoals = () => {
    const uncheckedGoals = standardGoals?.filter((el) => !el.isChecked);

    if (!uncheckedGoals.length) {
      return (
        <Box display="flex" flexDirection={'column'} justifyContent="center" alignItems="center">
          <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
            <img src={emptyBoardSrc} alt="board-empty" />
            <Box display="flex" flexDirection="column" style={{ maxWidth: '500px', flexGrow: 1, paddingLeft: '20px' }}>
              <Typography variant="h5" style={{ fontWeight: 600 }}>
                {t('your_plan_title_1')}
              </Typography>
              <div className={classes.congratulationText}>
                {t('your_plan_text_1')}
              </div>
            </Box>
          </Box>
          <Button onClick={generateRandomTask} className={classes.randomButton}>
            {t('your_plan_choose_random')}
          </Button>
        </Box>
      );
    }

    return uncheckedGoals.map((item) => {
      if (!item.question || !item.question.message || typeof item.question.message !== 'string') {
        return (
          <div
            key={`standard-goal-${item.id}`}
            className={classNames(classes.accordion, { 'personalized-plan-item': isProcessing && current === item.id })}
          >
            <div className={classes.noDescription}>{renderGoal(item)}</div>
          </div>
        );
      }

      let text = item.question.message;
      try {
        text = parse(lodash.unescape(text));
      } catch (e) {
        console.error(e);
      }

      return (
        <Accordion
          key={`standard-goal-${item.id}`}
          className={classNames(classes.accordion, { 'personalized-plan-item': isProcessing && current === item.id })}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            id="additional-actions1-header"
          >
            {renderGoal(item)}
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.container}>
              <div className={classes.hr} />
              <Box className={classes.content}>{text}</Box>
            </div>
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  return (
    <div>
      <Box mb={3} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">{t('your_plan_daily_tasks')}</Typography>
        {!!standardGoals.length && (
          <Button onClick={generateRandomTask} className={classes.randomButton}>
            {t('your_plan_choose_random')}
          </Button>
        )}
      </Box>
      <div className="main-form__section">
        {isCheckedAll ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <img src={boardSrc} alt="board-filled" />
            <Box display="flex" flexDirection="column" style={{ maxWidth: '500px', flexGrow: 1, paddingLeft: '20px' }}>
              <Typography variant="h5" style={{ fontWeight: 600 }}>
                {t('your_plan_congratulations')}
              </Typography>
              <div className={classes.congratulationText}>{t('your_plan_you_completed_tasks')}</div>
            </Box>
          </Box>
        ) : (
          renderAllGoals()
        )}
      </div>
    </div>
  );
};

StandardGoals.propTypes = {
  standardGoals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string,
      actionItem: PropTypes.string,
      score: PropTypes.number.isRequired,
      isChecked: PropTypes.bool.isRequired,
    })
  ).isRequired,
  isProcessing: PropTypes.bool.isRequired,
  error: PropTypes.object.isRequired,
  getStandardGoals: PropTypes.func.isRequired,
  getRandomGoals: PropTypes.func.isRequired,
  updateStandardGoal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  isDarkTheme: PropTypes.bool,
  showConfetti: PropTypes.func,
};

export default connect(
  (state) => ({
    standardGoals: state.dashboard.standardGoals,
    isProcessing: state.dashboard.processing,
    isDarkTheme: state.general.isDarkTheme,
    error: state.dashboard.error || {},
  }),
  {
    getStandardGoals: getStandardGoalsRequest,
    getRandomGoals: getRandomGoalsRequest,
    updateStandardGoal: updateStandardGoalRequest,
    showModal: showModalAction,
  }
)(StandardGoals);
