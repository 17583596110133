import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const iconStar = '/images/card-icon-star.svg';
const iconTrophy = '/images/card-icon-trophy.svg';
const iconCrown = '/images/card-icon-crown.svg';

import { QUESTIONNAIRE_STATUSES } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    '& button': {
      color: '#FFFFFF',
      padding: '9px!important',
      borderRadius: '8px',
      '& svg': {
        width: '38px',
        height: '38px',
      },
    },
  },
  questionnaire: {
    padding: '12px 24px',
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.type === 'dark' ? 'transparent' : '#F0F0F0'}`,
    borderRadius: '8px',
    minHeight: '116px',
    height: '100%',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
    '&.completed': {
      pointerEvents: 'none',
    },
    '&.completed .card-button-wrapper': {
      backgroundColor: 'rgba(255,255,255, 0.4)',
    },
  },
  image: {
    opacity: theme.overrides.textOpacity,
    color: theme.overrides.textBlue,
    fontSize: '90px',
  },
  title: {
    fontWeight: '600',
    color: '#FFFFFF',
    fontSize: '22px',
  },
  timeText: {
    color: '#FFFFFF',
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      marginTop: '5px',
    },
  },
  timeContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '60px',
    height: '100%',
    borderRadius: '6px',
    marginRight: '16px',
    textAlign: 'center',
    '& span': {
      display: 'block',
      color: theme.overrides.textBlue,
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 700,
      cursor: 'default',
      '&:first-child': {
        marginTop: '5px',
        fontSize: '1.125rem',
      },
      '&:last-child': {
        fontSize: '0.75rem',
      },
    },
    [theme.breakpoints.between(960, 1250)]: {
      width: '50px',
      '& span': {
        '&:first-child': {
          fontSize: '1rem !important',
        },
        '&:last-child': {
          fontSize: '0.685rem !important',
        },
      },
    },
  },
  description: {
    display: 'flex',
    flexWrap: 'nowrap !important',
    width: '100%',
    '& > div:last-child': {
      marginLeft: '20px',
    },
    [theme.breakpoints.between(960, 1250)]: {
      flexWrap: 'wrap',
      '& > div:last-child': {
        marginTop: '15px',
        textAlign: 'left',
      },
    },
  },
  first: {
    backgroundColor: '#15956C',
    '& button, & button:hover': {
      boxShadow: 'none',
      backgroundColor: '#15956C',
    },
  },
  second: {
    backgroundColor: '#468BC0',
    '& button, & button:hover': {
      boxShadow: 'none',
      backgroundColor: '#468BC0',
    },
  },
  third: {
    backgroundColor: '#CBA263',
    '& button, & button:hover': {
      backgroundColor: '#CBA263',
      boxShadow: 'none',
    },
  },
  inactive: {
    opacity: 0.7,
    pointerEvents: 'none',
    '& p': {
      cursor: 'default',
      pointerEvents: 'none',
    },
    '& button': {
      cursor: 'default',
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
    },
  },
  iconArrow: {
    position: 'absolute',
    bottom: '-26px',
    right: '-46px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  buttonWrapper: {
    textAlign: 'center',
    padding: '4px',
    borderRadius: '8px',
    '& button': {
      backgroundColor: 'transparent',
    },
  },
  opacityText: {
    opacity: 0.7,
  },
  linkWrapper: {
    position: 'relative',
    '& .first': {
      border: '2px solid #15956C',
      backgroundColor: 'rgba(21, 149, 108, 0.3)',
      '&.completed': {
        backgroundColor: '#15956C',
        pointerEvents: 'none',
      },
    },
    '& .second': {
      border: '2px solid #468BC0',
      backgroundColor: 'rgba(70, 139, 192, 0.3)',
      '&.completed': {
        backgroundColor: '#468BC0',
        pointerEvents: 'none',
      },
    },
    '& .third': {
      border: '2px solid #CBA263',
      backgroundColor: 'rgba(203, 162, 99, 0.3)',
      '&.completed': {
        backgroundColor: '#CBA263',
        pointerEvents: 'none',
      },
    },
  },
  pointerEventsNone: {
    pointerEvents: 'none',
  },
}));

const QuestionnaireCard = ({ status, order, profile, isDarkTheme, questionnaire = {} }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const isInactive =
    status === 'inactive' ||
    (!profile.hasTrial && questionnaire.plans?.length && !questionnaire.plans?.includes(profile.currentPlan));
  const isCompleted = status === 'completed';

  const imageSrc = isDarkTheme ? '/images/icon-arrow-way-dark.svg' : '/images/icon-arrow-way.svg';

  const renderButton = () => {
    switch (questionnaire.type) {
      case 'Basic Daily Targets':
        return (
          <div className={classNames(classes.buttonWrapper, 'card-button-wrapper', classes[order])}>
            <Button type="button" variant="contained" color="primary">
              <img src={iconStar} alt="icon star" />
            </Button>
            <Typography className={classNames(classes.timeText, { [classes.opacityText]: isInactive })}>
              {`${questionnaire.timeToPass || ''} ${t('minutes_shortly')}`}
            </Typography>
          </div>
        );
      case 'Premium Insights':
        return (
          <div className={classNames(classes.buttonWrapper, 'card-button-wrapper', classes[order])}>
            <Button type="button" variant="contained" color="primary">
              <img src={iconTrophy} alt="icon trophy" />
            </Button>
            <Typography className={classNames(classes.timeText, { [classes.opacityText]: isInactive })}>
              {`${questionnaire.timeToPass || ''} ${t('minutes_shortly')}`}
            </Typography>
          </div>
        );
      case 'In-depth Day':
        return (
          <div className={classNames(classes.buttonWrapper, 'card-button-wrapper', classes[order])}>
            <Button type="button" variant="contained">
              <img src={iconCrown} alt="icon crown" />
            </Button>
            <Typography className={classNames(classes.timeText, { [classes.opacityText]: isInactive })}>
              {`${questionnaire.timeToPass || ''} ${t('minutes_shortly')}`}
            </Typography>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Grid className={classes.container} item xs={12} md={4}>
      <div
        className={classNames(classes.linkWrapper, {
          [classes.inactive]: isInactive,
          [classes.pointerEventsNone]: isCompleted,
        })}
      >
        <Link to={`/dashboard/questionnaire/${questionnaire.id}`}>
          <Box display="flex" flexWrap="noWrap" className={classNames(classes.questionnaire, order, status)}>
            <div className={classes.description}>
              <div>{questionnaire.id && renderButton()}</div>
              <Box display="flex" flexDirection="column" width="100%">
                <Typography className={classes.title}>{t(questionnaire.type) || ''}</Typography>
              </Box>
            </div>
          </Box>
        </Link>
        {order === 'first' || order === 'second' ? (
          <img src={imageSrc} className={classes.iconArrow} alt="icon arrow" />
        ) : null}
      </div>
    </Grid>
  );
};

QuestionnaireCard.propTypes = {
  status: PropTypes.oneOf(QUESTIONNAIRE_STATUSES).isRequired,
  order: PropTypes.oneOf(['first', 'second', 'third']).isRequired,
  questionnaire: PropTypes.shape({
    id: PropTypes.number.isRequired,
    plans: PropTypes.arrayOf(PropTypes.number),
    timeToPass: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  profile: PropTypes.object.isRequired,
  isDarkTheme: PropTypes.bool,
};

export default connect(
  (state) => ({
    isDarkTheme: state.general.isDarkTheme,
    profile: state.profile.data,
  }),
  {}
)(QuestionnaireCard);
