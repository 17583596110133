import { all, put, call, takeLatest } from 'redux-saga/effects';

import questionnaire from '../../api/questionnaire';
import * as types from './questionnaireActionsTypes';
import * as profileTypes from '../profile/profileActionsTypes';
import { getCommonErrorProps } from '../reduxHelpers';

function* getSignUpQuestionnaireSaga() {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(questionnaire.getSignUpQuestionnaire);
    yield put({ type: types.GET_SIGN_UP_QUESTIONNAIRE_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.QUESTIONNAIRE_REQUEST_ERROR, error });
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getQuestionnaireListSaga({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(questionnaire.getQuestionnaireList, payload);
    yield put({ type: types.GET_QUESTIONNAIRE_LIST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.QUESTIONNAIRE_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getQuestionnaireGroupSaga() {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(questionnaire.getQuestionnaireGroup);
    yield put({ type: types.GET_QUESTIONNAIRE_GROUP_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.QUESTIONNAIRE_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getQuestionnaire({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(questionnaire.getQuestionnaire, payload);
    yield put({ type: types.GET_QUESTIONNAIRE_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.QUESTIONNAIRE_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getQuestionnaireSectionsSaga({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(questionnaire.getQuestionnaireSectionsApi, payload);
    yield put({ type: types.GET_QUESTIONNAIRE_SECTIONS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.QUESTIONNAIRE_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getQuestionnaireQuestions({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(questionnaire.getQuestionnaireQuestions, payload);
    yield put({ type: types.GET_QUESTIONNAIRE_QUESTIONS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.QUESTIONNAIRE_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* createUserTest({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(questionnaire.createUserTest, payload);
    yield put({ type: types.CREATE_USER_TEST_SUCCESS, payload: response });
    yield put({ type: profileTypes.GET_PROFILE_REQUEST });
  } catch (error) {
    yield put({ type: types.QUESTIONNAIRE_REQUEST_ERROR, error });
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_SIGN_UP_QUESTIONNAIRE_REQUEST, getSignUpQuestionnaireSaga),
    yield takeLatest(types.GET_QUESTIONNAIRE_LIST_REQUEST, getQuestionnaireListSaga),
    yield takeLatest(types.GET_QUESTIONNAIRE_GROUP_REQUEST, getQuestionnaireGroupSaga),
    yield takeLatest(types.GET_QUESTIONNAIRE_REQUEST, getQuestionnaire),
    yield takeLatest(types.GET_QUESTIONNAIRE_SECTIONS_REQUEST, getQuestionnaireSectionsSaga),
    yield takeLatest(types.GET_QUESTIONNAIRE_QUESTIONS_REQUEST, getQuestionnaireQuestions),
    yield takeLatest(types.CREATE_USER_TEST_REQUEST, createUserTest),
  ]);
}
