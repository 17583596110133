import { all, put, call, takeLatest } from 'redux-saga/effects';

import blog from '../../api/blog';
import * as types from './blogActionsTypes';
import { getCommonErrorProps } from '../reduxHelpers';

function* getArticleListSaga({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(blog.getArticleListApi, payload);
    yield put({ type: types.GET_ARTICLE_LIST_SUCCESS, payload: response });
  } catch (error) {
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getArticleListCategorySaga({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(blog.getArticleListCategoryApi, payload);
    yield put({ type: types.GET_ARTICLE_LIST_CATEGORY_SUCCESS, payload: response });
  } catch (error) {
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getArticleCategorySaga({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(blog.getArticleCategoryApi, payload);
    yield put({ type: types.GET_ARTICLE_CATEGORY_SUCCESS, payload: response });
  } catch (error) {
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* searchArticleListSaga({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(blog.searchArticleListApi, payload);
    yield put({ type: types.SEARCH_ARTICLE_LIST_SUCCESS, payload: response });
  } catch (error) {
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getSingleArticleSaga({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(blog.getSingleArticleApi, payload);
    yield put({ type: types.GET_SINGLE_ARTICLE_SUCCESS, payload: response });
  } catch (error) {
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_ARTICLE_LIST_REQUEST, getArticleListSaga),
    yield takeLatest(types.GET_ARTICLE_LIST_CATEGORY_REQUEST, getArticleListCategorySaga),
    yield takeLatest(types.GET_ARTICLE_CATEGORY_REQUEST, getArticleCategorySaga),
    yield takeLatest(types.SEARCH_ARTICLE_LIST_REQUEST, searchArticleListSaga),
    yield takeLatest(types.GET_SINGLE_ARTICLE_REQUEST, getSingleArticleSaga),
  ]);
}
