import { Dialog, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { hideModalAction } from '../../../redux/general/generalActions';
import * as types from '../../../redux/general/generalActionsTypes';
import CustomGoalForm from './CustomGoalForm';
import ModalAlert from './ModalAlert';
import ModalConfirm from './ModalConfirm';
import ModalCustomGoal from './ModalCustomGoal';
import ModalDowngradePlan from './ModalDowngradePlan';
import ModalRequestAccess from './ModalRequestAccess';
import ModalSkipTask from './ModalSkipTask';
import ModalTemplate from './ModalTemplate';
import ModalUpdateNote from './ModalUpdateNote';
import ModalUpdatePassword from './ModalUpdatePassword';
import ModalUpgradePlan from './ModalUpgradePlan';
import ModalVerifyPhone from './ModalVerifyPhone';
import ModalTemplateDeselect from './ModalTemplateDeselect';
import ModalTemplateShare from './ModalTemplateShare';
import ModalTemplatePersonal from './ModalTemplatePersonal';
import ModalTemplatePersonalDelete from './ModalTemplatePersonalDelete';
import ModalWelcome from './ModalWelcome';

const useStyles = makeStyles({
  root: {
    zIndex: '1301 !important',
    '& .MuiPaper-root': {
      width: 'calc(100% - 64px)',
      maxWidth: '540px',
      margin: 0,
    },
  },
});

const MainModal = ({ className, params, title, theme, type, hideModal, isDarkTheme, isWithoutTitle = false }) => {
  const classes = useStyles();

  const renderBody = () => {
    switch (type) {
      case types.MODAL_TYPE_CONFIRM:
        return <ModalConfirm {...params} />;
      case types.MODAL_TYPE_CUSTOM_GOAL:
        return <ModalCustomGoal {...params} />;
      case types.MODAL_TYPE_CUSTOM_TRACKER:
        return <CustomGoalForm {...params} />;
      case types.MODAL_TYPE_TEMPLATE:
        return <ModalTemplate {...params} />;
      case types.MODAL_TYPE_WELCOME:
        return <ModalWelcome {...params} hideModal={hideModal} />;
      case types.MODAL_TYPE_TEMPLATE_DESELECT:
        return <ModalTemplateDeselect {...params} />;
      case types.MODAL_TYPE_TEMPLATE_SHARE:
        return <ModalTemplateShare {...params} hideModal={hideModal} />;
      case types.MODAL_TYPE_TEMPLATE_PERSONAL:
        return <ModalTemplatePersonal {...params} hideModal={hideModal} />;
      case types.MODAL_TYPE_TEMPLATE_DELETE_PERSONAL:
        return <ModalTemplatePersonalDelete {...params} hideModal={hideModal} />;
      case types.MODAL_TYPE_UPDATE_PASSWORD:
        return <ModalUpdatePassword {...params} />;
      case types.MODAL_TYPE_UPGRADE_PLAN:
        return <ModalUpgradePlan {...params} />;
      case types.MODAL_TYPE_DOWNGRADE_PLAN:
        return <ModalDowngradePlan {...params} />;
      case types.MODAL_TYPE_VERIFY_PHONE:
        return <ModalVerifyPhone {...params} />;
      case types.MODAL_TYPE_REQUEST_SHARED_ACCESS:
        return <ModalRequestAccess {...params} />;
      case types.MODAL_TYPE_UPDATE_NOTE:
        return <ModalUpdateNote {...params} />;
      case types.MODAL_TYPE_SKIP_TASK:
        return <ModalSkipTask {...params} />;
      default:
        return null;
    }
  };

  const body = renderBody();

  const closeIconSrc = isDarkTheme ? '/images/icon-close-dark.svg' : '/images/icon-close-light.svg';

  const closeHandler = () => {
    params.cancelAction && params.cancelAction();
    hideModal();
  };

  return (
    <>
      <Dialog
        open={Boolean(type && type !== types.MODAL_TYPE_ALERT && body)}
        className={`modal modal--${type} ${theme}-theme${className ? ` ${className}` : ''} ${classes.root}`}
        maxWidth="sm"
        onClose={closeHandler}
      >
        {!isWithoutTitle && (
          <DialogTitle className={classNames({ dark: isDarkTheme })} id="form-dialog-title">
            {title}
            <img src={closeIconSrc} alt="close icon" onClick={closeHandler} className="modal-close-icon" />
          </DialogTitle>
        )}
        <DialogContent>{body}</DialogContent>
      </Dialog>
      <ModalAlert
        open={type === types.MODAL_TYPE_ALERT && !!params.type}
        className={className}
        hideModal={hideModal}
        {...params}
      />
    </>
  );
};

MainModal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  theme: PropTypes.string,
  params: PropTypes.object,
  hideModal: PropTypes.func.isRequired,
  isDarkTheme: PropTypes.bool,
  isWithoutTitle: PropTypes.bool,
};

export default connect(
  (state) => ({
    className: state.general.modal.className,
    title: state.general.modal.title,
    type: state.general.modal.type,
    params: state.general.modal.params,
    isWithoutTitle: state.general.modal.isWithoutTitle,
    theme: state.general.theme,
    isDarkTheme: state.general.isDarkTheme,
  }),
  {
    hideModal: hideModalAction,
  }
)(MainModal);
