import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Breadcrumbs, Button, makeStyles, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import DescriptionIcon from '@material-ui/icons/Description';

import { getLastUserTestRequest } from '../../redux/dashboard/dashboardActions';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '825px',
    margin: '30px auto 0',
    padding: '24px 0',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
    },
  },
  backLink: {
    display: 'flex',
    alignItems: 'center',
    width: '100px',
    height: '36px',
    fontSize: '1em',
    padding: '22px 16px 22px 8px',
    textTransform: 'capitalize',
    border: `solid 1px ${theme.overrides.searchPlaceholder}`,
    borderRadius: '8px',
    '& span': {
      marginLeft: '3px',
    },
  },
  backArrow: {
    transform: 'rotate(90deg)',
    fontSize: '1.75em',
  },
  articlesWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '20px',
  },
  articlesTitle: {
    margin: '50px 0 25px',
    color: theme.palette.text.primary,
    fontWeight: '700',
    fontSize: '20px',
  },
  icon: {
    marginRight: '10px',
    fill: theme.palette.text.primary,
    width: '20px',
  },
  articlesLink: {
    width: 'max-content',
    display: 'flex',
    alignItems: 'center',
    border: theme.palette.type === 'dark' ? '1px solid #F2F2F2' : '1px solid rgba(0,0,0, 0.2)',
    borderRadius: '8px',
    padding: '13px 17px',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'center',
    },
    '&:hover': {
      color: '#2A2B2A',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      border: '1px solid rgba(0, 0, 0, 0.2)',
      '& svg': {
        fill: '#2A2B2A',
      },
    },
  },
}));

const HowToImprove = ({ lastUserTestResult, getLastUserTest }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const history = useHistory();

  const urlParam = useParams();
  const category = urlParam.slug;
  const focusPoints = lastUserTestResult.focusPoints;
  useEffect(() => {
    getLastUserTest();
  }, []);

  return (
    <>
      <Helmet>
        <title>Myndful</title>
      </Helmet>
      <Box className={classes.container}>
        <Box my={5}>
          <Button onClick={history.goBack} className={classes.backLink}>
            <ExpandMore className={classes.backArrow} />
            <span>{t('back')}</span>
          </Button>
        </Box>
        <Box mb={3}>
          <div className="breadcrumbs-outer" style={{ marginTop: '50px' }}>
            <div className="breadcrumbs-wrapper">
              <Breadcrumbs className="breadcrumbs">
                <Link to="/dashboard">{t('m_dashboard')}</Link>
                <Link to="/how-to-improve">{t('m_how_to_improve')}</Link>
              </Breadcrumbs>
            </div>
          </div>
          {focusPoints?.map((item, index) => {
            return item.sectionName.indexOf(category) >= 0 ? (
              <Typography variant="h4" key={index}>
                {t('improve_your')} {item.sectionName}
              </Typography>
            ) : null;
          })}
        </Box>

        <div className="main-form__section">
          <div className="main-form__section-text">
            {focusPoints?.map((item, index) => {
              return item.sectionName.indexOf(category) >= 0 ? <p key={index}>{item.sectionDescription}</p> : null;
            })}
          </div>
        </div>

        {focusPoints?.map((item, index) => {
          return item.sectionName.indexOf(category) >= 0 && item.articles.length > 0 ? (
            <div key={index}>
              <Typography variant="h5" className={classes.articlesTitle}>
                {t('improve_suggested_articles')}
              </Typography>
              <div className={classes.articlesWrapper}>
                {item.articles.map((article, count) => {
                  return (
                    <Link key={count} to={`/resources/focus-point-articles/${article.slug}`} className={classes.articlesLink}>
                      <DescriptionIcon className={classes.icon} />
                      {article.title}
                    </Link>
                  );
                })}
              </div>
            </div>
          ) : null;
        })}
      </Box>
    </>
  );
};

HowToImprove.propTypes = {
  userTestList: PropTypes.array.isRequired,
  lastUserTestResult: PropTypes.object.isRequired,
  getLastUserTest: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    userTestList: state.dashboard.userTestList,
    lastUserTestResult: state.dashboard.lastUserTestResult,
  }),
  {
    getLastUserTest: getLastUserTestRequest,
  }
)(HowToImprove);
