import * as types from './feedbackActionsTypes';

export const createFeedbackRequest = (data) => {
  return { type: types.CREATE_FEEDBACK_REQUEST, payload: data };
};

export const getReCaptchaRequest = () => {
  return { type: types.GET_RECAPTCHA_REQUEST };
};

export const clearErrorAction = () => {
  return { type: types.CLEAR_ERROR };
};
