import { Box, Checkbox, FormControlLabel, Grid, Radio, RadioGroup, Slider } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { QUESTION_TYPE_CHECKBOX, QUESTION_TYPE_NUMERIC, QUESTION_TYPE_RADIO_BUTTON } from '../../constants';
import { useWindowDimension } from '../../hooks';

const multiplyTitles = {
  nonAbove: 'None of the above', // ! Bad practices, it should be flag in object on server. Because title can be change.
  decline: 'Decline to answer',
};

const Answers = ({ question, updateQuestion }) => {
  const [width] = useWindowDimension();

  const isBlockChecked = (name) => Object.values(multiplyTitles).find((item) => item === name);

  if (question.type === QUESTION_TYPE_CHECKBOX) {
    const checkIsDeclineOrNone = (e) => {
      const { name: checkboxName, id: checkboxId, checked: checkboxChecked } = e.target;

      if (isBlockChecked(checkboxName)) {
        return updateQuestion({
          ...question,
          answers: question.answers.map((item) =>
            item.id === +checkboxId ? { ...item, selected: checkboxChecked } : { ...item, selected: false }),
        });
      }

      updateQuestion({
        ...question,
        answers: question.answers
          .map((item) => (item.id === +checkboxId ? { ...item, selected: e.target.checked } : item))
          .map((item) => (isBlockChecked(item.title) ? { ...item, selected: false } : item)),
      });
    };

    return (
      <Box pt={2} className="questionnaire-checkbox">
        <Grid container alignItems="stretch" spacing={3} style={{ justifyContent: 'center' }}>
          {question.answers.map((answer) => {
            return (
              <Grid
                item
                xs={12}
                sm={4}
                md={question.answers.length < 4 ? 3 : 4}
                style={{ minWidth: '272px' }}
                key={`question-${question.id}-answer-${answer.id}`}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name={answer.title}
                      id={answer.id.toString()}
                      checked={!!answer.selected}
                      onChange={checkIsDeclineOrNone}
                    />
                  }
                  label={answer.title}
                  className={`questionnaire-checkbox__label${answer.selected ? ' checked' : ''}`}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  } else if (question.type === QUESTION_TYPE_NUMERIC) {
    const marks = question.answers.map((answer, index) => ({
      value: index,
      label: answer.title,
    }));
    const answerIndex = question.answers.findIndex((item) => item.selected);

    return (
      <Grid item className="questionnaire-numeric" xs={12}>
        <Slider
          valueLabelDisplay="auto"
          value={answerIndex >= 0 ? answerIndex : null}
          marks={marks}
          step={1}
          max={question.answers.length - 1}
          onChange={(evt, value) => {
            updateQuestion({
              ...question,
              answers: question.answers.map((item, index) =>
                index === value ? { ...item, selected: true } : { ...item, selected: false }),
            });
          }}
        />
      </Grid>
    );
  } else if (question.type === QUESTION_TYPE_RADIO_BUTTON) {
    const { answers } = question;
    let answersWidth =
      answers.length > 4 && width < 600 ? '100%' : `${Math.floor(10000 / (answers.length || 1)) / 100}%`;

    /* TODO need to change logic */
    const style = {};
    try {
      if (answers.length === 2 && answers.every((x) => ['yes', 'no'].includes(x.title.toLowerCase()))) {
        answersWidth = '130px';
        style.maxWidth = '260px';
      }
    } catch (e) {
      console.error(e);
    }

    return (
      <Box className="questionnaire-radio">
        <RadioGroup
          style={{ flexWrap: answers.length > 4 ? 'wrap' : 'nowrap' }}
          className="questionnaire-radio__group"
          onChange={(evt) => {
            const answerId = parseInt(evt.target.value, 10);
            updateQuestion({
              ...question,
              answers: question.answers.map((item) =>
                item.id === answerId ? { ...item, selected: true } : { ...item, selected: false }),
            });
          }}
        >
          {question.answers.map((answer) => (
            <FormControlLabel
              key={`question-${question.id}-answer-${answer.id}`}
              value={answer.id}
              control={<Radio color="primary" />}
              checked={answer.selected}
              label={answer.title}
              style={{ width: answersWidth, textAlign: answers.length > 4 ? 'center' : 'no-wrap' }}
              className={`questionnaire-radio__label${answer.selected ? ' checked' : ''}`}
            />
          ))}
        </RadioGroup>
      </Box>
    );
  }

  return null;
};

Answers.propTypes = {
  question: PropTypes.object.isRequired,
  updateQuestion: PropTypes.func.isRequired,
};

export default Answers;
