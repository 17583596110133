import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  preloaderContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: '12px',
    borderRadius: '8px',
    zIndex: 10,
  },
  preloader: {
    position: 'absolute',
    top: '100px',
    left: '50%',
    marginLeft: '-20px',
  },
});

const Preloader = ({ isBgTransparent }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.preloaderContainer}
      style={{ backgroundColor: isBgTransparent ? 'transparent' : 'rgba(255, 255, 255, 0.2)' }}
    >
      <CircularProgress disableShrink className={classes.preloader} />
    </div>
  );
};

Preloader.propTypes = {
  isBgTransparent: PropTypes.bool,
};

export default Preloader;
