import { Box, Breadcrumbs, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getItemsRequest } from '../../redux/summary/summaryActions';
import { SummaryTable } from './SummaryTable';
import { getQuestionnaireSectionsRequest } from '../../redux/questionnaire/questionnaireActions';
import { showModalAction } from '../../redux/general/generalActions';

const useStyles = makeStyles((theme) => {
  const isDark = theme.palette.type === 'dark';

  return {
    header: {
      fontWeight: '700',
      fontSize: '24px',
      paddingBottom: '11px',
      borderBottom: `1px solid ${isDark ? 'rgba(242, 242, 242, 0.2)' : 'rgba(0, 0, 0, 0.2)'}`,
    },
    loader: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
});

const Summary = ({
  summary,
  isProcessing,
  sections,
  isSectionsProcessing,
  getItems,
  getQuestionnaireSections,
  showModal
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    getItems();
    getQuestionnaireSections();
  }, []);

  useEffect(() => {
    setTableData(Object.entries(summary).map(([date, info]) => ({ ...info, date })) || []);
  }, [summary]);

  return (
    <>
      <div className="breadcrumbs-outer">
        <div className="breadcrumbs-wrapper">
          <Breadcrumbs className="breadcrumbs">
            <Link to="/dashboard">{t('m_dashboard')}</Link>
            <Link to="/summary">{t('m_summary')}</Link>
          </Breadcrumbs>
        </div>
      </div>
      <Box className={classes.header} mt={10} mb={4} display="flex" alignItems="flex-end" flexWrap="wrap">
        <span>{t('m_summary')}</span>
      </Box>

      {isProcessing && (
        <div className={classes.loader}>
          <CircularProgress color="primary" />
        </div>
      )}
      {!isProcessing && !isSectionsProcessing && !!tableData.length && (
        <SummaryTable
          sections={sections?.results?.slice(0, 6) || []}
          data={tableData}
          showModal={showModal}
        />
      )}
    </>
  );
};

Summary.propTypes = {
  isProcessing: PropTypes.bool,
  summary: PropTypes.object,
  sections: PropTypes.shape({
    count: PropTypes.number,
    results: PropTypes.array
  }),
  isSectionsProcessing: PropTypes.bool,
  getItems: PropTypes.func.isRequired,
  getQuestionnaireSections: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    summary: state.summary.summary,
    isProcessing: state.summary.processing,
    sections: state.questionnaire.sections,
    isSectionsProcessing: state.questionnaire.processing,
  }),
  {
    getItems: getItemsRequest,
    getQuestionnaireSections: getQuestionnaireSectionsRequest,
    showModal: showModalAction,
  }
)(Summary);
