import * as types from './feedbackActionsTypes';
import * as authTypes from '../auth/authActionsTypes';

export const initialState = {
  recaptcha: {
    siteKey: '',
  },
  processing: false,
  success: false,
  error: null,
};

const feedback = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };

    case types.CREATE_FEEDBACK_REQUEST:
      return {
        ...state,
        success: true,
        error: null,
      };

    case types.GET_RECAPTCHA_REQUEST_SUCCESS:
      return {
        ...state,
        recaptcha: action.payload,
        error: null,
      };

    case types.FEEDBACK_REQUEST_ERROR:
      return {
        ...state,
        recaptcha: {
          siteKey: '',
        },
        error: action.error,
      };

    case authTypes.LOGOUT_REQUEST:
      return initialState;

    case types.CLEAR_ERROR:
      return {
        ...state,
        success: false,
        error: null,
      };

    default:
      return state;
  }
};

export default feedback;
