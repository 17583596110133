import * as types from './journalActionTypes';

export const getNotesRequest = (payload) => {
  return { type: types.GET_NOTES, payload };
};

export const getNoteRequest = (payload) => {
  return { type: types.GET_NOTE_REQUEST, payload };
};

export const clearNoteSelectionRequest = () => {
  return { type: types.CLEAR_SINGLE_NOTE };
};

export const addNoteRequest = (payload) => {
  return { type: types.ADD_NOTE, payload };
};

export const updateNoteRequest = (payload) => {
  return { type: types.UPDATE_NOTE, payload };
};

export const removeNoteRequest = (payload) => {
  return { type: types.REMOVE_NOTE, payload };
};
