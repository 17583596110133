import { combineReducers } from 'redux';

import auth from './auth/authReducer';
import blog from './blog/blogReducer';
import dashboard from './dashboard/dashboardReducer';
import feedback from './feedback/feedbackReducer';
import general from './general/generalReducer';
import journal from './journal/journalReducer';
import summary from './summary/summaryReducer';
import profile from './profile/profileReducer';
import subscription from './subscription/subscriptionReducer';
import questionnaire from './questionnaire/questionnaireReducer';
import settings from './settings/settingsReducer';
import sharedAccess from './shared-access/sharedAccessReducer';
import ssr from './ssr/ssrReducer';
import template from './template/templateReducer';

// Combine reducer into single one
const rootReducer = combineReducers({
  auth,
  blog,
  dashboard,
  feedback,
  general,
  journal,
  profile,
  subscription,
  questionnaire,
  settings,
  sharedAccess,
  summary,
  ssr,
  template,
});

export default rootReducer;
