import * as queryString from 'query-string';

import axios from './axios';
import { API_URL } from '../constants';

const blog = {
  async getArticleListApi(data) {
    try {
      const result = await axios.get(`${API_URL}/focus-point-articles/?${queryString.stringify(data)}`);

      return result.data;
    } catch (err) {
      if ((err.response && err.response.data) || err.response.detail) throw err.response.data;
      throw err;
    }
  },
  async getArticleListCategoryApi(data) {
    try {
      const result = await axios.get(`${API_URL}/focus-point-articles/?${queryString.stringify(data)}`);

      return result.data;
    } catch (err) {
      if ((err.response && err.response.data) || err.response.detail) throw err.response.data;
      throw err;
    }
  },
  async getArticleCategoryApi(data) {
    try {
      const result = await axios.get(`${API_URL}/focus-point-articles/category/${queryString.stringify(data)}`);

      return result.data;
    } catch (err) {
      if ((err.response && err.response.data) || err.response.detail) throw err.response.data;
      throw err;
    }
  },
  async searchArticleListApi(data) {
    try {
      const result = await axios.get(`${API_URL}/focus-point-articles/search/?${queryString.stringify(data)}`);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getSingleArticleApi(data) {
    try {
      const result = await axios.get(`${API_URL}/focus-point-articles/${data.slug}/`);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default blog;
