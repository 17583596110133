import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import AuthorizedHeader from '../Header/AuthorizedHeader';
import AuthorizedFooter, { FOOTER_HEIGHT } from '../Footer/AuthorizedFooter';

export const mainLayoutContentContainerRef = createRef();

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    minHeight: `calc(100vh - ${FOOTER_HEIGHT}px)`,
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '64px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '64px',
    },
  },
  mainLayout: {
    backgroundColor: theme.palette.background.default,
    background: `${
      theme.palette.type === 'dark'
        ? 'none'
        : 'url("/images/background-yellow.svg") left top, url("/images/background-blue.svg") right center'
    }`,
    backgroundSize: '100% 100%',
  },
  content: {
    position: 'relative',
    width: '100%',
    padding: '12px 24px 68px',
    color: theme.palette.text.primary,
    overflow: 'auto',
    boxSizing: 'border-box',
    [theme.breakpoints.up('sm')]: {
      padding: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: '-450px top, right center',
    },
  },
  inner: {
    maxWidth: '1144px',
    margin: '0 auto',
  },
}));

const GeneralLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={`main-layout ${classes.mainLayout}`}>
      <AuthorizedHeader />
      <div className={classes.container}>
        <div ref={mainLayoutContentContainerRef} className={classes.content}>
          <div className={classes.inner}>{children}</div>
        </div>
      </div>
      <AuthorizedFooter />
    </div>
  );
};

GeneralLayout.propTypes = {
  children: PropTypes.any,
};

export default GeneralLayout;
