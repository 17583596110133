import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, DialogActions, DialogContentText, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { hideModalAction } from '../../../redux/general/generalActions';

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: '104px',
    marginLeft: '16px',
    color: '#FFFFFF',
    background: theme.palette.type === 'dark' ? '#406BE1' : 'linear-gradient(90deg, #0035FF 0%, #00C1FF 100%)',
  },
  cancelButton: {
    minWidth: '104px',
    marginLeft: '16px',
  },
}));

const ModalConfirm = ({
  text,
  cancelButton,
  cancelButtonText,
  confirmButtonText,
  cancelAction,
  confirmAction,
  hideModal,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <DialogContentText>{text}</DialogContentText>
      <DialogActions>
        {cancelButton && (
          <Button
            className={classes.cancelButton}
            color="primary"
            onClick={() => {
              cancelAction && cancelAction();
              hideModal();
            }}
          >
            {cancelButtonText || t('cancel')}
          </Button>
        )}
        <Button className={classes.button} type="submit" variant="contained" color="primary" onClick={confirmAction}>
          {confirmButtonText || t('proceed')}
        </Button>
      </DialogActions>
    </>
  );
};

ModalConfirm.propTypes = {
  text: PropTypes.any.isRequired,
  cancelButton: PropTypes.bool,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelAction: PropTypes.func,
  confirmAction: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default connect(null, {
  hideModal: hideModalAction,
})(ModalConfirm);
