import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import AchievementsCard from './AchievementsCard'
import { Grid } from '@material-ui/core'
import AchievementCard from '../../Achievements/AchievementCard'

const AchievementsGroup = ({ badges, isModalCards = false, setIsExistCompleted }) => {
    const listOfBadges = useMemo(() => {
        if(badges) {
            const unCompletedBadges = badges.filter((badge) => isModalCards ? badge.count : !badge.count);

            if(isModalCards && unCompletedBadges.length) {
                setIsExistCompleted(true)
            }
            
            if(unCompletedBadges.length < 3) {
                return badges.sort((a,b) => b.count - a.count).slice(0,3)
            }
            const unCompletedProgressBadges = unCompletedBadges.filter((badge) => badge.progress).sort((a,b) => b.progress - a.progress);
            if(unCompletedProgressBadges.length < 3){
                return unCompletedBadges.slice(0,3).sort((a,b) => b.progress - a.progress)
            }
            return unCompletedProgressBadges.slice(0, 3)
        }
        return []
    }, [badges])

  return (
    <Grid className='achievement__container' container spacing={3}>
        {
            listOfBadges.length && listOfBadges.map((badge, index) => isModalCards ? (
                <AchievementCard isModalCards={isModalCards} key={index} badge={badge} />
            ) : (
                <AchievementsCard key={index} badge={badge} />
            ))
        }
    </Grid>
  )
}

export default connect((state) => ({
    badges: state.profile.data.badges
})
)(AchievementsGroup)