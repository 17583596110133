import { all, call, put, takeLatest } from 'redux-saga/effects';

import auth from '../../api/auth';
import profile from '../../api/profile';
import * as profileTypes from '../profile/profileActionsTypes';
import { getCustomErrorProps } from '../reduxHelpers';
import * as types from './authActionsTypes';

function* login({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(auth.login, payload);
    const profileResponse = yield call(profile.getProfile);
    yield put({ type: profileTypes.GET_PROFILE_SUCCESS, payload: profileResponse });
    yield put({ type: types.LOGIN_SUCCESS, payload: response });
    localStorage.removeItem('verificationToken')
    localStorage.removeItem('refreshVerificationToken')
  } catch (error) {
    const commonError = getCustomErrorProps(error, ['email', 'password']);

    yield put(commonError ? commonError : { type: types.AUTH_REQUEST_ERROR, error });
    yield call(auth.logout);
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* register({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(auth.register, payload);
    localStorage.setItem('verificationToken', response.tokens.access);
    localStorage.setItem('refreshVerificationToken', response.tokens.refresh);
    yield call(auth.generateVerificationToken, response.tokens.access);
    yield put({ type: types.REGISTER_SUCCESS, payload: response });
  } catch (error) {
    const commonError = getCustomErrorProps(error, ['username', 'email', 'password']);
    yield put(commonError ? commonError : { type: types.AUTH_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* logout() {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(auth.logout);
    yield put({ type: types.LOGOUT_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.AUTH_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* resetPassword({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    yield call(auth.resetPassword, payload);
    yield put({ type: types.RESET_PASSWORD_SUCCESS });
  } catch (error) {
    const commonError = getCustomErrorProps(error, ['email']);
    yield put(commonError ? commonError : { type: types.AUTH_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* setNewPassword({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    yield call(auth.setNewPassword, payload);
    yield put({ type: types.SET_NEW_PASSWORD_SUCCESS });
  } catch (error) {
    const commonError = getCustomErrorProps(error, ['newPassword']);
    yield put(commonError ? commonError : { type: types.AUTH_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getSocialKeysSaga({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const resp = yield call(auth.getSocialKeys, payload);
    yield put({ type: types.GET_SOCIAL_KEYS_SUCCESS, payload: resp });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.LOGIN_REQUEST, login),
    yield takeLatest(types.REGISTER_REQUEST, register),
    yield takeLatest(types.LOGOUT_REQUEST, logout),
    yield takeLatest(types.RESET_PASSWORD_REQUEST, resetPassword),
    yield takeLatest(types.SET_NEW_PASSWORD_REQUEST, setNewPassword),
    yield takeLatest(types.GET_SOCIAL_KEYS_REQUEST, getSocialKeysSaga),
  ]);
}
