import * as types from './dashboardActionsTypes';

export const getLastUserTestRequest = (data) => {
  return { type: types.GET_LAST_USER_TEST_REQUEST, payload: data };
};

export const getUserTestSectionListRequest = (data) => {
  return { type: types.GET_USER_TEST_SECTION_LIST_REQUEST, payload: data };
};

export const getFocusPointArticleRequest = (data) => {
  return { type: types.GET_FOCUS_POINT_ARTICLE_REQUEST, payload: data };
};

/** START **/

export const skipTaskRequest = (data) => {
  return { type: types.SKIP_CUSTOM_GOAL_REQUEST, payload: data };
};

export const getStandardGoalsRequest = () => {
  return { type: types.GET_STANDARD_GOALS_REQUEST };
};

export const getRandomGoalsRequest = () => {
  return { type: types.GET_RANDOM_GOALS_REQUEST };
};

export const getPreviousGoalsRequest = () => {
  return { type: types.GET_PREVIOUS_GOALS_REQUEST };
};

export const updateStandardGoalRequest = (data) => {
  return { type: types.UPDATE_STANDARD_GOAL_REQUEST, payload: data };
};

export const getCustomGoalsRequest = () => {
  return { type: types.GET_CUSTOM_GOALS_REQUEST };
};

export const createCustomGoalRequest = (data) => {
  return { type: types.CREATE_CUSTOM_GOAL_REQUEST, payload: data };
};

export const getSingleCustomGoalRequest = (id) => {
  return { type: types.GET_SINGLE_CUSTOM_GOAL_REQUEST, payload: id };
};

export const getSingleCustomGoalSuccess = (data) => {
  return { type: types.GET_SINGLE_CUSTOM_GOAL_SUCCESS, payload: data };
};

export const updateCustomGoalRequest = (id, body) => {
  return { type: types.UPDATE_CUSTOM_GOAL_REQUEST, payload: { id, body } };
};

export const updateCustomTemplateGoalsRequest = (template) => {
  return { type: types.UPDATE_CUSTOM_TEMPLATE_GOALS_REQUEST, payload: { template } };
};

export const deleteCustomGoalRequest = (data) => {
  return { type: types.DELETE_CUSTOM_GOAL_REQUEST, payload: data };
};

/**  END  **/

export const clearFocusPointArticleAction = () => {
  return { type: types.CLEAR_FOCUS_POINT_ARTICLE };
};

export const clearErrorAction = () => {
  return { type: types.CLEAR_ERROR };
};
