import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, makeStyles, useMediaQuery } from '@material-ui/core';

import { theme } from '../../providers/ThemeProvider';
import MobileMenu from '../Header/PublicHeaderMobile';
import PublicHeaderDesktop from '../Header/PublicHeaderDesktop';
import PublicFooter from '../Footer/PublicFooter';

const logoHome = '/images/logo-home.svg';

const useStyles = makeStyles(() => ({
  header: {
    zIndex: theme.zIndex.drawer + 101,
    background: '#fff',
    boxShadow: '0px 8px 24px rgba(46, 62, 210, 0.15)',
  },
  toolbar: {
    justifyContent: 'space-between',
    padding: '15px 11%',
    height: 80,
    [theme.breakpoints.down('xs')]: {
      height: 65,
    },
  },
  logo: {
    maxWidth: 200,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
}));


const PublicLayout = ({ children }) => {
  const classes = useStyles();

  const isSmallMediaQuery = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      <Helmet>
        <title>Myndful</title>
      </Helmet>
      <AppBar position="fixed" xs={12} className={classes.header}>
        <Toolbar className={classes.toolbar}>
          <Link to="/">
            <img src={logoHome} className={classes.logo} alt="logo" />
          </Link>
          {isSmallMediaQuery ? <MobileMenu /> : <PublicHeaderDesktop />}
        </Toolbar>
      </AppBar>
      {children}
      <PublicFooter />
    </>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.any,
};

export default PublicLayout;
