import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CustomGoalForm from './CustomGoalForm';
import {
  createCustomGoalRequest,
  getCustomGoalsRequest,
  getSingleCustomGoalRequest,
  getSingleCustomGoalSuccess,
  updateCustomGoalRequest,
} from '../../../redux/dashboard/dashboardActions';
import { hideModalAction } from '../../../redux/general/generalActions';


const ModalCustomGoal = ({
  customGoalId,
  customGoal,
  isProcessing,
  getSingleCustomGoal,
  clearSingleCustomGoal,
  getCustomGoals,
  createCustomGoal,
  updateCustomGoal,
  hideModal,
}) => {
  useEffect(() => {
    if (customGoalId) {
      getSingleCustomGoal(customGoalId);
    }

    return () => customGoalId && clearSingleCustomGoal({});
  }, []);

  const onSubmitHandler = (data) => {
    if (customGoal.id) {
      updateCustomGoal(customGoal.id, data);
    } else {
      createCustomGoal(data);
    }
    getCustomGoals();
    hideModal();
  };

  return (
    <CustomGoalForm
      customGoal={customGoal}
      isProcessing={isProcessing}
      onCancel={hideModal}
      onSubmit={onSubmitHandler}
    />
  );
};

ModalCustomGoal.propTypes = {
  customGoalId: PropTypes.number,
  customGoal: PropTypes.object.isRequired,
  customGoals: PropTypes.array.isRequired,
  sections: PropTypes.object.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  getCustomGoals: PropTypes.func.isRequired,
  getSingleCustomGoal: PropTypes.func.isRequired,
  clearSingleCustomGoal: PropTypes.func.isRequired,
  createCustomGoal: PropTypes.func.isRequired,
  updateCustomGoal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    customGoal: state.dashboard.customGoal,
    customGoals: state.dashboard.customGoals,
    sections: state.questionnaire.sections,
    isProcessing: state.dashboard.processing,
  }),
  {
    getSingleCustomGoal: getSingleCustomGoalRequest,
    clearSingleCustomGoal: getSingleCustomGoalSuccess,
    createCustomGoal: createCustomGoalRequest,
    updateCustomGoal: updateCustomGoalRequest,
    hideModal: hideModalAction,
    getCustomGoals: getCustomGoalsRequest,
  }
)(ModalCustomGoal);
