import * as types from './profileActionsTypes';

export const getProfileRequest = () => {
  return { type: types.GET_PROFILE_REQUEST };
};

export const updateProfileRequest = (data) => {
  return { type: types.UPDATE_PROFILE_REQUEST, payload: data };
};

export const disconnectGoogleCalendarRequest = () => {
  return { type: types.DISCONNECT_GOOGLE_CALENDAR };
};

export const sendPhoneTokenRequest = (resend = false) => {
  return { type: types.SEND_PHONE_TOKEN_REQUEST, payload: resend };
};

export const verifyPhoneTokenRequest = (data) => {
  return { type: types.VERIFY_PHONE_TOKEN_REQUEST, payload: data };
};

export const updatePasswordRequest = (data) => {
  return { type: types.UPDATE_PASSWORD_REQUEST, payload: data };
};

export const createReminderRequest = (data) => {
  return { type: types.CREATE_REMINDER_REQUEST, payload: data };
};

export const updateReminderRequest = (id, body) => {
  return { type: types.UPDATE_REMINDER_REQUEST, payload: { id, body } };
};

export const unsubscribeEmailRequest = (token) => {
  return { type: types.UNSUBSCRIBE_EMAIL_REQUEST, payload: { token } };
};

export const clearErrorAction = () => {
  return { type: types.CLEAR_ERROR };
};

export const updatePhoneRequest = (phone) => ({
  type: types.UPDATE_PHONE_REQUEST,
  payload: phone,
});

export const getLanguagesOptionsRequest = () => {
  return { type: types.GET_LANGUAGES_OPTIONS_REQUEST };
};

export const toggleCurrentLanguageAction = (language) => {
  return { type: types.TOGGLE_CURRENT_LANGUAGE_ACTION, payload: { language } };
};
