import { all, call, put, takeLatest } from 'redux-saga/effects';

import dashboard from '../../api/dashboard';
import { getCommonErrorProps } from '../reduxHelpers';
import * as types from './summaryActionTypes';

function* getItems() {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const result = yield call(dashboard.getSummary);
    yield put({ type: types.GET_SUMMARY_SUCCESS, payload: result });
  } catch (error) {
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

export default function* () {
  yield all([yield takeLatest(types.GET_SUMMARY, getItems)]);
}
