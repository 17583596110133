import { Box, Breadcrumbs, Button, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import {
  addNoteRequest,
  clearNoteSelectionRequest,
  getNoteRequest,
  updateNoteRequest,
} from '../../redux/journal/journalActions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import parse from 'react-html-parser';
import { IconEdit } from '../../assets/inline-images';
import JournalForm from './JournalForm';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  journalInput: {
    '& .MuiInputBase-root': {
      borderRadius: '8px !important',
    },
  },
  content: {
    maxWidth: '825px',
    width: '100%',
    marginTop: '36px',
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    borderRadius: '8px',
    background: theme.palette.type === 'dark' ? '#3A5188' : '#fff',
    boxShadow: theme.palette.type === 'dark' ? 'none' : '0px 2px 5px 0px #0000001A',
    '& .form-journal__label': {
      marginBottom: '8px',
    },
  },
  rte: {
    margin: '8px 0 24px',
    color: theme.palette.type === 'dark' ? '#ffffff' : '#000000',
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(147, 160, 190, 0.1)' : 'white',
    '& .ql-toolbar.ql-snow': {
      borderRadius: '8px 8px 0px 0px',
    },
    '& .ql-toolbar.ql-snow + .ql-container.ql-snow': {
      borderRadius: '0px 0px 8px 8px',
    },
    '& .ql-editor': {
      minHeight: '75px',
      '&.ql-blank::before': {
        color: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.6)',
      },
    },
    '& .ql-toolbar': {
      '& .ql-stroke': {
        stroke: theme.palette.type === 'dark' ? '#fff' : '#444',
      },
    },
    '& .ql-picker-label::before': {
      color: theme.palette.type === 'dark' ? '#fff' : '#444',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '&.space': {
      justifyContent: 'space-between',
    },
  },
  loader: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backButton: {
    marginRight: '32px',
    cursor: 'pointer',
    display: 'flex',
    width: '100px',
    padding: '16px',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    border: `1px solid ${theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)'}`,
    borderRadius: '8px',
  },
  deleteButton: {
    height: '48px',
    padding: '16px',
    letterSpacing: '1px',
    borderRadius: '8px',
    textTransform: 'capitalize',
    boxShadow: '0 2px 0 rgba(0, 0, 0, 0.043)',
    background: theme.palette.type === 'dark' ? 'transparent' : '#fff',
    color: theme.palette.type === 'dark' ? '#fff' : '#F33935',
    border: `1px solid ${theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)'}`,
    [theme.breakpoints.down('sm')]: {
      margin: '20px 0',
    },
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '48px',
    padding: '16px',
    letterSpacing: '1px',
    borderRadius: '8px',
    textTransform: 'capitalize',
    boxShadow: '0 2px 0 rgba(0, 0, 0, 0.043)',
    background: theme.palette.type === 'dark' ? '#406BE1' : 'transparent',
    border: `1px solid ${theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)'}`,
    color: theme.palette.type === 'dark' ? '#fff' : '#0035FF',
    [theme.breakpoints.down('sm')]: {
      margin: '20px 0',
    },
  },
  viewContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  viewDetails: {
    overflowWrap: "anywhere"
  },
  viewHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  viewHeaderText: {
    fontSize: '24px',
  },
  viewHeaderDate: {
    fontSize: '16px',
    color: theme.palette.type === 'dark' ? '#ffffff' : '#757575',
  },
  viewButtons: {
    display: 'flex',
    justifyContent: 'end',
  },
  viewButtonIcon: {
    marginRight: '10px',
    width: '20px',
    height: '20px',
  },
}));

const JournalCreate = ({
  isDarkTheme,
  getSingleNote,
  note,
  match,
  clearNoteSelection,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const backArrowIconSrc = isDarkTheme ? '/images/icon-arrow-left-dark.svg' : '/images/icon-arrow-left-light.svg';

  const id = Number(match.params.id) || null;
  const isView = !!id && history.location.pathname.indexOf('view') !== -1;
  const isEdit = !!id && history.location.pathname.indexOf('edit') !== -1;
  const isCreate = !id && history.location.pathname.indexOf('create') !== -1;

  useEffect(() => {
    if (id) {
      getSingleNote({ id });
    }
  }, [id]);

  const backToPreviousPage = () => {
    history.push('/journal');
    clearNoteSelection();
  };

  const handleEditNote = () => {
    history.push(`/journal/${id}/edit`);
  };

  return (
    <>
      <Helmet>
        <title>Myndful</title>
      </Helmet>
      <Box my={3}>
        <div className="breadcrumbs-outer">
          <div className="breadcrumbs-wrapper">
            <Breadcrumbs className="breadcrumbs">
              <Link to="/dashboard">{t('m_dashboard')}</Link>
              <Link to="/journal">{t('m_journal')}</Link>
              <Link to="/journal/create">{t('new_journal')}</Link>
            </Breadcrumbs>
          </div>
        </div>
      </Box>
      <Grid container className={classes.container}>
        <div onClick={backToPreviousPage} className={classes.backButton}>
          <img src={backArrowIconSrc} alt="back arrow" />
          {t('back')}
        </div>

        <div className={classes.wrapper}>
          <div className={classes.content}>
            {!note && !isCreate ? (
              <div className={classes.loader}>
                <CircularProgress color="primary" />
              </div>
            ) : isView ? (
              <div className={classes.viewContainer}>
                <div className={classes.viewHeader}>
                  <h1 className={classes.viewHeaderText}>{note?.title}</h1>
                  <span className={classes.viewHeaderDate}>{moment(note?.createdAt).format('LL')}</span>
                </div>
                <div className={classes.viewDetails}>{parse(note?.text)}</div>
                <div className={classes.viewButtons}>
                  <Button className={classes.button} onClick={handleEditNote}>
                    <IconEdit size={26} className={classes.viewButtonIcon} />
                    {t('journal_edit_note')}
                  </Button>
                </div>
              </div>
            ) : (
              <JournalForm
                isEdit={isEdit}
              />
            )}
          </div>
        </div>
      </Grid>
    </>
  );
};

JournalCreate.propTypes = {
  isDarkTheme: PropTypes.bool,
  getSingleNote: PropTypes.func,
  note: PropTypes.object,
  match: PropTypes.object.isRequired,
  addNote: PropTypes.func,
  updateNote: PropTypes.func,
  clearNoteSelection: PropTypes.func,
};

export default connect(
  (state) => ({
    isDarkTheme: state.general.isDarkTheme,
    note: state.journal.note,
  }),
  {
    addNote: addNoteRequest,
    updateNote: updateNoteRequest,
    getSingleNote: getNoteRequest,
    clearNoteSelection: clearNoteSelectionRequest,
  }
)(JournalCreate);
