import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Button, makeStyles, TextareaAutosize, Typography } from '@material-ui/core';

import { skipTaskRequest } from '../../../redux/dashboard/dashboardActions';
import { hideModalAction } from '../../../redux/general/generalActions';

const useStyles = makeStyles((theme) => {
  const isDark = theme.palette.type === 'dark';

  return {
    textarea: {
      fontFamily: 'Inter, Montserrat, Helvetica, Arial, sans-serif',
      margin: '8px 0 24px',
      border: isDark ? '2px solid #93A0BE' : '1px solid rgba(0, 0, 0, 0.2);',
      borderRadius: '8px',
      padding: '16px',
      height: '110px',
      minHeight: '110px',
      width: '100%',
      maxWidth: '100%',
      color: isDark ? '#ffffff' : '#000000',
      backgroundColor: isDark ? 'rgba(147, 160, 190, 0.1)' : 'white',
      outline: 'none',
      '&::-webkit-resizer': {
        backgroundImage: 'url(\'/images/icon-resize.svg\')',
      },
      '&::placeholder': {
        color: isDark ? '#93A0BE' : 'rgba(42, 43, 42, 0.2)',
      },
    },
    button: {
      background: isDark ? '#406BE1' : 'linear-gradient(90deg, #0035FF 0%, #00C1FF 100%)',
      color: '#ffffff',
      textTransform: 'capitalize',
    },
  };
});

const ModalSkipTask = ({ id, isChecked, skipTask, hideModal }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [text, setText] = useState('');

  const changeContentHandler = (e) => {
    setText(e.target.value);
  };

  const save = () => {
    skipTask({ id, isChecked, skipReason: text });
    hideModal();
  };

  return (
    <>
      <Typography>{t('modal_plan_skip_reason')}</Typography>
      <TextareaAutosize value={text} onChange={changeContentHandler} className={classes.textarea} />
      <Box textAlign="right">
        <Button className={classes.button} onClick={save}>
          {t('modal_note_confirm_skip')}
        </Button>
      </Box>
    </>
  );
};

ModalSkipTask.propTypes = {
  isDarkTheme: PropTypes.bool.isRequired,
  id: PropTypes.number,
  isChecked: PropTypes.bool,
  skipTask: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isDarkTheme: state.general.isDarkTheme,
  }),
  {
    skipTask: skipTaskRequest,
    hideModal: hideModalAction,
  }
)(ModalSkipTask);
