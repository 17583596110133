import * as generalTypes from './general/generalActionsTypes';

export const getErrorMessage = (error) => {
  if (!error) return { message: '', type: null };

  const firstProp = error && Object.values(error).length ? Object.values(error)[0] : null;
  const message =
    typeof error === 'string'
      ? error
      : error && error.message && typeof error.message === 'string'
      ? error.message
      : typeof firstProp === 'string'
      ? firstProp
      : (firstProp && firstProp[0]) || null;

  if (typeof message !== 'string') {
    console.warn('Couldn\'t serialize server error');
    console.warn('error', error);
    console.warn('message', message);
  }

  return typeof message === 'string' ? { message: message, type: 'error' } : { message: 'Something wrong.', type: 'warning' };
};

export const getCommonErrorProps = (error) => ({
  type: generalTypes.SHOW_MODAL_ACTION,
  payload: {
    params: getErrorMessage(error),
    type: generalTypes.MODAL_TYPE_ALERT,
  },
});

export const getCustomErrorProps = (error, exclude = []) => {
  if (Object.keys(error).some((x) => exclude.find((y) => y === x))) return null;

  return getCommonErrorProps(error);
};

export const pause = (data) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, data.delay);
  });
};
