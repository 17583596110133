import * as types from './authActionsTypes';

//set auth state of application: true = is logged in, false = no user is logged in
export const loginRequest = (data) => {
  return { type: types.LOGIN_REQUEST, payload: data };
};

export const logoutRequest = () => {
  return { type: types.LOGOUT_REQUEST };
};

export const closeWelcomeModalRequest = () => {
  return { type: types.WELCOME_MODEL_CLEAR }
}

export const signUpRequest = (data) => {
  return { type: types.REGISTER_REQUEST, payload: data };
};

export const resetPasswordRequest = (data) => {
  return { type: types.RESET_PASSWORD_REQUEST, payload: data };
};

export const setNewPasswordRequest = (data) => {
  return { type: types.SET_NEW_PASSWORD_REQUEST, payload: data };
};

export const getSocialKeysRequest = () => {
  return { type: types.GET_SOCIAL_KEYS_REQUEST };
};

export const clearErrorAction = () => {
  return { type: types.CLEAR_ERROR };
};
