export const CREATE_FEEDBACK_REQUEST = 'CREATE_FEEDBACK_REQUEST';
export const CREATE_FEEDBACK_SUCCESS = 'CREATE_FEEDBACK_SUCCESS';

export const GET_RECAPTCHA_REQUEST = 'GET_RECAPTCHA_REQUEST';
export const GET_RECAPTCHA_REQUEST_SUCCESS = 'GET_RECAPTCHA_REQUEST_SUCCESS';

export const FEEDBACK_REQUEST_ERROR = 'FEEDBACK_REQUEST_ERROR';
export const CLEAR_ERROR = 'CLEAR_FEEDBACK_ERROR';

export const TOGGLE_PROCESSING = 'TOGGLE_PROFILE_PROCESSING';
