import axios from './axios';
import { API_URL } from '../constants';

const journal = {
  async getNotes({ title, days, page, page_size }) {
    try {
      const result = await axios.get(`${API_URL}/journal/notes`, {
        params: {
          title,
          page,
          ...(days && { by_days: days }),
          page_size: page_size,
        },
      });

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getNote({ id }) {
    try {
      const result = await axios.get(`${API_URL}/journal/notes/${id}`);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async addNote(data) {
    try {
      const result = await axios.post(`${API_URL}/journal/notes/`, data);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async updateNote(data) {
    try {
      const result = await axios.patch(`${API_URL}/journal/notes/${data.id}/`, data);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async deleteNote(id) {
    try {
      const result = await axios.delete(`${API_URL}/journal/notes/${id}/`);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default journal;
