import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import lodash from 'lodash';
import { Box, Button, makeStyles } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import Answers from './Answers';
import RadialSeparators from './RadialSeparators';
import {
  getSignUpQuestionnaireRequest,
  getQuestionnaireQuestionsRequest,
  createUserTestRequest,
  clearUserTestAction,
  getQuestionnaireGroupRequest,
} from '../../redux/questionnaire/questionnaireActions';
import { mainLayoutContentContainerRef } from '../shared/layouts/GeneralLayout';
import { useWindowDimension } from '../../hooks';
import { QUESTION_TYPE_NUMERIC } from '../../constants';
import { showModalAction } from '../../redux/general/generalActions';
import { MODAL_TYPE_ALERT } from '../../redux/general/generalActionsTypes';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '120px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '40px',
    },
  },
  body: {
    width: '100%',
    padding: '24px',
    borderRadius: '8px',
  },
  text: {
    maxWidth: '600px',
    textAlign: 'center',
    fontSize: '1.125rem',
    lineHeight: '28px',
  },

  resultTitle: {
    margin: '28px 0 24px',
    fontSize: '48px',
    lineHeight: '58px',
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  resultText: {
    maxWidth: '530px',
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 400,
    color: theme.palette.text.primary,
  },
  buttonReverse: {
    background: theme.palette.type === 'dark' ? '#406BE1' : 'linear-gradient(90deg, #0035FF 0%, #00C1FF 100%)',
    color: '#FFFFFF',
    borderRadius: '8px',
    minWidth: '133px',
    textTransform: 'capitalize',
    padding: '7px 24px!important',
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '90px',
      padding: '6px 24px!important',
    },
  },
  pageHeader: {
    width: '100%',
    alignItems: 'center',
  },
  questionTitle: {
    fontSize: '36px',
    textAlign: 'center',
    marginBottom: '32px',
    marginTop: '52px',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      marginBottom: '24px',
    },
  },
  stepper: {
    width: '136px',
    margin: '0 auto',
    position: 'relative',
  },
  iconMessage: {
    height: '115px',
    width: '100px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  textMessage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '16px',
    lineHeight: '1.5',
    fontWeight: '400',
    margin: '0',
    '& span': {
      fontSize: '36px',
      fontWeight: '700',
    },
  },
  backLink: {
    display: 'flex',
    alignItems: 'center',
    width: '100px',
    height: '52px',
    fontSize: '1em',
    padding: '22px 16px 22px 8px',
    textTransform: 'capitalize',
    border: `solid 1px ${theme.overrides.searchPlaceholder}`,
    borderRadius: '8px',
    '& span': {
      marginLeft: '3px',
      color: theme.palette.text.primary,
    },
  },
  backArrow: {
    transform: 'rotate(90deg)',
    fontSize: '1.75em',
    color: theme.palette.text.primary,
  },
  nextButton: {
    background: theme.palette.type === 'dark' ? '#406BE1' : 'linear-gradient(90deg, #0035FF 0%, #00C1FF 100%)',
    color: '#FFFFFF',
    borderRadius: '8px',
    minWidth: '133px',
    textTransform: 'capitalize',
    padding: '7px 24px!important',
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '90px',
      padding: '6px 24px!important',
    },
    '&:hover': {
      background: theme.palette.type === 'dark' ? '#406BE1' : 'linear-gradient(90deg, #0035FF 0%, #00C1FF 100%)',
    },
  },

  sectionTitle: {
    position: 'absolute',
    color: theme.overrides.circleTitles,
    opacity: '0.2',
    fontSize: '12px',
    minWidth: '60px',
    textAlign: 'center',
    '&.active': {
      opacity: '1',
      fontWeight: 600,
    },
  },
  button: {
    margin: '40px 16px 0',
    height: '46px',
  },
}));

const Questionnaire = ({
  singleQuestionnaire,
  questionnaireGroup,
  currentUserTest,
  isProcessing,
  match,
  history,
  getSignUpQuestionnaire,
  getQuestionnaire,
  createUserTest,
  isDarkTheme,
  clearUserTest,
  getQuestionnaireGroup,
  showModal,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { id } = match.params;

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionLength, setQuestionLength] = useState(0);
  const [userTestQuestions, setUserTestQuestions] = useState([]);
  const [nextQuestionnaire, setNextQuestionnaire] = useState(null);

  useEffect(() => {
    if (id === 'new') {
      getSignUpQuestionnaire();
    } else {
      getQuestionnaire({ id });
    }

    return () => {
      clearUserTest();
    };
  }, [id]);

  useEffect(() => {
    if (id && questionnaireGroup.length) {
      const notPassed = questionnaireGroup.filter((item) => !item.isPassed && +item.questionnaire.id !== +id);

      setNextQuestionnaire(notPassed.length > 0 ? notPassed[0].questionnaire.id : null);
    }
  }, [questionnaireGroup]);

  useEffect(() => {
    // needed for making 1st answer of NUMERIC question to be selected by default
    const mappedQuestions = singleQuestionnaire.questions?.map((question) => {
      if (question.type === QUESTION_TYPE_NUMERIC && question.answers?.length) {
        question.answers[0].selected = true;
      }

      return question;
    });

    setUserTestQuestions(mappedQuestions);
    setQuestionLength(singleQuestionnaire.questions?.length);
  }, [singleQuestionnaire]);

  const updateQuestion = (question) => {
    setUserTestQuestions((questions) => questions.map((item) => (item.id === question.id ? question : item)));
  };

  const createUserTestHandler = () => {
    const userTest = userTestQuestions.reduce(
      (gen, question) => {
        const section = gen.sections.find((sec) => sec.section === question.section);

        if (section) {
          section.questions = [
            ...section.questions,
            {
              question: question.id,
              answers: question.answers.filter((item) => item.selected).map((item) => ({ answer: item.id })),
            },
          ];
        } else {
          gen.sections = [
            ...gen.sections,
            {
              section: question.section,
              questions: [
                {
                  question: question.id,
                  answers: question.answers.filter((item) => item.selected).map((item) => ({ answer: item.id })),
                },
              ],
            },
          ];
        }

        return gen;
      },
      {
        questionnaire: singleQuestionnaire.id,
        sections: [],
      }
    );

    createUserTest(userTest);
  };

  const isFinished = currentUserTest && typeof currentUserTest.maxResult !== 'undefined';
  const isLastQuestion = currentQuestionIndex === questionLength - 1;

  const currentQuestion = userTestQuestions?.[currentQuestionIndex];

  const progressbarValue = currentQuestionIndex * (100 / userTestQuestions?.length);

  const checkQuestionRequirements = (question) => {
    if (!question?.requirements?.length) return true;

    return question.requirements?.some((requirement) => {
      return lodash.find(userTestQuestions, {
        id: requirement.requiredQuestion,
        answers: [{ id: requirement.requiredAnswer, selected: true }],
      });
    });
  };

  const onBackPress = (pageBack = 1) => {
    if (
      currentQuestionIndex - pageBack >= 0 &&
      !checkQuestionRequirements(userTestQuestions[currentQuestionIndex - pageBack])
    ) {
      return onBackPress(pageBack + 1);
    }

    setCurrentQuestionIndex((prevState) => prevState - pageBack);
  };

  const onNextFinishPress = (pageNext = 1) => {
    const question = userTestQuestions[currentQuestionIndex];

    if (question.isLabelQuestion && !question.answers.find((answer) => answer.selected)) {
      return showModal({
        type: MODAL_TYPE_ALERT,
        params: {
          type: 'error',
          message: t('form_question_is_mandatory'),
        },
      });
    }

    if (
      currentQuestionIndex + pageNext < questionLength &&
      !checkQuestionRequirements(userTestQuestions[currentQuestionIndex + pageNext])
    ) {
      return onNextFinishPress(pageNext + 1);
    }

    setCurrentQuestionIndex((prevState) => prevState + pageNext);

    if (mainLayoutContentContainerRef.current) {
      mainLayoutContentContainerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    if (isLastQuestion || currentQuestionIndex + pageNext >= questionLength) {
      createUserTestHandler();
    }
  };

  const [width] = useWindowDimension();

  return (
    <>
      <Helmet>
        <title>Myndful</title>
      </Helmet>
      <Box justify={isFinished ? 'space-between' : 'flex-start'} className={classes.container}>
        <Box display="flex" className={classes.pageHeader}>
          <Button
            disabled={isProcessing}
            className={classes.backLink}
            onClick={() => {
              if (currentQuestionIndex > 0 && !isFinished) {
                onBackPress();
              } else {
                history.push('/dashboard');
              }
            }}
          >
            <ExpandMore className={classes.backArrow} />
            {t('back')}
          </Button>
        </Box>
        {isFinished ? (
          <Box>
            <Box display="flex" style={{ flexWrap: 'wrap', justifyContent: width < 600 && 'center' }}>
              <div className={`${width < 600 && 'question-header-mobile'}`} style={{ textAlign: 'center' }}>
                <div className={classes.stepper}>
                  <p className={classes.textMessage}>
                    <span>{currentUserTest.userResult}</span>points
                  </p>
                  <CircularProgressbarWithChildren
                    value={progressbarValue}
                    strokeWidth={7}
                    styles={buildStyles({
                      strokeLinecap: 'butt',
                    })}
                  >
                    <RadialSeparators
                      count={singleQuestionnaire.length}
                      style={{
                        background: isDarkTheme ? '#253E7B' : 'rgba(255,255,255,0.7)',
                        width: '5px',
                        height: `${10}%`,
                      }}
                    />
                  </CircularProgressbarWithChildren>
                </div>
                <div className={classes.resultTitle}>
                  {t('dashboard_that_quite_awesome')}
                </div>
                <div className={classes.resultText}>
                  {t('dashboard_congrats')}
                </div>
              </div>
            </Box>
            <Box align="center">
              <Link to={'/dashboard'}>
                <Button
                  color="primary"
                  className={`${classes.button} ${classes.buttonReverse}`}
                  disabled={isProcessing}
                  type="button"
                  variant="contained"
                >
                  {t('dashboard_back_to_dashboard')}
                </Button>
              </Link>
            </Box>
          </Box>
        ) : currentQuestion ? (
          <div className={classes.body}>
            <div>
              <div className={classes.stepper}>
                <img
                  src={currentQuestion?.progressBarImage}
                  alt="icon message write"
                  className={classes.iconMessage}
                  width="53"
                  height="53"
                />
                <CircularProgressbarWithChildren
                  value={currentQuestionIndex * (100 / singleQuestionnaire?.questions?.length)}
                  strokeWidth={7}
                  styles={buildStyles({
                    strokeLinecap: 'butt',
                  })}
                >
                  <RadialSeparators
                    count={singleQuestionnaire?.questions?.length}
                    style={{
                      background: isDarkTheme ? '#253E7B' : '#eeeaeb',
                      width: '5px',
                      height: `${7}%`,
                    }}
                  />
                </CircularProgressbarWithChildren>
              </div>
              <h3 className={classes.questionTitle}>{currentQuestion.title}</h3>
              {userTestQuestions?.map((question, index) => {
                return (
                  <div key={`question-id-${index}`}>
                    {question === userTestQuestions?.[currentQuestionIndex] ? (
                      <Answers question={question} updateQuestion={updateQuestion} />
                    ) : null}
                  </div>
                );
              })}
            </div>

            <div className="questionnaire-buttons">
              <Button disabled={isProcessing} className={classes.nextButton} onClick={() => onNextFinishPress()}>
                {isLastQuestion ? t('finish') : t('next')}
              </Button>
            </div>
          </div>
        ) : null}
        <div />
      </Box>
    </>
  );
};

Questionnaire.propTypes = {
  singleQuestionnaire: PropTypes.object.isRequired,
  questionnaireGroup: PropTypes.array.isRequired,
  currentUserTest: PropTypes.object.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  isDarkTheme: PropTypes.bool,
  error: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getSignUpQuestionnaire: PropTypes.func.isRequired,
  getQuestionnaire: PropTypes.func.isRequired,
  createUserTest: PropTypes.func.isRequired,
  clearUserTest: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    singleQuestionnaire: state.questionnaire.singleQuestionnaire,
    questionnaireGroup: state.questionnaire.questionnaireGroup,
    currentUserTest: state.questionnaire.currentUserTest,
    isProcessing: state.questionnaire.processing,
    isDarkTheme: state.general.isDarkTheme,
    error: state.questionnaire.error || {},
  }),
  {
    getSignUpQuestionnaire: getSignUpQuestionnaireRequest,
    getQuestionnaire: getQuestionnaireQuestionsRequest,
    createUserTest: createUserTestRequest,
    clearUserTest: clearUserTestAction,
    getQuestionnaireGroup: getQuestionnaireGroupRequest,
    showModal: showModalAction,
  }
)(Questionnaire);
