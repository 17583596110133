import React from 'react';
import { Box, makeStyles, Typography, Breadcrumbs } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  content: {
    maxWidth: '825px',
    margin: '30px auto 0',
    padding: '24px 0',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
    },
  },
  missionImageContainer: {
    width: '100%',
    marginBottom: "36px",
    height: "242px",
    [theme.breakpoints.down('xs')]: {
      height: "150px",
    },
  },
  missionImage: {
    width: '100%',
    height: '100%',
    objectFit: "cover",
    borderRadius: "8px",
  }
}));

const OurMission = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Myndful</title>
      </Helmet>
      <div className={`main-form ${classes.content}`}>
        <Box mb={5}>
          <div className="breadcrumbs-outer">
            <div className="breadcrumbs-wrapper">
              <Breadcrumbs className="breadcrumbs">
                <Link to="/dashboard">{t('m_dashboard')}</Link>
                <Link to="/our-mission">{t('m_our_mission')}</Link>
              </Breadcrumbs>
            </div>
          </div>
          <Typography variant="h4">{t('m_our_mission')}</Typography>
        </Box>
        <div className={classes.missionImageContainer}>
          <img className={classes.missionImage} src='/images/mission_page_image.png' alt='mission' />
        </div>
        <div className="main-form__section">
          <div className="main-form__section-text">
            <p>{t('our_mission_text_1')}</p>
            <p>{t('our_mission_text_2')}</p>
            <p>
              <i>{t('our_mission_text_3')}</i>
              <i>Myndful</i>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurMission;
