import { LinearProgress, makeStyles, withStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  achieveContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '130px',
    width: '100%',
    position: 'relative',
    '&.modalView': {
      maxWidth: '112px',
    },
    '& .icon': {
      maxWidth: '128px',
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      '&.unactive': {
        opacity: '0.5',
      },
    },
    '&.modalView .icon': {
      maxWidth: '92px',
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      '&.unactive': {
        opacity: '0.5',
      },
    },
    '& .title': {
      marginTop: '12px',
      fontSize: '18px',
      fontWeight: '500',
      color: theme.palette.type === 'dark' ? '#F2F2F2' : '#2A2B2A',
      textAlign: 'center',
      '&.unactive': {
        opacity: '0.5',
      },
    },
    '&.modalView .title': {
      marginTop: '12px',
      fontSize: '14px',
      fontWeight: '400',
      color: theme.palette.type === 'dark' ? '#F2F2F2' : '#2A2B2A',
      textAlign: 'center',
      '&.unactive': {
        opacity: '0.5',
      },
    },
    '& .description': {
      marginTop: '6px',
      fontSize: '12px',
      width: '100%',
      color: theme.palette.type === 'dark' ? '#F2F2F260' : '#2A2B2A80',
      textAlign: 'center',
      '& .progress': {
        fontSize: '12px',
        marginTop: '4px',
        marginBottom: '0',
      },
    },
  },
  countBadge: {
    border: '1px solid #FFFFFF70',
    color: '#F2F2F2',
    backgroundColor: '#FE4A5E',
    padding: '2px 7px',
    position: 'absolute',
    right: '8px',
    top: '8px',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '48px',
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    marginTop: '6px',
  },
  colorPrimary: {
    backgroundColor: theme.palette.type === 'dark' ? '#F2F2F260' : '#2A2B2A30',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#406BE1',
  },
}))(LinearProgress);

const AchievementCard = ({ badge, isModalCards }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const MIN = 0;
  const normalise = (value, maxValue) => ((value - MIN) * 100) / (maxValue - MIN);
  const isBadgeHaveCount = !!badge.count;

  const setTypeOfValue = ({ value, maxValue }) => {
    if(maxValue <= 7){
      return `${value} of ${maxValue} days`;
    } else if (maxValue > 7 && maxValue <= 100) {
      return `${value} of ${maxValue}`;
    } else if (maxValue > 100 && maxValue <= 1000) {
      return `${value} points`;
    }
  };

  return (
    <div className={`${classes.achieveContainer} ${isModalCards ? 'modalView' : ''}`}>
      {isBadgeHaveCount ? <div className={classes.countBadge}>{badge.count}x</div> : null}
      <img className={`icon ${!isBadgeHaveCount ? 'unactive' : ''}`} src={badge.badge.icon} />
      <div className={`title ${!isBadgeHaveCount ? 'unactive' : ''}`}>{badge.badge.title}</div>
      {isBadgeHaveCount ? (
        <p className="description">{t('achievements', { returnObjects: true })[badge.badge.variant]}</p>
      ) : (
        <div className="description">
          <BorderLinearProgress variant="determinate" value={normalise(badge.progress, badge.badge.goal)} />
          <p className="progress">
            {
              setTypeOfValue({
                value: badge.progress,
                maxValue: badge.badge.goal,
              })
            }
          </p>
        </div>
      )}
    </div>
  );
};

export default AchievementCard;
