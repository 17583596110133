import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    position: 'relative',
    backgroundColor: 'transparent',
    borderRadius: '4px',
    zIndex: 0,
    '&.light': {
      backgroundColor: 'transparent',
      '& input': {
        border: '1px solid #D4DDF3',
        borderRadius: '4px',
        color: '#3B5266',
        padding: '10px 15px 10px 50px',
      },
    },
  },
  placeholder: {
    position: 'absolute',
    top: '50%',
    left: '15px',
    transform: 'translateY(-50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#94A3C8',
    zIndex: -1,
    '& svg': {
      fontSize: '20px',
      '& path': {
        stroke: theme.overrides.searchPlaceholder,
      },
    },
    '& span': {
      marginLeft: '8px',
    },
  },
  search: {
    width: '100%',
    padding: '26px',
    backgroundColor: 'transparent',
    border: '0',
    borderBottom: `solid 1px ${theme.overrides.itemBorder}`,
    borderRadius: '0',
    fontSize: '1em',
    fontWeight: 300,
    color: theme.palette.type === 'dark' ? '#FFFFFF' : '#000000',
    paddingLeft: 50,
    '&::placeholder': {
      color: theme.overrides.searchPlaceholder,
    },
  },
}));

const SearchField = ({ value, type, onChange }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={`${classes.searchContainer} ${type}`}>
      <div className={classes.placeholder}>
        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19 11C19 13.21 18.105 15.21 16.657 16.657C15.3483 17.9657 13.6263 18.7802 11.7844 18.9617C9.94243 19.1432 8.09457 18.6804 6.55563 17.6521C5.01668 16.6239 3.88187 15.0939 3.34455 13.3228C2.80723 11.5516 2.90064 9.64899 3.60887 7.93902C4.3171 6.22905 5.59633 4.81754 7.2286 3.94501C8.86086 3.07248 10.7452 2.7929 12.5605 3.15392C14.3757 3.51494 16.0097 4.49422 17.1839 5.92489C18.3581 7.35557 18.9999 9.14913 19 11V11Z"
            stroke="#94A3C8"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.0002 21L16.6572 16.657"
            stroke="#94A3C8"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <input placeholder={t('search')} defaultValue={value} className={classes.search} onChange={(evt) => onChange(evt.target.value)} />
    </div>
  );
};

SearchField.propTypes = {
  value: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['light', 'dark']).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SearchField;
