import React, { useEffect, useState, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Box, makeStyles, Typography, Breadcrumbs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Pagination } from '@material-ui/lab';

import SearchField from '../shared/components/SearchField';
import {
  getArticleListRequest,
  searchArticleListRequest,
  getArticleCategoryRequest,
} from '../../redux/blog/blogActions';
import { showModalAction } from '../../redux/general/generalActions';

const useStyles = makeStyles((Theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    maxWidth: '730px',
    margin: '0 auto',
    padding: '0 0 70px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    backgroundColor: Theme.overrides.backgroundResources,
    borderRadius: '8px',
    minHeight: 'calc(50vh - 136px)',
    [Theme.breakpoints.up('sm')]: {
      minHeight: 'calc(50vh - 104px)',
    },
  },
  content: {
    marginTop: '12px',
  },
  titleContent: {
    maxWidth: '730px',
    margin: '30px auto 0',
    padding: '24px 0',
    [Theme.breakpoints.down('sm')]: {
      margin: '0 auto',
    },
  },
  item: {
    display: 'block',
    paddingBottom: '16px',
    boxShadow:
      Theme.palette.type === 'dark' ? 'inset 0px -1px 0px rgba(242, 242, 242, 0.2)' : 'inset 0px -1px 0px #F5F5F5',
    '& div': {
      width: '100%',
      paddingTop: '16px',
      marginBottom: '0',
    },
    '& p': {
      margin: 0,
      maxWidth: 'calc(100% - 40px)',
      fontSize: '1em',
      fontWeight: '400',
      opacity: '0.8',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  nextArrow: {
    transform: 'rotate(-90deg)',
    fontSize: '1.75em',
    fill: Theme.overrides.searchPlaceholder,
  },
  pagination: {
    position: 'absolute',
    bottom: '24px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'fit-content',
    '& button': {
      border: '1px solid #CCCCCC',
      borderRadius: 2,
      backgroundColor: 'rgba(242, 242, 242, 0.2)',
    },
  },
  categoryFilterContainer: {
    padding: '16px 0 0',
    '& p': {
      padding: '0 24px',
    },
  },
  categoryFilterButtons: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '16px 24px',
    gap: '20px',
    boxShadow:
      Theme.palette.type === 'dark' ? 'inset 0px -1px 0px rgba(242, 242, 242, 0.2)' : 'inset 0px -1px 0px #F5F5F5',
    '& button': {
      cursor: 'pointer',
      backgroundColor: 'unset',
      fontSize: '16px',
      fontFamily: 'Inter, sans-serif',
    },
  },
  articlesLink: {
    width: 'max-content',
    display: 'flex',
    alignItems: 'center',
    border: Theme.palette.type === 'dark' ? '2px solid #F2F2F2' : '2px solid rgba(0,0,0, 0.2)',
    borderRadius: '8px',
    padding: '13px 17px',
    color: Theme.palette.text.primary,
    [Theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'center',
    },
    '&:hover, &:focus': {
      background:
        Theme.palette.type === 'dark'
          ? 'rgba(255, 255, 255, 0.1)'
          : 'linear-gradient(90deg, rgba(51, 93, 255, 0.3) 0%, rgba(76, 211, 255, 0.3) 100%)',
      border: Theme.palette.type === 'dark' ? '2px solid #F2F2F2' : '2px solid #0035FF',
    },
  },
}));

const FocusPointArticles = ({
  articleListLength = 0,
  articleList = [],
  articleCategory = [],
  getArticleCategory,
  getArticleList,
  isDarkTheme,
  searchArticleList,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const history = useHistory();
  const { search: searchLocation } = useLocation();

  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const searchParams = useMemo(() => new URLSearchParams(searchLocation), [searchLocation]);

  useEffect(() => {
    const pageParam = searchParams.get('page');
    if (pageParam) setPage(Number(pageParam));
  }, []);

  useEffect(() => {
    if (!search) {
      getArticleList({ page: page, page_size: 10 });
    } else {
      searchArticleList({ query: search });
    }
  }, [page, search]);

  useEffect(() => {
    getArticleCategory();

    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [page]);

  useEffect(() => {
    setCount(Math.ceil(articleListLength / 10));
  }, [articleListLength]);

  const searchHandler = (value) => {
    setSearch(value);
    page !== 1 && setPage(1);
  };

  const onPagination = (pageNumber) => {
    searchParams.set('page', String(pageNumber));
    history.replace({ search: searchParams.toString() });
    setPage(pageNumber);
  };

  return (
    <>
      <Helmet>
        <title>Myndful</title>
      </Helmet>
      <div className={`main-form ${classes.titleContent}`}>
        <Box my={3}>
          <div className="breadcrumbs-outer" style={{ marginTop: '20px' }}>
            <div className="breadcrumbs-wrapper">
              <Breadcrumbs className="breadcrumbs">
                <Link to="/dashboard">{t('m_dashboard')}</Link>
                <Link to="/resources/focus-point-articles">{t('m_articles')}</Link>
              </Breadcrumbs>
            </div>
          </div>
          <Typography variant="h4" style={{ fontWeight: 700 }}>
            {t('m_resources')}
          </Typography>
        </Box>
        <Box mb={3}>
          <div className="main-form__section-text">
            <p>{t('articles_subtitle')}</p>
          </div>
        </Box>
      </div>
      <div className={classes.container}>
        <Box>
          <SearchField value={search} type="dark" onChange={searchHandler} />
        </Box>
        <div>
          <div className={classes.categoryFilterContainer}>
            <Typography>{t('articles_filter_by_category')}</Typography>
            <div className={classes.categoryFilterButtons}>
              <button
                className={classes.articlesLink}
                onClick={() => {
                  getArticleList({ page: page, page_size: 10 });
                }}
              >
                {t('articles_all')}
              </button>
              {articleCategory?.map((item, index) => (
                <button
                  key={index}
                  className={`${classes.articlesLink}`}
                  onClick={() => {
                    getArticleList({ category: item.id });
                  }}
                >
                  {item.name}
                </button>
              ))}
            </div>
          </div>
          {articleList.map((item) => (
            <Link
              to={`/resources/focus-point-articles/${item.slug}`}
              key={`article-${item.id}`}
              className={`${classes.item} article-link`}
            >
              <Box mb={2} px={3} display="flex" alignItems="center" justifyContent="space-between">
                <Typography>{item.title}</Typography>
                <img
                  src={isDarkTheme ? '/images/icon-arrow-dark.svg' : '/images/icon-arrow-light.svg'}
                  alt="icon arrow"
                  width="20"
                  height="16"
                />
              </Box>
            </Link>
          ))}
        </div>
        {count > 1 && (
          <Pagination
            className={classes.pagination}
            siblingCount={0} 
            boundaryCount={1}
            count={count}
            shape="rounded"
            page={page}
            onChange={(evt, data) => onPagination(data)}
          />
        )}
      </div>
    </>
  );
};

FocusPointArticles.propTypes = {
  articleListLength: PropTypes.number,
  articleList: PropTypes.array.isRequired,
  articleCategory: PropTypes.array.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  isDarkTheme: PropTypes.bool.isRequired,
  error: PropTypes.object,
  getArticleList: PropTypes.func.isRequired,
  getArticleCategory: PropTypes.func.isRequired,
  searchArticleList: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    articleListLength: state.blog.articleList.count,
    articleList: state.blog.articleList.results,
    isProcessing: state.blog.processing,
    articleCategory: state.blog.articleCategory,
    isDarkTheme: state.general.isDarkTheme,
    error: state.blog.error || {},
  }),
  {
    getArticleList: getArticleListRequest,
    getArticleCategory: getArticleCategoryRequest,
    searchArticleList: searchArticleListRequest,
    showModal: showModalAction,
  }
)(FocusPointArticles);
