import * as types from './questionnaireActionsTypes';

export const getSignUpQuestionnaireRequest = () => {
  return { type: types.GET_SIGN_UP_QUESTIONNAIRE_REQUEST };
};

export const getQuestionnaireListRequest = (data) => {
  return { type: types.GET_QUESTIONNAIRE_LIST_REQUEST, payload: data };
};

export const getQuestionnaireGroupRequest = () => {
  return { type: types.GET_QUESTIONNAIRE_GROUP_REQUEST };
};

export const getQuestionnaireRequest = (data) => {
  return { type: types.GET_QUESTIONNAIRE_REQUEST, payload: data };
};

export const getQuestionnaireSectionsRequest = (data) => {
  return { type: types.GET_QUESTIONNAIRE_SECTIONS_REQUEST, payload: data };
};

export const getQuestionnaireQuestionsRequest = (data) => {
  return { type: types.GET_QUESTIONNAIRE_QUESTIONS_REQUEST, payload: data };
};

export const createUserTestRequest = (data) => {
  return { type: types.CREATE_USER_TEST_REQUEST, payload: data };
};

export const clearUserTestAction = () => {
  return { type: types.CLEAR_USER_TEST_ACTION };
};
