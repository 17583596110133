import { Route } from 'react-router-dom';

import PrivateRoute from './shared/routes/PrivateRoute';
import PublicRoute from './shared/routes/PublicRoute';

// Layouts
import AuthLayout from './shared/layouts/AuthLayout';
import GeneralLayout from './shared/layouts/GeneralLayout';
import PublicLayout from './shared/layouts/PublicLayout';

// Components
import ResetPassword from './Auth/ResetPassword';
import SetNewPassword from './Auth/SetNewPassword';
import SignIn from './Auth/SignIn';
import SignUp from './Auth/SignUp';
import GoogleOAuth from './Auth/GoogleOAuth';
import Verification from './Auth/Verification';
import Blog from './Blog/Blog';
import Dashboard from './Dashboard/Dashboard';
import Feedback from './Feedback/Feedback';
import Home from './Home/Home';
import HowToImprove from './HowToImprove/HowToImprove';
import Journal from './Journal/Journal';
import OurMission from './OurMission/OurMission';
import PersonalizedPlan from './PersonalizedPlan/PersonalizedPlan';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import Profile from './Profile/Profile';
import Questionnaire from './Questionnaire/Questionnaire';
import FocusPointArticles from './FocusPointArticles/FocusPointArticles';
import FocusPointArticle from './FocusPointArticles/FocusPointArticle';
import Templates from './Templates/Templates';
import Template from './Templates/Template';
import NotFound from './shared/NotFound';
import SharedAccess from './SharedAccess/SharedAccess';
import SharedDashboard from './SharedAccess/SharedDashboard';
import Summary from './Summary/Summary';
import Terms from './Terms/Terms';
import Unsubscribe from './Profile/Unsubscribe';
import Achievements from './Achievements/Achievements';
import JournalCreate from './Journal/JournalCreate';

const routes = [
  // Not Authorized users only - PublicRoute component
  // NOTE: you can pass layout prop. This layout component will wrapper your route component
  {
    path: '/auth/sign-up',
    exact: true,
    component: SignUp,
    route: PublicRoute,
    layout: AuthLayout,
  },
  {
    path: '/social-google',
    exact: true,
    component: GoogleOAuth,
    route: PublicRoute,
    layout: AuthLayout,
  },
  {
    path: '/auth/sign-in',
    exact: true,
    component: SignIn,
    route: PublicRoute,
    layout: AuthLayout,
  },
  {
    path: '/auth/reset-password',
    exact: true,
    component: ResetPassword,
    route: PublicRoute,
    layout: AuthLayout,
  },
  {
    path: '/auth/set-new-password',
    exact: true,
    component: SetNewPassword,
    route: PublicRoute,
    layout: AuthLayout,
  },
  {
    path: '/auth/verification',
    exact: true,
    component: Verification,
    route: PublicRoute,
    layout: AuthLayout,
  },

  // Authorized users only - PrivateRoute component
  {
    path: '/dashboard',
    exact: true,
    component: Dashboard,
    route: PrivateRoute,
    layout: GeneralLayout,
  },
  {
    path: '/dashboard/achievements',
    exact: true,
    component: Achievements,
    route: PrivateRoute,
    layout: GeneralLayout,
  },
  {
    path: '/dashboard/questionnaire/:id(new|[0-9]+)',
    exact: true,
    component: Questionnaire,
    route: PrivateRoute,
    layout: GeneralLayout,
  },
  {
    path: '/feedback',
    exact: true,
    component: Feedback,
    route: PrivateRoute,
    layout: GeneralLayout,
  },
  {
    path: '/shared-access',
    exact: true,
    component: SharedAccess,
    route: PrivateRoute,
    layout: GeneralLayout,
  },
  {
    path: '/shared-access/user/:id(new|[0-9]+)',
    exact: true,
    component: SharedDashboard,
    route: PrivateRoute,
    layout: GeneralLayout,
  },
  {
    path: '/journal',
    exact: true,
    component: Journal,
    route: PrivateRoute,
    layout: GeneralLayout,
  },
  {
    path: '/journal/:id',
    exact: true,
    component: JournalCreate,
    route: PrivateRoute,
    layout: GeneralLayout,
  },
  {
    path: '/journal/:id/view',
    exact: true,
    component: JournalCreate,
    route: PrivateRoute,
    layout: GeneralLayout,
  },
  {
    path: '/journal/:id/edit',
    exact: true,
    component: JournalCreate,
    route: PrivateRoute,
    layout: GeneralLayout,
  },
  {
    path: '/summary',
    exact: true,
    component: Summary,
    route: PrivateRoute,
    layout: GeneralLayout,
  },
  {
    path: '/personalized-plan',
    exact: true,
    component: PersonalizedPlan,
    route: PrivateRoute,
    layout: GeneralLayout,
  },
  {
    path: '/profile',
    exact: true,
    component: Profile,
    route: PrivateRoute,
    layout: GeneralLayout,
  },
  {
    path: '/resources/focus-point-articles',
    exact: true,
    component: FocusPointArticles,
    route: PrivateRoute,
    layout: GeneralLayout,
  },
  {
    path: '/resources/focus-point-articles/:slug',
    exact: true,
    component: FocusPointArticle,
    route: PrivateRoute,
    layout: GeneralLayout,
  },
  {
    path: '/resources/templates',
    exact: true,
    component: Templates,
    route: PrivateRoute,
    layout: GeneralLayout,
  },
  {
    path: '/resources/templates/:id',
    exact: true,
    component: Template,
    route: PrivateRoute,
    layout: GeneralLayout,
  },
  {
    path: '/resources/templates/:id/view',
    exact: true,
    component: Template,
    route: PrivateRoute,
    layout: GeneralLayout,
  },
  {
    path: '/how-to-improve/:slug',
    exact: true,
    component: HowToImprove,
    route: PrivateRoute,
    layout: GeneralLayout,
  },
  {
    path: '/our-mission',
    exact: true,
    component: OurMission,
    route: PrivateRoute,
    layout: GeneralLayout,
  },
  {
    path: '/',
    exact: true,
    component: Home,
    route: PublicRoute,
    layout: PublicLayout,
  },
  {
    path: '/blog',
    exact: true,
    component: Blog,
    route: PublicRoute,
    layout: PublicLayout,
  },
  {
    path: '/blog/:slug',
    exact: true,
    component: Blog,
    route: PublicRoute,
    layout: PublicLayout,
  },
  {
    path: '/privacy-policy',
    exact: true,
    component: PrivacyPolicy,
    route: PublicRoute,
    layout: PublicLayout,
  },
  {
    path: '/terms-and-conditions',
    exact: true,
    component: Terms,
    route: PublicRoute,
    layout: PublicLayout,
  },

  // All users - Route component
  {
    path: '/unsubscribe',
    exact: true,
    component: Unsubscribe,
    route: Route,
    layout: PublicLayout,
  },

  // 404 Not Found route
  {
    path: '*',
    component: NotFound,
    route: Route
  },
];

export default routes;
