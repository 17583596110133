import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Breadcrumbs, Button, makeStyles, MenuItem, Select } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

import { showModalAction } from '../../redux/general/generalActions';
import { getNotesRequest } from '../../redux/journal/journalActions';
import { BootstrapInput } from '../Dashboard/UserTestCharts';
import Note from './Note';
import { debounce } from 'lodash';

const useStyles = makeStyles((theme) => {
  const isDark = theme.palette.type === 'dark';

  return {
    header: {
      color: theme.palette.text.primary,
      fontWeight: '700',
      fontSize: '24px',
      paddingBottom: '11px',
      borderBottom: `1px solid ${isDark ? 'rgba(242, 242, 242, 0.2)' : 'rgba(0, 0, 0, 0.2)'}`,
      display: 'flex',
      alignItems: 'center',
      gap: '24px',
    },
    selectContainer: {
      marginLeft: 'auto',
      minWidth: '325px',
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      flexWrap: 'wrap',
    },
    select: {
      '& .MuiSelect-root': {
        padding: '12px 65px 12px 43px',
      },
      '& .MuiSelect-icon': {
        right: '40px',
      },
    },
    selectMenuPaper: {
      backgroundColor: theme.overrides.filterListBackground,
    },
    addButton: {
      cursor: 'pointer',
      padding: '8px 24px !important',
      textTransform: 'capitalize',
      borderRadius: '8px',
      border: '1px solid #ced4da',

      '& img': {
        marginRight: '13px',
      },
    },
    pagination: {
      marginTop: '24px',
      margin: '0 auto',
      '& button': {
        border: '1px solid #CCCCCC',
        borderRadius: 2,
        backgroundColor: 'rgba(242, 242, 242, 0.2)',
      },
    },
  };
});

const Journal = ({ isDarkTheme, notes, getNotes, pageSize, history }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [days, setDays] = useState('All');
  const [page, setPage] = useState(1);
  const [searchNotes, setSearchNotes] = useState('');

  const count = Math.ceil(notes.total / pageSize);
  const plusIconSrc = isDarkTheme ? '/images/icon-plus-dark.svg' : '/images/icon-plus-light.svg';

  useEffect(() => {
    getNotes({ title: searchNotes, days: days === 'All' ? '' : days, page, page_size: pageSize });
  }, [days, page, notes.data.length, getNotes, pageSize]);

  const handleDaysChange = (e) => {
    setDays(e.target.value);
  };

  const addNote = () => {
    history.push('/journal/create');
  };

  const debouncedSearch = debounce(async (value) => {
    setSearchNotes(value);
    getNotes({ title: value, days: days === 'All' ? '' : days });
  }, 500);

  async function handleChange(e) {
    debouncedSearch(e.target.value);
  }

  const renderNotes = () => {
    if (!notes.data.length) {
      return (
        <Box textAlign="center" mt="250px">
          {t('journal_notes_empty_text')}
        </Box>
      );
    }

    return (
      <>
        <Box>
          {notes.data.map((note) => (
            <Note key={note.id} {...note} content={note.title} />
          ))}
        </Box>
        <Box display="flex" alignItems={'center'}>
          <Pagination
            siblingCount={0}
            boundaryCount={1}
            color="primary"
            className={classes.pagination}
            count={count}
            page={page}
            onChange={(evt, data) => setPage(data)}
          />
        </Box>
      </>
    );
  };

  return (
    <>
      <div className="breadcrumbs-outer">
        <div className="breadcrumbs-wrapper">
          <Breadcrumbs className="breadcrumbs">
            <Link to="/dashboard">{t('m_dashboard')}</Link>
            <Link to="/journal">{t('m_journal')}</Link>
          </Breadcrumbs>
        </div>
      </div>
      <Box className={classes.header} mt={10} mb={4} display="flex" alignItems="flex-end" flexWrap="wrap">
        <span>{t('journal_your_quick_notes')}</span>
        <div className={classes.selectContainer}>
          <BootstrapInput
            onChange={handleChange}
            placeholder={t('journal_filter_search')}
            variant="contained"
            style={{ width: '185px', margin: '0 20px' }}
          />

          <Select
            labelId="days-label"
            id="days"
            value={days}
            MenuProps={{
              classes: { paper: classes.selectMenuPaper },
              transitionDuration: 0,
            }}
            onChange={handleDaysChange}
            input={<BootstrapInput variant="contained" className={classes.select} />}
          >
            <MenuItem value="All">{t('all_period')}</MenuItem>
            <MenuItem value={21}>{`21 ${t('dashboard_days')}`}</MenuItem>
            <MenuItem value={14}>{`14 ${t('dashboard_days')}`}</MenuItem>
            <MenuItem value={7}>{`7 ${t('dashboard_days')}`}</MenuItem>
          </Select>

          <Button className={classes.addButton} onClick={addNote}>
            <img src={plusIconSrc} alt="plus icon" />
            {t('journal_add_note')}
          </Button>
        </div>
      </Box>

      {renderNotes()}
    </>
  );
};

Journal.propTypes = {
  showModal: PropTypes.func.isRequired,
  isDarkTheme: PropTypes.bool,
  isProcessing: PropTypes.bool,
  notes: PropTypes.shape({
    total: PropTypes.number,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        content: PropTypes.string,
      })
    ),
  }),
  pageSize: PropTypes.number.isRequired,
  getNotes: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isDarkTheme: state.general.isDarkTheme,
    notes: state.journal.notes,
    pageSize: state.journal.pageSize,
  }),
  {
    getNotes: getNotesRequest,
    showModal: showModalAction,
  }
)(Journal);
