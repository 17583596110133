import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

import EmptyLayout from './shared/layouts/EmptyLayout';
import routes from './routes';
import { MODAL_TYPE_WELCOME } from '../redux/general/generalActionsTypes';
import { showModalAction } from '../redux/general/generalActions';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

function App({ isShowWelcomeModal, showModal }) {
  const { t } = useTranslation();

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);
  useEffect(() => {
    if (isShowWelcomeModal) {
      showModal({
        title: t('modal_welcome_title'),
        type: MODAL_TYPE_WELCOME,
      });
    }
  }, [isShowWelcomeModal]);

  return (
    <Switch>
      {routes.map((el) => {
        const RouteComponent = el.route || Route;

        return (
          <RouteComponent
            key={el.path}
            path={el.path}
            exact={el.exact}
            component={(props) => {
              const LayoutComponent = el.layout || EmptyLayout;

              return (
                <LayoutComponent>
                  <el.component {...props} />
                </LayoutComponent>
              );
            }}
          />
        );
      })}
    </Switch>
  );
}

export default connect(
  (state) => ({
    isShowWelcomeModal: state.auth.isShowWelcomeModal,
  }),
  {
    showModal: showModalAction,
  }
)(App);
