import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  eye: {
    cursor: 'pointer',
  },
  inputStyles: {
    borderRadius: '8px!important',
    border: `2px solid ${theme.overrides.inputBorder}`,
    backgroundColor: 'transparent',
  },
  labelStyles: {
    color: theme.overrides.labelFormColor,
  },
}));

const PasswordField = ({ error = {}, errors = {}, name, ...props }) => {
  const classes = useStyles();

  const [passwordIsMasked, setPasswordIsMasked] = useState(true);

  return (
    <TextField
      type={passwordIsMasked ? 'password' : 'text'}
      variant="filled"
      fullWidth
      InputLabelProps={{
        className: classes.labelStyles,
      }}
      error={!!errors[name] || !!error[name]}
      helperText={errors[name] ? errors[name].message : error[name] ? error[name][0] : ''}
      name={name}
      {...props}
      InputProps={{
        disableUnderline: true,
        className: classes.inputStyles,
        endAdornment: (
          <InputAdornment position="end" className={classes.eye} onClick={() => setPasswordIsMasked(!passwordIsMasked)}>
            {passwordIsMasked ? <VisibilityOff /> : <Visibility />}
          </InputAdornment>
        ),
      }}
    />
  );
};

PasswordField.propTypes = {
  error: PropTypes.object,
  errors: PropTypes.object,
  name: PropTypes.string.isRequired,
};

export default PasswordField;
