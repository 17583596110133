import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import 'react-phone-input-2/lib/material.css';
import 'react-circular-progressbar/dist/styles.css';
import './assets/styles/index.scss';

import './i18n';
import App from './react/App';
import Modal from './react/shared/Modal/Modal';
import ThemeProvider from './react/providers/ThemeProvider';
import LanguagesProvider from './react/providers/LanguagesProvider';
import ProfileSettingsProvider from './react/providers/ProfileSettingsProvider';
import { hasSSR, store } from './redux/configureStore';
import * as serviceWorker from './serviceWorker';

// Render App
if (typeof window !== 'undefined') {
  console.info('v1.2.0 (27.04.2023)');

  const renderFunc = hasSSR ? ReactDOM.hydrate : ReactDOM.render;

  renderFunc(
    <Provider store={store}>
      <Router>
        <ProfileSettingsProvider>
          <LanguagesProvider>
            <ThemeProvider>
              <App />
              <Modal />
            </ThemeProvider>
          </LanguagesProvider>
        </ProfileSettingsProvider>
      </Router>
    </Provider>,
    document.getElementById('root')
  );

  serviceWorker.unregister();
}
