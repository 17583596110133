import * as types from './blogActionsTypes';

export const getArticleListRequest = (data) => {
  return { type: types.GET_ARTICLE_LIST_REQUEST, payload: data };
};

export const getArticleListCategoryRequest = (data) => {
  return { type: types.GET_ARTICLE_LIST_CATEGORY_REQUEST, payload: data };
};

export const getArticleCategoryRequest = (data) => {
  return { type: types.GET_ARTICLE_CATEGORY_REQUEST, payload: data };
};

export const searchArticleListRequest = (data) => {
  return { type: types.SEARCH_ARTICLE_LIST_REQUEST, payload: data };
};

export const getSingleArticleRequest = (data) => {
  return { type: types.GET_SINGLE_ARTICLE_REQUEST, payload: data };
};

export const clearSingleArticleAction = () => {
  return { type: types.CLEAR_SINGLE_ARTICLE_ACTION };
};
