import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles, useMediaQuery } from '@material-ui/core';

import { theme } from '../../providers/ThemeProvider';
import { CONTAINER_MAX_WIDTH } from '../../../constants';

const logoWhite = '/images/logo-white.svg';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: CONTAINER_MAX_WIDTH,
  },
  footer: {
    padding: '70px 16px 40px',
    backgroundColor: '#263D79',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '35px 16px 20px',
      '& .MuiGrid-item': {
        padding: 10,
      },
    },
    '& .logo': {
      textAlign: 'left',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
    },
    '& .copyright': {
      fontWeight: 500,
      fontSize: 16,
      color: '#fff',
      opacity: 0.5,
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
      },
    },
  },
  greyLinks: {
    fontSize: 12,
    color: 'rgba(255, 255, 255, 0.5)',
    fontWeight: 500,
    textDecorationLine: 'underline',
    marginLeft: 16,
    '&:hover': {
      color: 'rgba(255, 255, 255, 0.7)',
      textDecorationLine: 'underline',
    },

    '&:visited': {
      color: 'rgba(255, 255, 255, 0.5)',
      textDecorationLine: 'underline',
    },
  },
  whiteLinks: {
    fontSize: 16,
    color: '#FFFFFF!important',
    margin: '0 26px',
    fontWeight: 500,
  },
}));

const PublicFooter = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const isSmallMediaQuery = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Grid item xs={12} className={classes.footer}>
      <Grid container justify="center">
        <Grid item xs={12} className={classes.container}>
          <Grid container spacing={5} justify="space-between" alignItems="center">
            <Grid item xs={12} sm={4} className="logo">
              <img src={logoWhite} alt="Myndful" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Link className={classes.whiteLinks} to="/">{t('m_home')}</Link>
              <Link className={classes.whiteLinks} to="/blog">{t('m_blog')}</Link>
            </Grid>
            <Grid container item xs={12} md={4} justify={isSmallMediaQuery ? 'center' : 'flex-end'}>
              <div className="copyright">{`© 2023 Myndful. ${t('m_all_rights_reserved')}.`}</div>
              <div>
                <Link className={classes.greyLinks} to="/privacy-policy">
                  {t('m_privacy_policy')}
                </Link>
                <Link className={classes.greyLinks} to="/terms-and-conditions">
                  {t('m_terms_conditions')}
                </Link>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PublicFooter;
