import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createMuiTheme, ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';

const THEME_PALETTE = {
  dark: {
    mainPrimaryColor: '#406BE1',
    mainSecondaryColor: '#FF0000',
    backgroundDefault: '#253E7C',
    backgroundPaper: '#3A5188',
    textPrimary: '#FFFFFF',
    overrides: {
      headerBackground: '#3A5188',
      noticeColor: '#9E9E9E',
      noticeBackground: '#3A5188',
      paperBackground: '#011341',
      backgroundResources: '#3A5188',
      sidebarItemColor: '#FFFFFF',
      sidebarItemBackground: 'rgba(255, 255, 255, 0.2)',
      premiumBadgeBackground: '#3A5188',
      textHeader: '#FFFFFF',
      textBlue: '#FFFFFF',
      textGray: 'rgba(255, 255, 255, 0.7)',
      textOpacity: 1,
      textChartLabel: '#FFFFFF',
      textChartPoints: '#FFFFFF',
      textGetPremium: '#15956C',
      searchPlaceholder: '#93A0BE',
      itemBorder: '#4D67A5',
      accordionBackground: '#374E86',
      goalButtonBackground: '#4A98E9',
      lightBorder: '#F2F2F2',
      inputBorder: '#93A0BE',
      circleTitles: '#F2F2F2',
      labelFormColor: '#93A0BE',
      textPrimaryRevert: '#000000',
      backgroundPlanPrice: '#FFFFFF',
      filterListBackground: '#071945',
    },
  },
  light: {
    mainPrimaryColor: '#4A98E9',
    mainSecondaryColor: '#2B66BA',
    backgroundDefault: '#FAFAFA',
    backgroundPaper: '#FFFFFF',
    textPrimary: '#2A2B2A',
    overrides: {
      headerBackground: '#FFFFFF',
      noticeColor: '#757575',
      noticeBackground: '#E5E5E5',
      paperBackground: '#FFFFFF',
      backgroundResources: '#FFFFFF',
      sidebarItemColor: '#2196F3',
      sidebarItemBackground: '#E3F2FD',
      premiumBadgeBackground: '#E3F2FD',
      textHeader: '#005086',
      textBlue: '#3D89DE',
      textGray: 'rgba(0, 0, 0, 0.5)',
      textOpacity: 0.5,
      textChartLabel: '#001244',
      textChartPoints: '#1B499B',
      textGetPremium: '#0035FF',
      searchPlaceholder: '#757575',
      itemBorder: '#FFFFFF',
      accordionBackground: '#FFFFFF',
      goalButtonBackground: '#FFFFFF',
      lightBorder: 'rgba(42,43,42, 0.2)',
      inputBorder: 'rgba(0,0,0, 0.2)',
      circleTitles: '#2A2B2A',
      labelFormColor: 'rgba(0,0,0, 0.4)',
      textPrimaryRevert: '#FFFFFF',
      backgroundPlanPrice: '#15956C',
      filterListBackground: '#FFFFFF',
    },
  },
};

export const theme = createMuiTheme({
  palette: {
    type: 'dark',
    background: {
      default: THEME_PALETTE.dark.backgroundDefault,
      paper: THEME_PALETTE.dark.backgroundPaper,
    },
    primary: {
      main: THEME_PALETTE.dark.mainPrimaryColor,
    },
    secondary: {
      main: THEME_PALETTE.dark.backgroundPaper,
    },
    text: {
      primary: THEME_PALETTE.dark.textPrimary,
    },
  },
  overrides: THEME_PALETTE.dark.overrides,
  typography: {
    allVariants: {
      fontFamily: 'Monserat, Roboto',
    },
  },
});

export const lightTheme = createMuiTheme({
  palette: {
    type: 'light',
    background: {
      default: THEME_PALETTE.light.backgroundDefault,
      paper: THEME_PALETTE.light.backgroundPaper,
    },
    primary: {
      main: THEME_PALETTE.light.mainPrimaryColor,
    },
    secondary: {
      main: THEME_PALETTE.light.mainSecondaryColor,
    },
    text: {
      primary: THEME_PALETTE.light.textPrimary,
    },
  },
  overrides: THEME_PALETTE.light.overrides,
  typography: {
    allVariants: {
      fontFamily: 'Monserat, Roboto',
    },
  },
});

const ThemeProvider = ({ children, isAuthenticated, isDarkTheme }) => {
  const themeColor = isAuthenticated && isDarkTheme ? 'dark' : 'light';
  const palette = THEME_PALETTE[themeColor];
  const customTheme = createMuiTheme({
    palette: {
      type: themeColor,
      background: {
        default: palette.backgroundDefault,
        paper: palette.backgroundPaper,
      },
      primary: {
        main: palette.mainPrimaryColor,
      },
      secondary: {
        main: palette.mainSecondaryColor,
      },
      text: {
        primary: palette.textPrimary,
      },
    },
    overrides: palette.overrides,
  });

  return (
    <div className={isDarkTheme ? 'dark-theme' : 'light-theme'}>
      <MUIThemeProvider theme={customTheme}>{children}</MUIThemeProvider>
    </div>
  );
};

export const LightThemeContainer = ({ children }) => {
  return (
    <div className="light-theme">
      <MUIThemeProvider theme={lightTheme}>{children}</MUIThemeProvider>
    </div>
  );
};

LightThemeContainer.propTypes = {
  children: PropTypes.any.isRequired,
};

ThemeProvider.propTypes = {
  children: PropTypes.any.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isDarkTheme: PropTypes.bool.isRequired,
};

export default connect((state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isDarkTheme: state.general.isDarkTheme,
}))(ThemeProvider);
