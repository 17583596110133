import React from 'react';
import { Helmet } from 'react-helmet';

function NotFound() {
  return (
    <>
      <Helmet>
        <title>Myndful</title>
      </Helmet>
      <div>404 Nothing is here</div>
    </>
  );
}

export default NotFound;
