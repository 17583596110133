import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import dashboard from '../../api/dashboard';
import profile from '../../api/profile';
import questionnaire from '../../api/questionnaire';
import * as authTypes from '../auth/authActionsTypes';
import * as profileTypes from '../profile/profileActionsTypes';
import { getCommonErrorProps } from '../reduxHelpers';
import * as types from './dashboardActionsTypes';

function* getLastUserTest({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(questionnaire.getLastUserTest, payload);
    yield put({ type: types.GET_LAST_USER_TEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.DASHBOARD_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getUserTestSectionList({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  const currentPlan = yield select((state) => state.profile.data.currentPlan);

  try {
    const response = yield call(dashboard.getUserTestSectionList, payload);
    if (!currentPlan) {
      const profileResponse = yield call(profile.getProfile);
      yield put({ type: profileTypes.GET_PROFILE_SUCCESS, payload: profileResponse });
    }
    yield put({ type: types.GET_USER_TEST_SECTION_LIST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.DASHBOARD_REQUEST_ERROR, error });
    if (error.status === 403) {
      try {
        const profileResponse = yield call(profile.getProfile);
        yield put({ type: profileTypes.GET_PROFILE_SUCCESS, payload: profileResponse });
      } catch (e) {
        yield put({ type: authTypes.LOGOUT_REQUEST });
      }
    } else {
      yield put(getCommonErrorProps(error.data ? error.data : error));
    }
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getFocusPointArticle({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(dashboard.getFocusPointArticle, payload);
    yield put({ type: types.GET_FOCUS_POINT_ARTICLE_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.DASHBOARD_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getPreviousGoalsSaga() {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(dashboard.getPreviousGoalsApi);
    yield put({ type: types.GET_PREVIOUS_GOALS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.DASHBOARD_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getStandardGoalsSaga() {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(dashboard.getStandardGoalsApi);
    yield put({ type: types.GET_STANDARD_GOALS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.DASHBOARD_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* skipStandardGoalsSaga({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield put({ type: types.DELETE_STANDARD_GOAL, payload: { id: payload.id } });
    yield call(dashboard.skipTaskApi, payload);
  } catch (error) {
    yield put({ type: types.DASHBOARD_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getRandomGoalsSaga() {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    yield call(dashboard.getRandomGoalsApi);
    const response = yield call(dashboard.getStandardGoalsApi);
    yield put({ type: types.GET_STANDARD_GOALS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.DASHBOARD_REQUEST_ERROR, error });
    yield put(getCommonErrorProps(error.data.details));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* updateStandardGoalSaga({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    yield put({ type: types.UPDATE_STANDARD_GOAL_SUCCESS, payload: { id: payload.id, isChecked: true } });
    yield put({
      type: types.UPDATE_PREVIOUS_GOAL_SUCCESS,
      payload: { id: payload.id, isChecked: payload?.body?.isChecked && true },
    });
    yield call(dashboard.updateStandardGoalApi, payload);
  } catch (error) {
    yield put({ type: types.DASHBOARD_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getCustomGoalsSaga() {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(dashboard.getCustomGoalsApi);
    yield put({ type: types.GET_CUSTOM_GOALS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.DASHBOARD_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getCustomGoalSaga({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(dashboard.getCustomGoalApi, payload);
    yield put({ type: types.GET_SINGLE_CUSTOM_GOAL_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.DASHBOARD_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* createCustomGoalSaga({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(dashboard.createCustomGoalApi, payload);
    yield put({ type: types.CREATE_CUSTOM_GOAL_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.DASHBOARD_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* updateCustomGoalSaga({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(dashboard.updateCustomGoalApi, payload);
    yield put({ type: types.UPDATE_CUSTOM_GOAL_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.DASHBOARD_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* updateCustomTemplateGoalsSaga({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(dashboard.updateCustomTemlpateGoalsApi, payload);
    yield put({ type: types.UPDATE_CUSTOM_TEMPLATE_GOALS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.DASHBOARD_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* deleteCustomGoalSaga({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    yield call(dashboard.deleteCustomGoalApi, payload);
    yield put({ type: types.DELETE_CUSTOM_GOAL_SUCCESS, payload: payload });
  } catch (error) {
    yield put({ type: types.DASHBOARD_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_LAST_USER_TEST_REQUEST, getLastUserTest),
    yield takeLatest(types.GET_USER_TEST_SECTION_LIST_REQUEST, getUserTestSectionList),
    yield takeLatest(types.GET_FOCUS_POINT_ARTICLE_REQUEST, getFocusPointArticle),
    yield takeLatest(types.GET_STANDARD_GOALS_REQUEST, getStandardGoalsSaga),
    yield takeLatest(types.GET_RANDOM_GOALS_REQUEST, getRandomGoalsSaga),
    yield takeLatest(types.UPDATE_STANDARD_GOAL_REQUEST, updateStandardGoalSaga),
    yield takeLatest(types.GET_CUSTOM_GOALS_REQUEST, getCustomGoalsSaga),
    yield takeLatest(types.GET_SINGLE_CUSTOM_GOAL_REQUEST, getCustomGoalSaga),
    yield takeLatest(types.CREATE_CUSTOM_GOAL_REQUEST, createCustomGoalSaga),
    yield takeLatest(types.UPDATE_CUSTOM_GOAL_REQUEST, updateCustomGoalSaga),
    yield takeLatest(types.UPDATE_CUSTOM_TEMPLATE_GOALS_REQUEST, updateCustomTemplateGoalsSaga),
    yield takeLatest(types.DELETE_CUSTOM_GOAL_REQUEST, deleteCustomGoalSaga),
    yield takeLatest(types.GET_PREVIOUS_GOALS_REQUEST, getPreviousGoalsSaga),
    yield takeLatest(types.SKIP_CUSTOM_GOAL_REQUEST, skipStandardGoalsSaga),
  ]);
}
