import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import lodash from 'lodash';
import { Box, Button, Checkbox, makeStyles, Typography } from '@material-ui/core';

import CustomGoalItem from './CustomGoalItem';
import { getQuestionnaireSectionsRequest } from '../../redux/questionnaire/questionnaireActions';
import { showModalAction, hideModalAction } from '../../redux/general/generalActions';
import {
  getCustomGoalsRequest,
  updateCustomGoalRequest,
  deleteCustomGoalRequest,
  updateCustomTemplateGoalsRequest,
} from '../../redux/dashboard/dashboardActions';
import { MODAL_TYPE_CONFIRM, MODAL_TYPE_CUSTOM_GOAL } from '../../redux/general/generalActionsTypes';
import GroupCustomGoalItem from './GroupCustomGoalItem';
import { deleteTemplateRequest, getTemplateListRequest } from '../../redux/template/templateActions';

const useStyles = makeStyles((theme) => ({
  congratulationText: {
    fontSize: '14px',
    fontWeight: 300,
    color: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.6)' : '#000000',
  },
  customContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '72px 0 24px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  customTitle: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  customControls: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
    },
  },
  checkbox: {
    '&.Mui-checked': {
      color: theme.palette.type === 'dark' ? '#FFFFFF' : '#0035FF',
    },
  },
  addGoalButton: {
    backgroundColor: 'transparent',
    textTransform: 'capitalize',
    color: theme.palette.text.primary,
    borderRadius: '8px',
    boxShadow: 'unset',
    border: `solid 1px ${theme.overrides.lightBorder}`,
    padding: '6px 13px!important',
    [theme.breakpoints.down('sm')]: {
      '& span': {
        fontSize: '13px!important',
      },
    },
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'flex-end',
    },
    '&:hover': {
      backgroundColor: theme.palette.type === 'dark' ? 'rgb(51, 106, 163)' : '#d5d5d5',
    },
  },
}));

const CustomGoals = ({
  customGoals,
  isProcessing,
  getCustomGoals,
  getQuestionnaireSections,
  updateCustomGoal,
  deleteCustomGoal,
  isDarkTheme,
  showModal,
  hideModal,
  deleteTemplate,
  updateCustomTemplateGoals,
  templateList = {},
  getTemplateList
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [current, setCurrent] = useState(null);
  const [showScheduled, setShowScheduled] = useState(false);
  const [showChecked, setShowChecked] = useState(false);
  const [sortedGoals, setSortedGoals] = useState([]);
  const [allGoals, setAllGoals] = useState([]);
  const [templates, setTemplates] = useState({});

  useEffect(() => {
    getCustomGoals();
    getQuestionnaireSections();
  }, [getCustomGoals, getQuestionnaireSections]);

  useEffect(() => {
    getTemplateList({ page: templateList.page, page_size: templateList.page_size });
    getCustomGoals();
  }, [templateList.count]);
  useEffect(() => {
    const array = lodash.orderBy(customGoals, 'id');

    const sortedArray = [
      ...array.filter(({ isActive, isChecked }) => isActive && !isChecked),
      ...(showScheduled ? array.filter(({ isActive, isChecked }) => !isActive && !isChecked) : []),
      ...(showChecked ? array.filter(({ isChecked }) => isChecked) : []),
    ];

    setAllGoals(sortedArray);

    setSortedGoals(sortedArray.filter((goal) => !goal.template));
  }, [showScheduled, showChecked, customGoals, templateList]);

  useEffect(() => {
    !isProcessing && setCurrent(null);
  }, [isProcessing]);

  useEffect(() => {
    const filteredTemplates = allGoals.reduce((acc, goal) => {
      if (goal.template) {
        if (Object.keys(acc).includes(`${goal.template.title}-${goal.template.id}`)) {
          acc[`${goal.template.title}-${goal.template.id}`].list = [...acc[`${goal.template.title}-${goal.template.id}`].list, goal];
        } else {
          acc[`${goal.template.title}-${goal.template.id}`] = {
            data: {
              ...goal.template,
              isActive: true,
              isChecked: false,
            },
            list: [goal],
          };
        }
      }
      return acc;
    }, {});
    setTemplates(filteredTemplates);
  }, [allGoals]);

  const onToggleCustomGoal = (itemId, value) => {
    setCurrent(itemId);
    updateCustomGoal(itemId, { isChecked: value });
  };

  const handleSwitchSyncCalendar = (id, value) => {
    updateCustomGoal(id, { uploadToGoogleCalendar: value });
  }

  const onEditCustomGoal = (itemId) => {
    showModal({
      params: {
        customGoalId: itemId,
      },
      title: 'Update custom goal',
      type: MODAL_TYPE_CUSTOM_GOAL,
    });
  };

  const onDeleteCustomGoal = (itemId) => {
    showModal({
      params: {
        cancelAction: hideModal,
        confirmAction: () => {
          deleteCustomGoal({ id: itemId });
          hideModal();
        },
        cancelButton: true,
        confirmButtonText: t('delete'),
        text: t('modal_plan_delete_text'),
      },
      title: t('modal_plan_delete_custom'),
      type: MODAL_TYPE_CONFIRM,
    });
  };

  const onDeleteCustomTemplate = (id) => {
    showModal({
      params: {
        cancelAction: hideModal,
        confirmAction: () => {
          deleteTemplate({ id });
          hideModal();
          getTemplateList({ page: templateList.page, page_size: templateList.page_size });
        },
        cancelButton: true,
        confirmButtonText: t('delete'),
        text: t('modal_plan_delete_template_text'),
      },
      title: t('modal_plan_delete_template_custom'),
      type: MODAL_TYPE_CONFIRM,
    });
  };

  const handleSelectAllTrackers = (templateId) => {
    showModal({
        params: {
          cancelAction: hideModal,
          confirmAction: () => {
            updateCustomTemplateGoals(templateId)
            hideModal();
            getTemplateList({ page: templateList.page, page_size: templateList.page_size });
            getCustomGoals();
          },
          cancelButton: true,
          confirmButtonText: t('confirm'),
          text: t('modal_plan_complete_template_text'),
        },
        title: t('modal_plan_complete_template_custom'),
        type: MODAL_TYPE_CONFIRM,
      });
  }

  const groupTemplatesGoals = () => {
    const templatesTitles = Object.keys(templates);
    return templatesTitles.map((tempTitle, index) => (
      <GroupCustomGoalItem
        handleSelectAllTrackers={handleSelectAllTrackers}
        key={index}
        actions="edit"
        template={templates[tempTitle]}
        isProcessing={isProcessing && current === templates[tempTitle].data.id}
        showTemplateLabel
        handleSwitchSyncCalendar={handleSwitchSyncCalendar}
        onDeleteCustomTemplate={onDeleteCustomTemplate}
        onToggleCustomGoal={onToggleCustomGoal}
        onEditCustomGoal={onEditCustomGoal}
        onDeleteCustomGoal={onDeleteCustomGoal}
      />
    ));
  };

  return (
    <div>
      <Box className={classes.customContainer}>
        <Typography variant="h5" className={classes.customTitle}>
          {t('your_plan_custom_trackers')}
        </Typography>
        <Box className={classes.customControls}>
          <Box display="flex" alignItems="center" mr={3}>
            <Checkbox
              className={classes.checkbox}
              color="primary"
              checked={showChecked}
              onChange={(_evt, value) => {
                setShowChecked(value);
              }}
            />
            <span>{t('your_plan_show_completed')}</span>
          </Box>
          <Box display="flex" alignItems="center" mr={3}>
            <Checkbox
              className={classes.checkbox}
              color="primary"
              checked={showScheduled}
              onChange={(_evt, value) => {
                setShowScheduled(value);
              }}
            />
            <span>{t('your_plan_show_upcoming')}</span>
          </Box>
          <Button
            className={classes.addGoalButton}
            color="primary"
            variant="contained"
            onClick={() => showModal({ title: 'Create custom goal', type: MODAL_TYPE_CUSTOM_GOAL })}
          >
            {t('template_add_tracker')}
          </Button>
        </Box>
      </Box>
      {allGoals.length ? (
        sortedGoals.map((item) => (
          <CustomGoalItem
            {...item}
            actions="edit"
            key={`custom-goal-${item.id}`}
            handleSwitchSyncCalendar={handleSwitchSyncCalendar}
            isProcessing={isProcessing && current === item.id}
            showTemplateLabel
            onToggleCustomGoal={onToggleCustomGoal}
            onEditCustomGoal={onEditCustomGoal}
            onDeleteCustomGoal={onDeleteCustomGoal}
            hideModal={hideModal}
            showModal={showModal}
          />
        ))
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center">
          <img src={isDarkTheme ? '/images/tasks-group.svg' : '/images/icon-tasks-light.svg'} alt="tasks-group" />
          <Box display="flex" flexDirection="column" style={{ maxWidth: '500px', flexGrow: 1, paddingLeft: '20px' }}>
            <Typography variant="h5" style={{ fontWeight: 600 }}>
              {t('your_plan_title_2')}
            </Typography>
            <div className={classes.congratulationText}>{t('your_plan_text_2')}</div>
          </Box>
        </Box>
      )}
      {Object.keys(templates).length ? groupTemplatesGoals() : null}
    </div>
  );
};

CustomGoals.propTypes = {
  templateList: PropTypes.object.isRequired,
  templateListLength: PropTypes.number.isRequired,
  customGoals: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      description: PropTypes.string,
      frequency: PropTypes.string,
      id: PropTypes.number.isRequired,
      isActive: PropTypes.bool,
      isChecked: PropTypes.bool,
      schedule: PropTypes.arrayOf(PropTypes.string),
      score: PropTypes.number,
      sectionIcon: PropTypes.string,
      sectionId: PropTypes.number,
      title: PropTypes.string,
    })
  ).isRequired,
  isProcessing: PropTypes.bool.isRequired,
  isDarkTheme: PropTypes.bool.isRequired,
  error: PropTypes.object.isRequired,
  getCustomGoals: PropTypes.func.isRequired,
  getQuestionnaireSections: PropTypes.func.isRequired,
  updateCustomGoal: PropTypes.func.isRequired,
  updateCustomTemplateGoals: PropTypes.func.isRequired,
  deleteTemplate: PropTypes.func.isRequired,
  getTemplateList: PropTypes.func.isRequired,
  deleteCustomGoal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    templateList: state.template.templateList,
    customGoals: state.dashboard.customGoals,
    templateList: state.template.templateList,
    templateListLength: state.template.templateList.count,
    isProcessing: state.dashboard.processing,
    isDarkTheme: state.general.isDarkTheme,
    error: state.dashboard.error || {},
  }),
  {
    getTemplateList: getTemplateListRequest,
    getCustomGoals: getCustomGoalsRequest,
    getQuestionnaireSections: getQuestionnaireSectionsRequest,
    updateCustomGoal: updateCustomGoalRequest,
    updateCustomTemplateGoals: updateCustomTemplateGoalsRequest,
    deleteCustomGoal: deleteCustomGoalRequest,
    deleteTemplate: deleteTemplateRequest,
    showModal: showModalAction,
    hideModal: hideModalAction,
  }
)(CustomGoals);
