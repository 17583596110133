import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';


const GoogleButton = ({ label }) => {
  const googleAuthUrl = useSelector((state) => state.auth.googleAuthUrl);

  if (!googleAuthUrl) {
    return null;
  }

  return (
    <Box>
      <Typography align="center">
        Or
      </Typography>
      <a href={googleAuthUrl}>
        <Button fullWidth variant="contained" color="primary" className="auth-button">
          {label}
        </Button>
      </a>
    </Box>
  );
};

GoogleButton.propTypes = {
  label: PropTypes.string.isRequired,
};

export default GoogleButton;
