import * as types from './subscriptionActionsTypes';

export const getAvailableSubscriptionsRequest = () => {
  return { type: types.GET_AVAILABLE_SUBSCRIPTIONS_REQUEST };
};

export const getSubscriptionPublicKeyRequest = () => {
  return { type: types.GET_SUBSCRIPTION_PUBLIC_KEY_REQUEST };
};

export const createUserSubscriptionRequest = (data) => {
  return { type: types.CREATE_USER_SUBSCRIPTION_REQUEST, payload: data };
};

export const createAnonymusUserSubscriptionRequest = (data) => {
  return { type: types.CREATE_USER_ANONYMOUS_SUBSCRIPTION_REQUEST, payload: data };
};

export const deleteUserSubscriptionRequest = () => {
  return { type: types.DELETE_USER_SUBSCRIPTION_REQUEST };
};

export const clearErrorAction = () => {
  return { type: types.CLEAR_ERROR };
};
