import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TimePicker from 'rc-time-picker';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Box, Button, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Switch } from '@material-ui/core';
import 'moment-timezone';
import 'date-fns';

import { createReminderRequest, updateReminderRequest } from '../../redux/profile/profileActions';
import { getTimezonesRequest } from '../../redux/settings/settingsActions';
import { useWindowDimension } from '../../hooks';

const useStyles = makeStyles((theme) => {
  const isDarkTheme = theme.palette.type === 'dark';

  return {
    content: {
      maxWidth: '720px',
      margin: '0 auto',
      padding: '24px 0',
      '& .MuiGrid-item': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    reminderContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: '20px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0',
      },
    },
    blockStyles: {
      backgroundColor: theme.overrides.accordionBackground,
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      padding: '20px 36px',
      [theme.breakpoints.down('sm')]: {
        padding: '15px 20px',
      },
    },
    text: {
      color: theme.palette.text.primary,
      fontSize: '0.75rem',
      letterSpacing: '0.4px',
      [theme.breakpoints.down('sm')]: {
        margin: '20px 0',
      },
    },
    blockFlex: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    alignEnd: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
    },
    switchLabel: {
      display: 'inline-flex',
      fontSize: '14px',
      margin: '8px 0',
      cursor: 'default',
    },
    textDisabled: {
      color: theme.overrides.labelFormColor,
    },
    textEnabled: {
      color: theme.palette.text.primary,
    },
    fixedWidth: {
      width: '125px',
      minWidth: '125px',
      textAlign: 'center',
    },
    switchRoot: {
      width: 32,
      height: 18,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 2,
      color: '#FFF',
      '&$checked': {
        transform: 'translateX(14px)',
        color: isDarkTheme ? '#253E7B' : '#FFF',
        '& + $track': {
          backgroundColor: isDarkTheme ? '#C2C2C2' : '#007EFF',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#FFF',
        border: '6px solid lightgrey',
      },
    },
    checked: {},
    focusVisible: {},
    thumb: {
      width: 14,
      height: 14,
    },
    track: {
      borderRadius: 26 / 2,
      backgroundColor: '#C2C2C2',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    selector: {
      width: 235,
      margin: '20px 24px 20px 0',

      '& .MuiFormControl-root': {
        margin: 0,
        padding: 0,
      },
    },
    saveButton: {
      background: isDarkTheme ? '#406BE1' : 'linear-gradient(90deg, #0035FF 0%, #00C1FF 100%)',
      color: '#ffffff',
    },
  };
});

const Notification = ({
  profile,
  isProcessing,
  createReminder,
  updateReminder,
  timezones,
  getTimezones,
  isDarkTheme,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isEnabled, setIsEnabled] = useState(profile.reminder ? profile.reminder.isEnabled : false);
  const [remindAt, setRemindAt] = useState(moment(profile.reminder?.remindAt || new Date(), 'hh:mm'));
  const [timezone, setTimezone] = useState('');
  const [width] = useWindowDimension();

  const canControlNotifications = !!profile.currentPlan || !!profile.hasTrial;

  const timepickerClass = isDarkTheme ? 'dark' : 'light';

  const handleSubmit = () => {
    const body = {
      isEnabled,
      remindAt: moment(remindAt.format('hh:mm a'), ['h:mm A']).format('HH:mm'),
      timezoneId: timezone,
    };

    if (profile.reminder) {
      updateReminder(profile.reminder.id, body);
    } else {
      createReminder(body);
    }
  };

  const handleTimeChange = (value) => {
    if (value) {
      setRemindAt(value);
    } else {
      setRemindAt(moment());
    }
  };

  useEffect(() => {
    if (!profile.isPhoneVerified) {
      setIsEnabled(false);
    }
  }, [profile.isPhoneVerified]);

  useEffect(() => {
    if (!timezones.length) {
      if (profile.currentPlan) {
        getTimezones();
      }
    } else {
      const userTimezone = timezones.find((item) => item.id === profile.reminder?.timezone?.id);
      if (userTimezone) {
        setTimezone(userTimezone?.id);
      }
    }
  }, [timezones, profile]);

  return (
    <div className={classNames(classes.blockStyles, 'main-form__section')}>
      <Box>
        <Grid container display="flex" alignItems="center">
          <Grid item xs={12} sm={6}>
            <div style={{ fontSize: 20 }}>{t('profile_notification')}</div>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.alignEnd}>
            <Box>
              <div className={classes.text}>
                {t('profile_notification_help_text')}
              </div>
            </Box>
            <Grid item xs={12} sm={6} className={classes.blockFlex}>
              <Switch
                classes={{
                  root: classes.switchRoot,
                  switchBase: classes.switchBase,
                  thumb: classes.thumb,
                  track: classes.track,
                  checked: classes.checked,
                }}
                color="default"
                checked={isEnabled}
                disabled={!canControlNotifications || !profile.isPhoneVerified}
                onChange={(evt) => setIsEnabled(evt.target.checked)}
              />
              <p
                className={classNames(classes.switchLabel, {
                  [classes.textEnabled]: isEnabled,
                  [classes.textDisabled]: !isEnabled,
                })}
              >
                {t('enable')}
              </p>
            </Grid>
          </Grid>
          {canControlNotifications && (
            <>
              <div className={classes.reminderContainer}>
                <Grid item>
                  <Box className={classNames('form-input__group form-input__select', classes.selector)} marginRight={3}>
                    <FormControl variant="filled" fullWidth>
                      <InputLabel shrink>{t('form_label_select_time')}</InputLabel>
                      <TimePicker
                        value={remindAt}
                        onChange={handleTimeChange}
                        showSecond={false}
                        use12Hours
                        disabled={!isEnabled}
                        className={timepickerClass}
                        popupClassName={timepickerClass}
                      />
                    </FormControl>
                  </Box>
                </Grid>

                <Grid item>
                  <Box className={classNames('form-input__group form-input__select', classes.selector)} marginRight={3}>
                    <FormControl variant="filled" fullWidth>
                      <InputLabel id="timezone-label">{t('form_label_select_timezone')}</InputLabel>
                      <Select
                        labelId="timezone-label"
                        id="timezone"
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                          className: classes.selector,
                        }}
                        MenuProps={{
                          transitionDuration: 0,
                        }}
                        disabled={!isEnabled}
                        value={timezone}
                        onChange={(e) => setTimezone(e.target.value)}
                      >
                        {timezones.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.timezone}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>

                <Grid item className={classes.alignEnd} style={{ marginLeft: '20px' }}>
                  <Button
                    style={{ marginTop: width < 600 ? '1rem' : '' }}
                    type="submit"
                    disabled={isProcessing || !canControlNotifications || !profile.isPhoneVerified}
                    variant="contained"
                    className={classNames(classes.fixedWidth, classes.saveButton)}
                    onClick={handleSubmit}
                  >
                    {t('save')}
                  </Button>
                </Grid>
              </div>
            </>
          )}
        </Grid>
      </Box>
    </div>
  );
};

Notification.propTypes = {
  profile: PropTypes.object.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  error: PropTypes.object,
  createReminder: PropTypes.func.isRequired,
  updateReminder: PropTypes.func.isRequired,
  timezones: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      timezone: PropTypes.string,
    })
  ).isRequired,
  getTimezones: PropTypes.func.isRequired,
  isDarkTheme: PropTypes.bool,
};

export default connect(
  (state) => ({
    profile: state.profile.data,
    isProcessing: state.profile.processing,
    error: state.profile.error || {},
    timezones: state.settings.timezones,
    isDarkTheme: state.general.isDarkTheme,
  }),
  {
    createReminder: createReminderRequest,
    updateReminder: updateReminderRequest,
    getTimezones: getTimezonesRequest,
  }
)(Notification);
