import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Drawer, List, ListItem, Button, makeStyles, darken } from '@material-ui/core';
import { Menu, Close } from '@material-ui/icons';
import PropTypes from 'prop-types';

// redux
import { logoutRequest } from '../../../redux/auth/authActions';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  paper: {
    width: '100%',
    minHeight: 300,
    background: '#FFF',
    padding: '76px 0 0 0',
  },
  linkContainer: {
    color: palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    borderColor: darken('#F9F9F9', 0.05),
  },
  link: {
    fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
    textTransform: 'none',
    width: '60%',
    height: '100%',
    textAlign: 'center',
    display: 'block',
    padding: spacing(1, 3),
    fontSize: 14,
    fontWeight: 500,
    borderRadius: 4,
    color: palette.primary.main,
  },
  primary: {
    marginTop: '16px',
    background: 'rgba(50,92,200, 0.3)',
  },
  greyLinks: {
    padding: spacing(2, 0),
    display: 'flex',
    justifyContent: 'center',
    color: '#B9B9B9',
    fontWeight: 500,
    fontSize: 11,
    textDecorationLine: 'underline',
  },
  greyLink: {
    padding: spacing(0, 1),
  },
  action: {
    color: '#F33935',
  },
}));


const PublicHeaderMobile = ({ isAuthenticated, logout }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const actions = {
    logout,
  };

  const createLinks = () => [
    { path: '/', label: t('m_home') },
    { path: '/blog', label: t('m_blog') },

    ...(isAuthenticated ? [
      { action: 'logout', label: t('m_logout') },
      { path: '/personalized-plan', label: t('m_open_app'), primary: true },
    ] : [
      { path: '/auth/sign-in', label: t('m_sign_in') },
      { path: '/auth/sign-up', label: t('m_sign_up'), primary: true },
    ]),
  ];

  return (
    <>
      <Grid item xs={8} sm={6} container justify="flex-end">
        <IconButton size="small" onClick={() => setOpen((prev) => !prev)}>
          {open ? (
            <Close fontSize="small" color="primary" />
          ) : (
            <Menu transitionDuration={0} fontSize="small" color="primary" />
          )}
        </IconButton>
      </Grid>
      <Drawer onClose={() => setOpen(false)} anchor="top" open={open} classes={{ paper: classes.paper }}>
        <List>
          {createLinks().map((item) => (
            <ListItem
              key={item.label}
              divider={!item.primary}
              dense
              className={classes.linkContainer}
              onClick={() => setOpen(false)}
            >
              {item.path && (
                <Link className={`${classes.link} ${item.primary ? classes.primary : ''}`} to={item.path}>
                  {item.label}
                </Link>
              )}
              {item.action && (
                <Button
                  disbleElevation
                  disableRipple
                  className={`${classes.link} ${item.primary ? classes.primary : ''} ${classes.action}`}
                  onClick={actions[item.action]}
                >
                  {item.label}
                </Button>
              )}
            </ListItem>
          ))}
          <ListItem className={classes.greyLinks}>
            <Link className={classes.greyLink} to="/terms-and-conditions">
              {t('m_terms_conditions')}
            </Link>
            <Link className={classes.greyLink} to="/privacy-policy">
              {t('m_privacy_policy')}
            </Link>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

PublicHeaderMobile.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logoutRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicHeaderMobile);
