import * as queryString from 'query-string';

import { API_URL } from '../constants';
import axios from './axios';

const sharedAccess = {
  async requestAccess({ email, sharingType }) {
    try {
      const result = await axios.post(`${API_URL}/user-test/shared/`, { email, sharingType });

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response;
      throw err;
    }
  },

  async getSharedUsers() {
    try {
      const result = await axios.get(`${API_URL}/user-test/shared/`);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getSharedUserResult(id) {
    try {
      const result = await axios.get(`${API_URL}/user-test/shared/result/${id}/`);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async updateSharedAccess({ id, isActive }) {
    try {
      const result = await axios.patch(`${API_URL}/user-test/shared/${id}/`, { isActive });

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async deleteSharedAccess(id) {
    try {
      const result = await axios.delete(`${API_URL}/user-test/shared/${id}/`);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getSharedUserTestSectionList({ id, data }) {
    try {
      const result = await axios.get(`${API_URL}/user-test/shared/sections/${id}/?${queryString.stringify(data)}`);

      return result.data;
    } catch (err) {
      if (err.response) throw err.response;
      throw err;
    }
  },

  async acceptSharedRequest({ id, secretKey, isRevoke }) {
    try {
      const result = await axios.post(`${API_URL}/user-test/shared/${id}/acceptance/`, { secretKey, isRevoke });

      return result.data;
    } catch (err) {
      if (err.response) throw err.response;
      throw err;
    }
  },
};

export default sharedAccess;
