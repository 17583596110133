import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@material-ui/core';

import { clearErrorAction, setNewPasswordRequest } from '../../redux/auth/authActions';
import PasswordField from '../shared/components/PasswordField';

const SetNewPasswordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required('Password can not be empty')
    .min(8, 'Password should be at least 8 characters long')
    .test("isValidPass", "Password is not valid", (value) => {
      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      let validConditions = 0;
      const numberOfMustBeValidConditions = 3;
      const conditions = [hasLowerCase, hasUpperCase, hasNumber];
      conditions.forEach((condition) =>
        condition ? validConditions++ : null
      );
      if (validConditions >= numberOfMustBeValidConditions) {
        return true;
      }
      return false;
    }),
  confirmedPassword: yup
    .string()
    .required('Password can not be empty')
    .min(8, 'Password should be at least 8 characters long')
    .oneOf([yup.ref('newPassword'), null], 'The two password fields didn\'t match.')
    .test("isValidPass", "Password is not valid", (value) => {
      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      let validConditions = 0;
      const numberOfMustBeValidConditions = 3;
      const conditions = [hasLowerCase, hasUpperCase, hasNumber];
      conditions.forEach((condition) =>
        condition ? validConditions++ : null
      );
      if (validConditions >= numberOfMustBeValidConditions) {
        return true;
      }
      return false;
    }),
});

const SetNewPassword = ({ isNewPasswordSet, isProcessing, error, location, setNewPassword, clearError }) => {
  const { t } = useTranslation();

  const { handleSubmit, errors, register } = useForm({
    reValidateMode: 'onChange',
    validationSchema: SetNewPasswordSchema,
  });

  const onSubmitHandler = (data) => {
    setNewPassword({ ...data, token: location.search.slice(7) });
  };

  if (isNewPasswordSet) {
    return (
      <>
        <Helmet>
          <title>Myndful</title>
        </Helmet>
        <Typography align="center" variant="h5" gutterBottom={true}>
          {t('auth_set_new_password')}
        </Typography>
        <Box mx={3} mb={2}>
          <Typography align="center" variant="body2">
            {t('auth_set_new_password_success')}
          </Typography>
        </Box>
        <Link to="/auth/sign-in" onClick={clearError}>
          <Button type="button" fullWidth variant="contained" color="primary" className="auth-button">
            {t('m_sign_in')}
          </Button>
        </Link>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Myndful</title>
      </Helmet>
      <Typography align="center" variant="h5">
        {t('auth_set_new_password')}
      </Typography>
      <Typography align="center" variant="body2">
        {t('auth_password_validation_1')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Box className="form-input__group">
          <PasswordField
            inputRef={register}
            error={error}
            errors={errors}
            name="newPassword"
            label={t('form_label_new_password')}
            id="newPassword"
            autoComplete="current-password"
            onChange={() => error.newPassword && clearError()}
          />
        </Box>
        <Box className="form-input__group">
          <PasswordField
            inputRef={register}
            error={error}
            errors={errors}
            name="confirmedPassword"
            label={t('form_label_confirm_new_password')}
            id="confirmedPassword"
            onChange={() => error.newPassword && clearError()}
          />
        </Box>
        <Button
          type="submit"
          disabled={isProcessing}
          fullWidth
          variant="contained"
          color="primary"
          className="auth-button"
        >
          {t('auth_set_new_password')}
        </Button>
      </form>
    </>
  );
};

SetNewPassword.propTypes = {
  isNewPasswordSet: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  error: PropTypes.object,
  setNewPassword: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isNewPasswordSet: state.auth.isNewPasswordSet,
    isProcessing: state.auth.processing,
    error: state.auth.error || {},
  }),
  {
    setNewPassword: setNewPasswordRequest,
    clearError: clearErrorAction,
  }
)(SetNewPassword);
