import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import CustomGoalItem from './CustomGoalItem';
import { ExpandMore } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { IconDelete, IconEdit } from '../../assets/inline-images';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles((theme) => ({
  accordion: {
    position: 'relative',
    marginBottom: '12px !important',
    borderRadius: '8px !important',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  checked: {
    backgroundColor: `${theme.overrides.accordionBackground} !important`,
    '& .custom-goal--label': {
      opacity: 0.38,
    },
  },
  summary: {
    background: theme.palette.type === 'dark' ? '#3A5188' : '#fff',
    borderRadius: '8px',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIcon': {
      margin: 0,
    },
    '& .group__actions': {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      position: "relative",
      width: '250px',
    },
    '& .MuiAccordionSummary-content': {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        '& .group__actions': {
          width: "100%",
        }
      },
    }
  },
  actions: {
    display: 'flex',
    position: "relative",
    marginLeft: "20px",
    gap: '16px',
    pointerEvents: 'all',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-around',
      position: 'relative',
      top: 'unset',
      right: 'unset',
      transform: 'none',
      width: '100%',
      marginTop: '16px',
      paddingTop: '12px',
      borderTop: theme.palette.type === 'dark' ? '1px solid #9c9c9c' : '1px solid rgba(0,0,0, 0.2)',
      '& img:not(:last-child)': {
        marginRight: '0',
      },
    },
  },
  actionIcon: {
    color: theme.palette.type === 'dark' ? '#F2F2F2' : '#757575',
    cursor: 'pointer',
  },
  countTrackers: {
    color: theme.palette.type === 'dark' ? '#ffffffb5' : '#0035FF',
  },
  checkbox: {
    '&.Mui-checked': {
      color: theme.palette.type === 'dark' ? '#FFFFFF' : 'rgba(0, 0, 0, 0.54)',
    },
  },
  shadowCheckbox: {
    width: '16px',
    height: '42px',
  },
  accordionQuestionWrapper: {
    wordBreak: 'break-word',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '8px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      maxWidth: "150px",
      width: "100%"
    },
  },
  goalLabel: {
    paddingRight: '8px',
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      padding: '0 !important',
    },
  },
  bigMarginRight: {
    marginRight: '86px',
  },
  goalValue: {
    fontSize: '12px',
    backgroundColor: '#0ABF53',
    padding: '6px 12px',
    color: '#FFFFFF',
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      position: "absolute",
      top: '-50px',
      right: 0,
      marginLeft: 0,
      marginTop: 10,
      fontSize: 12,
    },
  },
  template: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    maxWidth: '300px',
    height: '100%',
    marginRight: '86px',
  },
  content: {
    marginTop: '12px',
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-word',
  },
  cursorDefault: {
    cursor: 'default !important',
  },
  groupGoalsDetails: {
    display: 'flex',
    flexDirection: 'column',
    background: 'transparent',
  },
}));

const GroupCustomGoalItem = ({
  template,
  actions,
  isProcessing,
  showTemplateLabel,
  handleSelectAllTrackers,
  onToggleCustomGoal,
  onEditCustomGoal,
  onDeleteCustomGoal,
  onDeleteCustomTemplate,
  handleSwitchSyncCalendar,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const isEdit = actions === 'edit';
  const isTemplateLabel = Boolean(showTemplateLabel && !!template.data?.id);
  const score = useMemo(() => {
    if (template) {
      return template.list.reduce((acc, item) => (acc += item.score), 0);
    }
  }, [template]);

  const countOfTrackers = useMemo(() => {
    if(template) {
      return template.list.length
    }
  }, [template])

  const { isActive, isChecked, title } = template.data;

  const handleClickEdit = () => {
    history.push(`/resources/templates/${template.data.id}`);
  }


  return (
    <Accordion
      className={classNames(classes.accordion, { [classes.active]: isActive, [classes.checked]: isChecked })}
      disabled={showTemplateLabel && isEdit && (!isActive || isChecked)}
    >
      <AccordionSummary
        className={classes.summary}
        aria-label="Expand"
        aria-controls="additional-actions1-content"
        id="additional-actions1-header"
        {...(true
          ? {
              expandIcon: <ExpandMore />,
            }
          : {
              className: classes.cursorDefault,
            })}
      >
        <div
          className={classNames(
            classes.goalLabel,
            { [classes.bigMarginRight]: !isTemplateLabel },
            'custom-goal--label'
          )}
        >
          {typeof onToggleCustomGoal === 'function' ? (
            <FormControlLabel
              aria-label="Acknowledge"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              control={
                <Checkbox
                  className={classes.checkbox}
                  color="primary"
                  checked={isChecked}
                  disabled={isProcessing}
                  onChange={() => handleSelectAllTrackers(template.data.id)}
                />
              }
            />
          ) : (
            <div className={classes.shadowCheckbox} />
          )}
          <div className={classes.accordionQuestionWrapper}>
            <span style={{ textDecoration: isChecked ? 'line-through' : 'none' }}>{title}</span>
            <span className={classes.countTrackers}>
              { countOfTrackers } { countOfTrackers === 1 ? t("dashboard_tracker") : t("dashboard_trackers")}
            </span>
          </div>
        </div>
        <div className='group__actions'>
          {!!score && (
            <span className={classes.goalValue}>
              {Math.round(score) || 0}&#160;{t('total_points')}
            </span>
          )}
          {template && template.data.isOwn && (
            <div className={classes.actions}>
              <span className={classes.actionIcon} onClick={handleClickEdit}>
                <IconEdit size={26} />
              </span>
              <span className={classes.actionIcon} onClick={() => onDeleteCustomTemplate(template.data.id)}>
                <IconDelete size={26} />
              </span>
            </div>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.groupGoalsDetails}>
        {template.list.map((temp) => (
          <CustomGoalItem
            {...temp}
            handleSwitchSyncCalendar={handleSwitchSyncCalendar}
            actions={template.data.isOwn ? "edit" : 'view'}
            key={`custom-goal-${temp.id}`}
            isProcessing={isProcessing && current === temp.id}
            showTemplateLabel
            onToggleCustomGoal={onToggleCustomGoal}
            onEditCustomGoal={onEditCustomGoal}
            onDeleteCustomGoal={onDeleteCustomGoal}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};


export default GroupCustomGoalItem
