import React from 'react';
import PropTypes from 'prop-types';

const IconEdit = ({ size = 24, className }) => {
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
        <path d="M19.1974 4C18.9845 4 18.7803 4.08457 18.6298 4.23509L10.7528 12.1121L10.3744 13.6256L11.8879 13.2473L19.7649 5.37021C19.9155 5.21969 20 5.01553 20 4.80265C20 4.58978 19.9155 4.38562 19.7649 4.23509C19.6144 4.08457 19.4102 4 19.1974 4ZM17.2156 2.82088C17.7412 2.29528 18.4541 2 19.1974 2C19.9407 2 20.6535 2.29528 21.1791 2.82088C21.7047 3.34648 22 4.05934 22 4.80265C22 5.54596 21.7047 6.25883 21.1791 6.78443L13.1062 14.8573C12.9781 14.9855 12.8175 15.0764 12.6417 15.1204L9.24256 15.9701C8.90178 16.0553 8.54129 15.9555 8.29291 15.7071C8.04453 15.4587 7.94468 15.0982 8.02988 14.7575L8.87966 11.3583C8.92362 11.1825 9.01453 11.0219 9.14269 10.8938L17.2156 2.82088Z" />
        <path d="M15.2929 5.29289C15.6834 4.90237 16.3166 4.90237 16.7071 5.29289L18.7071 7.29289C19.0976 7.68342 19.0976 8.31658 18.7071 8.70711C18.3166 9.09763 17.6834 9.09763 17.2929 8.70711L15.2929 6.70711C14.9024 6.31658 14.9024 5.68342 15.2929 5.29289Z" />
        <path d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12V11.5C20 10.9477 20.4477 10.5 21 10.5C21.5523 10.5 22 10.9477 22 11.5V12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2H12.5C13.0523 2 13.5 2.44772 13.5 3C13.5 3.55228 13.0523 4 12.5 4H12Z" />
      </g>
    </svg>
  );
};

IconEdit.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

export default IconEdit;
