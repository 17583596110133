import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, makeStyles, Typography } from '@material-ui/core';

import profile from '../../api/profile';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    position: 'relative',
    paddingTop: '80px'
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: 'calc(100vh - 246px)',
    textAlign: 'center'
  },
  title: {
    fontSize: 25,
    color: '#304659',
    fontWeight: 500,
    marginBottom: 20
  },
  info: {
    fontSize: 17,
    color: '#3B5266',
    fontWeight: 400
  },
  errorText: {
    color: '#FE4A5E'
  }
});

const Unsubscribe = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { search } = useLocation();

  const [status, setStatus] = useState('');

  useEffect(() => {
    profile.unsubscribeEmail(search)
      .then(() => {
        setStatus('success');
      })
      .catch(() => {
        setStatus('error');
      })
  }, []);

  return (
    <div id="unsubscribe">
      <Box className={classes.container}>
        <div className={classes.content}>
          {status === 'success' && (
            <div>
              <Typography className={classes.title}>
                {t('profile_successfully_unsubscribed')}
              </Typography>
              <Typography className={classes.info}>
                {t('profile_subscription_cancelled')}
              </Typography>
            </div>
          )}
          {status === 'error' && (
            <div>
              <Typography className={`${classes.title} ${classes.errorText}`}>
                {t('profile_unsubscribe_token_is_not_valid')}
              </Typography>
            </div>
          )}
        </div>
      </Box>
    </div>
  );
};

Unsubscribe.propTypes = {
  unsubscribeEmail: PropTypes.func.isRequired,
};

export default Unsubscribe;
