import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, TextField, makeStyles } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import {
  addNoteRequest,
  clearNoteSelectionRequest,
  removeNoteRequest,
  updateNoteRequest,
} from '../../redux/journal/journalActions';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as yup from 'yup';
import QuillEditor from './QuillEditor';

const useStyles = makeStyles((theme) => ({
  journalInput: {
    '& .MuiInputBase-root': {
      borderRadius: '8px !important',
    },
  },
  rte: {
    margin: '8px 0 24px',
    marginBottom: "0",
    color: theme.palette.type === 'dark' ? '#ffffff' : '#000000',
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(147, 160, 190, 0.1)' : 'white',
    '& .ql-toolbar.ql-snow': {
      borderRadius: '8px 8px 0px 0px',
    },
    '& .ql-toolbar.ql-snow + .ql-container.ql-snow': {
      borderRadius: '0px 0px 8px 8px',
    },
    '& .ql-editor': {
      minHeight: '75px',
      '&.ql-blank::before': {
        color: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.6)',
      },
    },
    '& .ql-toolbar': {
      '& .ql-stroke': {
        stroke: theme.palette.type === 'dark' ? '#fff' : '#444',
      },
    },
    '& .ql-picker-label::before': {
      color: theme.palette.type === 'dark' ? '#fff' : '#444',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '34px',
    '&.space': {
      justifyContent: 'space-between',
    },
  },
  textFieldContainer: {
    position: 'relative'
  },
  deleteButton: {
    height: '48px',
    padding: '16px',
    letterSpacing: '1px',
    borderRadius: '8px',
    textTransform: 'capitalize',
    boxShadow: '0 2px 0 rgba(0, 0, 0, 0.043)',
    background: theme.palette.type === 'dark' ? 'transparent' : '#fff',
    color: theme.palette.type === 'dark' ? '#fff' : '#F33935',
    border: `1px solid ${theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)'}`,
    [theme.breakpoints.down('sm')]: {
      margin: '20px 0',
    },
  },
  button: {
    display: 'flex',
    color: '#fff',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '48px',
    padding: '16px',
    letterSpacing: '1px',
    borderRadius: '8px',
    textTransform: 'capitalize',
    boxShadow: '0 2px 0 rgba(0, 0, 0, 0.043)',
    background: theme.palette.type === 'dark' ? '#406BE1' : 'linear-gradient(90deg, #0035FF 0%, #00C1FF 100%)',
    border: theme.palette.type === 'dark' ? `1px solid rgba(255, 255, 255, 0.2)`: 'none',
    [theme.breakpoints.down('sm')]: {
      margin: '20px 0',
    },
  },
  errorMessage: {
    fontSize: '14px',
    color: "#F33935",
    marginTop: '10px',
    position: "absolute"
  }
}));

const JournalSchema = yup.object().shape({
  title: yup.string().required('Note name can not be empty'),
  text: yup.string().required('Section is required.'),
});

const JournalForm = ({ isEdit, note, addNote, updateNote, clearNoteSelection, removeNote }) => {
  const { handleSubmit, errors, control, register, reset, setValue } = useForm({
    reValidateMode: 'onChange',
    validationSchema: JournalSchema,
    defaultValues: {
      title: '',
      text: '',
    },
  });
  const classes = useStyles();
  const [quillText, setQuillText] = useState(note?.text || '');
  const { t } = useTranslation();

  const history = useHistory();

  useEffect(() => {
    register('text');
  }, [register]);

  useEffect(() => {
    if(!quillText.replace(/<[^>]+>/g, '')){
      setValue('text', quillText.replace(/<[^>]+>/g, ''));
    } else {
      setValue('text', quillText);
    }
  }, [quillText, note]);

  useEffect(() => {
    if (!note) return;

    reset({
      title: note.title,
      text: note.text,
    });
  }, [note]);

  const removeNoteHandler = () => {
    removeNote(note.id);
    history.push('/journal');
  };

  const onSubmitHandler = (data) => {
    if (note?.id) {
      updateNote({
        id: note.id,
        ...data,
      });
    } else {
      addNote(data);
    }
    clearNoteSelection();
    history.push('/journal');
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Box className="form-input__group">
        <label className="form-journal__label">{t('form_label_note_title')}</label>
        <Controller
          as={
            <TextField
              inputRef={register}
              variant="outlined"
              fullWidth
              className={classes.journalInput}
              error={!!errors?.title}
              helperText={errors?.title ? errors?.title?.message : ''}
              id="title"
              placeholder={t('form_label_note_title_placeholder')}
            />
          }
          name="title"
          control={control}
          defaultValue=""
        />
      </Box>
      <Box className={`form-input__group ${classes.textFieldContainer}`}>
        <label className="form-journal__label">{t('note')}</label>
        <QuillEditor
          theme="snow"
          className={classes.rte}
          placeholder={t('form_label_note_text_placeholder')}
          value={quillText || ''}
          onChange={setQuillText}
        />
        { errors?.text && <p className={classes.errorMessage}>
          { errors.text.message }
        </p> }
      </Box>
      <Box className={`${classes.buttonContainer} ${isEdit ? 'space' : null}`}>
        {isEdit ? (
          <Button className={classes.deleteButton} onClick={removeNoteHandler}>
            {t('journal_delete_note')}
          </Button>
        ) : null}
        <Button type="submit" className={classes.button}>
          {t('journal_save_note')}
        </Button>
      </Box>
    </form>
  );
};

JournalForm.propTypes = {
  removeNote: PropTypes.func,
  note: PropTypes.object,
  handleSubmit: PropTypes.func,
  classes: PropTypes.object,
  errors: PropTypes.object,
  control: PropTypes.object,
  setValue: PropTypes.func,
  isEdit: PropTypes.bool,
  addNote: PropTypes.func,
  updateNote: PropTypes.func,
  clearNoteSelection: PropTypes.func,
};

export default connect(
  (state) => ({
    note: state.journal.note,
  }),
  {
    addNote: addNoteRequest,
    updateNote: updateNoteRequest,
    removeNote: removeNoteRequest,
    clearNoteSelection: clearNoteSelectionRequest,
  }
)(JournalForm);
