import * as types from './summaryActionTypes';

export const initialState = {
  summary: {},
  processing: false,
};

const summary = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };

    case types.GET_SUMMARY_SUCCESS: {
      const result = action.payload;

      return { ...state, summary: result };
    }

    default:
      return state;
  }
};

export default summary;
