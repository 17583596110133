import { all } from 'redux-saga/effects';

import authSagas from './auth/authSagas';
import blogSagas from './blog/blogSagas';
import dashboardSagas from './dashboard/dashboardSagas';
import feedbackSagas from './feedback/feedbackSagas';
import profileSagas from './profile/profileSagas';
import subscriptionSagas from './subscription/subscriptionSagas';
import questionnaireSagas from './questionnaire/questionnaireSagas';
import settingsSagas from './settings/settingsSagas';
import sharedAccessSagas from './shared-access/sharedAccessSagas';
import journalSagas from './journal/journalSagas';
import summarySagas from './summary/summarySagas';
import templateSagas from './template/templateSagas';

export default function* rootSaga() {
  yield all([
    authSagas(),
    blogSagas(),
    dashboardSagas(),
    feedbackSagas(),
    profileSagas(),
    subscriptionSagas(),
    questionnaireSagas(),
    settingsSagas(),
    sharedAccessSagas(),
    journalSagas(),
    summarySagas(),
    templateSagas(),
  ]);
}
