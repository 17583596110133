import axios from 'axios';
import Cookies from 'universal-cookie';

import { store } from '../redux/configureStore';
import * as authTypes from '../redux/auth/authActionsTypes';
import { API_URL } from '../constants';

let processing = false;
let failedRequests = [];

function onAccessTokenFetched(access_token) {
  failedRequests = failedRequests.filter((callback) => callback(access_token));
}

function addFailedRequest(callback) {
  failedRequests.push(callback);
}

axios.interceptors.request.use(
  (config) => {
    const cookies = new Cookies();
    const token = cookies.get('token');
    const savedLanguage = localStorage.getItem('language');

    if (token && token.access) {
      config.headers.Authorization = `JWT ${token.access}`;
    }

    if (savedLanguage) {
      config.headers['Accept-Language'] = savedLanguage;
    }

    return config;
  },
  (error) => {
    if (error) return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  async function (error) {
    const originalRequest = error.config;

    if (error.message === 'Request failed with status code 500') {
      console.error(error.message, error);

      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: 'Internal server error', status: 500 });
    }

    if (error.message === 'Network Error' && !error.response) {
      console.error('Unexpected server error', error);

      return Promise.reject(error);
    }

    /* Log out when the token failed to update */
    if (error?.response?.config?.url === `${API_URL}/auth/refresh/`) {
      store.dispatch({ type: authTypes.LOGOUT_REQUEST });

      return;
    }

    if (error.response && error.response.status === 401) {
      const cookies = new Cookies();
      const token = cookies.get('token');

      if (!token || !token.refresh) {
        return Promise.reject(error);
      }

      if (!processing) {
        processing = true;
        const result = await axios.post(`${API_URL}/auth/refresh/`, { refresh: token.refresh });

        if (result.status === 200) {
          cookies.set('token', result.data, { path: '/' });
          processing = false;
          onAccessTokenFetched(result.data.access);

          return axios.request(originalRequest);
        }

        store.dispatch({ type: authTypes.LOGOUT_REQUEST });

        return Promise.reject(error);
      }

      const retryOriginalRequest = new Promise((resolve) => {
        addFailedRequest((access_token) => {
          originalRequest.headers.Authorization = `JWT ${access_token}`;
          resolve(axios(originalRequest));
        });
      });

      return retryOriginalRequest;
    }

    return Promise.reject(error);
  }
);

export default axios;
