import * as queryString from 'query-string';

import { API_URL } from '../constants';
import axios from './axios';

const questionnaire = {
  async getSignUpQuestionnaire() {
    try {
      const result = await axios.get(`${API_URL}/questionnaire/sign-up-questionnaire/`);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getQuestionnaireList(data) {
    try {
      const result = await axios.get(`${API_URL}/questionnaire/?${queryString.stringify(data)}`);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getQuestionnaireGroup() {
    try {
      const result = await axios.get(`${API_URL}/questionnaire/group/`);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getQuestionnaire(data) {
    try {
      const result = await axios.get(`${API_URL}/questionnaire/${data.id}/`);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getQuestionnaireSectionsApi() {
    try {
      const result = await axios.get(`${API_URL}/questionnaire/section/`);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getQuestionnaireQuestions(data) {
    try {
      const result = await axios.get(`${API_URL}/questionnaire/${data.id}/questions/`);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getLastUserTest() {
    try {
      const result = await axios.get(`${API_URL}/user-test/result/`);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async createUserTest(data) {
    try {
      const result = await axios.post(`${API_URL}/user-test/`, data);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default questionnaire;
