import React from 'react';
import PropTypes from 'prop-types';

const sleepIcon = '/images/image-sleep.svg';
const socialIcon = '/images/image-social.svg';
const exerciseIcon = '/images/image-exercise.svg';
const cutleryIcon = '/images/image-cutlery.svg';
const crossIcon = '/images/image-cross.svg';
const mindIcon = '/images/image-mind.svg';


export const SectionTitle = ({ sectionName }) => {
  switch (sectionName) {
    case 'Sleep':
      return <img src={sleepIcon} alt="icon sleep" />;
    case 'Social & Other':
      return <img src={socialIcon} alt="icon social" />;
    case 'Exercise':
      return <img src={exerciseIcon} alt="icon exercise" />;
    case 'Diet':
      return <img src={cutleryIcon} alt="icon mind" />;
    case 'Lifestyle':
      return <img src={crossIcon} alt="icon Lifestyle" />;
    case 'Mental Health':
      return <img src={mindIcon} alt="icon mental health" />;
    default:
      return null;
  }
};

SectionTitle.propTypes = {
  sectionName: PropTypes.string.isRequired,
};
