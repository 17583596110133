import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Box,
  makeStyles,
  Typography,
  Breadcrumbs,
  Select,
  MenuItem,
  debounce,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

import TemplateItem from './TemplateItem';
import {
  getTemplateListRequest,
  setTemplateParamsAction,
  templateViewRequest,
} from '../../redux/template/templateActions';
import { showModalAction } from '../../redux/general/generalActions';
import { getCustomGoalsRequest } from '../../redux/dashboard/dashboardActions';
import { getSharedUsersRequest } from '../../redux/shared-access/sharedAccessActions';
import { getQuestionnaireSectionsRequest } from '../../redux/questionnaire/questionnaireActions';
import { BootstrapInput } from '../Dashboard/UserTestCharts';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    maxWidth: '924px',
    margin: '0 auto',
    padding: '60px 0 70px',
  },
  content: {
    marginTop: '12px',
  },
  titleContent: {
    padding: '16px 0 0',
    '& .main-form__section-text': {
      [theme.breakpoints.down('sm')]: {
        width: 'calc(50% - 18px)',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  },
  titleContainer: {
    '& .main-form__section-text': {
      marginTop: '8px',
    },
  },
  switchContainer: {
    maxWidth: '200px',
    height: '42px',
    width: '100%',
    border: `1px solid ${theme.palette.type === 'dark' ? 'rgba(242, 242, 242, 0.8)' : 'rgba(0, 0, 0, 0.12);'}`,
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '16px',

    '& div:first-child': {
      borderLeft: 'none',
    },

    [theme.breakpoints.down('xs')]: {
      margin: '20px',
    },
  },
  switchItem: {
    padding: '10px 8px',
    cursor: 'pointer',
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderLeft: `1px solid ${theme.palette.type === 'dark' ? 'rgba(242, 242, 242, 0.8)' : 'rgba(0, 0, 0, 0.12);'}`,
  },
  activeLightSecondItem: {
    backgroundColor: '#0035FF',
    color: '#fff',
    borderRadius: '0px 8px 8px 0px',
    '& path': {
      fill: '#0035FF',
    },
  },
  activeSecondItem: {
    backgroundColor: '#0035FF',
    borderRadius: '0px 8px 8px 0px',
  },
  activeLightFirstItem: {
    backgroundColor: '#0035FF',
    color: '#fff',
    borderRadius: '8px 0px 0px 8px',
    '& path': {
      stroke: '#0035FF',
    },
  },
  activeFirstItem: {
    backgroundColor: '#0035FF',
    borderRadius: '8px 0px 0px 8px',
  },
  checkbox: {
    '&.Mui-checked': {
      color: theme.palette.type === 'dark' ? '#FFFFFF' : '#0035FF',
    },
  },
  loader: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkboxContainer: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: '20px',
    },
  },
  filterWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  pagination: {
    position: 'absolute',
    bottom: '24px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'fit-content',
    '& button': {
      border: '1px solid #CCCCCC',
      borderRadius: 2,
      backgroundColor: 'rgba(242, 242, 242, 0.2)',
    },
  },
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '18px',
  },
  gridItem: {
    width: '100%',
    maxWidth: 'calc(33.3% - 12px)',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(50% - 18px)',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  new: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '160px',
    backgroundColor: theme.palette.type === 'dark' ? '#374E86' : '#FFFFFF',
    borderRadius: '8px',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
  },
  categoryItem: {
    marginTop: 5,
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: 10,
    },
  },
  sectionFiltersContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > div': {
      [theme.breakpoints.down('sm')]: {
        margin: '10px 0',
      },
      [theme.breakpoints.down('xs')]: {
        margin: '10px 0',
      },
    },
  },
  selectMenuPaper: {
    backgroundColor: theme.overrides.filterListBackground,
  },
}));

const Templates = ({
  templateList = {},
  sections,
  getQuestionnaireSections,
  templateListLength = 0,
  customGoals,
  isDarkTheme,
  history,
  getTemplateList,
  setTemplateParams,
  getCustomGoals,
  getSharedUsers,
  handleTemplateShow,
  templateShow,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [filterCategory, setFilteredCategory] = useState('All Categories');
  const [searchValue, setSearchValue] = useState('');
  const [isShowPublished, setIsShowPublished] = useState(false);
  const [isTemplateListLoading, setIsTemplateListLoading] = useState(false);
  const isPublicView = useMemo(() => templateShow === 'public', [templateShow]);

  const plusIconSrc = isDarkTheme ? '/images/icon-plus-dark.svg' : '/images/icon-plus-light.svg';
  const count = Math.ceil(templateListLength / 10);

  useEffect(() => {
    getQuestionnaireSections();
    getCustomGoals();
    getSharedUsers();
  }, []);

  useEffect(() => {
    if (!isPublicView) {
      setIsShowPublished(false);
      getTemplateList({
        page: templateList.page,
        page_size: templateList.page_size,
        is_own: true,
      });
    } else {
      getTemplateList({
        page: templateList.page,
        page_size: templateList.page_size,
        is_own: isShowPublished,
        is_public: true,
      });
    }
    setTimeout(() => {
      setIsTemplateListLoading(false);
    }, 1000);
  }, [templateShow, isShowPublished]);

  useEffect(() => {
    const conditionForOwnTemplates = isPublicView ? { is_own: false, is_public: true } : { is_own: true };
    getTemplateList({ page: templateList.page, page_size: templateList.page_size, ...conditionForOwnTemplates });

    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [templateList.page]);

  const handleTemplateType = (value) => {
    setIsTemplateListLoading(true);
    handleTemplateShow(value);
  };

  const handleCategoryFilter = (event, data) => {
    const getSectionId = sections.results.find((item) => item.name === data.props.value);
    const conditionSearch = isPublicView ? { is_own: isShowPublished, is_public: true } : { is_own: true };
    setFilteredCategory(data.props.value);
    if (getSectionId) {
      getTemplateList({ ...conditionSearch, section: getSectionId.id, query: searchValue });
    } else {
      getTemplateList({ ...conditionSearch, query: searchValue });
    }
  };

  const searchHandler = (value) => {
    const conditionSearch = isPublicView ? { is_own: isShowPublished, is_public: true } : { is_own: true };
    getTemplateList({ ...conditionSearch, query: value });
    setSearchValue(value);
  };

  const debouncedSearch = debounce(async (value) => {
    searchHandler(value);
  }, 500);

  async function handleChange(e) {
    debouncedSearch(e.target.value);
  }

  return (
    <>
      <Helmet>
        <title>Myndful</title>
      </Helmet>
      <Box my={3}>
        <div className="breadcrumbs-outer" style={{ marginTop: '20px' }}>
          <div className="breadcrumbs-wrapper">
            <Breadcrumbs className="breadcrumbs">
              <Link to="/dashboard">{t('m_dashboard')}</Link>
              <Link to="/resources/templates">{t('m_templates')}</Link>
            </Breadcrumbs>
          </div>
        </div>
      </Box>
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <Typography variant="h4" style={{ fontWeight: 700 }}>
            {t('m_templates')}
          </Typography>
          <div className="main-form__section-text">
            <p>{t('template_subtitle')}</p>
          </div>
        </div>
        <div className={classes.titleContent}>
          <Box
            borderTop={`1px solid ${isDarkTheme ? '#ffffff20' : '#00000020'}`}
            borderBottom={`1px solid ${isDarkTheme ? '#ffffff20' : '#00000020'}`}
            mb={3}
            paddingTop={2}
            paddingBottom={2}
            display={'flex'}
            flexWrap={'wrap'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <div className={classes.switchContainer}>
              <div
                className={`${classes.switchItem} ${
                  templateShow === 'personal'
                    ? isDarkTheme
                      ? classes.activeFirstItem
                      : classes.activeLightFirstItem
                    : ''
                }`}
                onClick={() => handleTemplateType('personal')}
              >
                Personal
              </div>
              <div
                className={`${classes.switchItem} ${
                  templateShow === 'public'
                    ? isDarkTheme
                      ? classes.activeSecondItem
                      : classes.activeLightSecondItem
                    : ''
                }`}
                onClick={() => handleTemplateType('public')}
              >
                Public
              </div>
            </div>
            <div className={classes.filterWrapper}>
              {isPublicView && (
                <div className={classes.checkboxContainer}>
                  <Checkbox
                    className={classes.checkbox}
                    color="primary"
                    checked={isShowPublished}
                    onChange={(_evt, value) => {
                      setIsShowPublished(value);
                    }}
                  />
                  <span>{t('template_publish')}</span>
                </div>
              )}
              <div className={classes.sectionFiltersContainer}>
                <BootstrapInput
                  onChange={handleChange}
                  placeholder={t('template_filter_search')}
                  variant="contained"
                  style={{ width: '185px', margin: '0 20px' }}
                />
                <Select
                  labelId="category-label"
                  id="category"
                  value={filterCategory}
                  MenuProps={{
                    classes: { paper: classes.selectMenuPaper },
                    transitionDuration: 0,
                  }}
                  onChange={handleCategoryFilter}
                  input={<BootstrapInput variant="contained" style={{ width: '185px' }} />}
                >
                  <MenuItem value={t('template_filter_all-categories')}>
                    <em>{t('template_filter_all-categories')}</em>
                  </MenuItem>
                  {(sections.results || []).map((item) => (
                    <MenuItem key={`option-${item.id}`} value={item.name}>
                      <div className={classes.categoryItem}>
                        <img src={item.icon} width={24} alt="" /> {item.name}
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </Box>
        </div>
        {isTemplateListLoading ? (
          <div className={classes.loader}>
            <CircularProgress color="primary" />
          </div>
        ) : (
          <div>
            <div className={classes.grid}>
              {!isPublicView && (
                <div
                  className={`${classes.gridItem} ${classes.new}`}
                  onClick={() => history.push('/resources/templates/create')}
                >
                  <img src={plusIconSrc} alt="plus icon" width={30} height={30} />
                  {t('template_create_new')}
                </div>
              )}
              {templateList.results?.map((item) => (
                <div key={`template-${item.id}`} className={classes.gridItem}>
                  <TemplateItem
                    template={item}
                    isPublicView={isPublicView}
                    isApplied={!!customGoals.find((el) => item.id === el.template?.id)}
                  />
                </div>
              ))}
            </div>
            {count > 1 && (
              <Pagination
                siblingCount={0}
                boundaryCount={1}
                className={classes.pagination}
                shape="rounded"
                page={templateList.page}
                count={count}
                onChange={(_evt, data) => setTemplateParams({ page: data })}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

Templates.propTypes = {
  sections: PropTypes.object.isRequired,
  templateList: PropTypes.object.isRequired,
  templateListLength: PropTypes.number.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  customGoals: PropTypes.array.isRequired,
  isDarkTheme: PropTypes.bool.isRequired,
  error: PropTypes.object,
  history: PropTypes.object.isRequired,
  getTemplateList: PropTypes.func.isRequired,
  setTemplateParams: PropTypes.func.isRequired,
  getCustomGoals: PropTypes.func.isRequired,
  getSharedUsers: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    templateList: state.template.templateList,
    templateListLength: state.template.templateList.count,
    customGoals: state.dashboard.customGoals,
    isProcessing: state.template.processing,
    sections: state.questionnaire.sections,
    getQuestionnaireSections: PropTypes.func.isRequired,
    isDarkTheme: state.general.isDarkTheme,
    error: state.template.error || {},
    templateShow: state.template.templateShow,
  }),
  {
    getTemplateList: getTemplateListRequest,
    setTemplateParams: setTemplateParamsAction,
    getQuestionnaireSections: getQuestionnaireSectionsRequest,
    getCustomGoals: getCustomGoalsRequest,
    getSharedUsers: getSharedUsersRequest,
    showModal: showModalAction,
    handleTemplateShow: templateViewRequest,
  }
)(Templates);
