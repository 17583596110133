import { all, put, call, takeLatest } from 'redux-saga/effects';

import feedback from '../../api/feedback';
import * as types from './feedbackActionsTypes';
import * as generalTypes from '../general/generalActionsTypes';
import { getCustomErrorProps } from '../reduxHelpers';

function* createFeedback({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(feedback.createFeedback, payload);
    yield put({ type: types.CREATE_FEEDBACK_SUCCESS, payload: response });
    yield put({
      type: generalTypes.SHOW_MODAL_ACTION,
      payload: {
        params: { message: 'Feedback sent successfully.', type: 'success' },
        type: generalTypes.MODAL_TYPE_ALERT,
      },
    });
  } catch (error) {
    const commonError = getCustomErrorProps(error, ['subject', 'email', 'message', 'captcha']);
    yield put(commonError ? commonError : { type: types.FEEDBACK_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getReCaptcha() {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(feedback.getReCaptcha);
    yield put({ type: types.GET_RECAPTCHA_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.FEEDBACK_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.CREATE_FEEDBACK_REQUEST, createFeedback),
    yield takeLatest(types.GET_RECAPTCHA_REQUEST, getReCaptcha),
  ]);
}
