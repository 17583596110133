import * as types from './blogActionsTypes';
import * as authTypes from '../auth/authActionsTypes';

export const initialState = {
  articleList: {
    results: [],
  },
  articleCategory: [],
  articleListCategory: {},
  singleArticle: {},
  processing: false,
  error: null,
};

const blog = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };

    case types.GET_ARTICLE_LIST_SUCCESS:
      return {
        ...state,
        articleList: action.payload,
        error: null,
      };

    case types.GET_ARTICLE_LIST_CATEGORY_SUCCESS:
      return {
        ...state,
        articleListCategory: action.payload,
        error: null,
      };

    case types.GET_ARTICLE_CATEGORY_SUCCESS:
      return {
        ...state,
        articleCategory: action.payload,
        error: null,
      };

    case types.SEARCH_ARTICLE_LIST_SUCCESS:
      return {
        ...state,
        articleList: {
          count: action.payload.length,
          next: null,
          previous: null,
          results: action.payload,
        },
        error: null,
      };

    case types.GET_SINGLE_ARTICLE_SUCCESS:
      return {
        ...state,
        singleArticle: action.payload,
        error: null,
      };

    case types.CLEAR_SINGLE_ARTICLE_ACTION:
      return {
        ...state,
        singleArticle: {},
        error: null,
      };

    case authTypes.LOGOUT_REQUEST:
      return initialState;

    default:
      return state;
  }
};

export default blog;
