import { all, call, put, takeLatest } from 'redux-saga/effects';
import Cookies from 'universal-cookie';

import profile from '../../api/profile';
import * as generalTypes from '../general/generalActionsTypes';
import { getCustomErrorProps } from '../reduxHelpers';
import * as types from './profileActionsTypes';

const cookies = new Cookies();

function* getProfile() {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(profile.getProfile);
    yield put({ type: types.GET_PROFILE_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.PROFILE_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* disconnectGoogleCalendar() {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    yield call(profile.disconnectGoogleCalendar);
    yield put({ type: types.GET_PROFILE_REQUEST });
  } catch (error) {
    yield put({ type: types.DISCONNECT_GOOGLE_CALENDAR_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* updateProfile({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(profile.updateProfile, payload);
    yield put({ type: types.UPDATE_PROFILE_SUCCESS, payload: response });
    yield put({
      type: generalTypes.SHOW_MODAL_ACTION,
      payload: {
        params: { message: 'Profile updated successfully!', type: 'success' },
        type: generalTypes.MODAL_TYPE_ALERT,
      },
    });
  } catch (error) {
    const commonError = getCustomErrorProps(error, ['username', 'email', 'age']);
    yield put(commonError ? commonError : { type: types.PROFILE_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* sendPhoneToken({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    yield call(profile.sendPhoneToken);

    if (payload) {
      yield put({
        type: generalTypes.SHOW_MODAL_ACTION,
        payload: {
          params: { message: 'Token sent to your phone!', type: 'success' },
          type: generalTypes.MODAL_TYPE_ALERT,
        },
      });
      yield put({
        type: generalTypes.SHOW_MODAL_ACTION,
        payload: {
          params: { title: '' },
          type: generalTypes.MODAL_TYPE_VERIFY_PHONE,
        },
      });
    } else {
      yield put({
        type: generalTypes.SHOW_MODAL_ACTION,
        payload: {
          params: { title: '' },
          type: generalTypes.MODAL_TYPE_VERIFY_PHONE,
        },
      });
    }
  } catch (error) {
    yield put({ type: types.PROFILE_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* verifyPhoneToken({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    yield call(profile.verifyPhoneToken, payload);

    yield put({ type: types.GET_PROFILE_REQUEST });
    yield put({ type: generalTypes.HIDE_MODAL_ACTION });
    yield put({
      type: generalTypes.SHOW_MODAL_ACTION,
      payload: {
        params: { message: 'Your phone number is verified!', type: 'success' },
        type: generalTypes.MODAL_TYPE_ALERT,
      },
    });
  } catch (error) {
    yield put({ type: types.PROFILE_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* createReminder({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    yield call(profile.createReminder, payload);

    yield put({ type: types.GET_PROFILE_REQUEST });
    yield put({
      type: generalTypes.SHOW_MODAL_ACTION,
      payload: {
        params: { message: 'Reminder created!', type: 'success' },
        type: generalTypes.MODAL_TYPE_ALERT,
      },
    });
  } catch (error) {
    yield put({ type: types.PROFILE_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* updateReminder({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    yield call(profile.updateReminder, payload);

    yield put({ type: types.GET_PROFILE_REQUEST });
    yield put({
      type: generalTypes.SHOW_MODAL_ACTION,
      payload: {
        params: { message: 'Reminder updated!', type: 'success' },
        type: generalTypes.MODAL_TYPE_ALERT,
      },
    });
  } catch (error) {
    yield put({ type: types.PROFILE_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* updatePassword({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(profile.updatePassword, payload);
    yield put({ type: types.UPDATE_PASSWORD_SUCCESS, payload: response });
    yield put({
      type: generalTypes.SHOW_MODAL_ACTION,
      payload: {
        params: {
          type: 'success',
          message: 'Your password was successfully changed.',
        },
        type: generalTypes.MODAL_TYPE_ALERT,
      },
    });
  } catch (error) {
    yield put({ type: types.PROFILE_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* updatePhone({ payload }) {
  try {
    const { isPhoneVerified, phoneNumber } = yield call(profile.updatePhone, payload);

    cookies.set('user', { ...cookies.get('user'), isPhoneVerified: isPhoneVerified, phoneNumber: phoneNumber });

    // eslint-disable-next-line no-eq-null
    if (!isPhoneVerified && phoneNumber === null) {
      yield put({ type: types.GET_PROFILE_SUCCESS, payload: { ...cookies.get('user') } });

      yield put({
        type: generalTypes.SHOW_MODAL_ACTION,
        payload: {
          params: { message: 'Your phone number is removed!', type: 'success' },
          type: generalTypes.MODAL_TYPE_ALERT,
        },
      });

      return;
    }

    if (!isPhoneVerified) {
      yield put({
        type: generalTypes.SHOW_MODAL_ACTION,
        payload: {
          params: { title: '' },
          type: generalTypes.MODAL_TYPE_VERIFY_PHONE,
        },
      });
    }
  } catch (error) {
    const commonError = getCustomErrorProps(error);

    yield put(commonError ? commonError : { type: types.PROFILE_REQUEST_ERROR, error });
    console.error(error);
  }
}

function* getLanguagesOptionsSaga() {
  try {
    const response = yield call(profile.getLanguagesOptions);

    const savedLanguage = localStorage.getItem('language');

    yield put({
      type: types.GET_LANGUAGES_OPTIONS_SUCCESS,
      payload: {
        selectedLanguage: savedLanguage && response[savedLanguage] ? savedLanguage : 'en',
        languagesOptions: Object.entries(response).map(([value, label]) => ({ value, label })),
        languagesOptionsProcessing: false
      }
    });
  } catch (error) {
    yield put({ type: types.GET_LANGUAGES_OPTIONS_ERROR, error });
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_PROFILE_REQUEST, getProfile),
    yield takeLatest(types.UPDATE_PROFILE_REQUEST, updateProfile),
    yield takeLatest(types.SEND_PHONE_TOKEN_REQUEST, sendPhoneToken),
    yield takeLatest(types.VERIFY_PHONE_TOKEN_REQUEST, verifyPhoneToken),
    yield takeLatest(types.CREATE_REMINDER_REQUEST, createReminder),
    yield takeLatest(types.UPDATE_REMINDER_REQUEST, updateReminder),
    yield takeLatest(types.UPDATE_PASSWORD_REQUEST, updatePassword),
    yield takeLatest(types.UPDATE_PHONE_REQUEST, updatePhone),
    yield takeLatest(types.GET_LANGUAGES_OPTIONS_REQUEST, getLanguagesOptionsSaga),
    yield takeLatest(types.DISCONNECT_GOOGLE_CALENDAR, disconnectGoogleCalendar),
  ]);
}
