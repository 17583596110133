import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';

import QuestionnaireCard from './QuestionnaireCard';
import { getQuestionnaireGroupRequest } from '../../../redux/questionnaire/questionnaireActions';

function QuestionnaireGroup({ questionnaireGroup, getQuestionnaireGroup }) {
  const [list, setList] = useState([]);

  useEffect(() => {
    getQuestionnaireGroup();
  }, []);

  useEffect(() => {
    if (questionnaireGroup && questionnaireGroup.length === 3) {
      setList(
        questionnaireGroup
          .sort((a, b) => a.displayOrder - b.displayOrder)
          .map((x, i, a) => ({
            ...x,
            order: i === 0 ? 'first' : i === 1 ? 'second' : 'third',
            status: x.isPassed ? 'completed' : i === 0 || (a[i - 1] && a[i - 1].isPassed) ? 'available' : 'inactive',
          }))
      );
    }
  }, [questionnaireGroup]);

  if (!list || !list.length) return null;

  return (
    <Grid container spacing={3}>
      {list.map((item) => (
        <QuestionnaireCard key={`card-${item.id}`} {...item} />
      ))}
    </Grid>
  );
}

QuestionnaireGroup.propTypes = {
  questionnaireGroup: PropTypes.array.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  getQuestionnaireGroup: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    questionnaireGroup: state.questionnaire.questionnaireGroup,
    isProcessing: state.questionnaire.processing,
  }),
  {
    getQuestionnaireGroup: getQuestionnaireGroupRequest,
  }
)(QuestionnaireGroup);
