import Cookies from 'universal-cookie';
import * as types from './authActionsTypes';

// Get token from cookies, if it is in there than we keep user authenticated
const cookies = new Cookies();

export const initialState = {
  isAuthenticated: !!cookies.get('token'),
  isShowWelcomeModal: false,
  userProfileRegistered: false,
  isResetPasswordEmailSent: false,
  isNewPasswordSet: false,
  isProcessingToken: false,
  processing: false,
  error: null,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };

    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isShowWelcomeModal: true,
        error: null,
      };

    case types.REGISTER_SUCCESS:
      return {
        ...state,
        userProfileRegistered: true,
        error: null,
      };

    case types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isResetPasswordEmailSent: true,
        error: null,
      };

    case types.SET_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        isNewPasswordSet: true,
        error: null,
      };
    case types.WELCOME_MODEL_CLEAR: 
      return {
        ...state,
        isShowWelcomeModal: false,
      }
    case types.GET_SOCIAL_KEYS_SUCCESS:
      return {
        ...state,
        googleAuthUrl: action.payload?.google,
        error: null,
      };

    case types.TOGGLE_TOKEN_PROCESSING:
      return {
        ...state,
        isProcessingToken: action.payload,
      };

    case types.LOGOUT_REQUEST:
      return {
        ...state,
        isAuthenticated: false,
        isShowWelcomeModal: false,
        userProfileRegistered: false,
        isResetPasswordEmailSent: false,
        isNewPasswordSet: false,
        processing: false,
        error: null,
      };

    case types.AUTH_REQUEST_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case types.CLEAR_ERROR:
      return {
        ...state,
        isResetPasswordEmailSent: false,
        isNewPasswordSet: false,
        error: null,
      };

    default:
      return state;
  }
};

export default auth;
