import { Button, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import SubscribeDialog from './SubscribeDialog';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  subscribeButton: {
    background: '#325CC8',
    borderRadius: '8px',
    color: '#fff',
    textTransform: 'capitalize',
    '& .MuiButton-label': {
      fontWeight: 500,
    },
    '&.MuiButton-text': {
      padding: '18px 32px',
    },
    '&:visited': {
      color: '#fff',
    },
    '&:hover': {
      background: '#325CC8',
      color: '#fff',
    },
    '&:active': {
      color: '#fff',
    },
    [theme.breakpoints.down('xs')]: {
      '&.MuiButton-text': {
        padding: '10px 16px',
      },
    },
  },
});

export const SubscribeButton = withStyles(styles)((props) => {
  const { classes } = props;
  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  return (
    <>
      <Button className={classes.subscribeButton} onClick={handleOpen}>
        {t('newsletter_subscribe')}
      </Button>
      <SubscribeDialog 
        handleClose={handleClose} 
        show={show} 
      />
    </>
  );
});

export default connect(() => {})(SubscribeButton);
