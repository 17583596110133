import { Box, Grid, LinearProgress, makeStyles, withStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  content: {
    padding: '12px 24px 12px 15px',
    backgroundColor: theme.palette.type === 'dark' ? '#3A5188' : '#fff',
    width: '100%',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0px 2px 5px 0px #0000001A',
  },
  image: {
    width: '72px',
    height: '72px',
    marginRight: '20px',
    position: 'relative',
    '& .icon': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',

      '&.unactive': {
        opacity: '0.7',
      },
    },
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: theme.palette.type === 'dark' ? '#F2F2F2' : '#2A2B2A',
    margin: '0',
    marginBottom: '4px',
    '&.unactive': {
      opacity: '0.7',
    },
  },
  description: {
    width: '100%',
    '&.unactive': {
      opacity: '0.7',
    },
  },
  descriptionText: {
    fontSize: '14px',
    color: theme.palette.type === 'dark' ? '#F2F2F2' : '#2A2B2A',
    opacity: '0.6',
    margin: '0',
  },
  progressContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  progressText: {
    opacity: '0.7',
    fontSize: '12px',
    textAlign: 'center'
  },
  countBadge: {
    border: '1px solid #FFFFFF70',
    color: '#F2F2F2',
    backgroundColor: '#FE4A5E',
    padding: '2px 7px',
    position: 'absolute',
    right: '-15px',
    top: '0',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '48px',
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    marginTop: '6px',
  },
  colorPrimary: {
    backgroundColor: theme.palette.type === 'dark' ? '#F2F2F260' : '#2A2B2A30',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#406BE1',
  },
}))(LinearProgress);

const AchievementsCard = ({ badge }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isBadgeHaveCount = !!badge.count;
  const MIN = 0;
  const normalise = (value, maxValue) => ((value - MIN) * 100) / (maxValue - MIN);

  const setTypeOfValue = ({ value, maxValue }) => {
    if (maxValue <= 7) {
      return `${value} of ${maxValue} days`;
    } else if (maxValue > 7 && maxValue <= 100) {
      return `${value} of ${maxValue}`;
    } else if (maxValue > 100 && maxValue <= 1000) {
      return `${value} points`;
    }
  };

  return (
    <Grid className={classes.container} item xs={12} md={4}>
      <Box display="flex" flexWrap="noWrap">
        <div className={classes.content}>
          <div className={classes.image}>
            {isBadgeHaveCount ? <div className={classes.countBadge}>{badge.count}x</div> : null}
            <img
              className={`icon ${!isBadgeHaveCount ? 'unactive' : ''}`}
              src={badge.badge.icon}
              alt={badge.badge.title}
            />
          </div>
          <div className={`${classes.description} ${!isBadgeHaveCount ? 'unactive' : ''}`}>
            <h3 className={classes.title}>{badge.badge.title}</h3>
            {!isBadgeHaveCount ? (
              <div className={classes.progressContainer}>
                <BorderLinearProgress variant="determinate" value={normalise(badge.progress, badge.badge.goal)} />
                <span className={classes.progressText}>
                    {setTypeOfValue({
                    value: badge.progress,
                    maxValue: badge.badge.goal,
                    })}
                </span>
              </div>
            ) : (
              <p className={classes.descriptionText}>
                {t('achievements', { returnObjects: true })[badge.badge.variant]}
              </p>
            )}
          </div>
        </div>
      </Box>
    </Grid>
  );
};

export default connect()(AchievementsCard);
