import { all, put, call, takeLatest } from 'redux-saga/effects';

import subscription from '../../api/subscription';
import * as types from './subscriptionActionsTypes';
import * as generalTypes from '../general/generalActionsTypes';

function* getAvailableSubscriptions() {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(subscription.getAvailableSubscriptions);
    yield put({ type: types.GET_AVAILABLE_SUBSCRIPTIONS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.SUBSCRIPTION_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getSubscriptionPublicKey() {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(subscription.getSubscriptionPublicKey);
    yield put({ type: types.GET_SUBSCRIPTION_PUBLIC_KEY_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.SUBSCRIPTION_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* createUserSubscription({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(subscription.createUserSubscription, payload);
    yield put({ type: types.CREATE_USER_SUBSCRIPTION_SUCCESS, payload: response });
    yield put({ type: generalTypes.HIDE_MODAL_ACTION });
  } catch (error) {
    yield put({ type: types.SUBSCRIPTION_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* createAnonymousUserSubscription({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(subscription.createAnonymousUserSubscription, payload);
    yield put({ type: types.CREATE_USER_ANONYMOUS_SUBSCRIPTION_SUCCESS, payload: response });
    yield put({ type: generalTypes.HIDE_MODAL_ACTION });
  } catch (error) {
    yield put({ type: types.SUBSCRIPTION_REQUEST_ERROR, error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* deleteUserSubscription({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(subscription.deleteUserSubscription, payload);
    yield put({ type: types.DELETE_USER_SUBSCRIPTION_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.SUBSCRIPTION_REQUEST_ERROR, error });
  } finally {
    yield put({ type: generalTypes.HIDE_MODAL_ACTION });
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_AVAILABLE_SUBSCRIPTIONS_REQUEST, getAvailableSubscriptions),
    yield takeLatest(types.GET_SUBSCRIPTION_PUBLIC_KEY_REQUEST, getSubscriptionPublicKey),
    yield takeLatest(types.CREATE_USER_SUBSCRIPTION_REQUEST, createUserSubscription),
    yield takeLatest(types.CREATE_USER_ANONYMOUS_SUBSCRIPTION_REQUEST, createAnonymousUserSubscription),
    yield takeLatest(types.DELETE_USER_SUBSCRIPTION_REQUEST, deleteUserSubscription),
  ]);
}
