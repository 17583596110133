import { all, put, call, takeLatest } from 'redux-saga/effects';

import settings from '../../api/settings';
import * as types from './settingsActionTypes';
import { getCommonErrorProps } from '../reduxHelpers';

function* getTimezonesSaga() {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const response = yield call(settings.getTimezones);
    yield put({ type: types.GET_TIMEZONES_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.SETTINGS_REQUEST_ERROR, error });
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

export default function* () {
  yield all([yield takeLatest(types.GET_TIMEZONES_REQUEST, getTimezonesSaga)]);
}
