import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { makeStyles } from '@material-ui/core';

import axios from 'axios';
import Preloader from '../shared/components/Preloader';
import { API_URL } from '../../constants';


const useStyles = makeStyles({
  container: {
    position: 'relative'
  },
  error: {
    fontSize: '1rem',
    color: '#f44336'
  }
});

const GoogleOAuth = () => {
  const classes = useStyles();
  const history = useHistory();
  const [error, setError] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const code = params.get('code');

    if (code) {
      axios
        .post(`${API_URL}/social/jwt-pair/google-oauth2/`, {
          code,
          redirect_uri: `${process.env.REACT_APP_BASE_URL}/social-google`
        })
        .then((resp) => {
          if (resp?.data?.token) {
            const cookies = new Cookies();
            cookies.set('token', { access: resp.data.token, refresh: resp.data.refresh }, { path: '/' });

            history.replace({ search: '' });
            window.location.reload();
            history.replace('/personalized-plan');
          } else {
            console.error('Access token not found', resp);
            history.replace('/auth/sign-in');
          }
        })
        .catch((err) => {
          console.error(err);
          setError(err.message || 'Authorization error');
        })
    } else {
      setError('Invalid authorization code');
    }
  }, []);

  return (
    <div className={classes.container}>
      {error ? (
        <div className={classes.error}>{error}</div>
      ) : (
        <Preloader />
      )}
    </div>
  );
};

export default GoogleOAuth;
