export const GET_NOTES = 'GET_NOTES';
export const GET_NOTES_SUCCESS = 'GET_NOTES_SUCCESS';

export const GET_NOTE_REQUEST = 'GET_NOTE_REQUEST';
export const GET_NOTE_SUCCESS = 'GET_NOTE_SUCCESS';

export const CLEAR_SINGLE_NOTE = "CLEAR_SINGLE_NOTE";

export const ADD_NOTE = 'ADD_NOTE';
export const ADD_NOTE_SUCCESS = 'ADD_NOTE_SUCCESS';

export const UPDATE_NOTE = 'UPDATE_NOTE';
export const UPDATE_NOTE_SUCCESS = 'UPDATE_NOTE_SUCCESS';

export const REMOVE_NOTE = 'REMOVE_NOTE';
export const REMOVE_NOTE_SUCCESS = 'REMOVE_NOTE_SUCCESS';

export const TOGGLE_PROCESSING = 'TOGGLE_JOURNAL_PROCESSING';
export const JOURNAL_REQUEST_ERROR = 'JOURNAL_REQUEST_ERROR';
