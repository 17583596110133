import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Breadcrumbs } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Confetti from 'react-confetti';
import { useTranslation } from 'react-i18next';

import StandardGoals from './StandardGoals';
import CustomGoals from './CustomGoals';
import QuestionnaireGroup from '../shared/QuestionnaireGroup/QuestionnaireGroup';
import PreviousGoals from './PreviousGoals';


const PersonalizedPlan = () => {
  const { t } = useTranslation();

  const [showConfetti, setShowConfetti] = useState(false);

  return (
    <>
      <Helmet>
        <title>Myndful</title>
      </Helmet>
      <Box mt={2} mb={6}>
        <div className="breadcrumbs-outer">
          <div className="breadcrumbs-wrapper">
            <Breadcrumbs className="breadcrumbs">
              <Link to="/dashboard">{t('m_dashboard')}</Link>
              <Link to="/personalized-plan">{t('m_your_plan')}</Link>
            </Breadcrumbs>
          </div>
        </div>
        <QuestionnaireGroup />
      </Box>
      <StandardGoals showConfetti={setShowConfetti} />
      <CustomGoals />
      <PreviousGoals showConfetti={setShowConfetti} />

      <Confetti
        style={{ position: 'fixed' }}
        run={showConfetti}
        recycle={false}
        tweenDuration={5000}
        onConfettiComplete={() => setShowConfetti(false)}
        numberOfPieces={500}
      />
    </>
  );
};

PersonalizedPlan.propTypes = {};

export default PersonalizedPlan;
