import * as types from './sharedAccessActionTypes';

export const requestAccessRequest = (payload) => {
  return { type: types.REQUEST_ACCESS, payload };
};

export const sharedAccessClear = () => {
  return { type: types.SHARED_ACCESS_CLEAR };
};

export const getSharedUsersRequest = () => {
  return { type: types.REQUEST_SHARED_USERS };
};

export const getSharedUserResultRequest = (payload) => {
  return { type: types.REQUEST_SHARED_USER_RESULT, payload };
};

export const updateSharedAccessRequest = (payload) => {
  return { type: types.UPDATE_SHARED_ACCESS, payload };
};

export const deleteSharedAccessRequest = (payload) => {
  return { type: types.DELETE_SHARED_ACCESS, payload };
};

export const getSharedUserTestSectionListRequest = (payload) => {
  return { type: types.GET_SHARED_USER_SECTIONS_LIST, payload };
};

export const acceptSharedRequest = (payload) => {
  return { type: types.ACCEPT_SHARED_REQUEST, payload };
};
