import { Box, Button, Checkbox, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { clearErrorAction, signUpRequest } from '../../redux/auth/authActions';
import PasswordField from '../shared/components/PasswordField';

const useStyles = makeStyles({
  container: {
    position: 'relative',
  },
  check: {
    color: '#4CAF50',
    cursor: 'pointer',
  },
  termsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  termsTitle: {
    fontSize: 12,
    fontWeight: 500,
    color: '#5D5D5D',
  },
  conditionText: {
    fontSize: 12,
    fontWeight: 500,
    color: '#5D5D5D',
    marginBottom: '10px',
  },
  link: {
    textDecorationLine: 'underline',
  },
});

const SignUpSchema = yup.object().shape({
  username: yup.string().required('Username can not be empty'),
  email: yup.string().required('Email can not be empty').email('Please provide correct email'),
  password: yup
    .string()
    .required('Password can not be empty')
    .min(8, 'Password should be at least 8 characters long')
    .test('isValidPass', 'Password is not valid', (value) => {
      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      let validConditions = 0;
      const numberOfMustBeValidConditions = 3;
      const conditions = [hasLowerCase, hasUpperCase, hasNumber];
      conditions.forEach((condition) => (condition ? validConditions++ : null));
      if (validConditions >= numberOfMustBeValidConditions) {
        return true;
      }
      return false;
    }),
});

const SignUp = ({ isProcessing, error, signUp, clearError, userProfileRegistered }) => {
  const classes = useStyles();
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);

  const { handleSubmit, errors, register, getValues } = useForm({
    reValidateMode: 'onChange',
    validationSchema: SignUpSchema,
  });

  useEffect(() => {
    if (userProfileRegistered) {
      history.push('/auth/verification', {
        email: getValues('email'),
        password: getValues('password'),
      });
    }
  }, [getValues, history, userProfileRegistered]);

  const onSubmitHandler = (data) => {
    signUp({ ...data, email: (data.email || '').toLowerCase() });
  };

  return (
    <>
      <Helmet>
        <title>Myndful</title>
      </Helmet>
      <Typography align="center" variant="h5">
        Register
      </Typography>
      <form autoComplete="off" name="register" id="register" onSubmit={handleSubmit(onSubmitHandler)}>
        <Box className="form-input__group">
          <TextField
            variant="filled"
            inputRef={register}
            fullWidth
            error={!!errors.username || !!error.username}
            helperText={errors.username ? errors.username.message : error.username ? error.username[0] : ''}
            id="username"
            label="Username"
            name="username"
            autoComplete="new-password"
            spellCheck={false}
            onChange={() => {
              error.username && clearError();
            }}
          />
        </Box>
        <Box className="form-input__group">
          <TextField
            variant="filled"
            inputRef={register}
            fullWidth
            error={!!errors.email || !!error.email}
            helperText={errors.email ? errors.email.message : error.email ? error.email[0] : ''}
            id="email"
            label="Email"
            name="email"
            autoComplete="new-password"
            InputProps={{
              endAdornment:
                email && /\S+@\S+\.\S+/.test(email) ? (
                  <InputAdornment position="end" className={classes.check}>
                    <Check />
                  </InputAdornment>
                ) : null,
            }}
            onChange={(evt) => {
              error.email && clearError();
              setEmail(evt.target.value);
            }}
          />
        </Box>
        <Box className="form-input__group">
          <PasswordField
            className="form-input__password"
            inputRef={register}
            error={error}
            errors={errors}
            autoComplete="new-password"
            name="password"
            label="Password"
            id="password"
            onChange={() => error.password && clearError()}
          />
        </Box>
        <Box className="form-input__group">
          <div className={classes.conditionText}>
            * Passwords should be at least 8 characters long and include at least one number and one capital letter.
          </div>
          <div className={classes.termsContainer}>
            <Checkbox value={termsAccepted} onChange={(_event, checked) => setTermsAccepted(checked)} size="small" />
            <Typography className={classes.termsTitle}>
              I agree to the{' '}
              <Link className={classes.link} to="/terms-and-conditions">
                Terms and Conditions
              </Link>{' '}
              and the{' '}
              <Link className={classes.link} to="/privacy-policy">
                Privacy Policy
              </Link>
            </Typography>
          </div>
        </Box>
        <Button
          type="submit"
          disabled={isProcessing || !termsAccepted}
          fullWidth
          variant="contained"
          color="primary"
          className="auth-button"
        >
          Sign Up
        </Button>
      </form>
      <Typography align="center" variant="body2" className="auth-text">
        <Box component="span" m={0.5}>
          Have an account already?
        </Box>
        <Link to="/auth/sign-in">Sign In</Link>
      </Typography>
    </>
  );
};

SignUp.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  userProfileRegistered: PropTypes.bool.isRequired,
  error: PropTypes.object,
  signUp: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isProcessing: state.auth.processing,
    userProfileRegistered: state.auth.userProfileRegistered,
    error: state.auth.error || {},
  }),
  {
    signUp: signUpRequest,
    clearError: clearErrorAction,
  }
)(SignUp);
