import { all, put, call, takeLatest } from 'redux-saga/effects';

import template from '../../api/template';
import * as types from './templateActionsTypes';
import * as dashboardTypes from '../dashboard/dashboardActionsTypes';
import * as generalTypes from '../general/generalActionsTypes';
import { getCommonErrorProps } from '../reduxHelpers';

function* getTemplateListSaga({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(template.getTemplateListApi, payload);
    yield put({ type: types.GET_TEMPLATE_LIST_SUCCESS, payload: response });
  } catch (error) {
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getSingleTemplateSaga({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(template.getSingleTemplateApi, payload);
    yield put({ type: types.GET_SINGLE_TEMPLATE_SUCCESS, payload: response });
  } catch (error) {
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* createTemplateSaga({ payload, successCallback }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const result = yield call(template.createTemplateApi, payload);
    yield put({
      type: generalTypes.SHOW_MODAL_ACTION,
      payload: {
        params: { message: 'Template created successfully.', type: 'success' },
        type: generalTypes.MODAL_TYPE_ALERT,
      },
    });
    yield put({ type: types.CREATE_TEMPLATE_SUCCESS, payload: result });
    successCallback();
  } catch (error) {
    yield put({ type: types.CREATE_TEMPLATE_ERROR, error });
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* updateTemplateSaga({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const result = yield call(template.updateTemplateApi, payload);
    yield put({
      type: generalTypes.SHOW_MODAL_ACTION,
      payload: {
        params: { message: 'Template updated successfully.', type: 'success' },
        type: generalTypes.MODAL_TYPE_ALERT,
      },
    });
    yield put({ type: types.UPDATE_TEMPLATE_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: types.UPDATE_TEMPLATE_ERROR, error });
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* updateSingleTemplateSaga({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const result = yield call(template.updateSingleTemplateApi, payload);
    yield put({
      type: generalTypes.SHOW_MODAL_ACTION,
      payload: {
        params: { message: 'Template updated successfully.', type: 'success' },
        type: generalTypes.MODAL_TYPE_ALERT,
      },
    });
    yield put({ type: types.UPDATE_SINGLE_TEMPLATE_SUCCESS, payload: result });
    yield put({ type: types.GET_TEMPLATE_LIST_REQUEST, payload: { page: 1, page_size: 10, is_own: true } });
    yield put({ type: types.STATUS_TEMPLATE_SHOW_REQUEST, payload: 'personal' });
  } catch (error) {
    yield put({ type: types.UPDATE_SINGLE_TEMPLATE_ERROR, error });
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* cloneTemplateSaga({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const result = yield call(template.cloneTemplateApi, payload);
    yield put({ type: types.CLONE_TEMPLATE_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: types.CLONE_TEMPLATE_ERROR, error });
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* deleteTemplateSaga({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(template.deleteTemplateApi, payload);

    yield put({ type: types.SET_TEMPLATE_PARAMS_ACTION, payload: { page: 1 } });
    yield put({ type: types.GET_TEMPLATE_LIST_REQUEST, payload: { page: 1, page_size: 10, is_own: true } });
    yield put({
      type: generalTypes.SHOW_MODAL_ACTION,
      payload: {
        params: { message: 'Template deleted successfully.', type: 'success' },
        type: generalTypes.MODAL_TYPE_ALERT,
      },
    });
  } catch (error) {
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* deselectTemplateSaga({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    yield call(template.deselectTemplateApi, payload);

    yield put({ type: dashboardTypes.GET_CUSTOM_GOALS_REQUEST });

    yield put({
      type: generalTypes.SHOW_MODAL_ACTION,
      payload: {
        params: { message: 'Template deselected successfully.', type: 'success' },
        type: generalTypes.MODAL_TYPE_ALERT,
      },
    });
  } catch (error) {
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* applyTemplateSaga({ payload }) {
  try {
    yield call(template.applyTemplateApi, payload);

    yield put({ type: dashboardTypes.GET_CUSTOM_GOALS_REQUEST });

    yield put({
      type: generalTypes.SHOW_MODAL_ACTION,
      payload: {
        params: { message: 'Template applied successfully.', type: 'success' },
        type: generalTypes.MODAL_TYPE_ALERT,
      },
    });
  } catch (error) {
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* handleTemplateView({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield put({ type: types.STATUS_TEMPLATE_SHOW_SUCCESS, payload });

  } catch (error) {
    yield put({ type: types.STATUS_TEMPLATE_SHOW_ERROR, payload });
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_TEMPLATE_LIST_REQUEST, getTemplateListSaga),
    yield takeLatest(types.GET_SINGLE_TEMPLATE_REQUEST, getSingleTemplateSaga),
    yield takeLatest(types.CREATE_TEMPLATE_REQUEST, createTemplateSaga),
    yield takeLatest(types.UPDATE_TEMPLATE_REQUEST, updateTemplateSaga),
    yield takeLatest(types.UPDATE_SINGLE_TEMPLATE_REQUEST, updateSingleTemplateSaga),
    yield takeLatest(types.STATUS_TEMPLATE_SHOW_REQUEST, handleTemplateView),
    yield takeLatest(types.CLONE_TEMPLATE_REQUEST, cloneTemplateSaga),
    yield takeLatest(types.DELETE_TEMPLATE_REQUEST, deleteTemplateSaga),
    yield takeLatest(types.DESELECT_TEMPLATE_REQUEST, deselectTemplateSaga),
    yield takeLatest(types.APPLY_TEMPLATE_REQUEST, applyTemplateSaga),
  ]);
}
