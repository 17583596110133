import axios from 'axios';

import { API_URL } from '../constants';

const settings = {
  async getTimezones() {
    try {
      const result = await axios.get(`${API_URL}/reminders/timezones/`);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default settings;
