import * as types from './questionnaireActionsTypes';
import * as authTypes from '../auth/authActionsTypes';

export const initialState = {
  questionnaireList: {
    results: [],
  },
  singleQuestionnaire: {
    sections: [],
  },
  questionnaireGroup: [],
  sections: {
    results: [],
  },
  currentUserTest: {},
  processing: false,
  error: null,
};

const questionnaire = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };

    case types.GET_SIGN_UP_QUESTIONNAIRE_SUCCESS:
    case types.GET_QUESTIONNAIRE_QUESTIONS_SUCCESS:
      return {
        ...state,
        singleQuestionnaire: {
          ...action.payload,
          questions: action.payload?.questions?.map((question) => ({
            ...question,
            answers: question?.answers?.map((answer) => ({
              ...answer,
              title: answer.title === 'Немає' ? 'Ні' : answer.title
            }))
          })) || []
        },
        error: null,
      };

    case types.GET_QUESTIONNAIRE_LIST_SUCCESS:
      return {
        ...state,
        questionnaireList: action.payload,
        error: null,
      };

    case types.GET_QUESTIONNAIRE_GROUP_SUCCESS:
      return {
        ...state,
        questionnaireGroup: action.payload,
        error: null,
      };

    case types.GET_QUESTIONNAIRE_SECTIONS_SUCCESS:
      return {
        ...state,
        sections: action.payload,
        error: null,
      };

    case types.CREATE_USER_TEST_SUCCESS:
      return {
        ...state,
        currentUserTest: action.payload,
        error: null,
      };

    case types.QUESTIONNAIRE_REQUEST_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case types.CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    case types.CLEAR_USER_TEST_ACTION:
    case authTypes.LOGOUT_REQUEST:
      return initialState;

    default:
      return state;
  }
};

export default questionnaire;
