import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

export const FOOTER_HEIGHT = 60;

const useStyles = makeStyles((theme) => {
  return {
    footer: {
      minHeight: `${FOOTER_HEIGHT}px`,
      padding: '20px 0',
      textAlign: 'center',
      color: theme.palette.text.primary,
    },
    footerLink: {
      '&:not(:last-child)': {
        marginRight: '40px',
        paddingRight: '40px',
        borderRight: theme.palette.type === 'dark' ? '2px solid #f5f5f5' : '2px solid #000000',
      },
    },
  };
});

const AuthorizedFooter = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.footer}>
      <Link className={classes.footerLink} to="/feedback">
        {t('m_feedback')}
      </Link>
      <Link className={classes.footerLink} to="/our-mission">
        {t('m_our_mission')}
      </Link>
    </div>
  );
};

export default AuthorizedFooter;
