import React from 'react';

// import 'react-quill/dist/quill.snow.css'; // Quill's CSS

function QuillEditor({ className, placeholder, value, onChange }) {
  if (typeof window !== 'undefined') {
    const ReactQuill = require('react-quill');
    return (
      <ReactQuill
        className={className}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        theme="snow" // Choose a Quill theme, e.g., 'snow'
      />
    );
  }
  
  return <input type="textarea" />;
}

export default QuillEditor;
