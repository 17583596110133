import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Box, Button, DialogActions, makeStyles } from '@material-ui/core';
import { applyTemplateRequest } from '../../../redux/template/templateActions';
import AchievementsGroup from '../AchievementsGroup/AchievementsGroup';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { closeWelcomeModalRequest } from '../../../redux/auth/authActions';

const useStyles = makeStyles((theme) => ({
  group: {
    '& .achievement__container': {
      display: 'flex',
      gap: '40px',
      justifyContent: 'center',
    },
    marginBottom: '20px',
  },
  welcomeTitle: {
    fontSize: '16px',
    fontWeight: 500,
    textAlign: 'center',
    marginBottom: '32px',
  },
  actionContainer: {
    width: '100%',
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    height: '51px',
    marginLeft: '16px',
    fontSize: '16px',
    textTransform: 'none',
    background: theme.palette.type === 'dark' ? '#406BE1' : 'linear-gradient(90deg, #0035FF 0%, #00C1FF 100%)',
  },
}));

const ModalWelcome = ({ hideModal, closeWelcomeModal }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [isExistCompleted, setIsExistCompleted] = useState(false);

  return (
    <>
      <Box className={`form-input__group ${classes.group}`}>
        <div className={classes.welcomeTitle}>
          {isExistCompleted ? t('modal_welcome_completed_text') : t('modal_welcome_in-progress_text')}
        </div>
        <AchievementsGroup isModalCards={true} setIsExistCompleted={setIsExistCompleted} />
      </Box>
      <DialogActions>
        <div className={classes.actionContainer}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => {
              isExistCompleted ? history.push('/dashboard/achievements') : history.push('/personalized-plan');
              hideModal();
              closeWelcomeModal();
            }}
          >
            {isExistCompleted ? t('view_achievements') : t('daily_tasks')}
          </Button>
        </div>
      </DialogActions>
    </>
  );
};

ModalWelcome.propTypes = {
  hideModal: PropTypes.func,
};

export default connect(
  (state) => ({
    isProcessing: state.template.processing,
  }),
  {
    closeWelcomeModal: closeWelcomeModalRequest,
  }
)(ModalWelcome);
