export const API_URL = `${process.env.REACT_APP_API_URL}api`;
export const GOOGLE_OAUTH_REDIRECT_URL = `${process.env.REACT_APP_BASE_URL}/social-google`;
export const GOOGLE_OAUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;

export const QUESTION_TYPE_CHECKBOX = 'CHECKBOX';
export const QUESTION_TYPE_NUMERIC = 'NUMERIC';
export const QUESTION_TYPE_RADIO_BUTTON = 'RADIO_BUTTON';

export const FOCUS_POINT_LOW_TYPE = 'Low score';
export const FOCUS_POINT_HIGH_TYPE = 'High score';

export const QUESTIONNAIRE_STATUSES = ['completed', 'available', 'inactive'];
