export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const WELCOME_MODEL_CLEAR = 'WELCOME_MODEL_CLEAR';
export const WELCOME_MODEL_BADGES = 'WELCOME_MODEL_BADGES';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export const SET_NEW_PASSWORD_REQUEST = 'SET_NEW_PASSWORD_REQUEST';
export const SET_NEW_PASSWORD_SUCCESS = 'SET_NEW_PASSWORD_SUCCESS';

export const GET_SOCIAL_KEYS_REQUEST = 'GET_SOCIAL_KEYS_REQUEST';
export const GET_SOCIAL_KEYS_SUCCESS = 'GET_SOCIAL_KEYS_SUCCESS';

export const AUTH_REQUEST_ERROR = 'AUTH_REQUEST_ERROR';
export const CLEAR_ERROR = 'CLEAR_AUTH_ERROR';

export const TOGGLE_PROCESSING = 'TOGGLE_AUTH_PROCESSING';
export const TOGGLE_TOKEN_PROCESSING = 'TOGGLE_AUTH_TOKEN_PROCESSING';
