import * as types from './generalActionsTypes';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const systemTheme = typeof window !== 'undefined' ? window.matchMedia("(prefers-color-scheme: dark)") : {};
const systemThemeCondition = systemTheme?.matches ? 'dark' : 'light';

export const initialState = {
  theme: systemThemeCondition,
  isDarkTheme: typeof window !== 'undefined' ? systemTheme.matches : false,
  modal: {
    className: '',
    title: '',
    type: '',
    params: {},
  },
};

const setTheme = (state, isDarkTheme) => {
  const theme = isDarkTheme ? 'dark' : 'light';
  cookies.set('theme', theme, { path: '/' });

  return {
    ...state,
    theme: theme,
    isDarkTheme: isDarkTheme,
  };
};

const general = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_MODAL_ACTION:
      return {
        ...state,
        modal: {
          ...state.modal,
          ...action.payload,
        },
      };

    case types.TOGGLE_THEME_ACTION:
      return setTheme(state, action.payload);

    case types.HIDE_MODAL_ACTION:
      return {
        ...state,
        modal: {
          className: '',
          title: '',
          type: '',
          params: {},
        },
      };

    default:
      return state;
  }
};

export default general;
