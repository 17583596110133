import * as types from './templateActionsTypes';

export const getTemplateListRequest = (payload) => {
  return { type: types.GET_TEMPLATE_LIST_REQUEST, payload };
};

export const getSingleTemplateRequest = (payload) => {
  return { type: types.GET_SINGLE_TEMPLATE_REQUEST, payload };
};

export const createTemplateRequest = ({ successCallback, ...payload }) => {
  return { type: types.CREATE_TEMPLATE_REQUEST, payload, successCallback };
};

export const updateTemplateRequest = (payload) => {
  return { type: types.UPDATE_TEMPLATE_REQUEST, payload };
};

export const updateSingleTemplateRequest = (payload) => {
  return { type: types.UPDATE_SINGLE_TEMPLATE_REQUEST, payload };
};

export const templateViewRequest = (payload) => {
  return { type: types.STATUS_TEMPLATE_SHOW_REQUEST, payload };
}

export const cloneTemplateRequest = (payload) => {
  return { type: types.CLONE_TEMPLATE_REQUEST, payload };
};

export const deleteTemplateRequest = (payload) => {
  return { type: types.DELETE_TEMPLATE_REQUEST, payload };
};

export const deselectTemplateRequest = (payload) => {
  return { type: types.DESELECT_TEMPLATE_REQUEST, payload };
};

export const applyTemplateRequest = (payload) => {
  return { type: types.APPLY_TEMPLATE_REQUEST, payload };
};

export const clearTemplateAction = () => {
  return { type: types.CLEAR_SINGLE_TEMPLATE_ACTION };
};

export const setTemplateParamsAction = (payload) => {
  return { type: types.SET_TEMPLATE_PARAMS_ACTION, payload };
};
