import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    height: '100%',
    minHeight: '160px',
    padding: '18px 0',
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.15)' : '#FFFFFF',
    borderRadius: '8px',
  },
  noChartContainer: {
    height: 'calc(100% - 76px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontStyle: 'italic',
    fontWeight: 300,
    letterSpacing: '1px',
    textAlign: 'center',
    opacity: 0.8,
  },
  chartText: {
    fontSize: '12px',
    color: theme.palette.text.primary,
  },
  focusPoints: {
    fontSize: '12px',
    color: theme.palette.text.primary,
    position: 'relative',
  },
  textChartLabel: {
    color: theme.overrides.textChartLabel,
  },
  textChartPoints: {
    marginTop: '16px',
    color: theme.overrides.textChartPoints,
    padding: '18px 18px 0',
    borderTop: theme.palette.type === 'dark' ? '1px solid #FFFFFF' : '1px solid #F5F5F5',
  },
}));

const UserTestChartItem = ({ item, isDarkTheme }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getChartOptions = () => ({
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: isDarkTheme ? '#FFFFFF' : '#757575',
            maxTicksLimit: 7,
          },
          gridLines: {
            color: isDarkTheme ? 'rgba(242, 242, 242, 0.06)' : '#F5F5F5',
            zeroLineColor: isDarkTheme ? 'rgba(242, 242, 242, 0.06)' : '#F5F5F5',
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            padding: 0,
            maxTicksLimit: 5,
            fontColor: isDarkTheme ? '#FFFFFF' : '#757575',
          },
          gridLines: {
            color: isDarkTheme ? 'rgba(242, 242, 242, 0.06)' : '#F5F5F5',
            zeroLineColor: isDarkTheme ? 'rgba(242, 242, 242, 0.06)' : '#F5F5F5',
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    hover: {
      mode: false,
    },
    tooltips: {
      enabled: false,
    },
  });

  const lastData = item.data[item.data.length - 1] || {};
  const nextToTheLastData = item.data[item.data.length - 2] || {};
  const diff = Math.round(
    ((lastData.cumulativeResult - nextToTheLastData.cumulativeResult) * 100) / Math.max(lastData.maxResult, nextToTheLastData.maxResult)
  );

  const options = getChartOptions(item);
  const data = (canvas) => {
    const chart = canvas.getContext('2d');
    const gradient = chart.createLinearGradient(0, 0, 0, 166);
    gradient.addColorStop(0, diff < 0 ? 'rgba(243, 57, 53, 0.7)' : 'rgba(1, 220, 89, 1)');
    gradient.addColorStop(0.5, diff < 0 ? 'rgba(243, 57, 53, 0.4)' : 'rgba(1, 220, 89, 0.5)');
    gradient.addColorStop(0.6, diff < 0 ? 'rgba(243, 57, 53, 0.1)' : 'rgba(1, 220, 89, 0.4)');
    gradient.addColorStop(0.8, 'rgba(255, 255, 255, 0)');

    return {
      labels: item.data.map(
        ({ createdAt }) => typeof createdAt === 'number' ? moment(createdAt * 1000).format('MMM DD') : ''
      ),
      datasets: [
        {
          borderColor: diff < 0 ? '#F33935' : '#01DC59',
          borderWidth: 2,
          backgroundColor: gradient,
          data: item.data.map((x) => x.cumulativeResult),
        },
      ],
    };
  };

  return (
    <Grid item xs={12} sm={6} lg={4}>
      <div className={classes.chartContainer}>
        {item.data.length > 2 ? (
          <>
            <Box display="flex" justifyContent="space-between" flexWrap="noWrap" style={{ padding: '0 22px 18px' }}>
              <Typography variant="body1" className={classes.textChartLabel}>
                {item.name}
              </Typography>
              {typeof diff === 'number' && (
                <div style={{ color: diff < 0 ? '#F33935' : '#01DC59' }}>
                  {`${diff < 0 ? '-' : diff > 0 ? '+' : ''}${Math.abs(diff)}%`}
                </div>
              )}
            </Box>
            <div style={{ minHeight: '110px', position: 'relative', padding: '0 18px' }}>
              <Line id={`chart-${item.id}`} options={options} data={data} lineTension={0} />
            </div>
          </>
        ) : (
          <>
            <Typography variant="body1" className={classes.textChartLabel} style={{ padding: '0 18px' }}>
              {item.name}
            </Typography>
            <div className={classes.noChartContainer}>
              <span style={{ maxWidth: '205px' }}>{t('dashboard_chart_placeholder')}</span>
            </div>
          </>
        )}
        <Box
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
          flexWrap="noWrap"
          className={classes.textChartPoints}
        >
          <div className={classes.chartText}>{t('dashboard_today_questionnaire')}</div>
          <div className={classes.focusPoints}>
            <div>
              <span>{Math.round(lastData.result)}</span>
              <span style={{ padding: '0 5px' }}>{t('out_of')}</span>
              <span>{lastData.maxResult}</span>
            </div>
          </div>
        </Box>
      </div>
    </Grid>
  );
};

UserTestChartItem.propTypes = {
  item: PropTypes.object.isRequired,
  isDarkTheme: PropTypes.bool.isRequired,
};

export default UserTestChartItem;
