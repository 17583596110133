import { Box, makeStyles, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { theme } from '../providers/ThemeProvider';

const useStyles = makeStyles(() => ({
  previewImage: {
    height: 320,
    width: '100%',
    objectFit: 'cover',
  },
  previewImageMobile: {
    height: 200,
    width: '100%',
    objectFit: 'cover',
  },
}));

const RandomHeaderImage = ({ article }) => {
  const classes = useStyles();
  const isMobileVersion = useMediaQuery(theme.breakpoints.down('xs'));

  if (!article) {
    return null;
  }

  const selectImage = () => {
    if (article?.malePreview && article?.femalePreview) {
      const randomBoolean = Math.random() < 0.5;

      return randomBoolean ? article.malePreview : article.femalePreview;
    }

    return article?.malePreview || article?.femalePreview;
  };

  return (
    <Box>
      {(article?.malePreview || article?.femalePreview) && (
        <img
          className={isMobileVersion ? classes.previewImageMobile : classes.previewImage}
          src={selectImage()}
          alt="Article image"
        />
      )}
    </Box>
  );
};

RandomHeaderImage.propTypes = {
  article: PropTypes.object.isRequired,
};

export default connect((state) => ({
  article: state.blog.singleArticle || null,
}))(RandomHeaderImage);
