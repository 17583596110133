import { makeStyles, Box, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 90,
    padding: '32px 13%',
  },
  title: {
    fontWeight: 600,
    fontSize: 45,
    color: '#304659',
  },
  subTitle: {
    fontWeight: 500,
    fontSize: 25,
    color: '#304659',
    marginBottom: 25,
  },
  infoText: {
    fontSize: 17,
    fontWeight: 400,
    color: '#3B5266',
    lineHeight: '25px',
    '& a': {
      color: '#263D79',
    }
  },
  space: {
    marginBottom: 40,
  },
  lastBlock: {
    marginBottom: 140,
  },
}));

const MAIL_TO_LINK = <a href="mailto:support@myndful.us">support@myndful.us</a>;


const PrivacyPolicy = () => {
  const classes = useStyles();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  return (
    <div className={classes.container}>
      <Typography className={`${classes.title} ${classes.space}`}>Privacy Policy</Typography>
      <Box className={`${classes.infoText} ${classes.space}`}>
        <p>
          We value your trust and are committed to protecting your privacy. This page is used to inform visitors
          regarding
          our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our
          Service.
        </p>
        <p>
          We will only use your personal information to provide you with the best possible service and to improve our
          products and services. We will not share your personal information with third parties without your consent.
        </p>
        <p>
          We reserve the right to use, distribute, modify, or store your personal information in order to provide you
          with the best possible service. For example, we may use your personal information to personalize your
          experience on our website, to communicate with you about our products and services, or to improve our products
          and services.
        </p>
        <p>
          We also reserve the right to use your personal information for research and analysis. This research and
          analysis may help us to improve our products and services, to develop new products and services, or to better
          understand our customers.
        </p>
        <p>
          If you choose to use our Service, then you agree to the collection and use of information in relation to this
          policy. The Personal Information that we collect is used for providing and improving the Service. We will not
          use or share your information with anyone except as described in this Privacy Policy.
        </p>
        <p>
          The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is
          accessible at Myndful - Habit tracker & Routine Planner unless otherwise defined in this Privacy Policy.
        </p>
        <p>
          We take your privacy seriously and we will only use your personal information in ways that are consistent with
          this Privacy Policy. If you have any questions about this Privacy Policy, please contact us at {MAIL_TO_LINK}.
        </p>
      </Box>

      <Typography className={classes.subTitle}>Information Collection and Use</Typography>
      <Box className={`${classes.infoText} ${classes.space}`}>
        <p>
          When you access or use our Services, we automatically collect certain information from you and/or your device
          (which may be considered personal information under applicable data protection laws). We collect this
          information with either your explicit consent in accordance with GDPR or as part of our legitimate interest in
          accordance with GDPR.
        </p>
        <ul>
          <li>
            The application provides an option to create an account to give you a better experience. Your habits and
            your progress are stored only locally on your device and all calculations are done on your device as well.
            Premium users can backup their data on the cloud and they will be able to restore their data (habits &
            progresses) when they switch to a new device.
          </li>
          <li>
            We obtain your email address & your full name (your public info attached with your Google account) when you
            log in using Google. We could use your email to identify you when you contact us through the chat to provide
            you better customized support. We don't collect any personal identifiable information. The information that
            we could request in the future will be retained by us and used as described in this privacy policy.
          </li>
          <li>
            The app does use third party services that may collect information used to identify you. These services
            include:
            <ul>
              <li>
                Google Analytics: This service collects information about how you use our website, such as the pages you
                visit and the links you click. This information is used to improve our website and to provide you with
                better service.
              </li>
              <li>
                Facebook Pixel: This service collects information about how you use our website, such as the pages you
                visit and the links you click. This information is used to improve our website and to provide you with
                better service.
              </li>
              <li>
                Google AdSense: This service displays advertisements on our website. These advertisements may collect
                information about your browsing habits, such as the pages you visit and the links you click. This
                information is used to target the advertisements that you see.
              </li>
            </ul>
          </li>
        </ul>
      </Box>

      <Typography className={classes.subTitle}>How We Use Your Personal Information</Typography>
      <Box className={`${classes.infoText} ${classes.space}`}>
        <p>
          We use the information we collect to:
        </p>
        <ul>
          <li>
            Provide you with the Services you request
          </li>
          <li>
            Communicate with you about the Services
          </li>
          <li>
            Improve the Services
          </li>
          <li>
            Conduct research and analysis
          </li>
          <li>
            Comply with applicable laws and regulations
          </li>
        </ul>
      </Box>

      <Typography className={classes.subTitle}>Sharing Your Personal Information</Typography>
      <Box className={`${classes.infoText} ${classes.space}`}>
        <p>
          We may share your personal information with:
        </p>
        <ul>
          <li>
            Our service providers, who help us provide the Services to you
          </li>
          <li>
            Third-party vendors, who help us with marketing and advertising
          </li>
          <li>
            Government agencies, if required by law
          </li>
        </ul>
      </Box>

      <Typography className={classes.subTitle}>Your Rights</Typography>
      <Box className={`${classes.infoText} ${classes.space}`}>
        <p>
          You have the right to:
        </p>
        <ul>
          <li>
            Access your personal information
          </li>
          <li>
            Correct any inaccurate personal information
          </li>
          <li>
            Delete your personal information
          </li>
          <li>
            Object to the processing of your personal information
          </li>
          <li>
            Restrict the processing of your personal information
          </li>
          <li>
            Port your personal information to another company
          </li>
        </ul>
        <p>
          We offer a premium subscription that gives you access to additional features and benefits. When you sign up
          for a premium subscription, we will collect your credit card information in order to process your payment.
        </p>
        <p>
          We use your credit card information to process your payment and to keep your account active. We also use your
          credit card information to prevent fraud and to protect your account.
        </p>
        <p>
          You can cancel your premium subscription at any time. To cancel your subscription, please contact us
          at {MAIL_TO_LINK}.
        </p>
      </Box>

      <Typography className={classes.subTitle}>How to Contact Us</Typography>
      <Box className={`${classes.infoText} ${classes.space}`}>
        <p>
          If you have any questions about this Privacy Policy, please contact us at: {MAIL_TO_LINK}.
        </p>
      </Box>

      <Typography className={classes.subTitle}>Changes to This Privacy Policy</Typography>
      <Box className={`${classes.infoText} ${classes.space}`}>
        <p>
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new
          Privacy Policy on our website.
        </p>
      </Box>

      <Typography className={classes.subTitle}>California Consumer Privacy Act (CCPA)</Typography>
      <Box className={`${classes.infoText} ${classes.space}`}>
        <p>
          If you are a California resident, you have the following rights under the CCPA:
        </p>
        <ul>
          <li>
            The right to know what personal information we collect about you
          </li>
          <li>
            The right to have your personal information deleted
          </li>
          <li>
            The right to opt out of the sale of your personal information
          </li>
          <li>
            The right to non-discrimination for exercising your CCPA rights
          </li>
        </ul>
        <p>
          To exercise your CCPA rights, please contact us at: {MAIL_TO_LINK}.
        </p>
      </Box>

      <Typography className={classes.subTitle}>General Data Protection Regulation (GDPR)</Typography>
      <Box className={`${classes.infoText} ${classes.space}`}>
        <p>
          If you are a European resident, you have the following rights under the GDPR:
        </p>
        <ul>
          <li>
            The right to access your personal information
          </li>
          <li>
            The right to have your personal information corrected
          </li>
        </ul>
        <p>
          To exercise your CCPA rights, please contact us at:
        </p>
        <ul>
          <li>
            The right to have your personal information deleted
          </li>
          <li>
            The right to restrict the processing of your personal information
          </li>
          <li>
            The right to object to the processing of your personal information
          </li>
          <li>
            The right to data portability
          </li>
          <li>
            The right to lodge a complaint with a supervisory authority
          </li>
        </ul>
        <p>
          To exercise your GDPR rights, please contact us at: {MAIL_TO_LINK}.
        </p>
      </Box>

      <Typography className={classes.subTitle}>Security</Typography>
      <Box className={`${classes.infoText} ${classes.space}`}>
        <p>
          We take reasonable security measures to protect your personal information from unauthorized access, use, or
          disclosure. However, no security system is perfect, and we cannot guarantee the absolute security of your
          personal information.
        </p>
      </Box>

      <Typography className={classes.subTitle}>Children</Typography>
      <Box className={`${classes.infoText} ${classes.space}`}>
        <p>
          Our website is not intended for children under the age of 13. We do not knowingly collect personal information
          from children under the age of 13. If you are under the age of 13, please do not provide us with any personal
          information. If we learn that we have collected personal information from a child under the age of 13, we will
          delete that information as soon as possible.
        </p>
      </Box>

      <Typography className={classes.subTitle}>Changes to This Privacy Policy</Typography>
      <Box className={`${classes.infoText} ${classes.space}`}>
        <p>
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new
          Privacy Policy on our website. You should check our website periodically to see if there have been any changes
          to this Privacy Policy. Your continued use of our website after we post any changes to this Privacy Policy
          will constitute your acceptance of those changes.
        </p>
      </Box>

      <Typography className={classes.subTitle}>Contact Us</Typography>
      <Box className={`${classes.infoText} ${classes.space}`}>
        <p>
          If you have any questions about this Privacy Policy, please contact us at: {MAIL_TO_LINK}.
        </p>
      </Box>
    </div>
  );
};

export default PrivacyPolicy;
