import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, DialogActions, DialogContentText, makeStyles, TextField } from '@material-ui/core';

import { deleteUserSubscriptionRequest } from '../../../redux/subscription/subscriptionActions';
import { hideModalAction } from '../../../redux/general/generalActions';

const useStyles = makeStyles((theme) => ({
  button: {
    color: '#FFFFFF',
    background: theme.palette.type === 'dark' ? '#406BE1' : 'linear-gradient(90deg, #0035FF 0%, #00C1FF 100%)',
  },
  stayButton: {
    color: theme.overrides.textBlue,
  },
}));


const ModalDowngradePlan = ({ deleteUserSubscription, hideModal }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [checkWord, setCheckWord] = useState('');

  return (
    <>
      <DialogContentText>
        {t('modal_plan_title_1')}
      </DialogContentText>
      <Box className="form-input__group">
        <TextField
          variant="outlined"
          fullWidth
          id="checkWord"
          label="Type word"
          name="checkWord"
          onChange={(evt) => setCheckWord(evt.target.value)}
        />
      </Box>
      <DialogActions>
        <Button className={classes.stayButton} color="primary" onClick={hideModal}>
          {t('modal_plan_stay_premium')}
        </Button>
        <Button
          className={classes.button}
          type="submit"
          disabled={checkWord !== 'Myndful'}
          variant="contained"
          color="primary"
          onClick={deleteUserSubscription}
        >
          {t('modal_plan_downgrade_basic')}
        </Button>
      </DialogActions>
    </>
  );
};

ModalDowngradePlan.propTypes = {
  deleteUserSubscription: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default connect(null, {
  deleteUserSubscription: deleteUserSubscriptionRequest,
  hideModal: hideModalAction,
})(ModalDowngradePlan);
