import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNumberString, length } from 'class-validator';
import { Box, Button, DialogActions, makeStyles } from '@material-ui/core';

import {
  sendPhoneTokenRequest,
  verifyPhoneTokenRequest,
  clearErrorAction,
} from '../../../redux/profile/profileActions';
import { hideModalAction } from '../../../redux/general/generalActions';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: '-12px',
    fontSize: '1.5rem',
  },
  text: {
    padding: '16px 0 50px',
    color: theme.palette.text.primary,
    '& span': {
      marginLeft: '6px',
      color: '#FFFFFF',
      fontWeight: 300,
    },
  },
  inputWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
    width: '16.81rem',
    margin: '0 auto 24px',
  },
  input: {
    border: 'none',
    background: 'transparent',
    outline: 'none',
    color: 'transparent',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 2,
  },
  inputDivs: {
    color: theme.palette.text.primary,
    display: 'flex',
    justifyContent: 'space-between',
    '& div': {
      borderBottom: `2px solid ${theme.palette.text.primary}`,
      fontSize: '1.5rem',
      width: '2.385rem',
      textAlign: 'center',
      height: '34px',
      '&:not(:last-child)': {
        marginRight: '0.5rem',
      },
    },
  },
  helperText: {
    position: 'absolute',
    left: 0,
    bottom: '-20px',
    fontSize: '0.75rem',
    color: theme.palette.text.primary,
  },
  button: {
    minWidth: '104px',
    marginLeft: '16px',
    background: theme.palette.type === 'dark' ? '#406BE1' : 'linear-gradient(90deg, #0035FF 0%, #00C1FF 100%)',
  },
  cancelButton: {
    minWidth: '104px',
    marginLeft: '16px',
  },
  color: {
    color: theme.overrides.textBlue,
  },
  resend: {
    margin: '0 auto',
    textTransform: 'capitalize',
  },
}));

const ModalVerifyPhone = ({ phone, isProcessing, sendPhoneToken, verifyPhoneToken, hideModal, error, clearError }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [value, setValue] = useState('');

  const { handleSubmit, register, errors } = useForm({
    defaultValues: {
      token: '',
    },
    reValidateMode: 'onChange',
  });

  const verifyPhoneNumber = (data) => {
    verifyPhoneToken({ token: data.token });
  };

  const tokenValidator = (token) => {
    const isNumbers = isNumberString(token);
    const isSixSymbols = length(token, 6, 6);
    if (isNumbers && isSixSymbols) return true;

    if (!isNumbers) {
      return t('modal_phone_only_numbers');
    }

    if (!isSixSymbols) {
      return t('modal_phone_6_numbers');
    }

    return t('modal_phone_incorrect_code');
  };

  const array = value.split('');
  const nodes = Array(6)
    .fill(0)
    .map((x, i) => <div key={i}>{array[i] || ''}</div>);

  const onCancel = () => {
    hideModal();
    if (error) {
      clearError();
    }
  };

  return (
    <>
      <div className={classes.title}>{t('modal_phone_verification_code')}</div>
      <div className={classes.text}>
        {t('modal_phone_enter_code')}
        {phone ? <span>{phone}</span> : ` ${t('modal_phone_your_phone')}`}
      </div>
      <form onSubmit={handleSubmit(verifyPhoneNumber)}>
        <Box className={classes.inputWrapper}>
          <div className={classes.inputDivs}>{nodes}</div>
          <input
            type="text"
            className={classes.input}
            name="token"
            id="token"
            ref={(instance) => register(instance, { validate: tokenValidator })}
            autoComplete="off"
            maxLength={6}
            onChange={(evt) => {
              if (error) clearError();
              setValue(evt.target.value);
            }}
          />
          {!!errors.token && <div className={classes.helperText}>{errors.token.message}</div>}
          {error && error?.token.length && <div className={classes.helperText}>{error.token[0]}</div>}
        </Box>
        <Box display="flex" justifyContent="center" pt={1} pb={3}>
          <Button
            className={`${classes.cancelButton} ${classes.color} ${classes.resend}`}
            color="primary"
            onClick={() => sendPhoneToken(true)}
          >
            {t('modal_phone_resend_code')}
          </Button>
        </Box>
        <DialogActions>
          <Button
            className={`${classes.button} ${classes.color}`}
            disabled={isProcessing}
            color="primary"
            onClick={onCancel}
          >
            {t('cancel')}
          </Button>
          <Button className={classes.button} type="submit" disabled={isProcessing} variant="contained" color="primary">
            {t('verify')}
          </Button>
        </DialogActions>
      </form>
    </>
  );
};

ModalVerifyPhone.propTypes = {
  phone: PropTypes.string,
  isProcessing: PropTypes.bool.isRequired,
  sendPhoneToken: PropTypes.func.isRequired,
  verifyPhoneToken: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  clearError: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    phone: state.profile.data.phone,
    isProcessing: state.profile.processing,
    error: state.profile.error,
  }),
  {
    sendPhoneToken: sendPhoneTokenRequest,
    verifyPhoneToken: verifyPhoneTokenRequest,
    hideModal: hideModalAction,
    clearError: clearErrorAction,
  }
)(ModalVerifyPhone);
