import React, { useEffect } from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  DialogActions,
  DialogContentText,
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { IconClock } from '../../../assets/inline-images';
import IconCalendar from '../../../assets/inline-images/IconCalendar';

const SCHEDULE_OPTIONS = [
  { value: 'MONDAY', label: 'MON' },
  { value: 'TUESDAY', label: 'TUE' },
  { value: 'WEDNESDAY', label: 'WED' },
  { value: 'THURSDAY', label: 'THU' },
  { value: 'FRIDAY', label: 'FRI' },
  { value: 'SATURDAY', label: 'SAT' },
  { value: 'SUNDAY', label: 'SUN' },
];

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: '104px',
    marginLeft: '16px',
    background: theme.palette.type === 'dark' ? '#406BE1' : 'linear-gradient(90deg, #0035FF 0%, #00C1FF 100%)',
  },
  cancelButton: {
    minWidth: '104px',
    marginLeft: '16px',
  },
  color: {
    color: theme.overrides.textBlue,
  },
  categoryItem: {
    marginTop: 5,
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: 10,
    },
  },
  scheduleWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '30px',
    width: '100%',
  },
  scheduleItem: {
    display: 'flex',
  },
  scheduleLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '45px',
    minWidth: '45px',
    height: '45px',
    fontSize: '12px',
    borderRadius: '50%',
    overflow: 'hidden',
    cursor: 'pointer',
    transition: 'all 0.2s',
    borderWidth: '2px',
    borderStyle: 'solid',
  },
  scheduleInput:
    theme.palette.type === 'dark'
      ? {
          display: 'none',
          '& + span': {
            color: 'rgba(255, 255, 255, 0.5)',
          },
          '&:hover + span': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
            color: 'rgba(255, 255, 255, 0.8)',
            borderColor: 'rgba(255, 255, 255, 0.8)',
          },
          '&:checked + span': {
            backgroundColor: 'rgba(255, 255, 255, 0.16)',
            color: '#fff',
            borderColor: '#fff',
          },
        }
      : {
          display: 'none',
          '& + span': {
            color: '#AEAEAE',
            borderColor: 'rgba(0, 0, 0, 0.04)',
          },
          '&:hover + span': {
            borderColor: 'rgba(0, 0, 0, 0.07)',
            backgroundColor: 'rgba(0, 0, 0, 0.02)',
          },
          '&:checked + span': {
            backgroundColor: 'rgba(0, 0, 0, 0.15)',
            color: '#757575',
            borderColor: 'transparent',
          },
        },
  timePicker: {
    '& input[type="time"]::-webkit-calendar-picker-indicator': {
      background: 'none',
    },

    '& input[type="time"]': {
      zIndex: '0',
      position: 'relative',
    },

    '& input[type="time"]:after': {
      content: "''",
      backgroundImage: `url(data:image/svg+xml,${encodeURIComponent(
        ReactDOMServer.renderToStaticMarkup(
          <IconClock currentColor={theme.palette.type === 'dark' ? '#fff' : '#000'} />
        )
      )})`,
      height: '20px',
      width: '20px',
      backgroundSize: 'contain',
      zIndex: '-1',
      position: 'absolute',
      right: '10px',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
    },

  },
  datePicker: {
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      background: 'none',
    },

    '& input[type="date"]': {
      zIndex: '0',
      position: 'relative',
    },

    '& input[type="date"]:after': {
      content: "''",
      backgroundImage: `url(data:image/svg+xml,${encodeURIComponent(
        ReactDOMServer.renderToStaticMarkup(
          <IconCalendar currentColor={theme.palette.type === 'dark' ? '#fff' : '#000'} />
        )
      )})`,
      height: '20px',
      width: '20px',
      backgroundSize: 'contain',
      zIndex: '-1',
      position: 'absolute',
      right: '10px',
    },
  },
}));

const CustomGoalSchema = yup.object().shape({
  title: yup.string().required('Title can not be empty').max(255, 'Title must be at most 255 characters'),
  score: yup.number().transform((cv, ov) => {
    return ov === '' ? undefined : cv;
  }),
  sectionId: yup.string().required('Section is required.'),
  description: yup.string().max(255, 'Description must be at most 255 characters'),
});

const CustomGoalForm = ({ customGoal = {}, isProcessing = false, onCancel, onSubmit }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const sections = useSelector((state) => state.questionnaire.sections);

  const { handleSubmit, errors, control, register, reset, watch } = useForm({
    reValidateMode: 'onChange',
    validationSchema: CustomGoalSchema,
    defaultValues: {
      eventTime: '',
      eventDay: null,
      score: '',
      sectionId: '',
      frequency: 'DAILY',
      schedule: [],
    },
  });

  useEffect(() => {
    reset({
      ...customGoal,
      eventTime: customGoal.eventTime || null,
      eventDay: customGoal.eventDay || null,
      score: customGoal.score || '',
      sectionId: customGoal.sectionId || customGoal.section?.id || '',
      frequency: customGoal.frequency || 'DAILY',
      schedule: customGoal.schedule || [],
    });
  }, [customGoal]);

  const onSubmitHandler = (data) => {
    onSubmit({
      ...data,
      score: Number.parseInt(data.score, 10) || null,
      isChecked: false,
    });
  };
  const getDaysInCurrentMonth = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Months are zero-based, so add 1
  
    // To get the number of days in the current month, set the date to the 0th day of the next month
    const lastDayOfMonth = new Date(year, month, 0);
  
    return lastDayOfMonth.getDate();
  }
  

  return (
    <>
      <DialogContentText>{t('modal_custom_goal_title')}</DialogContentText>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Box className="form-input__group">
          <Controller
            as={
              <TextField
                variant="filled"
                fullWidth
                error={!!errors.title}
                helperText={errors.title ? errors.title.message : ''}
                id="title"
                label={t('form_label_goal_title')}
              />
            }
            name="title"
            control={control}
            defaultValue=""
          />
        </Box>
        <Box className="form-input__group max-width">
          <FormControl variant="filled" fullWidth error={!!errors.sectionId}>
            <InputLabel id="sectionId-label">{t('form_label_select_category')}</InputLabel>
            <Controller
              as={
                <Select
                  MenuProps={{
                    transitionDuration: 0,
                  }}
                  labelId="sectionId-label"
                  id="sectionId"
                  inputRef={register}
                >
                  {(sections.results || []).map((item) => (
                    <MenuItem key={`option-${item.id}`} value={item.id}>
                      <div className={classes.categoryItem}>
                        <img src={item.icon} width={24} alt="" /> {item.name}
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              }
              name="sectionId"
              control={control}
            />
            {!!errors.sectionId && <FormHelperText id="my-helper-text">{errors.sectionId.message}</FormHelperText>}
          </FormControl>
        </Box>
        <Box className="form-input__group max-width">
          <FormControl variant="filled" fullWidth error={!!errors.frequency}>
            <InputLabel id="sectionId-label">{t('form_label_select_frequency')}</InputLabel>
            <Controller
              as={
                <Select
                  MenuProps={{
                    transitionDuration: 0,
                  }}
                  labelId="frequency-label"
                  id="frequency"
                  inputRef={register}
                >
                  <MenuItem value="DAILY">
                    <div className={classes.categoryItem}>{t('daily')}</div>
                  </MenuItem>
                  <MenuItem value="WEEKLY">
                    <div className={classes.categoryItem}>{t('weekly')}</div>
                  </MenuItem>
                  <MenuItem value="MONTHLY">
                    <div className={classes.categoryItem}>{t('monthly')}</div>
                  </MenuItem>
                </Select>
              }
              name="frequency"
              control={control}
              defaultValue="DAILY"
            />
          </FormControl>
          {watch('frequency') === 'WEEKLY' && (
            <div className={classes.scheduleWrapper}>
              {SCHEDULE_OPTIONS.map(({ value, label }) => (
                <label key={value} className={classes.scheduleItem}>
                  <input
                    className={classes.scheduleInput}
                    name="schedule"
                    type="checkbox"
                    value={value}
                    ref={register}
                  />
                  <span className={classes.scheduleLabel}>{label}</span>
                </label>
              ))}
            </div>
          )}
        </Box>
        {watch('frequency') === 'MONTHLY' ? (
          <Box className="form-input__group">
            <Controller
              as={
                <TextField
                  id="number"
                  type="number"
                  variant="filled"
                  InputProps={{
                    inputProps: {
                      min: 1,
                      max: getDaysInCurrentMonth()
                    },
                  }}
                  className={classes.datePicker}
                  inputRef={register}
                  fullWidth
                  error={!!errors.eventDay}
                  helperText={errors.eventDay ? errors.eventDay : ''}
                  label={t('form_label_date')}
                />
              }
              InputLabelProps={{
                shrink: true,
              }}
              name="eventDay"
              control={control}
              defaultValue=""
            />
          </Box>
        ) : null}
        <Box className="form-input__group">
          <Controller
            as={
              <TextField
                id="time"
                type="time"
                variant="filled"
                inputRef={register}
                className={classes.timePicker}
                fullWidth
                error={!!errors.eventTime}
                defaultValue="17:00"
                helperText={errors.eventTime ? errors.eventTime.message : ''}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                label={t('form_label_time')}
              />
            }
            name="eventTime"
            control={control}
            defaultValue="17:00"
          />
        </Box>
        <Box className="form-input__group">
          <Controller
            as={
              <TextField
                type="number"
                variant="filled"
                inputRef={register}
                fullWidth
                error={!!errors.score}
                onKeyPress={(evt) => {
                  if ((evt.which !== 8 && evt.which !== 0 && evt.which < 48) || evt.which > 57) {
                    evt.preventDefault();
                  }
                }}
                helperText={errors.score ? errors.score.message : ''}
                id="score"
                label={t('form_label_number_of_points')}
              />
            }
            name="score"
            control={control}
            defaultValue=""
          />
        </Box>
        <Box className="form-input__group">
          <Controller
            as={
              <TextField
                variant="filled"
                inputRef={register}
                fullWidth
                error={!!errors.description}
                helperText={errors.description ? errors.description.message : ''}
                id="description"
                label={t('form_label_goal_description')}
                name="description"
                multiline={true}
                rows={3}
              />
            }
            name="description"
            control={control}
            defaultValue=""
          />
        </Box>
        <DialogActions>
          <Button
            className={`${classes.cancelButton} ${classes.color}`}
            disabled={isProcessing}
            color="primary"
            onClick={onCancel}
          >
            {t('cancel')}
          </Button>
          <Button className={classes.button} type="submit" disabled={isProcessing} variant="contained" color="primary">
            {customGoal.id ? t('update') : t('create')}
          </Button>
        </DialogActions>
      </form>
    </>
  );
};

CustomGoalForm.propTypes = {
  customGoal: PropTypes.object.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CustomGoalForm;
