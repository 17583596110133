import React from 'react';
import PropTypes from 'prop-types';

const IconDelete = ({ size = 24, className }) => {
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
        <path d="M4 5C4 4.44772 4.44772 4 5 4H19C19.5523 4 20 4.44772 20 5C20 5.55228 19.5523 6 19 6H5C4.44772 6 4 5.55228 4 5Z" />
        <path d="M8.77778 3.33333C8.77778 2.59695 9.37473 2 10.1111 2L13.8889 2C14.6253 2 15.2222 2.59695 15.2222 3.33333C15.2222 3.70152 15.5207 4 15.8889 4H16V6H15.8889C14.6463 6 13.6023 5.15015 13.3062 4L10.6938 4C10.3977 5.15015 9.35367 6 8.11111 6H8V4L8.11111 4C8.4793 4 8.77778 3.70152 8.77778 3.33333Z" />
        <path d="M5.91701 8.00351C6.46739 7.95765 6.95074 8.36663 6.9966 8.91701L7.76736 18.1661C7.85374 19.2027 8.72028 20.0001 9.76045 20.0001H14.2397C15.2798 20.0001 16.1464 19.2027 16.2327 18.1661L17.0035 8.91701C17.0494 8.36663 17.5327 7.95765 18.0831 8.00351C18.6335 8.04937 19.0425 8.53272 18.9966 9.0831L18.2258 18.3322C18.0531 20.4054 16.32 22.0001 14.2397 22.0001H9.76045C7.6801 22.0001 5.94704 20.4054 5.77427 18.3322L5.00351 9.0831C4.95765 8.53272 5.36663 8.04937 5.91701 8.00351Z" />
      </g>
    </svg>
  );
};

IconDelete.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

export default IconDelete;
