import * as types from './settingsActionTypes';

export const initialState = {
  timezones: [],
  processing: false,
  error: null,
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_PROCESSING:
      return { ...state, processing: action.payload };

    case types.GET_TIMEZONES_SUCCESS:
      return { ...state, timezones: action.payload };

    case types.SETTINGS_REQUEST_ERROR:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
};

export default settings;
