import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import moment from 'moment';

import ScoreResult from '../Questionnaire/ScoreResult';
import { SectionTitle } from '../shared/components/SectionTitle';
import ScoreResultSmall from '../Questionnaire/ScoreResultSmall';
import { FOCUS_POINT_LOW_TYPE } from '../../constants';
import { getSharedUserResultRequest, getSharedUsersRequest } from '../../redux/shared-access/sharedAccessActions';
import UserTestCharts from '../Dashboard/UserTestCharts';

const useStyles = makeStyles((theme) => {
  const isDark = theme.palette.type === 'dark';

  return {
    container: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    iconMessage: {
      marginBottom: '69px',
    },
    focusPoints: {
      marginBottom: '72px',
    },
    scoreDate: {
      marginTop: 'auto',
      '& span': {
        fontSize: '12px',
        color: isDark ? '#F2F2F2' : '#757575',
      },
    },
    improveBlock: {
      marginTop: 'auto',
    },
    scoreImprove: {
      display: '-webkit-inline-box',
      fontWeight: 600,
      alignItems: 'center',
      marginTop: '40px',
      color: theme.palette.text.primary,
      background: 'linear-gradient(90deg, #0035FF 0%, #198EFB 100%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: isDark ? 'unset' : 'transparent',
      '& img': {
        marginRight: '14px',
        position: 'relative',
        top: '2px',
      },
    },
    scoreInner: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '320px',
      height: '100%',
      minHeight: '365px',
      padding: '20px 24px',
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${isDark ? 'transparent' : '#F0F0F0'}`,
      borderRadius: '8px',
    },
    scoreTitle: {
      fontSize: '1.25rem',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '50px',
      '& img': {
        width: '23px',
        height: '23px',
        marginRight: '12px',
      },
    },
    scoreText: {
      display: 'flex',
      textAlign: 'center',
      marginTop: '8px',
      fontSize: '12px',
    },
    lowScore: {
      color: '#FE4A5E',
    },
    backButton: {
      marginRight: '32px',
      display: 'flex',
      width: '100px',
      padding: '16px',
      boxSizing: 'border-box',
      justifyContent: 'space-between',
      border: `1px solid ${isDark ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)'}`,
      borderRadius: '8px',
    },
  };
});

const SharedDashboard = ({ isDarkTheme, getSharedUserResult, sharedUserResult, sharedUsers, getSharedUsers }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { id } = useParams();

  const currentUser = useMemo(() => {
    return sharedUsers.find((user) => user.client === +id);
  }, [sharedUsers, id]);

  useEffect(() => {
    if (!sharedUsers.length) {
      getSharedUsers();
    }

    getSharedUserResult(id);
  }, []);

  const { improveIconSrc, backArrowIconSrc } = isDarkTheme
    ? { improveIconSrc: '/images/icon-improve.svg', backArrowIconSrc: '/images/icon-arrow-left-dark.svg' }
    : { improveIconSrc: '/images/icon-improve-light.svg', backArrowIconSrc: '/images/icon-arrow-left-light.svg' };

  return (
    <>
      <Box className={classes.container}>
        <Box display="flex" alignItems="center" my={3} mr="auto">
          <Link to="/shared-access" className={classes.backButton}>
            <img src={backArrowIconSrc} alt="back arrow" />
            {t('back')}
          </Link>
          <Typography variant="h5" component="h3">
            {currentUser?.clientUsername}'s {t('dashboard_summary')}
          </Typography>
        </Box>

        <Grid container spacing={4} className={classes.focusPoints}>
          <Grid item md={3} sm={6}>
            <div className={classes.scoreInner}>
              <div className={classes.scoreTitle}>{t('dashboard_total_score')}</div>
              <ScoreResult
                userResult={sharedUserResult.userResult}
                maxResult={100}
                size={200}
                showMaxResult
              />
              <div className={classes.scoreDate}>
                <span>{t('last_update')}:&nbsp;</span>
                <span>{moment(sharedUserResult.createdAt).format('DD MMM YYYY')}</span>
              </div>
            </div>
          </Grid>

          {sharedUserResult.focusPoints?.map((item) => (
            <Grid item md={3} sm={6} key={item.id}>
              <div className={classes.scoreInner}>
                <div className={classes.scoreTitle}>
                  <SectionTitle sectionName={item.sectionName} />
                  {item.sectionName}
                </div>
                <ScoreResultSmall
                  userResult={item.result}
                  maxResult={item.maxResult}
                  size={155}
                  showMaxResult
                />
                <div
                  className={classNames(classes.scoreText, {
                    [classes.lowScore]: item.notification?.type === FOCUS_POINT_LOW_TYPE,
                  })}
                >
                  {item.notification?.text}
                </div>
                <div className={classes.improveBlock}>
                  <Link to={`/how-to-improve/${item.sectionName}/`} className={classes.scoreImprove}>
                    <img src={improveIconSrc} alt="icon star" width="17" height="17" />
                    {t('dashboard_how_to_improve')}
                  </Link>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
        <UserTestCharts isSharedAccess />
      </Box>
    </>
  );
};

SharedDashboard.propTypes = {
  isDarkTheme: PropTypes.bool.isRequired,
  getSharedUserResult: PropTypes.func.isRequired,
  getSharedUsers: PropTypes.func.isRequired,
  sharedUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isActive: PropTypes.bool.isRequired,
      client: PropTypes.number.isRequired,
      clientUsername: PropTypes.string.isRequired,
      clientAge: PropTypes.number,
      clientSex: PropTypes.string,
      clientNationality: PropTypes.string,
    })
  ).isRequired,
  sharedUserResult: PropTypes.shape({
    id: PropTypes.number,
    createdAt: PropTypes.string,
    maxResult: PropTypes.number,
    title: PropTypes.string,
    userResult: PropTypes.number,
    focusPoints: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        result: PropTypes.number.isRequired,
        sectionName: PropTypes.string.isRequired,
        notification: PropTypes.shape({
          text: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
        }),
      })
    ),
  }),
};

export default connect(
  (state) => ({
    isDarkTheme: state.general.isDarkTheme,
    sharedUsers: state.sharedAccess.sharedUsers,
    sharedUserResult: state.sharedAccess.sharedUserResult,
  }),
  {
    getSharedUsers: getSharedUsersRequest,
    getSharedUserResult: getSharedUserResultRequest,
  }
)(SharedDashboard);
