import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, CssBaseline, makeStyles } from '@material-ui/core';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

const imageLogo = '/images/logo.svg';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: '434px',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  logo: {
    width: '150px',
    height: '150px',
    [theme.breakpoints.down('xs')]: {
      width: '100px',
      height: '100px',
    },
  },
  text: {
    marginBottom: '40px',
    fontSize: '48px',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '20px',
    },
  },
  form: {
    width: '100%',
    padding: '32px 32px 16px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2), 0 1px 18px rgba(0, 0, 0, 0.12), 0 6px 10px rgba(0, 0, 0, 0.14)',
  },
  logoLink: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const AuthLayout = ({ children, isAuthenticated }) => {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation();

  useEffect(() => {
    if (isAuthenticated) {
      if (state?.from) {
        history.push(state.from);
      } else {
        history.push('/personalized-plan');
      }
    }
  }, [isAuthenticated]);

  return (
    <div className="auth-layout">
      <Container maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <NavLink to="/" className={classes.logoLink}>
            <img src={imageLogo} className={classes.logo} alt="logo" />
            <div className={classes.text}>Myndful</div>
          </NavLink>
          {history.location.pathname === '/social-google' ? (
            <>{children}</>
          ) : (
            <div className={classes.form}>{children}</div>
          )}
        </div>
      </Container>
    </div>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.any,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default connect((state) => ({ isAuthenticated: state.auth.isAuthenticated }))(AuthLayout);
