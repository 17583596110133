import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Box, Grid, makeStyles, Switch } from '@material-ui/core';

import { updateProfileRequest } from '../../redux/profile/profileActions';

const useStyles = makeStyles((theme) => {
  const isDarkTheme = theme.palette.type === 'dark';

  return {
    blockStyles: {
      backgroundColor: theme.overrides.accordionBackground,
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      padding: '20px 36px',
      [theme.breakpoints.down('sm')]: {
        padding: '15px 20px',
      },
    },
    blockFlex: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    switchLabel: {
      display: 'inline-flex',
      fontSize: '14px',
      margin: '8px 0',
      cursor: 'default',
    },
    switchRoot: {
      width: 32,
      height: 18,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 2,
      color: '#FFF',
      '&$checked': {
        transform: 'translateX(14px)',
        color: isDarkTheme ? '#253E7B' : '#FFF',
        '& + $track': {
          backgroundColor: isDarkTheme ? '#C2C2C2' : '#007EFF',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#FFF',
        border: '6px solid lightgrey',
      },
    },
    focusVisible: {},
    thumb: {
      width: 14,
      height: 14,
    },
    track: {
      borderRadius: 26 / 2,
      backgroundColor: '#C2C2C2',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
  };
});

const EmailSubscription = ({
  profile,
  isProcessing,
  updateProfile,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isSubscription, setIsSubscription] = useState(!!profile.hasArticleSubscription);

  useEffect(() => {
    setIsSubscription(!!profile.hasArticleSubscription);
  }, [profile]);

  const onChangeSubscription = (evt) => {
    updateProfile({ ...profile, hasArticleSubscription: evt.target.checked });
  };

  return (
    <div className={classNames(classes.blockStyles, 'main-form__section')}>
      <Box>
        <Grid container display="flex" alignItems="center">
          <Grid item xs={12} sm={6}>
            <div style={{ fontSize: 20 }}>{t('profile_email_subscriptions')}</div>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.blockFlex}>
            <Switch
              classes={{
                root: classes.switchRoot,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
              }}
              color="default"
              checked={isSubscription}
              disabled={isProcessing}
              onChange={onChangeSubscription}
            />
            <p className={classes.switchLabel}>
              {t('enable')}
            </p>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

EmailSubscription.propTypes = {
  profile: PropTypes.object.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  error: PropTypes.object,
  isDarkTheme: PropTypes.bool,
  updateProfile: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    profile: state.profile.data,
    isProcessing: state.profile.processing,
    error: state.profile.error || {},
    isDarkTheme: state.general.isDarkTheme,
  }),
  {
    updateProfile: updateProfileRequest,
  }
)(EmailSubscription);
