import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import PhoneInput from 'react-phone-input-2';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import * as yup from 'yup';
import { isPhoneNumber } from 'class-validator';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  Breadcrumbs,
} from '@material-ui/core';

import PasswordField from '../shared/components/PasswordField';
import Notification from './Notification';
import EmailSubscription from './EmailSubscription';
import GoogleCalendarSubscription from './GoogleCalendarSubscription';
import Subscription from './Subscription';
import SharedAccess from './SharedAccess';
import { showModalAction } from '../../redux/general/generalActions';
import {
  clearErrorAction, getProfileRequest,
  sendPhoneTokenRequest,
  updatePasswordRequest,
  updatePhoneRequest,
  updateProfileRequest,
} from '../../redux/profile/profileActions';
import { API_URL, ISO31661ALPHA2 } from '../../constants';

const useStyles = makeStyles((theme) => ({
  content: {
    maxWidth: '825px',
    margin: '0 auto',
    padding: '24px 0',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  blockStyles: {
    backgroundColor: theme.overrides.accordionBackground,
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    marginBottom: '46px',
  },
  formContainer: {
    padding: '20px 36px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px 20px',
    },
    '& .react-tel-input .form-control': {
      padding: '15.5px 14px 16px 58px'
    },
    '& .special-label': {
      opacity: 0,
      '&::after': {
        content: 'phone'
      }
    },
  },
  blockTitle: {
    color: theme.palette.text.primary,
  },
  transparentButton:
    theme.palette.type === 'dark'
      ? {
          marginTop: '24px',
          color: '#FFFFFF !important',
          borderColor: '#FFFFFF !important',
          backgroundColor: 'transparent !important',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1) !important',
          },
        }
      : {
          marginTop: '24px',
          color: '#2196F3',
          borderColor: '#2196F3',
        },
  absoluteButton: {
    position: 'absolute',
    top: '16px',
    right: '12px',
    height: '36px',
    margin: 0,
    zIndex: 1,
  },
  inputStyles: {
    borderRadius: '8px!important',
    border: `2px solid ${theme.overrides.inputBorder}`,
    backgroundColor: 'transparent',
  },
  labelStyles: {
    color: theme.overrides.labelFormColor,
  },
  changePassword: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      '& > div': {
        width: '100%',
        paddingLeft: '16px',
      },
    },
  },
  text: {
    color: theme.palette.text.primary,
    fontSize: '0.75rem',
    margin: '-10px 0 30px',
  },
  buttonsContainer: {
    textAlign: 'left',
    '& button': {
      [theme.breakpoints.down('sm')]: {
        minWidth: '100px',
      },
    },
    '& button:first-child': {
      marginLeft: 0,
    },
  },
  saveButton: {
    background: theme.palette.type === 'dark' ? '#406BE1' : 'linear-gradient(90deg, #0035FF 0%, #00C1FF 100%)',
    color: '#FFFFFF',
    borderRadius: '8px',
    minWidth: '133px',
    textTransform: 'capitalize',
    padding: '7px 24px!important',
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '90px',
      padding: '6px 24px!important',
    },
  },
  sendButton: {
    background: theme.palette.type === 'dark' ? '#406BE1' : 'linear-gradient(90deg, #0035FF 0%, #00C1FF 100%)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  phoneForm: {
    marginTop: 20,
  },
}));

const ProfileSchema = yup.object().shape({
  username: yup.string().required('Username can not be empty'),
  email: yup.string().required('Email can not be empty').email('Please provide correct email'),
  age: yup
    .string()
    .required('Age can not be empty')
    .matches(/^[0-9]+$/gi, 'Enter number only'),
});

const ProfilePasswordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required('Password can not be empty.')
    .min(8, 'Password should be at least 8 characters long.')
    .test("isValidPass", "Password is not valid", (value) => {
      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      let validConditions = 0;
      const numberOfMustBeValidConditions = 3;
      const conditions = [hasLowerCase, hasUpperCase, hasNumber];
      conditions.forEach((condition) =>
        condition ? validConditions++ : null
      );
      if (validConditions >= numberOfMustBeValidConditions) {
        return true;
      }
      return false;
    }),
  confirmedPassword: yup
    .string()
    .required('Password can not be empty.')
    .min(8, 'Password should be at least 8 characters long.')
    .oneOf([yup.ref('newPassword')], 'The two password fields didn\'t match.')
    .test("isValidPass", "Password is not valid", (value) => {
      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      let validConditions = 0;
      const numberOfMustBeValidConditions = 3;
      const conditions = [hasLowerCase, hasUpperCase, hasNumber];
      conditions.forEach((condition) =>
        condition ? validConditions++ : null
      );
      if (validConditions >= numberOfMustBeValidConditions) {
        return true;
      }
      return false;
    }),
});

const Profile = ({
  profile,
  isProcessing,
  error,
  location,
  history,
  getProfile,
  updateProfile,
  updatePassword,
  // sendPhoneToken,
  clearError,
  updatePhone,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const serverError = Object.values(error).length && Object.values(error)[0].length ? Object.values(error)[0][0] : '';

  const defaultValues = {
    username: profile.username || '',
    email: profile.email || '',
    sex: profile.sex || '',
    age: profile.age || '',
    nationality: profile.nationality || '',
    isStudent: profile.isStudent || false,
    isWorking: profile.isWorking || false,
  };

  // Personal logic
  const { handleSubmit, errors, control, reset } = useForm({
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
    validationSchema: ProfileSchema,
  });
  const {
    handleSubmit: handlePasswordSubmit,
    passwordErrors,
    register,
  } = useForm({
    reValidateMode: 'onChange',
    validationSchema: ProfilePasswordSchema,
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');

    if (code) {
      axios
        .post(`${API_URL}/social/jwt-pair/google-oauth2/`, {
          code,
          redirect_uri: `${process.env.REACT_APP_BASE_URL}/profile`
        })
        .finally(() => {
          getProfile();
          history.replace('/profile');
        });
    }
  }, []);

  useEffect(() => {
    if (profile.id) {
      reset(defaultValues);
    }
  }, [profile]);

  const onSubmitHandler = (data) => {
    updateProfile(data);
  };

  // Phone logic
  const defaultPhoneForm = {
    value: profile?.isPhoneVerified ? profile.phoneNumber || '' : '',
    valid: false,
    haveChanges: false,
  };
  const [phone, setPhone] = useState(defaultPhoneForm);

  const onChangePhone = (e) => {
    const valid =
      isPhoneNumber(e, 'US') || // This is number for USA?
      isPhoneNumber(e, 'UA') || // This is number for UA?
      (e.length === 0 && profile.isPhoneVerified);

    setPhone({
      valid,
      value: e.length === 0 && profile.isPhoneVerified ? null : e,
      haveChanges: e !== profile.phoneNumber && e !== '',
    });
    if (error.phoneNumber) {
      clearError();
    }
  };

  const onSavePhone = () => {
    if (phone.valid) updatePhone(`+${phone.value}`);
  };

  return (
    <>
      <Helmet>
        <title>Myndful</title>
      </Helmet>
      <div className={`main-form ${classes.content}`}>
        <Box mb={3}>
          <div className="breadcrumbs-outer">
            <div className="breadcrumbs-wrapper">
              <Breadcrumbs className="breadcrumbs">
                <Link to="/dashboard">{t('m_dashboard')}</Link>
                <Link to="/profile">{t('m_profile')}</Link>
              </Breadcrumbs>
            </div>
          </div>
          <Typography variant="h4">{t('m_profile_settings')}</Typography>
        </Box>
        <Box mb={4}>
          <div className="main-form__section-text">
            <p>{t('profile_form_title')}</p>
          </div>
        </Box>
        <form
          onSubmit={handleSubmit(onSubmitHandler)}
          id="updateProfileForm"
          className={classes.blockStyles}
        >
          <div className="main-form__section">
            <Box
              className="main-form__section-title"
              display="flex"
              form="updateProfileForm"
              justifyContent="space-between"
              alignItems="center"
              px={4}
            >
              <p className={classes.blockTitle}>{t('m_personal')}</p>
              <Button type="submit" variant="contained" className={classes.saveButton} color="primary">
                {t('save')}
              </Button>
            </Box>
            <Grid container spacing={4} className={classes.formContainer}>
              <Grid item xs={12} sm={6}>
                <Box className="form-input__group">
                  <Controller
                    as={
                      <TextField
                        variant="filled"
                        fullWidth
                        error={!!errors.username || !!error.username}
                        helperText={errors.username ? errors.username.message : error.username ? error.username[0] : ''}
                        InputProps={{
                          disableUnderline: true,
                          className: classes.inputStyles,
                        }}
                        InputLabelProps={{
                          className: classes.labelStyles,
                        }}
                        id="username"
                        label={t('form_label_username')}
                        autoComplete="nope"
                        onChange={() => error.username && clearError()}
                      />
                    }
                    name="username"
                    control={control}
                    defaultValue={profile.username || ''}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className="form-input__group">
                  <Controller
                    as={
                      <TextField
                        variant="filled"
                        fullWidth
                        error={!!errors.email || !!error.email}
                        helperText={errors.email ? errors.email.message : error.email ? error.email[0] : ''}
                        InputProps={{
                          disableUnderline: true,
                          className: classes.inputStyles,
                        }}
                        InputLabelProps={{
                          className: classes.labelStyles,
                        }}
                        id="email"
                        label={t('form_label_email')}
                        autoComplete="nope"
                        onChange={() => error.email && clearError()}
                      />
                    }
                    name="email"
                    control={control}
                    defaultValue={profile.email || ''}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className="form-input__group form-input__select">
                  <FormControl variant="filled" fullWidth error={!!errors.sex}>
                    <InputLabel id="sex-label">{t('form_label_sex')}</InputLabel>
                    <Controller
                      as={
                        <Select
                          MenuProps={{
                            transitionDuration: 0,
                          }}
                          labelId="sex-label"
                          id="sex"
                        >
                          <MenuItem value="MALE">{t('form_label_male')}</MenuItem>
                          <MenuItem value="FEMALE">{t('form_label_female')}</MenuItem>
                        </Select>
                      }
                      name="sex"
                      control={control}
                      defaultValue={profile.sex || ''}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className="form-input__group">
                  <Controller
                    as={
                      <TextField
                        type="number"
                        variant="filled"
                        fullWidth
                        error={!!errors.age || !!error.age}
                        helperText={errors.age ? errors.age.message : error.age ? error.age[0] : ''}
                        InputProps={{
                          disableUnderline: true,
                          className: classes.inputStyles,
                        }}
                        InputLabelProps={{
                          className: classes.labelStyles,
                        }}
                        id="age"
                        label={t('form_label_age')}
                        onChange={() => error.age && clearError()}
                      />
                    }
                    name="age"
                    control={control}
                    defaultValue={profile.age || ''}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className="form-input__group form-input__select">
                  <FormControl variant="filled" fullWidth>
                    <InputLabel id="nationality-label">{t('form_label_nationality')}</InputLabel>
                    <Controller
                      as={
                        <Select
                          MenuProps={{
                            transitionDuration: 0,
                          }}
                          labelId="nationality-label"
                          id="nationality"
                        >
                          {ISO31661ALPHA2.map((country) => (
                            <MenuItem key={country[1]} value={country[1]}>
                              {country[0]}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      name="nationality"
                      control={control}
                      defaultValue={profile.nationality || ''}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="main-form__section-input main-form__section-radio">
                  <FormControl component="fieldset">
                    <FormLabel component="legend">{t('form_label_occupation')}</FormLabel>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Controller
                            as={<Checkbox color="primary" />}
                            name="isStudent"
                            id="isStudent"
                            control={control}
                            defaultValue={profile.isStudent || false}
                          />
                        }
                        label={t('form_label_is_student')}
                      />
                      <FormControlLabel
                        control={
                          <Controller
                            as={<Checkbox color="primary" />}
                            name="isWorking"
                            id="isWorking"
                            control={control}
                            defaultValue={profile.isWorking || false}
                          />
                        }
                        label={t('form_label_is_working')}
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              </Grid>
            </Grid>
          </div>
        </form>
        <div className={classes.blockStyles}>
          <div className="main-form__section">
            <Box
              className="main-form__section-title"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px={4}
            >
              <p className={classes.blockTitle}>{t('profile_device_verification')}</p>
            </Box>
            <Grid container spacing={4} className={classes.formContainer} display="flex" alignItems="center" px={4}>
              <Grid item xs={12} sm={6}>
                <Box mb={3}>
                  <PhoneInput
                    country={'us'}
                    id="phoneNumber"
                    label={t('form_label_phone_number')}
                    autoComplete="off"
                    value={phone.value}
                    onChange={onChangePhone}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box mb={3}>
                  <Button
                    mb={3}
                    disabled={!phone.valid || profile.phoneNumber === phone.value}
                    onClick={onSavePhone}
                    variant="contained"
                    className={`${classes.saveButton} ${classes.sendButton}`}
                    color="primary"
                  >
                    {t('profile_send_code')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <Notification phone={phone} />
        <EmailSubscription />
        <GoogleCalendarSubscription />
        <Subscription />
        <div className={classes.blockStyles}>
          <Box
            className="main-form__section-title"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={4}
          >
            <p className={classes.blockTitle}>{t('profile_security')}</p>
            <Button
              className={classes.saveButton}
              type="submit"
              disabled={isProcessing}
              variant="contained"
              color="primary"
              form="updatePasswordForm"
            >
              {t('save')}
            </Button>
          </Box>
          <Grid container>
            <form onSubmit={handlePasswordSubmit(updatePassword)} id="updatePasswordForm">
              <Grid container spacing={4} className={classes.formContainer}>
                <Grid item xs={12} sm={6}>
                  <Box className="form-input__group">
                    <PasswordField
                      inputRef={register}
                      error={error}
                      errors={passwordErrors}
                      name="newPassword"
                      label={t('form_label_new_password')}
                      id="newPassword"
                      autoComplete="current-password"
                      onChange={() => serverError && clearError()}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box className="form-input__group">
                    <PasswordField
                      inputRef={register}
                      error={error}
                      errors={passwordErrors}
                      name="confirmedPassword"
                      label={t('form_label_confirm_new_password')}
                      id="confirmedPassword"
                      onChange={() => serverError && clearError()}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box pr={2} className={classes.changePassword}>
                    <div className={classes.text}>
                      {t('form_password_validation')}
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </div>
        <SharedAccess />
      </div>
    </>
  );
};

Profile.propTypes = {
  profile: PropTypes.shape({
    age: PropTypes.number,
    currentPlan: PropTypes.number,
    email: PropTypes.string,
    hasArticleSubscription: PropTypes.bool,
    hasPassTestFirstTime: PropTypes.bool,
    hasStudentPrivileges: PropTypes.bool,
    hasTrial: PropTypes.bool,
    id: PropTypes.number,
    isEmailVerified: PropTypes.bool,
    isGoogleCalendarConnected: PropTypes.bool,
    isPhoneVerified: PropTypes.bool.isRequired,
    isStudent: PropTypes.bool,
    isWorking: PropTypes.bool,
    nationality: PropTypes.string,
    phoneNumber: PropTypes.string,
    reminder: PropTypes.shape({
      id: PropTypes.number.isRequired,
      timezone: PropTypes.shape({
        id: PropTypes.number,
        timezone: PropTypes.string,
      }),
      isEnabled: PropTypes.bool,
      remindAt: PropTypes.string,
    }),
    sex: PropTypes.string,
    sharedAccess: PropTypes.array,
    stripeSubscription: PropTypes.shape({
      cancelAtPeriodEnd: PropTypes.bool,
      currentPeriodEnd: PropTypes.string,
      endingBalance: PropTypes.number,
      isActive: PropTypes.bool,
      trialEnd: PropTypes.string,
    }),
    studentPrivilegesExpDate: PropTypes.string,
    trialDaysLeft: PropTypes.number,
    username: PropTypes.string
  }).isRequired,
  isProcessing: PropTypes.bool.isRequired,
  error: PropTypes.object,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getProfile: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  updatePhone: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  sendPhoneToken: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    profile: state.profile.data,
    isProcessing: state.profile.processing,
    error: state.profile.error || {},
  }),
  {
    getProfile: getProfileRequest,
    updateProfile: updateProfileRequest,
    updatePassword: updatePasswordRequest,
    updatePhone: updatePhoneRequest,
    sendPhoneToken: sendPhoneTokenRequest,
    clearError: clearErrorAction,
    showModal: showModalAction,
  }
)(Profile);
