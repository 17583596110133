import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import parse from 'react-html-parser';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import { CheckOutlined, AddOutlined } from '@material-ui/icons';

import { IconDelete, IconEdit } from '../../assets/inline-images';
import { deleteTemplateRequest } from '../../redux/template/templateActions';
import { hideModalAction, showModalAction } from '../../redux/general/generalActions';
import {
  MODAL_TYPE_TEMPLATE,
  MODAL_TYPE_TEMPLATE_DELETE_PERSONAL,
  MODAL_TYPE_TEMPLATE_DESELECT,
  MODAL_TYPE_TEMPLATE_PERSONAL,
  MODAL_TYPE_TEMPLATE_SHARE,
} from '../../redux/general/generalActionsTypes';
import IconSend from '../../assets/inline-images/IconSend';
import IconAddPublic from '../../assets/inline-images/IconAddPublic';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '160px',
    backgroundColor: theme.palette.type === 'dark' ? '#374E86' : '#FFFFFF',
    borderRadius: '8px',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.1)',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '42px',
    padding: '0 16px 0 12px',
    borderBottom: '1px solid #F5F5F5',
  },
  body: {
    display: 'flex',
    alignItems: 'center',
    height: '70px',
    padding: '0 12px',
  },
  description: {
    height: '40px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    '& p': {
      margin: 0,
    },
  },
  ownPublicText: {
    color: theme.palette.type === 'dark' ? '#F5F5F5' : '#757575',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '48px',
    padding: '0 12px',
    borderTop: '1px solid #F5F5F5',
  },
  title: {
    width: 'calc(100% - 36px)',
    fontWeight: 700,
    color: '2A2B2A',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  button: {
    color: theme.palette.type === 'dark' ? '#FFFFFF' : '#0035FF',
    '& .MuiSvgIcon-root': {
      width: '30px',
      height: '30px',
    },
  },
  actions: {
    display: 'flex',
    gap: '16px',
  },
  editIcon: {
    '& path': {
      fill: theme.palette.type === 'dark' ? '#F2F2F2' : '#757575',
    },
    cursor: 'pointer',
  },
  buttonIcon: {
    '& path': {
      fill: theme.palette.type === 'dark' ? '#F2F2F2' : '#0035FF',
    },
    cursor: 'pointer',
  },
  deleteIcon: {
    color: theme.palette.type === 'dark' ? '#F2F2F2' : '#f33835',
    cursor: 'pointer',
  },
  sendIcon: {
    color: theme.palette.type === 'dark' ? '#F2F2F2' : '#757575',
    cursor: 'pointer',
  },
}));

const TemplateItem = ({ template, isApplied, deleteTemplate, showModal, isPublicView }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const history = useHistory();

  const apply = () => {
    showModal({
      title: t('modal_template_title'),
      type: MODAL_TYPE_TEMPLATE,
      params: { id: template.id },
    });
  };

  const handleDeselect = () => {
    showModal({
      title: t('modal_template_title_deselect'),
      type: MODAL_TYPE_TEMPLATE_DESELECT,
      params: { id: template.id },
    });
  };

  const handleClickView = () => {
    history.push(`/resources/templates/${template.id}/view`);
  };

  const handleShare = () => {
    showModal({
      isWithoutTitle: true,
      type: MODAL_TYPE_TEMPLATE_SHARE,
      params: { id: template.id, data: template },
    });
  };

  const handleAddToPersonal = () => {
    showModal({
      isWithoutTitle: true,
      type: MODAL_TYPE_TEMPLATE_PERSONAL,
      params: { id: template.id },
    });
  };

  const handleClickEdit = () => {
    history.push(`/resources/templates/${template.id}`);
  };

  const handleClickDelete = () => {
    deleteTemplate({ id: template.id });
  };

  const handleRemoveFromPublic = () => {
    showModal({
      isWithoutTitle: true,
      type: MODAL_TYPE_TEMPLATE_DELETE_PERSONAL,
      params: { id: template.id },
    });
  };

  return (
    <Box className={classes.container}>
      <div className={classes.header}>
        <div className={classes.title}>{template.title}</div>
        <div className={classes.button} style={{ height: '30px' }}>
          {template.isOwn && isPublicView ? (
            <span className={classes.deleteIcon} onClick={handleRemoveFromPublic}>
              <IconDelete size={26} />
            </span>
          ) : isPublicView ? (
            <span className={classes.buttonIcon} onClick={handleAddToPersonal}>
              <IconAddPublic size={26} />
            </span>
          ) : isApplied ? (
            <CheckOutlined style={{ cursor: 'pointer' }} onClick={handleDeselect} />
          ) : (
            <AddOutlined style={{ cursor: 'pointer' }} onClick={apply} />
          )}
        </div>
      </div>
      <div className={classes.body}>
        <div className={classes.description}>{parse(template.description)}</div>
      </div>
      <div className={classes.footer}>
        <div className={classes.button} style={{ cursor: 'pointer' }} onClick={handleClickView}>
          {t('view_all')}
        </div>
        {template.isOwn && !isPublicView ? (
          <div className={classes.actions}>
            <span className={classes.editIcon} onClick={handleShare}>
              <IconSend size={26} className={classes.sendIcon} />
            </span>
            <span className={classes.editIcon} onClick={handleClickEdit}>
              <IconEdit size={26} />
            </span>
            <span className={classes.deleteIcon} onClick={handleClickDelete}>
              <IconDelete size={26} />
            </span>
          </div>
        ) : template.isOwn && isPublicView ? (
          <span className={classes.ownPublicText}>Published by me</span>
        ) : null}
      </div>
    </Box>
  );
};

TemplateItem.propTypes = {
  template: PropTypes.object.isRequired,
  isApplied: PropTypes.bool.isRequired,
  isPublicView: PropTypes.bool.isRequired,
  deleteTemplate: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isProcessing: state.template.processing,
    error: state.template.error || {},
  }),
  {
    deleteTemplate: deleteTemplateRequest,
    showModal: showModalAction,
    hideModal: hideModalAction,
  }
)(TemplateItem);
