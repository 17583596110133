import React from 'react';
import PropTypes from 'prop-types';

const IconClock = ({ size = 24, className, currentColor }) => {
  return (
    <svg className={className} width={size} height={size} fill={currentColor} xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.92893 4.92893C6.73748 3.12038 9.23885 2 12 2C14.7611 2 17.2625 3.12038 19.0711 4.92893C20.8796 6.73748 22 9.23885 22 12C22 14.7611 20.8796 17.2625 19.0711 19.0711C17.2625 20.8796 14.7611 22 12 22C9.23885 22 6.73748 20.8796 4.92893 19.0711C3.12038 17.2625 2 14.7611 2 12C2 9.23885 3.12038 6.73748 4.92893 4.92893ZM12 4C9.79058 4 7.79195 4.89434 6.34315 6.34315C4.89434 7.79195 4 9.79058 4 12C4 14.2094 4.89434 16.208 6.34315 17.6569C7.79196 19.1057 9.79058 20 12 20C14.2094 20 16.208 19.1057 17.6569 17.6569C19.1057 16.208 20 14.2094 20 12C20 9.79058 19.1057 7.79196 17.6569 6.34315C16.208 4.89434 14.2094 4 12 4Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12 7C12.5523 7 13 7.44772 13 8V12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12L11 8C11 7.44772 11.4477 7 12 7Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.2929 11.2929C11.6834 10.9024 12.3166 10.9024 12.7071 11.2929L14.7071 13.2929C15.0976 13.6834 15.0976 14.3166 14.7071 14.7071C14.3166 15.0976 13.6834 15.0976 13.2929 14.7071L11.2929 12.7071C10.9024 12.3166 10.9024 11.6834 11.2929 11.2929Z" />
    </svg>
  );
};

IconClock.propTypes = {
  className: PropTypes.string,
  currentColor: PropTypes.string,
  size: PropTypes.number,
};

export default IconClock;
