import React from 'react';
import lodash from 'lodash';
import PropTypes from 'prop-types';

const Separator = ({ turns, style }) => (
  <div
    style={{
      position: 'absolute',
      height: '100%',
      transform: `rotate(${turns}turn)`,
    }}
  >
    <div style={style} />
  </div>
);

Separator.propTypes = {
  turns: PropTypes.number,
  style: PropTypes.object,
};

const RadialSeparators = ({ count, style }) => {
  const turns = 1 / count;

  return lodash.range(count).map((index) => (
    <Separator key={index} turns={index * turns} style={style} />
  ));
}

export default RadialSeparators;
