import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Menu,
  MenuItem,
  Select,
  Switch,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Close, ExpandMore, Menu as MenuIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { logoutRequest } from '../../../redux/auth/authActions';
import { hideModalAction, showModalAction, toggleThemeAction } from '../../../redux/general/generalActions';
import { MODAL_TYPE_CONFIRM } from '../../../redux/general/generalActionsTypes';
import { getProfileRequest, toggleCurrentLanguageAction } from '../../../redux/profile/profileActions';
import { clearErrorAction } from '../../../redux/subscription/subscriptionActions';
import { BootstrapInput } from '../../Dashboard/UserTestCharts';
import MenuDropdown from './MenuDropdown';

const iconStar = '/images/icon-star.svg';
const iconStarBlue = '/images/icon-star-blue.svg';
const iconLogoWhite = '/images/logo-white-2.svg';
const iconLogoBlue = '/images/logo-blue.svg';
const iconLogout = '/images/icon-logout-red.svg';

const useStyles = makeStyles((theme) => {
  return {
    appBar: {
      zIndex: 1301,
      backgroundColor: theme.overrides.headerBackground,
      boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.05)',
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
        marginLeft: '16px',
      },
    },
    logoContainer: {
      display: 'block',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    headerWrapper: {
      width: '100%',
      color: theme.palette.text.primary,
    },
    headerLink: {
      '&:not(:last-child)': {
        marginRight: '40px',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    mobileMenuList: {
      '& li': {
        color: theme.palette.text.primary,
        marginBottom: 20,
        '& a': {
          color: theme.palette.text.primary,
        },
      },
    },
    languagesSelect: {
      '& .MuiSelect-root': {
        border: 'none',
        lineHeight: 1,
        minWidth: '70px',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        borderRadius: 0,
        padding: '15px 20px 14px 0',
      },
      '& .MuiSelect-icon': {
        right: '-6px',
      },
    },
    selectMenuPaper: {},
    headerInner: {
      display: 'flex',
      alignItems: 'center',
    },
    headerName: {
      lineHeight: 1,
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        border: '1px solid #F5F5F5',
        borderRadius: '8px',
        padding: '0 12px',
      },
    },
    mobileMenuPremium: {
      display: 'flex',
      alignItems: 'center',
      color: theme.overrides.textGetPremium,
      marginRight: '22px',
      cursor: 'pointer',
      '& img': {
        marginRight: '10px',
      },
    },
    pointer: {
      display: '-webkit-inline-box',
      alignItems: 'center',
      color: `${theme.overrides.textGetPremium} !important`,
      marginRight: '22px',
      background: 'linear-gradient(90deg, #0035FF 0%, #198EFB 100%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: theme.palette.type === 'dark' ? 'unset' : 'transparent',
      cursor: 'pointer',
      '& img': {
        marginRight: '10px',
        position: 'relative',
        top: '2px',
      },
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    pointerLink: {
      cursor: 'pointer',
    },
    mobileMenuLogout: {
      display: 'flex',
      alignItems: 'center',
      boxShadow: theme.palette.type === 'dark' ? 'inset 0px 1px 0px rgba(0,0,0, 0.1)' : 'inset 0px 1px 0px #F5F5F5',
      padding: '23px 27px',
      color: '#F33935',
      marginTop: 'auto',
      '& img': {
        marginRight: 12,
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      '& button': {
        padding: '6px',
        border: '2px solid #F5F5F5',
        borderRadius: '8px',
      },
    },
    premiumDays: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      backgroundColor: '#0035FF',
      padding: '10px 0',
    },
    premiumDaysText: {
      fontSize: '12px',
      margin: '0',
      marginRight: '10px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
      },
    },
    premiumDaysLink: {
      fontSize: '12px',
      fontWeight: '700',
      textDecoration: 'underline',
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
      },
    },
    closePremiumDays: {
      position: 'absolute',
      right: '10px',
      top: '10px',
      width: '20px',
      height: '20px',
      cursor: 'pointer',
      '& span': {
        display: 'block',
        position: 'absolute',
        left: '9px',
        top: '0',
        width: '1px',
        height: '17px',
        backgroundColor: '#FFFFFF',
        transform: 'rotate(45deg)',
        '&:last-child': {
          transform: 'rotate(-45deg)',
        },
        [theme.breakpoints.down('sm')]: {
          height: '14px',
        },
      },
    },
    topBarMargin: {
      marginBottom: '35px',
    },
    menu: {
      zIndex: '1500 !important',
      '& .header__list': {
        width: "208px",
      }
    },
  };
});

const AuthorizedHeader = ({
  username,
  logout,
  profile,
  currentPlan,
  getProfile,
  subscriptionCreated,
  clearError,
  showModal,
  hideModal,
  isDarkTheme,
  toggleTheme,
  languagesOptions,
  selectedLanguage,
  toggleCurrentLanguage,
}) => {
  const { i18n, t } = useTranslation();

  const closeModalHelper = () => {
    getProfile();
    clearError();
    hideModal();
  };

  useEffect(() => {
    if (subscriptionCreated) {
      showModal({
        params: {
          cancelAction: closeModalHelper,
          confirmAction: closeModalHelper,
          confirmButtonText: t('modal_subscription_hooray'),
          text: t('modal_subscription_processing_premium'),
        },
        title: t('modal_subscription_welcome_on_board'),
        type: MODAL_TYPE_CONFIRM,
      });
    }
  }, [closeModalHelper, showModal, subscriptionCreated]);

  const scrollBarWidthRef = useRef(0);
  if (typeof window !== 'undefined') {
    const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
    if (scrollBarWidth) {
      scrollBarWidthRef.current = scrollBarWidth;
    }
  }

  const classes = useStyles({ scrollBarWidth: scrollBarWidthRef.current });
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(false);
  const [premiumEndDate, setPremiumEndDate] = useState('');
  const [isPremiumEnabled, setIsPremiumEnabled] = useState(false);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isLanguagesOptions = Boolean(languagesOptions) && languagesOptions.length > 0;

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePremiumDays = () => {
    setIsPremiumEnabled(false);
    let expireDate = new Date(Date.now() + 86400e3);
    expireDate = expireDate.toUTCString();
    document.cookie = `subscriptionLeft=disable; expires=${expireDate}`;
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleOpenProfile = () => {
    history.push('/profile');
    handleMenuClose();
  };

  const handleLogOut = () => {
    handleMenuClose();
    logout();
  };
  const getCookie = (name) => {
    const cookie = {};
    document.cookie.split(';').map(function (el) {
      const [k, v] = el.split('=');
      cookie[k.trim()] = v;
    });

    return cookie[name];
  };
  useEffect(() => {
    if (profile?.stripeSubscription?.cancelAtPeriodEnd && getCookie('subscriptionLeft') !== 'disable') {
      setIsPremiumEnabled(true);
      setPremiumEndDate(new Date(profile.stripeSubscription.currentPeriodEnd) - new Date());
    } else {
      setIsPremiumEnabled(false);
    }
  }, [profile]);

  const menuId = 'primary-search-account-menu';

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      id={menuId}
      keepMounted
      transitionDuration={0}
      transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      className={classes.menu}
      style={isPremiumEnabled ? { top: '80px' } : { top: '40px' }}
    >
      <div className='header__list'>
        <MenuItem style={{ minHeight: '50px' }} onClick={handleOpenProfile}>
          {t('m_profile_settings')}
        </MenuItem>

        {/* <MenuItem style={{ minHeight: '50px' }}>
          <span style={{ marginRight: '32px' }}>{t('m_dark_mode')}</span>
          <Switch color="primary" checked={isDarkTheme} onChange={(evt) => toggleTheme(evt.target.checked)} />
        </MenuItem> */}
        <MenuItem style={{ minHeight: '50px' }} onClick={handleLogOut}>
          {t('m_logout')}
        </MenuItem>
      </div>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <>
      <Drawer onClose={handleMobileMenuClose} anchor="top" open={isMobileMenuOpen}>
        <div
          style={{
            height: '100vh',
            paddingTop: 56,
            marginTop: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'space-between',
          }}
        >
          <List className={classes.mobileMenuList}>
            <ListItem>
              <Box className={classes.pointerLink} onClick={handleOpenProfile}>
                {t('m_my_account')}
              </Box>
            </ListItem>
            <ListItem>
              <Link to="/dashboard">{t('m_dashboard')}</Link>
            </ListItem>
            <ListItem>
              <Link to="/dashboard/achievements">{t('m_achievements')}</Link>
            </ListItem>
            <ListItem>
              <Link to="/personalized-plan">{t('m_your_plan')}</Link>
            </ListItem>
            <ListItem>
              <Link to="/shared-access">{t('m_shared_access')}</Link>
            </ListItem>
            <ListItem>
              <Link to="/resources/focus-point-articles">{t('m_articles')}</Link>
            </ListItem>
            <ListItem>
              <Link to="/resources/templates">{t('m_templates')}</Link>
            </ListItem>
            <ListItem>
              <Link to="/journal">{t('m_journal')}</Link>
            </ListItem>
            <ListItem>
              <Link to="/summary">{t('m_summary')}</Link>
            </ListItem>
            <ListItem>
              <Box>
                <div>
                  {currentPlan ? (
                    <div className={classes.mobileMenuPremium}>{t('m_premium')}</div>
                  ) : (
                    <Link className={classes.mobileMenuPremium} to="/profile#subscription">
                      {isDarkTheme ? (
                        <img src={iconStar} alt="icon star" width="17" height="17" />
                      ) : (
                        <img src={iconStarBlue} alt="icon star" width="17" height="17" />
                      )}
                      {t('m_get_premium')}
                    </Link>
                  )}
                </div>
              </Box>
            </ListItem>
            {/* <ListItem>
              <span style={{ marginRight: '32px' }}>{t('m_dark_mode')}</span>
              <Switch color="primary" checked={isDarkTheme} onChange={(evt) => toggleTheme(evt.target.checked)} />
            </ListItem> */}
            {isLanguagesOptions && (
              <ListItem>
                <Select
                  labelId="languages-label"
                  id="languages"
                  value={selectedLanguage}
                  MenuProps={{
                    classes: { paper: classes.selectMenuPaper },
                    transitionDuration: 0,
                  }}
                  onChange={(evt) => {
                    i18n.changeLanguage(String(evt.target.value));
                    toggleCurrentLanguage(evt.target.value);
                  }}
                  input={<BootstrapInput variant="contained" className={classes.languagesSelect} />}
                >
                  <MenuItem key="en" value="en">
                    English
                  </MenuItem>
                  {languagesOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </ListItem>
            )}
          </List>
          <div className={classes.mobileMenuLogout} onClick={handleLogOut}>
            <img src={iconLogout} alt="icon logout" width="24" height="24" />
            {t('m_logout')}
          </div>
        </div>
      </Drawer>
    </>
  );

  return (
    <div className={`${classes.grow} ${isPremiumEnabled ? classes.topBarMargin : ''}`}>
      <AppBar position="fixed" className={classes.appBar}>
        {isPremiumEnabled && (
          <div className={classes.premiumDays}>
            <p className={classes.premiumDaysText}>
              {t('profile_subscription_days_left', { count: Math.abs(Math.ceil(premiumEndDate / (1000 * 3600 * 24))) })}
            </p>
            <Link to="/profile#subscription" className={classes.premiumDaysLink}>
              {t('renew_now')}
            </Link>
            <div className={classes.closePremiumDays} onClick={handlePremiumDays}>
              <span />
              <span />
            </div>
          </div>
        )}

        <Toolbar>
          <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.headerWrapper}>
            <Link to="/">
              <Box display="flex" flexWrap="nowrap" alignItems="center" className={classes.logoContainer}>
                {isDarkTheme ? (
                  <img src={iconLogoWhite} alt="logo" width="140" height="30" />
                ) : (
                  <img src={iconLogoBlue} alt="logo" width="140" height="30" />
                )}
              </Box>
            </Link>
            <Box>
              <Link to="/dashboard" className={classes.headerLink}>
                {t('m_dashboard')}
              </Link>
              <Link to="/dashboard/achievements" className={classes.headerLink}>
                {t('m_achievements')}
              </Link>
              <Link to="/personalized-plan" className={classes.headerLink}>
                {t('m_your_plan')}
              </Link>
              <Link to="/shared-access" className={classes.headerLink}>
                {t('m_shared_access')}
              </Link>
              <MenuDropdown
                className={classes.headerLink}
                items={[
                  { path: '/resources/focus-point-articles', label: t('m_articles') },
                  { path: '/resources/templates', label: t('m_templates') },
                ]}
              >
                {t('m_resources')}
              </MenuDropdown>
              <Link to="/journal" className={classes.headerLink}>
                {t('m_journal')}
              </Link>
              <Link to="/summary" className={classes.headerLink}>
                {t('m_summary')}
              </Link>
            </Box>
            <Box className={classes.headerInner}>
              <div>
                {currentPlan ? (
                  <div className={classes.pointer}>{t('m_premium')}</div>
                ) : (
                  <Link className={classes.pointer} to="/profile#subscription">
                    {isDarkTheme ? (
                      <img src={iconStar} alt="icon star" width="17" height="17" />
                    ) : (
                      <img src={iconStarBlue} alt="icon star" width="17" height="17" />
                    )}
                    {t('m_get_premium')}
                  </Link>
                )}
              </div>
              <div className={classes.sectionDesktop} onClick={handleProfileMenuOpen}>
                <Typography variant="subtitle1" className={classes.headerName} noWrap>
                  {username}
                </Typography>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  color="inherit"
                >
                  <ExpandMore />
                </IconButton>
              </div>
              {isLanguagesOptions && (
                <div className={classes.sectionDesktop} style={{ marginLeft: '16px' }}>
                  <Select
                    labelId="languages-label"
                    id="languages"
                    value={selectedLanguage}
                    MenuProps={{
                      classes: { paper: classes.selectMenuPaper },
                      transitionDuration: 0,
                    }}
                    onChange={(evt) => {
                      toggleCurrentLanguage(evt.target.value);
                    }}
                    input={<BootstrapInput variant="contained" className={classes.languagesSelect} />}
                  >
                    <MenuItem key="en" value="en">
                      English
                    </MenuItem>
                    {languagesOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={() => setMobileMoreAnchorEl((prev) => !prev)}
                  color="inherit"
                >
                  {isMobileMenuOpen ? <Close /> : <MenuIcon />}
                </IconButton>
              </div>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
};

AuthorizedHeader.propTypes = {
  profile: PropTypes.object.isRequired,
  currentPlan: PropTypes.number,
  subscriptionCreated: PropTypes.bool.isRequired,
  username: PropTypes.string,
  languagesOptions: PropTypes.array,
  selectedLanguage: PropTypes.string,
  isDarkTheme: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  toggleTheme: PropTypes.func.isRequired,
  toggleCurrentLanguage: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    profile: state.profile.data,
    username: state.profile.data.username,
    currentPlan: state.profile.data.currentPlan,
    languagesOptions: state.profile.languagesOptions,
    selectedLanguage: state.profile.selectedLanguage,
    subscriptionCreated: state.subscription.subscriptionCreated,
    isDarkTheme: state.general.isDarkTheme,
  }),
  {
    logout: logoutRequest,
    getProfile: getProfileRequest,
    clearError: clearErrorAction,
    showModal: showModalAction,
    hideModal: hideModalAction,
    toggleTheme: toggleThemeAction,
    toggleCurrentLanguage: toggleCurrentLanguageAction,
  }
)(AuthorizedHeader);
