export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';

export const SEND_PHONE_TOKEN_REQUEST = 'SEND_PHONE_TOKEN_REQUEST';
export const SEND_PHONE_TOKEN_SUCCESS = 'SEND_PHONE_TOKEN_SUCCESS';

export const VERIFY_PHONE_TOKEN_REQUEST = 'VERIFY_PHONE_TOKEN_REQUEST';
export const VERIFY_PHONE_TOKEN_SUCCESS = 'VERIFY_PHONE_TOKEN_SUCCESS';

export const CREATE_REMINDER_REQUEST = 'CREATE_REMINDER_REQUEST';
export const CREATE_REMINDER_SUCCESS = 'CREATE_REMINDER_SUCCESS';

export const UPDATE_REMINDER_REQUEST = 'UPDATE_REMINDER_REQUEST';
export const UPDATE_REMINDER_SUCCESS = 'UPDATE_REMINDER_SUCCESS';

export const UNSUBSCRIBE_EMAIL_REQUEST = 'UNSUBSCRIBE_EMAIL_REQUEST';

export const DISCONNECT_GOOGLE_CALENDAR = 'DISCONNECT_GOOGLE_CALENDAR';
export const DISCONNECT_GOOGLE_CALENDAR_SUCCESS = 'DISCONNECT_GOOGLE_CALENDAR_SUCCESS';
export const DISCONNECT_GOOGLE_CALENDAR_ERROR = 'DISCONNECT_GOOGLE_CALENDAR_ERROR';

export const PROFILE_REQUEST_ERROR = 'PROFILE_REQUEST_ERROR';
export const CLEAR_ERROR = 'CLEAR_PROFILE_ERROR';

export const TOGGLE_PROCESSING = 'TOGGLE_PROFILE_PROCESSING';

export const UPDATE_PHONE_REQUEST = 'UPDATE_PHONE_REQUEST';
export const UPDATE_PHONE_SUCCESS = 'UPDATE_PHONE_SUCCESS';

export const GET_LANGUAGES_OPTIONS_REQUEST = 'GET_LANGUAGES_OPTIONS_REQUEST';
export const GET_LANGUAGES_OPTIONS_SUCCESS = 'GET_LANGUAGES_OPTIONS_SUCCESS';
export const GET_LANGUAGES_OPTIONS_ERROR = 'GET_LANGUAGES_OPTIONS_ERROR';

export const TOGGLE_CURRENT_LANGUAGE_ACTION = 'TOGGLE_CURRENT_LANGUAGE_ACTION';
