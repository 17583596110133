import React from 'react';
import { connect } from 'react-redux';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import CustomGoalItem from '../PersonalizedPlan/CustomGoalItem';
import { hideModalAction, showModalAction } from '../../redux/general/generalActions';
import { MODAL_TYPE_CUSTOM_TRACKER } from '../../redux/general/generalActionsTypes';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: '1100px',
    margin: '0 auto',
    padding: '0 24px'
  },
  customTitle: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  customControls: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '16px',
    padding: '12px 0',
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%'
    },
  },
  addGoalButton: {
    backgroundColor: 'transparent',
    textTransform: 'capitalize',
    color: theme.palette.text.primary,
    borderRadius: '8px',
    boxShadow: 'unset',
    border: `solid 1px ${theme.overrides.lightBorder}`,
    padding: '6px 13px!important',
    [theme.breakpoints.down('sm')]: {
      '& span': {
        fontSize: '13px!important',
      },
    },
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'flex-end',
    },
    '&:hover': {
      backgroundColor: theme.palette.type === 'dark' ? 'rgb(51, 106, 163)' : '#d5d5d5',
    },
  },
}));


const TemplateTrackers = ({ templateTrackers, isView, showModal, hideModal, setTemplateTrackers }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const onAddTrackerHandler = (data) => {
    if(data.eventDay){
      data.eventDay = Number(data.eventDay.split('-').at(-1));
    }
    setTemplateTrackers(templateTrackers.concat({ ...data, key: `key-${Date.now()}` }));
    hideModal();
  }

  const onUpdateTrackerHandler = (data) => {
    const customGoal = templateTrackers.find(({ key }) => key === data) || {};

    showModal({
      title: t('template_update_tracker'),
      type: MODAL_TYPE_CUSTOM_TRACKER,
      params: {
        customGoal,
        isProcessing: false,
        onCancel: hideModal,
        onSubmit: (values) => {
          setTemplateTrackers(templateTrackers.map((el) => el.key === data ? { ...el, ...values } : el));
          hideModal();
        }
      }
    })
  };

  const onDeleteTrackerHandler = (data) => {
    setTemplateTrackers(templateTrackers.filter(({ key }) => key !== data));
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.customControls}>
        <Typography variant="h5" className={classes.customTitle}>
          {t('template_trackers')}
        </Typography>
        {!isView && (
          <Button
            className={classes.addGoalButton}
            color="primary"
            variant="contained"
            onClick={() => showModal({
              title: t('template_create_tracker'),
              type: MODAL_TYPE_CUSTOM_TRACKER,
              params: {
                customGoal: {},
                isProcessing: false,
                onCancel: hideModal,
                onSubmit: onAddTrackerHandler
              }
            })}
          >
            {t('template_add_tracker')}
          </Button>
        )}
      </Box>
      {templateTrackers.map((item, index) => (
        <CustomGoalItem
          {...item}
          isTemplateGoal={true}
          actions={isView ? 'view' : 'edit'}
          key={`custom-goal-${item.key}-${index}`}
          onEditCustomGoal={() => onUpdateTrackerHandler(item.key)}
          onDeleteCustomGoal={() => onDeleteTrackerHandler(item.key)}
        />
      ))}
    </Box>
  );
};

TemplateTrackers.propTypes = {
  templateTrackers: PropTypes.array.isRequired,
  isView: PropTypes.bool.isRequired,
  setTemplateTrackers: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default connect(
  null,
  {
    showModal: showModalAction,
    hideModal: hideModalAction,
  }
)(TemplateTrackers);
