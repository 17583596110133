import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar } from '@material-ui/core';
import {
  Close,
  CheckCircleOutlined,
  ErrorOutlineOutlined,
  InfoOutlined,
  ReportProblemOutlined,
} from '@material-ui/icons';

const ModalAlert = ({ className, message, open, type, hideModal }) => {
  const Icon =
    type === 'success'
      ? CheckCircleOutlined
      : type === 'error'
      ? ErrorOutlineOutlined
      : type === 'warning'
      ? ReportProblemOutlined
      : type === 'info'
      ? InfoOutlined
      : null;

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      className={`modal--alert${className ? ` ${className}` : ''}${type ? ` ${type}` : ''}`}
      onClose={hideModal}
    >
      {Icon ? (
        <div>
          <Icon className="modal--alert__icon" />
          <div className="modal--alert__message">{message}</div>
          <Close className="modal--alert__close" onClick={hideModal} />
        </div>
      ) : (
        <div />
      )}
    </Snackbar>
  );
};

ModalAlert.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  open: PropTypes.bool.isRequired,
  type: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
};

export default ModalAlert;
