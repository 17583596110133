import React from 'react';
import PropTypes from 'prop-types';

const IconLineChart = ({ size = 24, className }) => {
  return (
    <>
        <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 7C3 4.79086 4.79086 3 7 3H17C19.2091 3 21 4.79086 21 7V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17V7Z" stroke="#0035FF" stroke-width="2"/>
            <path d="M6 15L8.02857 11.4867C8.47834 10.7078 9.2833 10.5346 9.88235 11.088C10.4814 11.6413 11.2864 11.4681 11.7361 10.6892L12.865 8.73417C13.3589 7.87866 14.2637 7.75205 14.8712 8.45343L18 12.066" stroke="#0035FF" stroke-width="2"/>
        </svg>
    </>
  );
};

IconLineChart.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

export default IconLineChart;
