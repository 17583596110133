import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import parse from 'react-html-parser';
import PropTypes from 'prop-types';
import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';

import TemplateTrackers from './TemplateTrackers';
import ScrollToTop from '../shared/components/ScrollToTop';
import { useWindowDimension } from '../../hooks';
import {
  clearTemplateAction,
  createTemplateRequest,
  getSingleTemplateRequest, updateTemplateRequest
} from '../../redux/template/templateActions';
import { getQuestionnaireSectionsRequest } from '../../redux/questionnaire/questionnaireActions';
import { MODAL_TYPE_ALERT } from '../../redux/general/generalActionsTypes';
import { showModalAction } from '../../redux/general/generalActions';
import QuillEditor from '../Journal/QuillEditor';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    maxWidth: '1100px',
    margin: '50px auto 0',
  },
  rte: {
    margin: '8px 0 24px',
    marginBottom: "0",
    color: theme.palette.type === 'dark' ? '#ffffff' : '#000000',
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(147, 160, 190, 0.1)' : 'white',
    '& .ql-toolbar.ql-snow': {
      borderRadius: '8px 8px 0px 0px',
    },
    '& .ql-toolbar.ql-snow + .ql-container.ql-snow': {
      borderRadius: '0px 0px 8px 8px',
    },
    '& .ql-editor': {
      minHeight: '75px',
      '&.ql-blank::before': {
        color: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.6)',
      },
    },
    '& .ql-toolbar': {
      '& .ql-stroke': {
        stroke: theme.palette.type === 'dark' ? '#fff' : '#444',
      },
    },
    '& .ql-picker-label::before': {
      color: theme.palette.type === 'dark' ? '#fff' : '#444',
    },
  },
  blockStyles: {
    backgroundColor: theme.overrides.accordionBackground,
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    marginBottom: '46px',
  },
  formContainer: {
    padding: '34px',
    '& .MuiFormControl-root': {
      marginTop: 0
    }
  },
  gridContainer: {
    justifyContent: 'space-between',
    '& > div': {
      [theme.breakpoints.up('sm')]: {
        maxWidth: 'calc(50% - 17px)',
      },
    }
  },
  formTitle: {
    position: 'absolute',
    top: '-56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'calc(100% - 48px)',
    fontSize: '36px',
    fontWeight: 700
  },
  formValue: {
    marginTop: '12px',
    fontWeight: 700
  },
  inputStyles: {
    borderRadius: '8px !important',
    border: `2px solid ${theme.overrides.inputBorder}`,
    backgroundColor: 'transparent',
  },
  labelStyles: {
    color: theme.overrides.labelFormColor,
  },
  categoryItem: {
    marginTop: 5,
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: 10,
    },
  },
  saveButton: {
    background: theme.palette.type === 'dark' ? '#406BE1' : 'linear-gradient(90deg, #0035FF 0%, #00C1FF 100%)',
    color: '#FFFFFF',
    borderRadius: '8px',
    minWidth: '133px',
    textTransform: 'capitalize',
    padding: '7px 24px!important',
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '90px',
      padding: '6px 24px!important',
    },
  },
}));

const TemplateSchema = yup.object().shape({
  title: yup.string().required('Template Name can not be empty'),
  sectionId: yup.string().required('Section is required.'),
});

const Template = ({
  template,
  sections,
  error = {},
  match,
  getSingleTemplate,
  createTemplate,
  updateTemplate,
  clearTemplate,
  getQuestionnaireSections,
  showModal
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [width] = useWindowDimension();
  const history = useHistory();

  const id = Number(match.params.id) || null;
  const isView = !!id && history.location.pathname.indexOf('view') !== -1;

  const [templateTrackers, setTemplateTrackers] = useState([]);

  const { handleSubmit, errors, control, reset } = useForm({
    reValidateMode: 'onChange',
    validationSchema: TemplateSchema,
  });

  useEffect(() => {
    getQuestionnaireSections();

    return () => clearTemplate();
  }, []);

  useEffect(() => {
    if (id) {
      getSingleTemplate({ id });
    }
  }, [id]);

  useEffect(() => {
    if (template.id && !template.isOwn) {
      history.replace(`/resources/templates/${template.id}/view`);
    }

    reset({
      title: template.title,
      sectionId: template.section?.id,
      description: template.description,
    });

    setTemplateTrackers(template?.questions?.map((el) => ({ ...el, key: el.id })) || []);
  }, [template]);

  const successCallback = () => {
    history.push('/resources/templates');
  };

  const onSubmitHandler = (data) => {
    if (!templateTrackers.length) {
      return showModal({
        type: MODAL_TYPE_ALERT,
        params: {
          type: 'error',
          message: 'At least one question must be created',
        },
      });
    }

    const questions = templateTrackers.map((el) => ({ ...el, sectionId: el.sectionId || el.section?.id }))

    if (template.id) {
      updateTemplate({ ...data, questions, id: template.id });
      successCallback();
    } else {
      createTemplate({ ...data, questions, successCallback });
    }
  };

  return (
    <>
      <Helmet>
        <title>Myndful</title>
      </Helmet>
      <Grid
        container
        style={{
          marginTop: width < 1150 ? '0' : '100px',
          height: 0
        }}
      >
        <div className="breadcrumbs-outer">
          <div className="breadcrumbs-wrapper">
            <Breadcrumbs className="breadcrumbs">
              <Link to="/dashboard">{t('m_dashboard')}</Link>
              <Link to="/resources/templates">{t('m_templates')}</Link>
              {!id && <span>{t('template_new')}</span>}
              {!!template?.id && <Link to={`/resources/templates/${template.id}`}>{template.title}</Link>}
            </Breadcrumbs>
          </div>
        </div>
        <ScrollToTop />
      </Grid>
      <div className={`main-form ${classes.container}`}>
        <form
          onSubmit={handleSubmit(onSubmitHandler)}
          id="updateTemplateForm"
          className={classes.blockStyles}
        >
          <div className={classes.formTitle}>
            <span>{id ? template?.title : t('template_create_new')}</span>
            {!isView && (
              <Button type="submit" variant="contained" className={classes.saveButton} color="primary">
                {t('save')}
              </Button>
            )}
          </div>
          <div className="main-form__section">
            <Grid container className={classes.formContainer}>
              <Grid item xs={12}>
                <Grid container className={classes.gridContainer}>
                  <Grid item xs={12} sm={6}>
                    <Box className="form-input__group">
                      {isView ? (
                        <>
                          <Box>{t('template_form_name')}</Box>
                          <Box className={classes.formValue}>{template?.title || ''}</Box>
                        </>
                      ) : (
                        <Controller
                          as={
                            <TextField
                              variant="filled"
                              fullWidth
                              error={!!errors.title || !!error.title}
                              helperText={errors.title ? errors.title.message : error.title ? error.title[0] : ''}
                              InputProps={{
                                disableUnderline: true,
                                className: classes.inputStyles,
                              }}
                              InputLabelProps={{
                                className: classes.labelStyles,
                              }}
                              id="title"
                              label={t('template_form_name')}
                              autoComplete="nope"
                            />
                          }
                          name="title"
                          control={control}
                          defaultValue={template?.title || ''}
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box className="form-input__group form-input__select">
                      {isView ? (
                        <>
                          <Box>{t('template_form_category')}</Box>
                          <Box className={classes.formValue}>{template?.section?.name || ''}</Box>
                        </>
                      ) : (
                        <FormControl variant="filled" fullWidth error={!!errors.sectionId}>
                          <InputLabel id="sectionId-label">{t('template_form_category')}</InputLabel>
                          <Controller
                            as={
                              <Select
                                MenuProps={{
                                  transitionDuration: 0,
                                }}
                                style={{ maxHeight: '54px' }}
                                labelId="sectionId-label"
                                id="sectionId"
                              >
                                {(sections.results || []).map((item) => (
                                  <MenuItem key={`option-${item.id}`} value={item.id}>
                                    <div className={classes.categoryItem}>
                                      <img src={item.icon} width={24} alt="" /> {item.name}
                                    </div>
                                  </MenuItem>
                                ))}
                              </Select>
                            }
                            name="sectionId"
                            control={control}
                            defaultValue={''}
                          />
                          {!!errors.sectionId && (
                            <FormHelperText id="my-helper-text">{errors.sectionId.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box className="form-input__group" style={{ margin: isView ? '32px 0' : '' }}>
                  {isView ? (
                    <>
                      <Box>{t('template_form_description')}</Box>
                      <Box className={classes.formValue}>{parse(template?.description) || ''}</Box>
                    </>
                  ) : (
                    <Controller
                      as={
                        <QuillEditor 
                          theme="snow"
                          className={classes.rte}
                          placeholder={t('template_form_description')}
                        />
                      }
                      name="description"
                      control={control}
                      defaultValue=""
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </form>
      </div>
      <TemplateTrackers
        templateTrackers={templateTrackers}
        setTemplateTrackers={setTemplateTrackers}
        isView={isView}
      />
    </>
  );
};

Template.propTypes = {
  template: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    isOwn: PropTypes.bool,
    questions: PropTypes.array,
    section: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    }),
    title: PropTypes.string,
  }).isRequired,
  isProcessing: PropTypes.bool.isRequired,
  sections: PropTypes.object.isRequired,
  isDarkTheme: PropTypes.bool.isRequired,
  error: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  getSingleTemplate: PropTypes.func.isRequired,
  createTemplate: PropTypes.func.isRequired,
  updateTemplate: PropTypes.func.isRequired,
  clearTemplate: PropTypes.func.isRequired,
  getQuestionnaireSections: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    template: state.template.singleTemplate,
    isProcessing: state.template.processing,
    error: state.template.error || {},
    sections: state.questionnaire.sections,
    isDarkTheme: state.general.isDarkTheme,
  }),
  {
    getSingleTemplate: getSingleTemplateRequest,
    createTemplate: createTemplateRequest,
    updateTemplate: updateTemplateRequest,
    clearTemplate: clearTemplateAction,
    getQuestionnaireSections: getQuestionnaireSectionsRequest,
    showModal: showModalAction,
  }
)(Template);
