import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import * as profileTypes from '../../redux/profile/profileActionsTypes';
import * as authTypes from '../../redux/auth/authActionsTypes';

const ProfileSettingsProvider = ({ children }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch({ type: profileTypes.GET_PROFILE_REQUEST });
    }

    dispatch({ type: authTypes.GET_SOCIAL_KEYS_REQUEST });
  }, []);

  return (
    <div>
      {children}
    </div>
  );
}

ProfileSettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProfileSettingsProvider;
