import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Box,
  CircularProgress,
  Grid,
  InputBase,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  withStyles,
} from '@material-ui/core';

import UserTestChartItem from './UserTestChartItem';
import { getUserTestSectionListRequest } from '../../redux/dashboard/dashboardActions';
import { getSharedUserTestSectionListRequest } from '../../redux/shared-access/sharedAccessActions';
import InsertChartOutlinedRoundedIcon from '@material-ui/icons/InsertChartOutlinedRounded';
import VerticalBarChart from './VerticalBarChart';
import IconLineChart from '../../assets/inline-images/IconLineChart';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '12px',
    width: '100%',
    '& > div': {
      position: 'relative',
      width: '100%',
      borderRadius: '8px',
    },
  },
  titleContainer: {
    marginBottom: '35px',
    paddingBottom: '10px',
    borderBottom: `1px solid ${theme.palette.type === 'dark' ? 'rgba(242, 242, 242, 0.8)' : 'rgba(0, 0, 0, 0.12);'}`,
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  selectContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      marginTop: '10px',
    },
  },
  selectMenuPaper: {
    backgroundColor: theme.overrides.filterListBackground,
  },
  emptyContainer: {
    width: '100%',
    minHeight: 300,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    background: 'transparent',
  },
  emptyText: {
    textAlign: 'center',
    fontStyle: 'italic',
    fontWeight: '300',
    color: theme.palette.text.primary,

    '& a': {
      textDecoration: 'underline',
    },
  },
  switchContainer: {
    maxWidth: "80px",
    width: "100%",
    border: `1px solid ${theme.palette.type === 'dark' ? 'rgba(242, 242, 242, 0.8)' : 'rgba(0, 0, 0, 0.12);'}`,
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "16px",
    '& div:first-child': {
      borderLeft: "none"
    }
  },
  switchItem: {
    padding: "8.5px 8px",
    cursor: "pointer",
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderLeft: `1px solid ${theme.palette.type === 'dark' ? 'rgba(242, 242, 242, 0.8)' : 'rgba(0, 0, 0, 0.12);'}`,
  },
  lineBarTab: {
    '& path': {
      stroke: theme.palette.type === 'dark' ? "#fff" : "#2A2B2A"
    }
  },
  activeLightSecondItem: {
    '& path': {
      fill: "#0035FF"
    }
  },
  activeSecondItem: {
      backgroundColor: "#0035FF",
      borderRadius: "0px 8px 8px 0px"
  },
  activeLightFirstItem: {
    '& path': {
      stroke: "#0035FF"
    }
  },
  activeFirstItem: {
    backgroundColor: "#0035FF",
    borderRadius: "8px 0px 0px 8px"
  },
}));

export const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: 'transparent',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'Roboto', 'Arial', 'sans-serif'].join(','),
    '&:focus': {
      borderRadius: 8,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
    },
  },
}))(InputBase);

const UserTestCharts = ({
  userTestList,
  sharedUserTestList,
  isProcessing,
  isDarkTheme,
  getUserTestSectionList,
  getSharedUserTestSectionList,
  isSharedAccess,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { id } = useParams();

  const [order, setOrder] = useState('name');
  const [barView, setBarView] = useState('chart');
  const [days, setDays] = useState(7);
  const [testSectionList, setTestSectionList] = useState(isSharedAccess ? sharedUserTestList : userTestList);

  const getTestSectionList = (data) => {
    if (isSharedAccess) {
      getSharedUserTestSectionList({ id, data });
    } else {
      getUserTestSectionList(data);
    }
  };

  useEffect(() => {
    getTestSectionList({
      ordering: order,
      last_days: days,
    });
  }, []);

  useEffect(() => {
    if (isSharedAccess) {
      setTestSectionList(sharedUserTestList);
    } else {
      setTestSectionList(userTestList);
    }
  }, [sharedUserTestList, userTestList]);

  const handleOrderChange = (event, data) => {
    setOrder(data.props.value);
    getTestSectionList({
      ordering: data.props.value,
      last_days: days,
    });
  };

  const handleBarView = (value) => {
    setBarView(value)
  }

  const handleDaysChange = (event, data) => {
    setDays(data.props.value);
    getTestSectionList({
      ordering: order,
      last_days: data.props.value,
    });
  };

  return (
    <div className={classes.container}>
      <div>
        <Box mb={3} display="flex" justifyContent="space-between" className={classes.titleContainer}>
          <Typography component="div" variant="h6" style={{ width: '100%', fontWeight: '700' }}>
            {isSharedAccess ? t('dashboard_progress') : t('dashboard_your_progress')}
          </Typography>
          <div className={classes.selectContainer}>
            <Select
              labelId="order-label"
              id="order"
              value={order}
              MenuProps={{
                classes: { paper: classes.selectMenuPaper },
                transitionDuration: 0,
              }}
              onChange={handleOrderChange}
              input={<BootstrapInput variant="contained" style={{ width: '140px' }} />}
            >
              <MenuItem value={'name'}>{t('dashboard_alphabetic')}</MenuItem>
              <MenuItem value={'-result'}>{t('dashboard_best')}/{t('dashboard_worst')}</MenuItem>
              <MenuItem value={'result'}>{t('dashboard_worst')}/{t('dashboard_best')}</MenuItem>
            </Select>
            <Select
              labelId="days-label"
              id="days"
              value={days}
              MenuProps={{
                classes: { paper: classes.selectMenuPaper },
                transitionDuration: 0,
              }}
              onChange={handleDaysChange}
              input={<BootstrapInput variant="contained" style={{ width: '110px', marginLeft: '24px' }} />}
            >
              <MenuItem value={7}>{`7 ${t('dashboard_days')}`}</MenuItem>
              <MenuItem value={14}>{`14 ${t('dashboard_days')}`}</MenuItem>
              <MenuItem value={21}>{`21 ${t('dashboard_days')}`}</MenuItem>
            </Select>
            <div className={classes.switchContainer}>
              <div className={`${classes.switchItem} ${barView === 'line' ? isDarkTheme ? classes.activeFirstItem : classes.activeLightFirstItem : ''}`} onClick={() => handleBarView('line')}>
                <IconLineChart className={classes.lineBarTab} />
              </div>
              <div className={`${classes.switchItem} ${barView === 'chart' ? isDarkTheme ? classes.activeSecondItem : classes.activeLightSecondItem : ''}`}>
                <InsertChartOutlinedRoundedIcon onClick={() => handleBarView('chart')} />
              </div>
            </div>
          </div>
        </Box>
        <Grid container spacing={3} style={{ position: 'relative' }}>
          {isProcessing ? (
            <Grid item className={classes.emptyContainer}>
              <CircularProgress disableShrink />
            </Grid>
          ) : testSectionList && testSectionList.length ? testSectionList.map((item) => barView !== 'line' ? (
            <VerticalBarChart
              key={`user-test-chart-${item.id}`}
              item={item}
              isDarkTheme={isDarkTheme}
            />
          ) : (
            <UserTestChartItem
              key={`user-test-chart-${item.id}`}
              item={item}
              isDarkTheme={isDarkTheme}
            />
          )) : (
            <Grid item className={classes.emptyContainer}>
              <Typography className={classes.emptyText}>
                {t('dashboard_placeholder')}
              </Typography>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

UserTestCharts.propTypes = {
  userTestList: PropTypes.array.isRequired,
  sharedUserTestList: PropTypes.array.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  error: PropTypes.object.isRequired,
  getUserTestSectionList: PropTypes.func.isRequired,
  getSharedUserTestSectionList: PropTypes.func.isRequired,
  isDarkTheme: PropTypes.bool.isRequired,
  isSharedAccess: PropTypes.bool,
};

export default connect(
  (state) => ({
    userTestList: state.dashboard.userTestList,
    sharedUserTestList: state.sharedAccess.sharedUserTestSectionList,
    isProcessing: state.dashboard.processing,
    error: state.dashboard.error || {},
    isDarkTheme: state.general.isDarkTheme,
  }),
  {
    getSharedUserTestSectionList: getSharedUserTestSectionListRequest,
    getUserTestSectionList: getUserTestSectionListRequest,
  }
)(UserTestCharts);
