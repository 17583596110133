import { Box, Button, makeStyles, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import { clearErrorAction, loginRequest } from '../../redux/auth/authActions';
import PasswordField from '../shared/components/PasswordField';
import GoogleButton from './GoogleButton';

const useStyles = makeStyles((theme) => ({
  inputStyles: {
    borderRadius: '8px!important',
    border: `2px solid ${theme.overrides.inputBorder}`,
    backgroundColor: 'transparent',
  },
  labelStyles: {
    color: theme.overrides.labelFormColor,
  },
}));

const SignInSchema = yup.object().shape({
  email: yup.string().required('Email can not be empty'),
  password: yup.string().required('Password can not be empty'),
});

const SignIn = ({ isProcessing, error, login, clearError }) => {
  const classes = useStyles();
  const { handleSubmit, errors, register } = useForm({
    reValidateMode: 'onChange',
    validationSchema: SignInSchema,
  });

  const onSubmitHandler = (data) => {
    login({ ...data, email: (data.email || '').toLowerCase() });
  };

  return (
    <>
      <Helmet>
        <title>Myndful</title>
      </Helmet>
      <Typography align="center" variant="h5">
        Sign in
      </Typography>
      <form role="presentation" name="login" id="login" onSubmit={handleSubmit(onSubmitHandler)}>
        <Box className="form-input__group">
          <TextField
            variant="filled"
            inputRef={register}
            fullWidth
            InputLabelProps={{
              className: classes.labelStyles,
            }}
            InputProps={{ disableUnderline: true, className: classes.inputStyles }}
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : error.email ? error.email[0] : ''}
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={() => error.email && clearError()}
          />
        </Box>
        <Box className="form-input__group">
          <PasswordField
            inputRef={register}
            error={error}
            errors={errors}
            name="password"
            label="Password"
            id="password"
            autoComplete="current-password"
            onChange={() => error.password && clearError()}
          />
          <span className="forgot-password-link">
            <Link to="/auth/reset-password">Forgot Password?</Link>
          </span>
        </Box>
        <Button
          type="submit"
          disabled={isProcessing}
          fullWidth
          variant="contained"
          color="primary"
          className="auth-button"
        >
          Sign In
        </Button>
      </form>
      <GoogleButton label="Sign In with Google" />
      <Typography align="center" variant="body2" className="auth-text">
        <Box component="span" m={0.5}>
          Don't have an account yet?
        </Box>
        <Link to="/auth/sign-up">Sign Up</Link>
      </Typography>
    </>
  );
};

SignIn.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  error: PropTypes.object,
  login: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isProcessing: state.auth.processing,
    error: state.auth.error || {},
  }),
  {
    login: loginRequest,
    clearError: clearErrorAction,
  }
)(SignIn);
