import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';

import { showModalAction } from '../../redux/general/generalActions';
import {
  acceptSharedRequest,
  deleteSharedAccessRequest,
  sharedAccessClear,
} from '../../redux/shared-access/sharedAccessActions';
import { MODAL_TYPE_REQUEST_SHARED_ACCESS } from '../../redux/general/generalActionsTypes';

const useStyles = makeStyles((theme) => {
  const isDark = theme.palette.type === 'dark';

  return {
    content: {
      maxWidth: '825px',
      margin: '0 auto',
      padding: '24px 0',
      '& .MuiGrid-item': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    blockStyles: {
      backgroundColor: theme.overrides.accordionBackground,
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      marginBottom: '46px',
    },
    userBlock: {
      border: `1px solid ${isDark ? 'rgba(242, 242, 242, 0.2)' : '#f5f5f5'}`,
      borderRadius: '8px',

      '&:not(:last-child)': {
        marginBottom: '24px',
      },
    },
    shareAccessButton: {
      background: isDark ? '#406BE1' : 'linear-gradient(90deg, #0035FF 0%, #00C1FF 100%)',
      color: '#FFFFFF',
      borderRadius: '8px',
      minWidth: '133px',
      textTransform: 'capitalize',
      padding: '7px 24px!important',
      fontSize: '16px',
      [theme.breakpoints.down('sm')]: {
        minWidth: '90px',
        padding: '6px 24px!important',
      },
    },
    button: {
      margin: 0,
      padding: '0 !important',
      fontSize: '16px',
      lineHeight: '19px',

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    acceptRequestButton: {
      color: isDark ? '#fff' : '#0035FF',
    },
    revokeAccessButton: {
      color: '#F33935',
    },
    emptyText: {
      textAlign: 'center',
      fontStyle: 'italic',
      fontWeight: '300',
      color: theme.palette.text.primary,
    },
  };
});

const SharedAccess = ({ showModal, sharedUsers, isProcessing, deleteSharedAccess, acceptShared, clear }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { search } = useLocation();
  const history = useHistory();

  const users = useMemo(() => {
    return sharedUsers?.filter((user) => user.isActive) || [];
  }, [sharedUsers]);

  const addUser = () => {
    showModal({
      title: t('modal_shared_access_share'),
      type: MODAL_TYPE_REQUEST_SHARED_ACCESS,
      params: {
        sharingType: 'sharing',
        cancelAction: clear,
      },
    });
  };

  const revokeSharedAccessHandler = (id) => {
    deleteSharedAccess(id);
  };

  useEffect(() => {
    const params = new URLSearchParams(search);

    if (search && params.get('id')) {
      acceptShared({
        id: params.get('id'),
        secretKey: params.get('secret_key'),
        isRevoke: params.get('is_revoke') === 'true',
      });

      history.replace({ search: '' });
    }
  }, []);

  return (
    <div className={classes.blockStyles}>
      <Box
        className="main-form__section-title"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={4}
      >
        <p className={classes.blockTitle}>{t('m_shared_access')}</p>
        <Button
          className={classes.shareAccessButton}
          type="submit"
          variant="contained"
          color="primary"
          onClick={addUser}
          disabled={isProcessing}
        >
          {t('modal_shared_access_share')}
        </Button>
      </Box>
      <Box px={4} py={3}>
        {users?.length ? users.map((user) => (
          <Box
            key={`user-${user.id}-${user.client}`}
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
            px={3}
            py="27px"
            className={classes.userBlock}
          >
            <span>{user.username}</span>
            <Button
              onClick={() => revokeSharedAccessHandler(user.id)}
              disabled={isProcessing}
              className={classNames(classes.button, classes.revokeAccessButton)}
            >
              {t('shared_access_revoke_access')}
            </Button>
          </Box>
        )) : (
          <Typography className={classes.emptyText}>{t('shared_access_empty_text')}</Typography>
        )}
      </Box>
    </div>
  );
};

SharedAccess.propTypes = {
  showModal: PropTypes.func.isRequired,
  sharedUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isActive: PropTypes.bool.isRequired,
      username: PropTypes.string.isRequired,
      client: PropTypes.number,
      clientUsername: PropTypes.string,
    })
  ).isRequired,
  isDarkTheme: PropTypes.bool,
  isProcessing: PropTypes.bool,
  deleteSharedAccess: PropTypes.func.isRequired,
  acceptShared: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isDarkTheme: state.general.isDarkTheme,
    sharedUsers: state.profile.data.sharedAccess,
    isProcessing: state.sharedAccess.isProcessing,
  }),
  {
    showModal: showModalAction,
    deleteSharedAccess: deleteSharedAccessRequest,
    acceptShared: acceptSharedRequest,
    clear: sharedAccessClear,
  }
)(SharedAccess);
