import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Table, TableBody, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import PropTypes from 'prop-types';

import dashboard from '../../api/dashboard';
import { MODAL_TYPE_ALERT } from '../../redux/general/generalActionsTypes';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => {
  const isDark = theme.palette.type === 'dark';

  return {
    table: {
      minWidth: 1100,
      width: '100%',
      textAlign: 'left',
      borderSpacing: '0 12px !important',
      borderCollapse: 'separate !important',
      color: isDark ? 'white' : 'black',
      fontFamily: 'Inter',
      tableLayout: 'fixed',
      '& td': {
        padding: 16,
      },
    },
    tableHead: {
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '12px',
      textTransform: 'uppercase',
      '& th': {
        paddingBottom: '0px !important',
        paddingTop: '0px !important',
      },
      '& th:nth-child(1)': {
        paddingLeft: 24,
      },
    },
    bodyRowsWrapper: ({ fitWidthColsCount }) => ({
      backgroundColor: isDark ? '#3A5188' : '#ffffff',
      borderRadius: '8px !important ',
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
      '& td': {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
      },
      '& td:nth-child(1)': {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        paddingLeft: 24,
      },
      '& td:nth-child(n+3)': {
        width: `${100 / fitWidthColsCount}%`,
      },
      '& td:nth-last-child(1)': {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
      },
    }),
    colorWrapper: {
      display: 'flex',
      gap: '20px',
      fontSize: 14,
      marginBottom: 20,
    },
    colorSquare: {
      display: 'block',
      width: 24,
      height: 24,
      borderRadius: '100%',
    },
    noButton: {
      width: '20px',
      color: isDark ? '#FFFFFF' : '#0035FF',
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  };
});

export const SummaryTable = ({ data, sections, showModal }) => {
  const classes = useStyles({ fitWidthColsCount: 7 });
  const { t } = useTranslation();

  const history = useHistory();

  const skipColor = '#FFB805';
  const doneColor = '#0ABF53';
  const notDone = '#F33935';

  const colorResolver = ({ isChecked, isSkipped }) => {
    if (isChecked) {
      return doneColor;
    } else if (isSkipped) {
      return skipColor;
    }

    return notDone;
  };

  const redirectToPlanPage = () => {
    history.push('/personalized-plan');
  };

  const onClickContent = (section) => {
    if (!section.isChecked && !section.isSkipped) {
      dashboard.generateTaskBySection({ sectionId: section.sectionId })
        .then(() => redirectToPlanPage())
        .catch(() => showModal({
          type: MODAL_TYPE_ALERT,
          params: {
            type: 'error',
            message: t('alert_question_is_mandatory'),
          },
        }))
    }
  };

  return (
    <>
      <div className={classes.colorWrapper}>
        <Box display="flex" alignItems="center">
          <Box className={classes.colorSquare} bgcolor={doneColor} />
          <Box marginLeft={'5px'}>{`- ${t('done')}`}</Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box className={classes.colorSquare} bgcolor={skipColor} />
          <Box marginLeft={'5px'}>{`- ${t('skip')}`}</Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box className={classes.colorSquare} bgcolor={notDone} />
          <Box marginLeft={'5px'}>{`- ${t('not_done')}`}</Box>
        </Box>
      </div>

      <TableContainer>
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow key="table-head-row">
              <th>{t('date')}</th>
              {sections.map((item) => (
                <th key={`th-${item.id}`}>{item.name}</th>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, rowIndex) => (
              <TableRow key={rowIndex} className={classes.bodyRowsWrapper}>
                <td>{item.date}</td>
                {sections.map((section) => {
                  const content = Array.isArray(item[section.id]) ? (
                    item[section.id].map((el, index) => (
                      <Box
                        key={el.id}
                        marginTop={index ? '8px' : '0px'}
                        color={colorResolver({ isChecked: el.isChecked, isSkipped: el.isSkipped })}
                        onClick={() => onClickContent(el)}
                        style={{ cursor: !el.isChecked && !el.isSkipped ? 'pointer' : 'default' }}
                      >
                        {el.actionItem}
                      </Box>
                    ))
                  ) : (
                    <Box className={classes.noButton} onClick={redirectToPlanPage}>
                      {t('no')}
                    </Box>
                  );

                  return <td key={section.id}>{content}</td>;
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

SummaryTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      date: PropTypes.string,
      sleep: PropTypes.string,
      diet: PropTypes.string,
      exercise: PropTypes.string,
      'social & other': PropTypes.string,
      lifestyle: PropTypes.string,
      'mental health': PropTypes.string,
    })
  ),
  sections: PropTypes.array,
  showModal: PropTypes.func.isRequired,
};
