import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import ReactQuill from 'react-quill';
import { useTranslation } from 'react-i18next';

import 'react-quill/dist/quill.snow.css';

import { addNoteRequest, updateNoteRequest } from '../../../redux/journal/journalActions';
import { hideModalAction } from '../../../redux/general/generalActions';

const useStyles = makeStyles((theme) => {
  const isDark = theme.palette.type === 'dark';

  return {
    textarea: {
      fontFamily: 'Inter, Montserrat, Helvetica, Arial, sans-serif',
      margin: '8px 0 24px',
      border: isDark ? '2px solid #93A0BE' : '1px solid rgba(0, 0, 0, 0.2);',
      borderRadius: '8px',
      padding: '16px',
      height: '110px',
      minHeight: '110px',
      width: '100%',
      maxWidth: '100%',
      color: isDark ? '#ffffff' : '#000000',
      backgroundColor: isDark ? 'rgba(147, 160, 190, 0.1)' : 'white',
      outline: 'none',
      '&::-webkit-resizer': {
        backgroundImage: 'url("/images/icon-resize.svg")',
      },
      '&::placeholder': {
        color: isDark ? '#93A0BE' : 'rgba(42, 43, 42, 0.2)',
      },
    },
    rte: {
      margin: '8px 0 24px',
      color: isDark ? '#ffffff' : '#000000',
      backgroundColor: isDark ? 'rgba(147, 160, 190, 0.1)' : 'white',
      '& .ql-editor': {
        minHeight: '75px',
        '&.ql-blank::before': {
          color: isDark ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.6)',
        },
      },
      '& .ql-toolbar': {
        '& .ql-stroke': {
          stroke: isDark ? '#fff' : '#444',
        },
      },
      '& .ql-picker-label::before': {
        color: isDark ? '#fff' : '#444',
      },
    },
    button: {
      background: isDark ? '#406BE1' : 'linear-gradient(90deg, #0035FF 0%, #00C1FF 100%)',
      color: '#ffffff',
      textTransform: 'capitalize',
    },
  };
});

const ModalUpdateNote = ({ id, content, addNote, updateNote, hideModal }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [text, setText] = useState(content || '');

  const save = () => {
    if (id) {
      updateNote({ id, text });
    } else {
      addNote({ text });
    }
    hideModal();
  };

  return (
    <>
      <Typography>{t('modal_note_your_note')}</Typography>
      {typeof window !== 'undefined' ? (
        <ReactQuill
          theme="snow"
          className={classes.rte}
          placeholder={t('form_label_drop_some_words')}
          value={text}
          onChange={setText}
        />
      ) : (
        <input type="textarea" />
      )}
      <Box textAlign="right">
        <Button className={classes.button} onClick={save}>
          {content ? t('save') : t('journal_add_note')}
        </Button>
      </Box>
    </>
  );
};

ModalUpdateNote.propTypes = {
  isDarkTheme: PropTypes.bool.isRequired,
  id: PropTypes.number,
  content: PropTypes.string,
  addNote: PropTypes.func.isRequired,
  updateNote: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isDarkTheme: state.general.isDarkTheme,
  }),
  {
    addNote: addNoteRequest,
    updateNote: updateNoteRequest,
    hideModal: hideModalAction,
  }
)(ModalUpdateNote);
