import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DialogContentText } from '@material-ui/core';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';

import StripeForm from '../Stripe/StripeForm';
import {
  getAvailableSubscriptionsRequest,
  getSubscriptionPublicKeyRequest,
  createUserSubscriptionRequest,
  clearErrorAction,
} from '../../../redux/subscription/subscriptionActions';
import { hideModalAction } from '../../../redux/general/generalActions';

const ModalUpgradePlan = ({
  availableSubscriptions,
  plan,
  publicKey,
  email,
  isProcessing,
  getAvailableSubscriptions,
  getSubscriptionPublicKey,
  createUserSubscription,
  hideModal,
  warning,
}) => {
  const { t } = useTranslation();

  const [stripePromise, setStripePromise] = useState(null);
  const [premiumPlan, setPremiumPlan] = useState(null);

  useEffect(() => {
    getSubscriptionPublicKey();
    !plan && getAvailableSubscriptions();
  }, []);

  useEffect(() => {
    publicKey && setStripePromise(loadStripe(publicKey));
  }, [publicKey]);

  useEffect(() => {
    if (!plan && availableSubscriptions && availableSubscriptions[0]) {
      setPremiumPlan(availableSubscriptions[0].id);
    }
  }, [availableSubscriptions]);

  const submitHandler = (data) => {
    createUserSubscription({
      source: data.source.id,
      subscription: plan || premiumPlan,
    });
  };

  return (
    <>
      {warning && (
        <DialogContentText>
          {t('modal_plan_title_2')}
        </DialogContentText>
      )}
      <DialogContentText>
        {t('modal_plan_subtitle_2')}
      </DialogContentText>
      <div style={{ minHeight: '236px' }}>
        <Elements stripe={stripePromise}>
          {stripePromise && (
            <StripeForm
              email={email}
              isProcessing={isProcessing}
              disabled={!plan && !premiumPlan}
              hideModal={hideModal}
              onSubmit={submitHandler}
            />
          )}
        </Elements>
      </div>
    </>
  );
};

ModalUpgradePlan.propTypes = {
  availableSubscriptions: PropTypes.array.isRequired,
  plan: PropTypes.number,
  publicKey: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  error: PropTypes.object.isRequired,
  getAvailableSubscriptions: PropTypes.func.isRequired,
  getSubscriptionPublicKey: PropTypes.func.isRequired,
  createUserSubscription: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  warning: PropTypes.bool,
};

export default connect(
  (state) => ({
    availableSubscriptions: state.subscription.data,
    publicKey: state.subscription.publicKey,
    email: state.profile.data.email,
    isProcessing: state.subscription.processing,
    error: state.subscription.error || {},
  }),
  {
    getAvailableSubscriptions: getAvailableSubscriptionsRequest,
    getSubscriptionPublicKey: getSubscriptionPublicKeyRequest,
    createUserSubscription: createUserSubscriptionRequest,
    clearError: clearErrorAction,
    hideModal: hideModalAction,
  }
)(ModalUpgradePlan);
