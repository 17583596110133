import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(({ breakpoints }) => ({
  getEarlyAccess: {
    display: 'inline-block',
    background: '#325CC8',
    borderRadius: '8px',
    color: '#fff',
    padding: '18px 32px',
    fontWeight: 500,
    '&:visited': {
      color: '#fff',
    },
    '&:hover': {
      color: '#fff',
    },
    '&:active': {
      color: '#fff',
    },
    [breakpoints.down('xs')]: {
      padding: '10px 16px',
    },
  },
}));

const GetEarlyAccessButton = ({ isAuthenticated }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Link to={isAuthenticated ? '/personalized-plan' : '/auth/sign-up'} className={classes.getEarlyAccess}>
      {isAuthenticated ? t('m_open_app') : t('m_sign_up')}
    </Link>
  );
};

GetEarlyAccessButton.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, null)(GetEarlyAccessButton);
