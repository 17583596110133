import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { Check } from '@material-ui/icons';
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  FormHelperText,
  FormLabel,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';

import { clearErrorAction, createFeedbackRequest, getReCaptchaRequest } from '../../redux/feedback/feedbackActions';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '825px',
    padding: '24px 0',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  formContainer: {
    padding: '36px',
    borderRadius: '8px',
    backgroundColor: theme.palette.background.paper,
  },
  inputStyles: {
    borderRadius: '8px!important',
    border: `2px solid ${theme.overrides.inputBorder}`,
    backgroundColor: 'transparent',
  },
  labelStyles: {
    color: theme.overrides.labelFormColor,
  },
  recaptcha: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#FFFFFF',
    padding: '8px 16px',
    border: theme.palette.type === 'dark' ? '1px solid #909090' : '1px solid rgba(0,0,0, 0.2)',
    borderRadius: 4,
  },
  captchaText: {
    color: '#000000',
    fontSize: '13px',
  },
  checkbox: {
    width: '20px',
    height: '20px',
    borderRadius: 4,
    border: '2px solid #AEAEAE',
    '&:hover': {
      borderColor: '#A0A0A0',
    },
  },
  error: {
    borderColor: '#f44336',
  },
  button: {
    width: '220px',
    height: '48px',
    padding: '16px',
    letterSpacing: '1px',
    borderRadius: '8px',
    textTransform: 'capitalize',
    boxShadow: '0 2px 0 rgba(0, 0, 0, 0.043)',
    background: theme.palette.type === 'dark' ? '#406BE1' : 'linear-gradient(90deg, #0035FF 0%, #00C1FF 100%)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '20px 0',
    },
  },
}));

const FeedbackSchema = yup.object().shape({
  subject: yup.string().required('Subject can not be empty'),
  email: yup.string().email('Please provide correct email'),
  message: yup.string().required('Type your feedback'),
});

const Feedback = ({
  email,
  siteKey,
  isProcessing,
  requestSuccess,
  error,
  createFeedback,
  getReCaptcha,
  clearError,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [captchaError, setCaptchaError] = useState('');
  const [executing, setExecuting] = useState(false);
  const [captcha, setCaptcha] = useState('');

  const { handleSubmit, errors, control, reset } = useForm({
    reValidateMode: 'onChange',
    validationSchema: FeedbackSchema,
  });

  useEffect(() => {
    getReCaptcha();
  }, []);

  useEffect(() => {
    if (requestSuccess) {
      reset({
        subject: '',
        email: '',
        message: '',
      });
      setCaptchaError('');
      clearError();
    }
  }, [requestSuccess]);

  useEffect(() => {
    if (error.captcha) {
      getReCaptcha();
      setCaptchaError(error.captcha);
      setCaptcha('');
    }
  }, [error]);

  useEffect(() => {
    if (siteKey) {
      const script = document.createElement('script');
      script.async = true;
      script.className = 'recaptcha-script';
      script.type = 'text/javascript';
      script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
      const node = document.getElementsByTagName('script')[0];
      node.parentNode.insertBefore(script, node);
    }
  }, [siteKey]);

  const executeCaptcha = () => {
    if (!captcha && typeof window !== 'undefined' && window.grecaptcha) {
      setExecuting(true);
      setCaptchaError('');

      window.grecaptcha.ready(function () {
        window.grecaptcha
          .execute(siteKey, { action: 'submit' })
          .then(function (token) {
            setCaptcha(token);
            setExecuting(false);
          })
          .catch((e) => {
            console.error(e);
            setExecuting(false);
            setCaptchaError(e || 'Invalid domain for site key');
          });
      });
    }
  };

  const onSubmitHandler = (data) => {
    if (!captcha) return setCaptchaError('Confirm that you are human.');

    createFeedback({ ...data, captcha });
  };

  return (
    <>
      <Helmet>
        <title>Myndful</title>
      </Helmet>
      <div className={`main-form ${classes.container}`}>
        <Box my={3}>
          <div className="breadcrumbs-outer">
            <div className="breadcrumbs-wrapper">
              <Breadcrumbs className="breadcrumbs">
                <Link to="/dashboard">{t('m_dashboard')}</Link>
                <Link to="/feedback">{t('m_feedback')}</Link>
              </Breadcrumbs>
            </div>
          </div>
          <Typography variant="h4">{t('m_feedback')}</Typography>
        </Box>
        <Box mb={5}>
          <div className="main-form__section-text">
            <p>{t('feedback_form_title')}</p>
          </div>
        </Box>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <div className={`main-form__section ${classes.formContainer}`}>
            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <Box className="form-input__group">
                  <Controller
                    as={
                      <TextField
                        variant="filled"
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                          className: classes.inputStyles,
                        }}
                        InputLabelProps={{
                          className: classes.labelStyles,
                        }}
                        error={!!errors.subject || !!error.subject}
                        helperText={errors.subject ? errors.subject.message : error.subject ? error.subject[0] : ''}
                        id="subject"
                        label={t('form_label_subject')}
                        onChange={() => error.subject && clearError()}
                      />
                    }
                    name="subject"
                    control={control}
                    defaultValue=""
                  />
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box className="form-input__group">
                  <Controller
                    as={
                      <TextField
                        variant="filled"
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                          className: classes.inputStyles,
                        }}
                        InputLabelProps={{
                          className: classes.labelStyles,
                        }}
                        error={!!errors.email || !!error.email}
                        helperText={errors.email ? errors.email.message : error.email ? error.email[0] : ''}
                        id="email"
                        label={t('form_label_email_optional')}
                        onChange={() => error.email && clearError()}
                      />
                    }
                    name="email"
                    control={control}
                    defaultValue={email}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className="form-input__group">
                  <Controller
                    as={
                      <TextField
                        variant="filled"
                        fullWidth
                        error={!!errors.message || !!error.message}
                        helperText={errors.message ? errors.message.message : error.message ? error.message[0] : ''}
                        multiline={true}
                        InputProps={{
                          disableUnderline: true,
                          className: classes.inputStyles,
                        }}
                        InputLabelProps={{
                          className: classes.labelStyles,
                        }}
                        rows={11}
                        id="message"
                        label={t('form_label_message')}
                        onChange={() => error.message && clearError()}
                      />
                    }
                    name="message"
                    control={control}
                    defaultValue=""
                  />
                </Box>
              </Grid>
              <Grid item container justify="space-between" alignItems="center">
                <Grid item md={4} xs={12}>
                  <Box className="form-input__group">
                    <div className={classes.recaptcha}>
                      <Box display="flex" alignItems="center" onClick={executeCaptcha}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          width="40px"
                          height="40px"
                          mr={1}
                        >
                          {executing ? (
                            <CircularProgress style={{ color: '#2196F3' }} size={33} thickness={7} />
                          ) : captcha ? (
                            <Check style={{ width: '35px', height: '35px', color: '#009E55', fontSize: '1.5rem' }} />
                          ) : (
                            <div className={`${classes.checkbox}${captchaError ? ` ${classes.error}` : ''}`} />
                          )}
                        </Box>
                        <FormLabel className={classes.captchaText}>{t('form_label_captcha')}</FormLabel>
                      </Box>
                      <img src="/images/recaptcha.svg" alt="recaptcha" />
                      {captchaError && <FormHelperText error={true}>{captchaError}</FormHelperText>}
                    </div>
                  </Box>
                </Grid>
                <Grid container item md={4} xs={12} display="flex" justify="flex-end">
                  <Button
                    type="submit"
                    className={classes.button}
                    fullWidth
                    disabled={isProcessing}
                    variant="contained"
                    color="primary"
                  >
                    {t('feedback_submit_button')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </form>
      </div>
    </>
  );
};

Feedback.propTypes = {
  email: PropTypes.string.isRequired,
  siteKey: PropTypes.string.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  requestSuccess: PropTypes.bool.isRequired,
  error: PropTypes.object.isRequired,
  createFeedback: PropTypes.func.isRequired,
  getReCaptcha: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    email: state.profile.data.email || '',
    siteKey: state.feedback.recaptcha.siteKey,
    isProcessing: state.feedback.processing,
    requestSuccess: state.feedback.success,
    error: state.feedback.error || {},
  }),
  {
    createFeedback: createFeedbackRequest,
    getReCaptcha: getReCaptchaRequest,
    clearError: clearErrorAction,
  }
)(Feedback);
