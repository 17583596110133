import * as queryString from 'query-string';

import axios from './axios';
import { API_URL } from '../constants';

const template = {
  async getTemplateListApi(data) {
    try {
      const result = await axios.get(`${API_URL}/myndfulness-scope/templates/?${queryString.stringify(data)}`);

      return result.data;
    } catch (err) {
      if ((err.response && err.response.data) || err.response.detail) throw err.response.data;
      throw err;
    }
  },
  async getSingleTemplateApi(data) {
    try {
      const result = await axios.get(`${API_URL}/myndfulness-scope/templates/${data.id}/`);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async createTemplateApi(data) {
    try {
      const result = await axios.post(`${API_URL}/myndfulness-scope/templates/`, data);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async updateTemplateApi({ id, ...data }) {
    try {
      const result = await axios.put(`${API_URL}/myndfulness-scope/templates/${id}/`, data);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async updateSingleTemplateApi({ id, ...data }) {
    try {
      const result = await axios.patch(`${API_URL}/myndfulness-scope/templates/${id}/`, data);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async cloneTemplateApi({ id, ...data }) {
    try {
      const result = await axios.post(`${API_URL}/myndfulness-scope/templates/${id}/clone/`, data);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async deleteTemplateApi(data) {
    try {
      const result = await axios.delete(`${API_URL}/myndfulness-scope/templates/${data.id}/`,);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async deselectTemplateApi({ id, targetUser }) {
    try {
      const result = await axios.post(`${API_URL}/myndfulness-scope/templates/${id}/remove/`, { targetUser })
      return result.data
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async applyTemplateApi({ id, targetUser }) {
    try {
      const result = await axios.post(`${API_URL}/myndfulness-scope/templates/${id}/apply/`, { targetUser });

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default template;
