export const GET_LAST_USER_TEST_REQUEST = 'GET_LAST_USER_TEST_REQUEST';
export const GET_LAST_USER_TEST_SUCCESS = 'GET_LAST_USER_TEST_SUCCESS';

export const GET_USER_TEST_SECTION_LIST_REQUEST = 'GET_USER_TEST_SECTION_LIST_REQUEST';
export const GET_USER_TEST_SECTION_LIST_SUCCESS = 'GET_USER_TEST_SECTION_LIST_SUCCESS';

export const GET_FOCUS_POINT_ARTICLE_REQUEST = 'GET_FOCUS_POINT_ARTICLE_REQUEST';
export const GET_FOCUS_POINT_ARTICLE_SUCCESS = 'GET_FOCUS_POINT_ARTICLE_SUCCESS';

/** START **/

export const GET_STANDARD_GOALS_REQUEST = 'GET_STANDARD_GOALS_REQUEST';
export const GET_STANDARD_GOALS_SUCCESS = 'GET_STANDARD_GOALS_SUCCESS';

export const GET_PREVIOUS_GOALS_REQUEST = 'GET_PREVIOUS_GOALS_REQUEST';
export const GET_PREVIOUS_GOALS_SUCCESS = 'GET_PREVIOUS_GOALS_SUCCESS';

export const GET_RANDOM_GOALS_REQUEST = 'GET_RANDOM_GOALS_REQUEST';

export const UPDATE_STANDARD_GOAL_REQUEST = 'UPDATE_STANDARD_GOAL_REQUEST';
export const UPDATE_STANDARD_GOAL_SUCCESS = 'UPDATE_STANDARD_GOAL_SUCCESS';
export const UPDATE_PREVIOUS_GOAL_SUCCESS = 'UPDATE_PREVIOUS_GOAL_SUCCESS';

export const DELETE_STANDARD_GOAL = 'DELETE_STANDARD_GOAL';

export const GET_CUSTOM_GOALS_REQUEST = 'GET_CUSTOM_GOALS_REQUEST';
export const GET_CUSTOM_GOALS_SUCCESS = 'GET_CUSTOM_GOALS_SUCCESS';

export const GET_SINGLE_CUSTOM_GOAL_REQUEST = 'GET_SINGLE_CUSTOM_GOAL_REQUEST';
export const GET_SINGLE_CUSTOM_GOAL_SUCCESS = 'GET_SINGLE_CUSTOM_GOAL_SUCCESS';

export const CREATE_CUSTOM_GOAL_REQUEST = 'CREATE_CUSTOM_GOAL_REQUEST';
export const CREATE_CUSTOM_GOAL_SUCCESS = 'CREATE_CUSTOM_GOAL_SUCCESS';

export const UPDATE_CUSTOM_GOAL_REQUEST = 'UPDATE_CUSTOM_GOAL_REQUEST';
export const UPDATE_CUSTOM_GOAL_SUCCESS = 'UPDATE_CUSTOM_GOAL_SUCCESS';

export const UPDATE_CUSTOM_TEMPLATE_GOALS_REQUEST = 'UPDATE_CUSTOM_TEMPLATE_GOALS_REQUEST';
export const UPDATE_CUSTOM_TEMPLATE_GOALS_SUCCESS = 'UPDATE_CUSTOM_TEMPLATE_GOALS_SUCCESS';

export const DELETE_CUSTOM_GOAL_REQUEST = 'DELETE_CUSTOM_GOAL_REQUEST';
export const DELETE_CUSTOM_GOAL_SUCCESS = 'DELETE_CUSTOM_GOAL_SUCCESS';

export const SKIP_CUSTOM_GOAL_REQUEST = 'SKIP_CUSTOM_GOAL_REQUEST';

/**  END  **/

export const DASHBOARD_REQUEST_ERROR = 'DASHBOARD_REQUEST_ERROR';
export const CLEAR_FOCUS_POINT_ARTICLE = 'CLEAR_FOCUS_POINT_ARTICLE';
export const CLEAR_ERROR = 'CLEAR_DASHBOARD_ERROR';

export const TOGGLE_PROCESSING = 'TOGGLE_DASHBOARD_PROCESSING';
