import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: (props) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: `${props.size}px`,
    height: `${props.size}px`,
    marginBottom: '32px',
    borderRadius: '50%',
  }),
  inner: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    textAlign: 'center',
  },
  numeric: {
    fontSize: '20px',
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  text: (props) => ({
    textTransform: props.showMaxResult ? 'lowercase' : 'uppercase',
    color: theme.palette.text.primary,
    fontSize: '20px',
    '& span': {
      fontWeight: 700,
    },
  }),
  chart: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
}));

const ScoreResult = ({ maxResult, userResult, size, showMaxResult }) => {
  const classes = useStyles({
    size: size,
    showMaxResult: showMaxResult,
  });
  const { t } = useTranslation();

  const maxResultRounded = Math.round(maxResult) || 0;
  const userResultRounded = Math.round(userResult) || 0;
  const resultPercent = Math.round((userResult / maxResult) * 100);

  return (
    <div className={classes.container}>
      <div className={classes.inner}>
        <div>
          <div className={classes.numeric}>{userResultRounded}</div>
          <div className={classes.text}>
            {t('out_of')} <span>{maxResultRounded}</span>
          </div>
        </div>
        <div className={classes.chart}>
          <CircularProgressbar
            value={userResultRounded}
            maxValue={maxResult}
            strokeWidth={10}
            styles={buildStyles({
              pathColor: resultPercent < 50 ? 'transparent' : resultPercent < 70 ? '#1AC7FF' : '#15956C',
              trailColor: 'rgba(242,242,242, 0.2)',
            })}
          />
        </div>
      </div>
    </div>
  );
};

ScoreResult.defaultProps = {
  maxResult: 100,
  userResult: 0,
  size: 200,
};

ScoreResult.propTypes = {
  maxResult: PropTypes.number,
  userResult: PropTypes.number,
  size: PropTypes.number,
  showMaxResult: PropTypes.bool,
};

export default ScoreResult;
