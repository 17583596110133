import axios from './axios';
import * as queryString from 'query-string';

import { API_URL } from '../constants';

const dashboard = {
  async getUserTestSection(data) {
    try {
      const result = await axios.get(`${API_URL}/user-test/sections/${data.id}/`);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getUserTestSectionList(data) {
    try {
      const result = await axios.get(`${API_URL}/user-test/sections/?${queryString.stringify(data)}`);

      return result.data;
    } catch (err) {
      if (err.response) throw err.response;
      throw err;
    }
  },
  async getFocusPointArticle(data) {
    try {
      const result = await axios.get(`${API_URL}/focus-point-articles/${data.slug}/`);

      return result.data;
    } catch (err) {
      if (err.response) throw err.response;
      throw err;
    }
  },
  async getPreviousGoalsApi() {
    try {
      const result = await axios.get(`${API_URL}/myndfulness-scope/yesterday/`);

      return result.data;
    }
    catch (err) {
      if (err.response) throw err.response;
      throw err;
    }
  },
  async getStandardGoalsApi() {
    try {
      const result = await axios.get(`${API_URL}/myndfulness-scope/`);

      return result.data;
    } catch (err) {
      if (err.response) throw err.response;
      throw err;
    }
  },
  async getRandomGoalsApi() {
    try {
      const result = await axios.post(`${API_URL}/myndfulness-scope/generate-extra/`);

      return result.data;
    } catch (err) {
      if (err.response) throw err.response;
      throw err;
    }
  },
  async updateStandardGoalApi(data) {
    try {
      const result = await axios.put(`${API_URL}/myndfulness-scope/${data.id}/`, data.body);

      return result.data;
    } catch (err) {
      if (err.response) throw err.response;
      throw err;
    }
  },
  async getCustomGoalsApi() {
    try {
      const result = await axios.get(`${API_URL}/myndfulness-scope/custom/`);

      return result.data;
    } catch (err) {
      if (err.response) throw err.response;
      throw err;
    }
  },
  async getCustomGoalApi(data) {
    try {
      const result = await axios.get(`${API_URL}/myndfulness-scope/custom/${data}/`);

      return result.data;
    } catch (err) {
      if (err.response) throw err.response;
      throw err;
    }
  },
  async createCustomGoalApi(data) {
    try {
      const result = await axios.post(`${API_URL}/myndfulness-scope/custom/`, data);

      return result.data;
    } catch (err) {
      if (err.response) throw err.response;
      throw err;
    }
  },
  async updateCustomGoalApi(data) {
    try {
      const result = await axios.patch(`${API_URL}/myndfulness-scope/custom/${data.id}/`, data.body);

      return result.data;
    } catch (err) {
      if (err.response) throw err.response;
      throw err;
    }
  },
  async updateCustomTemlpateGoalsApi(data) {
    try {
      const result = await axios.post(`${API_URL}/myndfulness-scope/custom/check-template/`, data);

      return result.data;
    } catch (err) {
      if (err.response) throw err.response;
      throw err;
    }
  },
  async skipTaskApi(data) {
    const { id, isChecked, skipReason } = data;
    try {
      const result = await axios.patch(`${API_URL}/myndfulness-scope/${id}/`, {
        isChecked,
        skipReason,
        isSkipped: true,
      });

      return result.data;
    } catch (err) {
      if (err.response) throw err.response;
      throw err;
    }
  },
  async deleteCustomGoalApi(data) {
    try {
      const result = await axios.delete(`${API_URL}/myndfulness-scope/custom/${data.id}/`, data);

      return result.data;
    } catch (err) {
      if (err.response) throw err.response;
      throw err;
    }
  },
  async deleteCustomTemplateApi(data) {
    try {
      const result = await axios.delete(`${API_URL}/myndfulness-scope/templates/${data.id}/`,);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getSummary() {
    try {
      const result = await axios.get(`${API_URL}/myndfulness-scope/summary/`);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async generateTaskBySection(data) {
    try {
      const result = await axios.post(`${API_URL}/myndfulness-scope/generate-by-section/`, data);

      return result.data;
    } catch (err) {
      if (err.response) throw err.response;
      throw err;
    }
  },
};

export default dashboard;
