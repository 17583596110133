import * as types from './templateActionsTypes';
import * as authTypes from '../auth/authActionsTypes';

export const initialState = {
  templateList: {
    count: 0,
    page: 1,
    page_size: 10,
    results: [],
  },
  singleTemplate: {},
  templateShow: "personal",
  processing: false,
  error: null,
};

const template = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };

    case types.GET_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        templateList: { ...state.templateList, ...action.payload },
        error: null,
      };

    case types.GET_SINGLE_TEMPLATE_SUCCESS:
    case types.UPDATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        singleTemplate: action.payload,
        error: null,
      };
    case types.STATUS_TEMPLATE_SHOW_SUCCESS:
      return {
        ...state,
        templateShow: action.payload
      }
    case types.SET_TEMPLATE_PARAMS_ACTION:
      return {
        ...state,
        templateList: { ...state.templateList, ...action.payload },
        error: null,
      };

    case types.CLEAR_SINGLE_TEMPLATE_ACTION:
      return {
        ...state,
        singleTemplate: {},
        error: null,
      };

    case authTypes.LOGOUT_REQUEST:
      return initialState;

    default:
      return state;
  }
};

export default template;
