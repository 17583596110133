import { Button, DialogActions, makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateSingleTemplateRequest } from '../../../redux/template/templateActions';

const useStyles = makeStyles((theme) => ({
  group: {
    marginBottom: '24px',
  },
  radioGroup: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    marginBottom: '24px',
  },
  selector: {
    margin: '8px 0 0 !important',

    '& .MuiFormControl-root': {
      margin: 0,
      padding: 0,
    },
  },
  selectMenuPaper: {
    backgroundColor: theme.overrides.filterListBackground,
  },
  button: {
    minWidth: '104px',
    marginLeft: '16px',
    background: theme.palette.type === 'dark' ? '#406BE1' : 'linear-gradient(90deg, #0035FF 0%, #00C1FF 100%)',
  },
  cancelButton: {
    minWidth: '104px',
    marginLeft: '16px',
    '&.MuiButton-outlined.MuiButtonBase-root': {
        color: theme.palette.type === 'dark' ? "#fff" : "#00000060",
        borderColor: theme.palette.type === 'dark' ? "#ffffff60" : "#00000020",
        backgroundColor: 'transparent',
        transparent: "backgorund .3s ease",
        '&:hover': {
            backgroundColor: '#00000020',
        }
    }
  },
}));

export const ModalTemplateShare = ({ id, profileId, data, hideModal, updateTemplate }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const onSubmitHandler = () => {
    updateTemplate(
      {
        ...data,
        id,
        questions: [],
        sectionId: data.section.id,
        isPublic: true
      }
    )
  };
  return (
    <>
        <p>
            {t('modal_template_description_share')}
        </p>
      <DialogActions>
        <Button className={classes.cancelButton} variant="outlined" color="primary" onClick={hideModal}>
          {t('cancel')}
        </Button>
        <Button className={classes.button} variant="contained" color="primary" onClick={onSubmitHandler}>
          {t('apply')}
        </Button>
      </DialogActions>
    </>
  );
};

ModalTemplateShare.propTypes = {
  id: PropTypes.number.isRequired,
  profileId: PropTypes.number,
  deselectRequest: PropTypes.func.isRequired,
  hideModal: PropTypes.func,
};

export default connect(
  (state) => ({
    profileId: state.profile.data.id,
    isProcessing: state.template.processing,
  }), {
    updateTemplate: updateSingleTemplateRequest,
  }
)(ModalTemplateShare);
