import { all, put, call, takeLatest } from 'redux-saga/effects';

import sharedAccess from '../../api/sharedAccess';
import * as types from './sharedAccessActionTypes';
import * as profileTypes from '../profile/profileActionsTypes';
import * as generalTypes from '../general/generalActionsTypes';
import { getCommonErrorProps } from '../reduxHelpers';

function* requestAccess({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(sharedAccess.requestAccess, payload);
    yield put({ type: types.REQUEST_ACCESS_SUCCESS });
  } catch (error) {
    yield put({ type: types.SHARED_ACCESS_REQUEST_ERROR, payload: { error: error?.data } });
    if (error.status === 429) {
      const seconds = error?.data?.detail.match(/\d+/)[0];
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const becomeAvailable =
        hours === 0 ? (minutes === 0 ? `${seconds} seconds` : `${minutes} minutes`) : `${hours} hours`;
      yield put(getCommonErrorProps(`Daily limit is exhausted, will be available after ${becomeAvailable}`));
    } else if (!error.data?.email) {
      yield put(getCommonErrorProps(error.data));
    }
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getSharedUsers() {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const response = yield call(sharedAccess.getSharedUsers);
    yield put({ type: types.REQUEST_SHARED_USERS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.SHARED_ACCESS_REQUEST_ERROR, payload: { error } });
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getSharedUserResult({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const response = yield call(sharedAccess.getSharedUserResult, payload);
    yield put({ type: types.REQUEST_SHARED_USER_RESULT_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.SHARED_ACCESS_REQUEST_ERROR, payload: { error } });
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* updateSharedAccess({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(sharedAccess.updateSharedAccess, payload);
    yield put({ type: profileTypes.GET_PROFILE_REQUEST });
  } catch (error) {
    yield put({ type: types.SHARED_ACCESS_REQUEST_ERROR, payload: { error } });
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* deleteSharedAccess({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(sharedAccess.deleteSharedAccess, payload);
    yield put({ type: profileTypes.GET_PROFILE_REQUEST });
    yield put({ type: types.DELETE_SHARED_ACCESS_SUCCESS, payload });
  } catch (error) {
    yield put({ type: types.SHARED_ACCESS_REQUEST_ERROR, payload: { error } });
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getShareUserSectionsList({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const response = yield call(sharedAccess.getSharedUserTestSectionList, payload);
    yield put({ type: types.GET_SHARED_USER_SECTIONS_LIST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.SHARED_ACCESS_REQUEST_ERROR, payload: { error } });
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* acceptSharedAccessRequest({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(sharedAccess.acceptSharedRequest, payload);
    yield put({ type: profileTypes.GET_PROFILE_REQUEST });

    const message = payload.isRevoke
      ? 'Sharing access was successfully revoked'
      : 'Sharing access was successfully granted';

    yield put({
      type: generalTypes.SHOW_MODAL_ACTION,
      payload: {
        params: { message, type: 'success' },
        type: generalTypes.MODAL_TYPE_ALERT,
      },
    });
  } catch (error) {
    yield put({ type: types.SHARED_ACCESS_REQUEST_ERROR, payload: { error } });
    yield put(getCommonErrorProps(error));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.REQUEST_ACCESS, requestAccess),
    yield takeLatest(types.REQUEST_SHARED_USERS, getSharedUsers),
    yield takeLatest(types.REQUEST_SHARED_USER_RESULT, getSharedUserResult),
    yield takeLatest(types.UPDATE_SHARED_ACCESS, updateSharedAccess),
    yield takeLatest(types.DELETE_SHARED_ACCESS, deleteSharedAccess),
    yield takeLatest(types.GET_SHARED_USER_SECTIONS_LIST, getShareUserSectionsList),
    yield takeLatest(types.ACCEPT_SHARED_REQUEST, acceptSharedAccessRequest),
  ]);
}
