import * as authTypes from '../auth/authActionsTypes';
import * as types from './dashboardActionsTypes';

export const initialState = {
  userTestList: [],
  lastUserTestResult: {},
  singleArticle: {},
  standardGoals: [],
  customGoals: [],
  previewGoals: [],
  customGoal: {},
  processing: false,
  error: null,
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };

    case types.GET_USER_TEST_SECTION_LIST_SUCCESS:
      try {
        return {
          ...state,
          userTestList: action.payload
            .filter((item) => item.data && item.data.length)
            .map((item) => {
              const min = item.data.reduce((acc, cur) => (acc > cur.result ? cur.result : acc), null);
              const max = item.data.reduce((acc, cur) => (acc < cur.result ? cur.result : acc), null);

              return {
                ...item,
                minResult: min || 0,
                maxResult: Math.round(max) || 0,
              };
            }),
          error: null,
        };
      } catch (e) {
        console.error('dashboard reducer: ', e);

        return state;
      }

    case types.GET_LAST_USER_TEST_SUCCESS:
      return {
        ...state,
        lastUserTestResult: action.payload,
      };

    case types.GET_FOCUS_POINT_ARTICLE_SUCCESS:
      return {
        ...state,
        singleArticle: action.payload,
      };

    case types.GET_PREVIOUS_GOALS_SUCCESS:
      return {
        ...state,
        previewGoals: action.payload,
      };
    case types.UPDATE_PREVIOUS_GOAL_SUCCESS:
      return {
        ...state,
        previewGoals: state.previewGoals.map((x) => (x.id === action.payload.id ? { ...x, ...action.payload } : x)),
      };
    case types.GET_STANDARD_GOALS_SUCCESS:
      return {
        ...state,
        standardGoals: action.payload,
      };

    case types.UPDATE_STANDARD_GOAL_SUCCESS:
      return {
        ...state,
        standardGoals: state.standardGoals.map((x) => (x.id === action.payload.id ? { ...x, ...action.payload } : x)),
      };
    case types.DELETE_STANDARD_GOAL:
      return {
        ...state,
        standardGoals: state.standardGoals.filter((x) => x.id !== action.payload.id),
      };

    case types.GET_CUSTOM_GOALS_SUCCESS:
      return {
        ...state,
        customGoals: action.payload,
      };

    case types.GET_SINGLE_CUSTOM_GOAL_SUCCESS:
      return {
        ...state,
        customGoal: action.payload,
      };

    case types.CREATE_CUSTOM_GOAL_SUCCESS:
      return {
        ...state,
        customGoals: state.customGoals.concat(action.payload),
      };

    case types.UPDATE_CUSTOM_GOAL_SUCCESS:
      return {
        ...state,
        customGoals: state.customGoals.map((x) => (x.id === action.payload.id ? { ...x, ...action.payload } : x)),
      };

    case types.DELETE_CUSTOM_GOAL_SUCCESS:
      return {
        ...state,
        customGoals: state.customGoals.filter((x) => x.id !== action.payload.id),
      };

    case types.DASHBOARD_REQUEST_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case types.CLEAR_FOCUS_POINT_ARTICLE:
      return {
        ...state,
        singleArticle: {},
      };

    case types.CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    case authTypes.LOGOUT_REQUEST:
      return initialState;

    default:
      return state;
  }
};

export default dashboard;
