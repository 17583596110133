import React from 'react';
import { makeStyles, Icon } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import parse from 'react-html-parser';

import { showModalAction } from '../../redux/general/generalActions';
import { removeNoteRequest } from '../../redux/journal/journalActions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles((theme) => {
  const isDark = theme.palette.type === 'dark';

  return {
    wrapper: {
      background: isDark ? '#3A5188' : '#ffffff',
      padding: '20px',
      marginBottom: '12px',
      marginTop: 0,
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      transition: 'box-shadow .3s ease',
      cursor: 'pointer',
      "&:hover":{
        boxShadow: `0px 0px 10px ${isDark ? 'rgb(255 255 255 / 49%)': 'rgb(0 0 0 / 50%)'}`,
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse'
      },
    },
    actions: {
      display: 'flex',
      gap: '36px',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'space-between',
        width: '100%'
      },
    },
    content: {
      padding: '4px',
      fontSize: "24px",
      fontWeight: "bold"
    },
    dateColumn: {
      textAlign: 'center',
    },
    iconColumn: {
      display: 'flex',
      gap: '24px',
      '& span': {
        cursor: 'pointer',
        transition: '0.2s',

        '&:hover': {
          opacity: 0.5,
        },
      },
    },
  };
});

const Note = ({ id, content, createdAt, isDarkTheme, removeNote }) => {
  const classes = useStyles();
  const history = useHistory();

  const { editIconSrc, removeIconSrc } = isDarkTheme
    ? { editIconSrc: '/images/icon-edit-dark.svg', removeIconSrc: '/images/icon-remove-dark.svg' }
    : { editIconSrc: '/images/icon-edit-light.svg', removeIconSrc: '/images/icon-remove-light.svg' };

  const editNote = (e) => {
    e.stopPropagation();
    
    history.push(`/journal/${id}/edit`)
  };

  const removeNoteHandler = (e) => {
    e.stopPropagation();

    removeNote(id);
  };

  const handleOpenNote = (e) => {
    history.push(`/journal/${id}/view`)
  }

  return (
    <div className={classes.wrapper} onClick={handleOpenNote}>
      <div className={classes.content}>
        {typeof content === 'string' && content.length ? parse(content) : ''}
      </div>
      <div className={classes.actions}>
        <div className={classes.dateColumn}>{moment(createdAt).format('LL')}</div>
        <div className={classes.iconColumn}>
          <Icon onClick={editNote}>
            <img src={editIconSrc} alt="edit icon" />
          </Icon>
          <Icon onClick={removeNoteHandler}>
            <img src={removeIconSrc} alt="remove icon" />
          </Icon>
        </div>
      </div>
    </div>
  );
};

Note.propTypes = {
  showModal: PropTypes.func.isRequired,
  isDarkTheme: PropTypes.bool,
  isProcessing: PropTypes.bool,
  id: PropTypes.number,
  content: PropTypes.string,
  date: PropTypes.string,
  removeNote: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isDarkTheme: state.general.isDarkTheme,
  }),
  {
    showModal: showModalAction,
    removeNote: removeNoteRequest,
  }
)(Note);
