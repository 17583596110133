import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: (props) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: `${props.size}px`,
    height: `${props.size}px`,
    marginBottom: '8px',
    borderRadius: '50%',
  }),
  inner: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    textAlign: 'center',
  },
  resultContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  numeric: () => ({
    fontSize: '28px',
    color: theme.palette.text.primary,
    background: 'linear-gradient(90deg, #0035FF 0%, #198EFB 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: theme.palette.type === 'dark' ? 'unset' : 'transparent',
  }),
  text: (props) => ({
    textTransform: props.showMaxResult ? 'lowercase' : 'uppercase',
    color: theme.palette.type === 'dark' ? '#FFFFFF' : '#757575',
    fontSize: '13px',
  }),
  chart: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
}));

const ScoreResultSmall = ({ maxResult, userResult, size, showMaxResult }) => {
  const { t } = useTranslation();

  const userResultRounded = Math.round(userResult);
  const resultPercent = Math.round((userResult / maxResult) * 100);
  const classes = useStyles({
    size: size,
    showMaxResult: showMaxResult,
  });

  return (
    <div className={classes.container}>
      <div className={classes.inner}>
        <div className={classes.resultContainer}>
          <div className={classes.numeric}>{userResultRounded}</div>
          <div className={classes.text}>{t('your_score')}</div>
        </div>
        <div className={classes.chart}>
          <CircularProgressbar
            value={userResultRounded}
            maxValue={maxResult}
            strokeWidth={10}
            styles={buildStyles({
              pathColor: resultPercent < 50 ? 'transparent' : resultPercent < 70 ? '#1AC7FF' : '#15956C',
              trailColor: 'rgba(242,242,242, 0.2)',
            })}
          />
        </div>
      </div>
    </div>
  );
};

ScoreResultSmall.defaultProps = {
  maxResult: 100,
  userResult: 0,
  size: 200,
};

ScoreResultSmall.propTypes = {
  maxResult: PropTypes.number,
  userResult: PropTypes.number,
  size: PropTypes.number,
  showMaxResult: PropTypes.bool,
};

export default ScoreResultSmall;
