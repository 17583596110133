import React from 'react';
import PropTypes from 'prop-types';

function EmptyLayout({ children }) {
  return <>{children}</>;
}

EmptyLayout.propTypes = {
  children: PropTypes.any,
};

export default EmptyLayout;
