import { Button, DialogActions, makeStyles } from '@material-ui/core';
import React from 'react';
import { deselectTemplateRequest } from '../../../redux/template/templateActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  group: {
    marginBottom: '24px',
  },
  radioGroup: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    marginBottom: '24px',
  },
  selector: {
    margin: '8px 0 0 !important',

    '& .MuiFormControl-root': {
      margin: 0,
      padding: 0,
    },
  },
  selectMenuPaper: {
    backgroundColor: theme.overrides.filterListBackground,
  },
  button: {
    minWidth: '104px',
    marginLeft: '16px',
    background: theme.palette.type === 'dark' ? '#406BE1' : 'linear-gradient(90deg, #0035FF 0%, #00C1FF 100%)',
  },
}));

export const ModalTemplateDeselect = ({ id, profileId, deselectRequest }) => {
  const classes = useStyles();
  const { t } = useTranslation();


  const onSubmitHandler = () => {
    deselectRequest({ id, targetUser: profileId });
  };

  return (
    <>
        <p>
            {t('modal_template_description_deselect')}
        </p>
      <DialogActions>
        <Button className={classes.button} variant="contained" color="primary" onClick={onSubmitHandler}>
          {t('apply')}
        </Button>
      </DialogActions>
    </>
  );
};

ModalTemplateDeselect.propTypes = {
  id: PropTypes.number.isRequired,
  profileId: PropTypes.number,
  deselectRequest: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    profileId: state.profile.data.id,
    isProcessing: state.template.processing,
  }),
  {
    deselectRequest: deselectTemplateRequest,
  }
)(ModalTemplateDeselect);
