import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ScoreResult from '../Questionnaire/ScoreResult';
import ScoreResultSmall from '../Questionnaire/ScoreResultSmall';
import { SectionTitle } from '../shared/components/SectionTitle';
import Preloader from '../shared/components/Preloader';
import QuestionnaireGroup from '../shared/QuestionnaireGroup/QuestionnaireGroup';
import Notice from './Notice';
import UserTestCharts from './UserTestCharts';
import { getLastUserTestRequest } from '../../redux/dashboard/dashboardActions';
import { FOCUS_POINT_LOW_TYPE } from '../../constants';
import AchievementsGroup from '../shared/AchievementsGroup/AchievementsGroup';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  focusPoints: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    gap: '30px',
    padding: '12px',
    [theme.breakpoints.down(1120)]: {
      flexWrap: 'wrap',
      '& > div': {
        minWidth: 'calc(49% - 12px) !important',
        width: 'calc(49% - 12px) !important',
      },
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      gap: 0,
      '& > div': {
        minWidth: '100% !important',
        width: '100% !important',
        marginBottom: '30px',
      },
    },
  },
  scoreContainer: {
    position: 'relative',
    padding: '20px 24px',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.type === 'dark' ? 'transparent' : '#F0F0F0'}`,
    borderRadius: '8px',
    width: '100%',
    [theme.breakpoints.down(1120)]: {
      height: 'auto',
    },
  },
  iconMessage: {
    marginBottom: '69px',
  },
  scoreDate: {
    marginTop: 'auto',
    '& span': {
      fontSize: '12px',
      color: theme.palette.type === 'dark' ? '#F2F2F2' : '#757575',
    },
  },
  scoreText: {
    color: theme.palette.text.primary,
    marginBottom: '25px',
    display: 'flex',
    textAlign: 'center',
    marginTop: '8px',
    fontSize: '12px',
  },
  improveBlock: {
    marginTop: 'auto',
  },
  scoreImprove: {
    display: '-webkit-inline-box',
    fontWeight: 600,
    alignItems: 'center',
    marginTop: '40px',
    color: theme.palette.text.primary,
    background: 'linear-gradient(90deg, #0035FF 0%, #198EFB 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: theme.palette.type === 'dark' ? 'unset' : 'transparent',
    '& img': {
      marginRight: '14px',
      position: 'relative',
      top: '2px',
    },
  },
  scoreInner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '320px',
    height: '100%',
    minHeight: '365px',
    margin: 'auto',
  },
  scoreTitle: {
    fontSize: '1.25rem',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '50px',
    '& img': {
      width: '23px',
      height: '23px',
      marginRight: '12px',
    },
  },
  titleContainer: {
    marginBottom: '35px',
    paddingBottom: '10px',
    fontWeight: 700,
    borderBottom: `1px solid ${theme.palette.type === 'dark' ? 'rgba(242, 242, 242, 0.8)' : 'rgba(0, 0, 0, 0.12);'}`,
  },
  flexJustify: {
    marginTop: '10%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      marginTop: '80px',
    },
  },
  questionnaire: {
    padding: '12px',
    width: '100%',
    marginBottom: '80px',
    '& .achievments-btn': {
      padding: '9px 12px',
      border: `1px solid ${theme.palette.type === 'dark' ? 'rgba(242, 242, 242, 0.8)' : 'rgba(0, 0, 0, 0.12);'}`,
      borderRadius: '8px',
      fontSize: '16px',
      fontWeight: '400',
      textTransform: 'capitalize',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0',
    },
  },
  title: {
    paddingBottom: '24px',
    fontSize: '3rem',
    fontWeight: 700,
    color: theme.palette.text.primary,
    textTransform: 'capitalize',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  text: {
    maxWidth: '610px',
    textAlign: 'center',
    fontSize: '1.25rem',
    lineHeight: '24px',
  },
  button: {
    margin: '48px 16px 0',
    background: theme.palette.type === 'dark' ? '#406BE1' : 'linear-gradient(90deg, #0035FF 0%, #00C1FF 100%)',
    color: '#FFFFFF',
    fontWeight: '700',
    borderRadius: '8px',
    minWidth: '155px',
    textTransform: 'capitalize',
    padding: '9px 48px!important',
    '& .MuiButton-label': {
      fontSize: '24px',
    },
  },
  lowScore: {
    color: '#FE4A5E',
  },
}));

const Dashboard = ({
  lastUserTestResult,
  hasPassTestFirstTime,
  isProcessing,
  isDarkTheme,
  getLastUserTest,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    getLastUserTest();
  }, []);

  const focusPoints = lastUserTestResult.focusPoints;

  const { messageIconSrc, improveIconSrc } = isDarkTheme
    ? { messageIconSrc: '/images/icon-message-write.svg', improveIconSrc: '/images/icon-improve.svg' }
    : { messageIconSrc: '/images/icon-message-write-blue.svg', improveIconSrc: '/images/icon-improve-light.svg' };

  return hasPassTestFirstTime ? (
    <>
      <Helmet>
        <title>Myndful</title>
      </Helmet>
      <Box className={classes.container}>
        <Box className={classes.focusPoints}>
          <div className={classes.scoreContainer}>
            <div className={classes.scoreInner}>
              {isProcessing ? (
                <Preloader isBgTransparent />
              ) : (
                <>
                  <div className={classes.scoreTitle}>{t('dashboard_total_score')}</div>
                  <ScoreResult
                    userResult={lastUserTestResult.userResult}
                    maxResult={lastUserTestResult.maxResult}
                    size={200}
                    showMaxResult
                  />
                  <div className={classes.scoreDate}>
                    <span>{t('last_update')}:&nbsp;</span>
                    <span>
                      {lastUserTestResult.createdAt && moment(lastUserTestResult.createdAt).format('DD MMM YYYY')}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
          {focusPoints?.map((item, index) => {
            return (
              index < 3 && (
                <div key={`focus-point-${index}`} className={classes.scoreContainer}>
                  <div className={classes.scoreInner}>
                    <div className={classes.scoreTitle}>
                      <SectionTitle sectionName={item.sectionName} />
                      {item.sectionName}
                    </div>
                    <ScoreResultSmall userResult={item.result} maxResult={item.maxResult} size={155} showMaxResult />
                    <div
                      className={classNames(classes.scoreText, {
                        [classes.lowScore]: item.notification?.type === FOCUS_POINT_LOW_TYPE,
                      })}
                    >
                      {item.notification?.text}
                    </div>
                    <div className={classes.improveBlock}>
                      <Link to={`/how-to-improve/${item.sectionName}/`} className={classes.scoreImprove}>
                        <img src={improveIconSrc} alt="icon star" width="17" height="17" />
                        {t('dashboard_how_to_improve')}
                      </Link>
                    </div>
                  </div>
                </div>
              )
            );
          })}
        </Box>
        <Box className={classes.questionnaire}>
          <Typography component="div" variant="h6" className={classes.titleContainer} style={{ width: '100%' }}>
            {t('dashboard_daily_questionnaire')}
          </Typography>
          <QuestionnaireGroup />
        </Box>
        <Box className={classes.questionnaire}>
          <Typography
            component="div"
            variant="h6"
            className={classes.titleContainer}
            style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            {t('m_achievements')}
            <Link className="achievments-btn" to={'/dashboard/achievements'}>
              {t('m_achievements_view_badges')}
            </Link>
          </Typography>
          <AchievementsGroup />
        </Box>
        <UserTestCharts />
      </Box>
    </>
  ) : (
    <>
      <Helmet>
        <title>Myndful</title>
      </Helmet>
      <div className={classNames(classes.container, classes.flexJustify)}>
        <div />
        <Box align="center" width="100%">
          <img src={messageIconSrc} alt="icon message write" className={classes.iconMessage} width="53" height="53" />
          <div className={classes.title}>{t('dashboard_questionnaire')}</div>
          <div className={classes.text}>{t('dashboard_questionnaire_text')}</div>
          <Link to={'/dashboard/questionnaire/new'}>
            <Button color="primary" variant="contained" className={classes.button}>
              {t('start')}
            </Button>
          </Link>
          <Notice />
        </Box>
      </div>
    </>
  );
};

Dashboard.propTypes = {
  userTestList: PropTypes.array.isRequired,
  lastUserTestResult: PropTypes.object.isRequired,
  hasPassTestFirstTime: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  error: PropTypes.object.isRequired,
  getLastUserTest: PropTypes.func.isRequired,
  isDarkTheme: PropTypes.bool.isRequired,
  showModal: PropTypes.func,
};

export default connect(
  (state) => ({
    userTestList: state.dashboard.userTestList,
    lastUserTestResult: state.dashboard.lastUserTestResult,
    hasPassTestFirstTime: state.profile.data.hasPassTestFirstTime,
    currentPlan: state.profile.data.currentPlan,
    isProcessing: state.dashboard.processing,
    error: state.dashboard.error || {},
    isDarkTheme: state.general.isDarkTheme,
  }),
  {
    getLastUserTest: getLastUserTestRequest,
  }
)(Dashboard);
