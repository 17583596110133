import React from 'react';
import PropTypes from 'prop-types';

const IconClose = ({ size = 24, currentColor }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill={currentColor} xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
        fill={currentColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.7071 5.29289C18.3166 4.90237 17.6834 4.90237 17.2929 5.29289L5.29289 17.2929C4.90237 17.6834 4.90237 18.3166 5.29289 18.7071C5.68342 19.0976 6.31658 19.0976 6.70711 18.7071L18.7071 6.70711C19.0976 6.31658 19.0976 5.68342 18.7071 5.29289Z"
        fill={currentColor}
      />
    </svg>
  );
};

IconClose.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

export default IconClose;
