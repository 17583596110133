export const GET_AVAILABLE_SUBSCRIPTIONS_REQUEST = 'GET_AVAILABLE_SUBSCRIPTIONS_REQUEST';
export const GET_AVAILABLE_SUBSCRIPTIONS_SUCCESS = 'GET_AVAILABLE_SUBSCRIPTIONS_SUCCESS';

export const GET_SUBSCRIPTION_PUBLIC_KEY_REQUEST = 'GET_SUBSCRIPTION_PUBLIC_KEY_REQUEST';
export const GET_SUBSCRIPTION_PUBLIC_KEY_SUCCESS = 'GET_SUBSCRIPTION_PUBLIC_KEY_SUCCESS';

export const CREATE_USER_SUBSCRIPTION_REQUEST = 'CREATE_USER_SUBSCRIPTION_REQUEST';
export const CREATE_USER_SUBSCRIPTION_SUCCESS = 'CREATE_USER_SUBSCRIPTION_SUCCESS';

export const CREATE_USER_ANONYMOUS_SUBSCRIPTION_REQUEST = 'CREATE_USER_ANONYMOUS_SUBSCRIPTION_REQUEST';
export const CREATE_USER_ANONYMOUS_SUBSCRIPTION_SUCCESS = 'CREATE_USER_ANONYMOUS_SUBSCRIPTION_SUCCESS';

export const DELETE_USER_SUBSCRIPTION_REQUEST = 'DELETE_USER_SUBSCRIPTION_REQUEST';
export const DELETE_USER_SUBSCRIPTION_SUCCESS = 'DELETE_USER_SUBSCRIPTION_SUCCESS';

export const SUBSCRIPTION_REQUEST_ERROR = 'SUBSCRIPTION_REQUEST_ERROR';
export const CLEAR_ERROR = 'CLEAR_SUBSCRIPTION_ERROR';

export const TOGGLE_PROCESSING = 'TOGGLE_SUBSCRIPTION_PROCESSING';
