import axios from './axios';
import { API_URL } from '../constants';

const subscription = {
  async getAvailableSubscriptions() {
    try {
      const result = await axios.get(`${API_URL}/subscriptions/`);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getSubscriptionPublicKey() {
    try {
      const result = await axios.get(`${API_URL}/subscriptions/public-key/`);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async createUserSubscription(data) {
    try {
      const result = await axios.post(`${API_URL}/user-subscriptions/`, data);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async createAnonymousUserSubscription(data) {
    try {
      const result = await axios.post(`${API_URL}/focus-point-articles/anonymous-subscription/`, data);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async deleteUserSubscription(data) {
    try {
      const result = await axios.delete(`${API_URL}/user-subscriptions/cancel/`, data);

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default subscription;
