import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, DialogActions, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';

import StripeInput from './StripeInput';
import { useDispatch, useSelector, connect } from 'react-redux';
import { clearErrorAction } from '../../../redux/subscription/subscriptionActions';

const useStyles = makeStyles((theme) => ({
  errorText: {
    fontSize: '0.75rem',
    color: '#f44336',
    marginLeft: 4,
    marginRight: 4,
    height: '1rem',
  },
  saveButton: {
    background: theme.palette.type === 'dark' ? '#406BE1' : 'linear-gradient(90deg, #0035FF 0%, #00C1FF 100%)',
  },
}));

const StripeForm = ({ email, isProcessing, disabled, hideModal, onSubmit, isDarkTheme }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [cardNumberError, setCardNumberError] = useState(null);
  const [expiryDateError, setExpiryDateError] = useState(null);
  const [cardCvcError, setCardCvcError] = useState(null);
  const [processing, setProcessing] = useState(null);

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.subscription);
  const onCancel = () => {
    if (error) {
      dispatch(clearErrorAction());
    }
    hideModal();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    try {
      const response = await stripe.createSource(elements.getElement(CardNumberElement), { owner: { email: email } });

      if (response.error) {
        const { code, message } = response.error;

        switch (code) {
          case 'incomplete_number':
            setCardNumberError(message);
            break;
          case 'incomplete_expiry':
            setExpiryDateError(message);
            break;
          case 'incomplete_cvc':
            setCardCvcError(message);
            break;
          default:
            console.error(response.error);
        }
        console.warn(response.error);
      } else {
        onSubmit(response);
      }
    } catch (e) {
      console.error(e);
    }

    setProcessing(false);
  };

  const options = {
    style: {
      base: {
        color: isDarkTheme ? '#FFFFFF' : '#000000',
      },
      '::placeholder': {
        color: 'rgba(255, 255, 255, 0.7)',
      },
    },
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box className="form-input__group">
        <TextField
          variant="filled"
          error={!!cardNumberError}
          helperText={cardNumberError}
          fullWidth
          required
          id="cardNumber"
          label={t('form_label_card_number')}
          name="cardNumber"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardNumberElement,
              options: options,
            },
          }}
          onChange={() => setCardNumberError(null)}
        />
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Box className="form-input__group">
            <TextField
              variant="filled"
              error={!!expiryDateError}
              helperText={expiryDateError}
              fullWidth
              required
              id="expiryDate"
              label={t('form_label_expiration_date')}
              name="expiryDate"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardExpiryElement,
                  options: options,
                },
              }}
              onChange={() => setExpiryDateError(null)}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className="form-input__group">
            <TextField
              variant="filled"
              error={!!cardCvcError}
              helperText={cardCvcError}
              fullWidth
              required
              id="cardCvc"
              label="CVC"
              name="cardCvc"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardCvcElement,
                  options: options,
                },
              }}
              onChange={() => setCardCvcError(null)}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.errorText}>
            {error && error?.subscription?.length > 0 ? error.subscription[0] : ''}
          </Typography>
        </Grid>
      </Grid>
      <DialogActions>
        <Button disabled={isProcessing || processing} color="primary" onClick={onCancel}>
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          className={classes.saveButton}
          disabled={isProcessing || processing || disabled || !stripe || !elements}
          variant="contained"
          color="primary"
        >
          {t('save')}
        </Button>
      </DialogActions>
    </form>
  );
};

StripeForm.propTypes = {
  email: PropTypes.string.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isDarkTheme: PropTypes.bool.isRequired,
};

export default connect((state) => ({
  isDarkTheme: state.general.isDarkTheme,
}))(StripeForm);
