import React from 'react';
import PropTypes from 'prop-types';

const IconSend = ({ size = 24, className }) => {
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.6211 2.65271C20.2053 2.2001 21.6698 3.66468 21.2172 5.24882L17.2176 19.2475C16.5509 21.5809 13.434 22.0192 12.1496 19.9602L9.3522 15.4754C9.11015 15.0874 8.78257 14.7598 8.39452 14.5177L3.90977 11.7203C1.85072 10.436 2.28902 7.31902 4.62243 6.65233L18.6211 2.65271ZM19.2232 4.57327C19.213 4.57043 19.1975 4.56806 19.1706 4.57576L5.17187 8.57538C4.50518 8.76586 4.37995 9.65642 4.96825 10.0234L9.45301 12.8208C10.0998 13.2242 10.6457 13.7702 11.0491 14.4169L13.8466 18.9017C14.2135 19.49 15.1041 19.3648 15.2946 18.6981L19.2942 4.69938C19.3019 4.67244 19.2995 4.65696 19.2967 4.64675C19.2929 4.63336 19.2842 4.61669 19.2687 4.6012C19.2532 4.5857 19.2366 4.57701 19.2232 4.57327Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.435 9.43449C14.8255 9.82502 14.8255 10.4582 14.435 10.8487L10.8995 14.3842C10.509 14.7748 9.8758 14.7748 9.48528 14.3842C9.09475 13.9937 9.09475 13.3606 9.48528 12.97L13.0208 9.43449C13.4113 9.04397 14.0445 9.04397 14.435 9.43449Z"
      />
    </svg>
  );
};

IconSend.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

export default IconSend;
